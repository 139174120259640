import React from "react";
import './Art.css'


function art() {
  return (
    <>
    <div className="illustration_div">
      <div className="illustration_div-2">
        <div className="illustration_div-3">
          <div className="illustration_div-4">One stop solution for all your needs!</div>
          <div className="illustration_div-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d3cb09dc-3561-4ad7-b99b-4859784e562a?"
              className="illustration_img"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f078ffe8-ee22-425d-9b50-8a5d23ae2fcf?"
              className="illustration_img"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9113e146-4edd-4d6d-a3fa-a1c157599144?"
              className="illustration_img-2"
            />
          </div>
        </div>
        <div className="illustration_div-6">
          <div className="illustration_div-7">
            <div className="illustration_div-8">Your work</div>
            <div className="illustration_div-9">Designers</div>
            <div className="illustration_div-10">Categories</div>
            <div className="illustration_div-11">Inspiration</div>
          </div>
        </div>
      </div>
      <div className="illustration_div-12">
        <div className="illustration_div-13">What do you need designed?</div>
        <div className="illustration_div-14">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/902de5d2-4522-4a93-9d87-ea6a799d94fb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/902de5d2-4522-4a93-9d87-ea6a799d94fb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/902de5d2-4522-4a93-9d87-ea6a799d94fb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/902de5d2-4522-4a93-9d87-ea6a799d94fb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/902de5d2-4522-4a93-9d87-ea6a799d94fb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/902de5d2-4522-4a93-9d87-ea6a799d94fb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/902de5d2-4522-4a93-9d87-ea6a799d94fb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/902de5d2-4522-4a93-9d87-ea6a799d94fb?apiKey=db6f3fe714c542fe811ae992d63ea686&"
            className="illustration_img-3"
          />
          <div className="illustration_div-15">Web & app design</div>
          <div className="illustration_div-16">Business & advertising</div>
          <div className="illustration_div-17">Clothing & merchandise</div>
          <div className="illustration_div-18">
            <div className="illustration_div-19">Art & illustration</div>
          </div>
        </div>
      </div>
      <div className="illustration_div-20">
        <div className="illustration_div-21">
          <div className="illustration_div-22">
            <div className="illustration_div-23">
              <img
                loading="lazy"
                srcSet="..."
                className="illustration_img-4"
              />
              <div className="illustration_div-24">
                Illustration or
                <br />
                graphics
              </div>
              <div className="illustration_div-25">
                Stunning illustrations and graphics that will draw attention
              </div>
            </div>
            <div className="illustration_div-26" />
          </div>
          <div className="illustration_div-27">
            <img
              loading="lazy"
              srcSet="..."
              className="illustration_img-5"
            />
            <div className="illustration_div-28">Business Illustration</div>
            <div className="illustration_div-29">
              Business illustrations to instruct or delight
            </div>
          </div>
          <div className="illustration_div-30" />
          <div className="illustration_div-31">
            <img
              loading="lazy"
              srcSet="..."
              className="illustration_img-6"
            />
            <div className="illustration_div-32">
              Website <br />
              Illustration
            </div>
            <div className="illustration_div-33">
              Website illustrations that paints a picture
            </div>
          </div>
          <div className="illustration_div-30" />
          <div className="illustration_div-30" />
          <div className="illustration_div-34">
            <img
              loading="lazy"
              srcSet="..."
              className="illustration_img-7"
            />
            <div className="illustration_div-35">Book Illustration</div>
            <div className="illustration_div-36">
              A book illustration that captures readers imagination
            </div>
          </div>
        </div>
        <div className="bottom_line" />
        <div className="illustration_div-37">
          <div className="illustration_div-38">
            <div className="column">
              <div className="illustration_div-39">
                <img
                  loading="lazy"
                  srcSet="..."
                  className="illustration_img-8"
                />
                <div className="illustration_div-40">
                  <div className="illustration_div-41">
                    <div className="illustration_column-2">
                      <div className="illustration_div-42">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-9"
                        />
                        <div className="illustration_div-43">
                          Wedding
                          <br />
                          Invitation
                        </div>
                        <div className="illustration_div-44">
                          The perfect wedding invitation for your big day
                        </div>
                      </div>
                    </div>
                    <div className="illustration_div-30" />
                    <div className="illustration_column-3">
                      <div className="illustration_div-45">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-10"
                        />
                        <div className="illustration_div-46">
                          Character or
                          <br />
                          mascot
                        </div>
                        <div className="illustration_div-47">
                          A character or mascot <br />
                          with personality
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="illustration_div-48">
                  <div className="illustration_div-49">
                    <div className="illustration_column-4">
                      <div className="illustration_div-50">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-11"
                        />
                        <div className="illustration_div-51">
                          3D Architectural Rendering
                        </div>
                        <div className="illustration_div-52">
                          3D architectural rendering to bring your project to
                          life
                        </div>
                      </div>
                    </div>
                    <div className="illustration_div-30" />
                    <div className="illustration_column-5">
                      <div className="illustration_div-53">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-12"
                        />
                        <div className="illustration_div-54">
                          Other art or illustration
                        </div>
                        <div className="illustration_div-55">
                          Art and illustration so
                          <br />
                          creative you can’t <br />
                          categorize it
                        </div>
                      </div>
                    </div>
                    <div className="illustration_div-30" />
                  </div>
                </div>
              </div>
            </div>
            <div className="illustration_column-6">
              <div className="illustration_div-56">
                <div className="illustration_div-57">
                  <div className="illustration_div-58">
                    <div className="illustration_column-7">
                      <div className="illustration_div-59">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-13"
                        />
                        <div className="illustration_div-60">Pattern</div>
                        <div className="illustration_div-61">
                          Patterns that make a statement
                        </div>
                      </div>
                    </div>
                    <div className="illustration_div-30" />
                    <div className="illustration_column-8">
                      <div className="illustration_div-62">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-14"
                        />
                        <div className="illustration_div-63">Card or invitation</div>
                        <div className="illustration_div-64">
                          Card or invitation design that sounds a message
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom_line" />
                <div className="illustration_div-65">
                  <div className="illustration_div-66">
                    <div className="illustration_column-9">
                      <div className="illustration_div-67">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-15"
                        />
                        <div className="illustration_div-68">Invitation</div>
                        <div className="illustration_div-69">
                          An invitation that gets RSVP’s
                        </div>
                      </div>
                    </div>
                    <div className="illustration_div-30" />
                    <div className="illustration_column-10">
                      <div className="illustration_div-70">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-16"
                        />
                        <div className="illustration_div-71">Greeting Card</div>
                        <div className="illustration_div-72">
                          A greeting card that brightens the receiver’s day
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom_line" />
                <div className="illustration_div-73">
                  <div className="illustration_div-74">
                  <div className="illustration_div-30" />
                  <div className="illustration_div-30" />
                    <div className="illustration_column-11">
                      <div className="illustration_div-75">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-17"
                        />
                        <div className="illustration_div-76">Tattoo</div>
                        <div className="illustration_div-77">
                          Tattoo designs that’ll <br />
                          make grandma jealous
                        </div>
                      </div>
                    </div>
                    <div className="illustration_div-30" />
                    <div className="illustration_column-12">
                      <div className="illustration_div-78">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="illustration_img-18"
                        />
                        <div className="illustration_div-79">3D</div>
                        <div className="illustration_div-80">
                          3D digital design that takes it to the next
                          dimension
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_line" />
      </div>
      
      <div className="illustration_div-81">
        <div className="illustration_div-82">
          Still haven’t found what you’re looking for? Search and you shall
          find
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/5668bf44-b0d7-4ee7-a571-f7a2210579aa?"
          className="illustration_img-19"
        />
      </div>
      <div className="illustration_div-83">
        <div className="illustration_div-84">
          <div className="illustration_div-85">
            <div className="illustration_column-13">
              <div className="illustration_div-86">
                <div className="illustration_div-87">
                  <div className="column">
                    <div className="illustration_div-88">
                      <div className="illustration_div-89">Company</div>
                      <div className="illustration_div-90">About</div>
                      <div className="illustration_div-91">Contact</div>
                      <div className="illustration_div-92">Careers</div>
                      <div className="illustration_div-93">Team</div>
                      <div className="illustration_div-94">Press releases</div>
                      <div className="illustration_div-95">In the media</div>
                      <div className="illustration_div-96">Testimonals</div>
                    </div>
                  </div>
                  <div className="illustration_column-14">
                    <div className="illustration_div-97">
                      <div className="illustration_div-98">Design services</div>
                      <div className="illustration_div-99">Design contests</div>
                      <div className="illustration_div-100">1-to-1 projects</div>
                      <div className="illustration_div-101">Find a designer</div>
                      <div className="illustration_div-102">Discover inspiration</div>
                      <div className="illustration_div-103">Pricing</div>
                      <div className="illustration_div-104">Agencies</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="illustration_column-15">
              <div className="illustration_div-105">
                <div className="illustration_div-106">
                  <div className="illustration_column-16">
                    <div className="illustration_div-107">
                      <div className="illustration_div-108">Get a design</div>
                      <div className="illustration_div-109">Logo design</div>
                      <div className="illustration_div-110">Business card</div>
                      <div className="illustration_div-111">Web page design</div>
                      <div className="illustration_div-112">Brand guide</div>
                      <div className="illustration_div-113">Packaging design</div>
                      <div className="illustration_div-114">T-Shirt design</div>
                      <div className="illustration_div-115">Book cover design</div>
                      <div className="illustration_div-116">Browse all categories</div>
                    </div>
                  </div>
                  <div className="illustration_column-17">
                    <div className="illustration_div-117">
                      <div className="illustration_div-118">Resources</div>
                      <div className="illustration_div-119">Become a designer</div>
                      <div className="illustration_div-120">Blog</div>
                      <div className="illustration_div-121">Awards</div>
                      <div className="illustration_div-122">Affiliates</div>
                      <div className="illustration_div-123">Logo - ideas</div>
                      <div className="illustration_div-124">T-shirt ideas</div>
                      <div className="illustration_div-125">Designer resources</div>
                      <div className="illustration_div-126">Featured Partners</div>
                      <div className="illustration_div-127">Help</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="illustration_div-128">
          <div className="illustration_div-129">Help?</div>
        </div>
        <div className="illustration_div-130" />
      </div>
    </div>
    
  
    </>
  );
}

export default art;

// EnvelopsContext.js
import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const T_ShirtContext = createContext();

export const T_ShirtContextProvider = ({ children }) => {
  const [t_shirt, setT_Shirt] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Product/Products/t-shirts').get();
        const t_shirtData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));
        setT_Shirt(t_shirtData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <T_ShirtContext.Provider value={{ t_shirt }}>
      {children}
    </T_ShirtContext.Provider>
  );
};

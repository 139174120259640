import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const Accessories_Data_Context = createContext();

export const Accessories_Data_ContextProvider = ({ children }) => {
  const [accessories_data, setAccessories_Data] = useState([]); // Corrected the state variable name

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('School Section/Category Display/School Accessories').get();
        const accessoriesData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));
        setAccessories_Data(accessoriesData); // Corrected the state setter function
      } catch (error) {
        console.error('Error:', error);
      }
    };


    
    fetchData();
  }, []);

  return (
    <Accessories_Data_Context.Provider value={{ accessories_data }}>
      {children}
    </Accessories_Data_Context.Provider>
  );
};


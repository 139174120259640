import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
function About_Us(props) {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  /*useEffect(() => {
    
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

    // Redirect to the mobile version if on a mobile device
    if (isMobile) {
      window.location.href = '/School_Services'; // Replace with the actual URL for your mobile version
    }
  }, []); */
  
  return (
    <>

<>
      <Helmet>
        <title>Custoking - About Us: Transforming School Experience</title>
        <meta name="description" content="Discover how Custoking transforms school experiences! Our tailored services, from uniforms to ID cards, enhance brand images. Dive into a world of customized solutions, uniquely designed for every school's identity and excellence" />
        {/* Add more meta tags as needed */}
      </Helmet>
      {/* Other content of your component */}
    </>
    <div className="about_us_div">
      <div className="about_us_div-7">

        <div className="about_us_div-8">About Us</div>
        <div className="about_us_div-9">
          From preschool to pre-tertiary, all your school supplies under one
          umbrella
        </div>
        {/*<div className="about_us_div-10">See More</div>*/}
      </div>
      <div className="about_us_div-11">
        Services Helping School to enhance brand image
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/845eb5f8-402a-4a56-ae88-127f3cde886a?apiKey=db6f3fe714c542fe811ae992d63ea686&"
        className="about_us_img-2"
      />
      <div className="about_us_div-12">
        <div className="about_us_div-13">
          <div className="about_us_column">
            <div className="about_us_div-14">
              <div className="about_us_div-15">Uniforms / Stationary / Accessories</div>
              <div className="about_us_div-16">
                Source high quality school uniforms , writing supplies and
                school accessories at one place to reduce hassle of going
                through multiple vendors and paying high margins and we
                provide straight from factory{" "}
              </div>
              <Link to = '/Second_Page' style={{ textDecoration: 'none' }}  className="about_us_div-17">
                <div className="about_us_div-18">View More</div>

              </Link>
            </div>
          </div>
          <div className="about_us_column-2">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f5f9338b-a9d1-4f05-a15b-4f0e7e9e9464?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5f9338b-a9d1-4f05-a15b-4f0e7e9e9464?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5f9338b-a9d1-4f05-a15b-4f0e7e9e9464?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5f9338b-a9d1-4f05-a15b-4f0e7e9e9464?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5f9338b-a9d1-4f05-a15b-4f0e7e9e9464?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5f9338b-a9d1-4f05-a15b-4f0e7e9e9464?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5f9338b-a9d1-4f05-a15b-4f0e7e9e9464?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5f9338b-a9d1-4f05-a15b-4f0e7e9e9464?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="about_us_img-4"
            />
          </div>
        </div>
      </div>
      <div className="about_us_div-19">
        <div className="about_us_div-20">
          <div className="about_us_column-3">
            <div className="about_us_div-21">

              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3f12c096-3466-47b4-9e0b-ab4385258932?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3f12c096-3466-47b4-9e0b-ab4385258932?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3f12c096-3466-47b4-9e0b-ab4385258932?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3f12c096-3466-47b4-9e0b-ab4385258932?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3f12c096-3466-47b4-9e0b-ab4385258932?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3f12c096-3466-47b4-9e0b-ab4385258932?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3f12c096-3466-47b4-9e0b-ab4385258932?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3f12c096-3466-47b4-9e0b-ab4385258932?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="about_us_img-5"
              />
            </div>
          </div>
          <div className="about_us_column-4">
            <div className="about_us_div-22">
              <div className="about_us_div-23">
                Get all your customised marketing and printing supplies{" "}
              </div>
              <div className="about_us_div-24">
                Source school printing and marketing materials of schools with impressive design to enhance the school image and boost school admissions.
              </div>
              <Link to = '/Apparel_Design' style={{ textDecoration: 'none' }} className="about_us_div-25">
                <div className="about_us_div-26">View More</div>

              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="about_us_div-27">
        <div className="about_us_div-28">
          <div className="about_us_column-5">
            <div className="about_us_div-29">
              <div className="about_us_div-30">
                Hosting events was never so easy !!
              </div>
              <div className="about_us_div-31">
                <div >
                Unlock the magic of seamless school events with our bespoke event items, accompanied by complimentary event photography sessions. Say goodbye to the hassle and hello to elevated student engagement in every school event.
                </div>
              </div>
              <Link to = '/PartnerProgram' style={{ textDecoration: 'none' }} className="about_us_div-32">
                <div className="about_us_div-33">View More</div>

              </Link>
            </div>
          </div>
          <div className="about_us_column-6">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/bded921c-8a92-41ab-9624-e86cdc60af88?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="about_us_img-8"
            />
          </div>
        </div>
      </div>
      <div className="about_us_div-34">
        <div className="about_us_div-35">Founders</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/370a7463-fcc1-47cc-b326-7074c343b75f?apiKey=db6f3fe714c542fe811ae992d63ea686&"
          className="about_us_img-9"
        />
        <div className="about_us_div-36">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2991045b-3222-4a12-a11f-a9ccd54599f3?apiKey=db6f3fe714c542fe811ae992d63ea686&"
            className="about_us_img-10"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0d08e8eb-94d2-42e5-83c5-545defefe0c4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d08e8eb-94d2-42e5-83c5-545defefe0c4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d08e8eb-94d2-42e5-83c5-545defefe0c4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d08e8eb-94d2-42e5-83c5-545defefe0c4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d08e8eb-94d2-42e5-83c5-545defefe0c4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d08e8eb-94d2-42e5-83c5-545defefe0c4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d08e8eb-94d2-42e5-83c5-545defefe0c4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d08e8eb-94d2-42e5-83c5-545defefe0c4?apiKey=db6f3fe714c542fe811ae992d63ea686&"
            className="about_us_img-11"
          />
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5e55df67-5238-4318-94a8-be1f6a4ada9f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e55df67-5238-4318-94a8-be1f6a4ada9f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e55df67-5238-4318-94a8-be1f6a4ada9f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e55df67-5238-4318-94a8-be1f6a4ada9f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e55df67-5238-4318-94a8-be1f6a4ada9f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e55df67-5238-4318-94a8-be1f6a4ada9f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e55df67-5238-4318-94a8-be1f6a4ada9f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e55df67-5238-4318-94a8-be1f6a4ada9f?apiKey=db6f3fe714c542fe811ae992d63ea686&"
            className="about_us_img-12"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6079cdf6-c0c4-41e1-a975-9d282358526c?apiKey=db6f3fe714c542fe811ae992d63ea686&"
            className="about_us_img-13"
          />
        </div>
      </div>
      <div className="about_us_div-37">Why it’s works</div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddb21d9d-978c-4d9e-829e-bf148c1fdd69?apiKey=db6f3fe714c542fe811ae992d63ea686&"
        className="about_us_img-14"
      />
      <div className="about_us_div-38">
        <div className="about_us_div-39">
          <div className="about_us_column-7">
            <div className="about_us_div-40">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c0a2e602-ebb3-4b41-837b-336ae16b1538?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="about_us_img-15"
              />
              <div className="about_us_div-41">Lowest Prices</div>
              <div className="about_us_div-42">
                Get high quality school essentials at the best price with customizations
              </div>
            </div>
          </div>
          <div className="about_us_column-8">
            <div className="about_us_div-43">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/83f1b9bc-2ba1-432d-92b2-8e1c37d9660a?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="about_us_img-16"
              />
              <div className="about_us_div-44">One Stop Solution</div>
              <div className="about_us_div-45">
                Get all school necessities of the school at one place with a unique touch of customization for every school
              </div>
            </div>
          </div>
          <div className="about_us_column-9">
            <div className="about_us_div-46">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d67bcbb0-5df7-484c-ba6f-0a977db214b9?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="about_us_img-17"
              />
              <div className="about_us_div-47">Tools to empower teachers</div>
              <div className="about_us_div-48">
                Teachers would find all the study materials right at the school for student activities
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about_us_div-49" />
    </div>
    <style jsx>{`
      .about_us_div {
        background-color: #ffffe9;
        display: flex;
        
        flex-direction: column;
      }
      .about_us_div-2 {
        background-color: #cbcda9;
        align-self: stretch;
        display: flex;
        min-height: 53px;
        width: 100%;
        flex-direction: column;
      }
      @media (max-width: 991px) {
        .about_us_div-2 {
          max-width: 100%;
        }
      }
      .about_us_div-3 {
        align-self: center;
        display: flex;
        margin-top: 35px;
        width: 304px;
        max-width: 100%;
        justify-content: space-between;
        gap: 20px;
        padding: 0 20px;
      }
      @media (max-width: 991px) {
        .about_us_div-3 {
          justify-content: center;
        }
      }
      .about_us_div-4 {
        color: #000;
        font: 500 18px/22px Inter, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      .about_us_div-5 {
        color: #000;
        font: 700 18px/22px Inter, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      .about_us_div-6 {
        color: #000;
        white-space: nowrap;
        font: 500 18px/22px Inter, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-6 {
          white-space: initial;
        }
      }
      .about_us_div-7 {
        disply: flex;
        background: url('https://cdn.builder.io/api/v1/image/assets/TEMP/2da79c98-ade2-4849-80d3-28f63b7865fb?apiKey=db6f3fe714c542fe811ae992d63ea686&');
        flex-direction: column;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        align-self: stretch;
        position: relative;
        display: flex;
        min-height: 450px;
    
        width: 100%;
        align-items: center;
        padding: 0 0 10px;
      }
      @media (max-width: 991px) {
        .about_us_div-7 {
          max-width: 100%;
          margin-top: 0;
          padding: 0 20px;
        }
      }
      .about_us_img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
      .about_us_div-8 {
        position: relative;
        color: #fff;
        margin-top: 73px;
        white-space: nowrap;
        font: 700 96px/120px Source Serif Pro, -apple-system, Roboto,
          Helvetica, sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-8 {
          font-size: 40px;
          line-height: 56px;
          margin-top: 40px;
          white-space: initial;
        }
      }
      .about_us_div-9 {
        position: relative;
        color: #fff;
        text-align: center;
        max-width: 663px;
        font: 600 24px/29px Lato, sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-9 {
          max-width: 100%;
        }
      }
      .about_us_div-10 {
        position: relative;
        color: #000;
        text-align: center;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #f3f25b;
        width: 135px;
        max-width: 100%;
        margin: 35px 0 73px;
        padding: 9px 20px;
        font: 600 18px/22px Lato, sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-10 {
          white-space: initial;
          margin-bottom: 40px;
        }
      }
      .about_us_div-11 {
        color: #000;
        align-self: center;
        margin-top: 88px;
        white-space: nowrap;
        font: 600 45px/56px Source Serif Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-11 {
          max-width: 100%;
          padding:10px;
          font-size: 40px;
          line-height: 55px;
          margin-top: 40px;
          white-space: initial;
        }
      }
      .about_us_img-2 {
        aspect-ratio: 7.1;
        object-fit: contain;
        object-position: center;
        width: 284px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        align-self: center;
        margin-top: 15px;
        max-width: 100%;
      }
      .about_us_div-12 {
        align-self: center;
        margin-top: 13px;
        width: 100%;
        max-width: 1118px;
      }
      @media (max-width: 991px) {
        .about_us_div-12 {
          max-width: 100%;
        }
      }
      .about_us_div-13 {
        gap: 20px;
        display: flex;
      }
      @media (max-width: 991px) {
        .about_us_div-13 {
          flex-direction: column;
          align-items: stretch;
          gap: 0px;
        }
      }
      .about_us_column {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 50%;
        margin-left: 0px;
      }
      @media (max-width: 991px) {
        .about_us_column {
          width: 100%;
        }
      }
      .about_us_div-14 {
        align-items: start;
        display: flex;
        flex-direction: column;
        margin: auto 0;
        padding: 0 20px;
      }
      @media (max-width: 991px) {
        .about_us_div-14 {
          max-width: 100%;
          margin-top: 40px;
        }
      }
      .about_us_div-15 {
        color: #000;
        align-self: stretch;
        margin-right: -20px;
        
        font: 600 36px/45px Source Serif Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-15 {
          max-width: 100%;
          white-space: normal;
          margin-right: 0;
          align-self: center;
          
        }
      }
      .about_us_div-16 {
        color: #848383;
        align-self: stretch;
        margin: 25px -20px 0 0;
        font: 400 20px/24px Lato, sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-16 {
          max-width: 100%;
        }
      }
      .about_us_div-17 {
        border-radius: 12px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12),
          0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
        background-color: #f3f25b;
        display: flex;
        margin-top: 25px;
        width: 242px;
        text-align: center;
        max-width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 19px 0 0 80px;
      }
      @media (max-width: 991px) {
        .about_us_div-17 {
          padding-left: auto;
        }
      }
      .about_us_div-18 {
        color: #000;
        display: flex;
        text-align: center;
        align-items: center;
        margin: auto 0;
        padding-bottom: 15px;
        justify-content: center;
        font: 600 16px/19px Lato, sans-serif;
      }

      .about_us_img-3 {
        aspect-ratio: 1.79;
        object-fit: contain;
        object-position: center;
        width: 100%;
        filter: drop-shadow(0px -4px 3px rgba(0, 0, 0, 0.03));
        overflow: hidden;
        flex: 1;
      }
      .about_us_column-2 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 50%;
        margin-left: 20px;
      }
      @media (max-width: 991px) {
        .about_us_column-2 {
          width: 100%;
          margin-left: 0;
        }
      }
      .about_us_img-4 {
        aspect-ratio: 1.03;
        object-fit: contain;
        object-position: center;
        width: 100%;
        overflow: hidden;
        flex-grow: 1;
      }
      @media (max-width: 991px) {
        .about_us_img-4 {
          max-width: 100%;
          margin-top: 40px;
        }
      }
      .about_us_div-19 {
        align-self: center;
        margin-top: 29px;
        width: 100%;
        max-width: 1096px;
      }
      @media (max-width: 991px) {
        .about_us_div-19 {
          max-width: 100%;
        }
      }
      .about_us_div-20 {
        gap: 20px;
        display: flex;
      }
      @media (max-width: 991px) {
        .about_us_div-20 {
          flex-direction: column;
          align-items: stretch;
          gap: 0px;
        }
      }
      .about_us_column-3 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 46%;
        margin-left: 0px;
      }
      @media (max-width: 991px) {
        .about_us_column-3 {
          width: 100%;
        }
      }
      .about_us_div-21 {
        disply: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        display: flex;
        min-height: 512px;
        flex-grow: 1;
        align-items: start;
        padding: 50px 19px 6px;
      }
      @media (max-width: 991px) {
        .about_us_div-21 {
          max-width: 100%;
        }
      }
      .about_us_img-5 {
        aspect-ratio: 1.1;
        object-fit: contain;
        object-position: center;
        width: 100%;
        overflow: hidden;
        margin-top: 6px;
      }
      @media (max-width: 991px) {
        .about_us_img-5 {
          max-width: 100%;
        }
      }
      .about_us_column-4 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 54%;
        margin-left: 20px;
      }
      @media (max-width: 991px) {
        .about_us_column-4 {
          width: 100%;
          margin-left: 0;
        }
      }
      .about_us_div-22 {
        display: flex;
        margin-top: 89px;
        flex-direction: column;
        padding: 0 20px;
      }
      @media (max-width: 991px) {
        .about_us_div-22 {
          max-width: 100%;
          margin-top: 40px;
        }
      }
      .about_us_div-23 {
        color: #000;
        text-align: right;
        margin-right: -20px;
        font: 600 36px/45px Source Serif Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-23 {
          max-width: 100%;
          text-align: center;
        }
      }
      .about_us_div-24 {
        color: #848383;
        text-align: right;
        align-self: end;
        max-width: 528px;
        margin: 25px -20px 0 0;
        font: 400 20px/24px Lato, sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-24 {
          max-width: 100%;
          text-align: center;
          align-self: center;
          margin:auto;
        }
      }
      .about_us_div-25 {
        border-radius: 12px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12),
          0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
        background-color: #f3f25b;
        align-self: end;
        display: flex;
        width: 242px;
        max-width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        margin: 25px -20px 0 0;
        padding: 19px 0 0 80px;
      }
      @media (max-width: 991px) {
        .about_us_div-25 {
          padding-left: auto;
          align-self: center;
        }
      }
      .about_us_div-26 {
        color: #000;
        text-align: center;
        margin: auto 0;
        padding-bottom: 15px;
        font: 600 16px/19px Lato, sans-serif;
      }
      .about_us_img-6 {
        aspect-ratio: 1.79;
        object-fit: contain;
        object-position: center;
        width: 100%;
        filter: drop-shadow(0px -4px 3px rgba(0, 0, 0, 0.03));
        overflow: hidden;
        flex: 1;
      }
      .about_us_div-27 {
        align-self: center;
        margin-top: 51px;
        width: 100%;
        max-width: 1123px;
      }
      @media (max-width: 991px) {
        .about_us_div-27 {
          max-width: 100%;
          margin-top: 40px;
        }
      }
      .about_us_div-28 {
        gap: 20px;
        display: flex;
      }
      @media (max-width: 991px) {
        .about_us_div-28 {
          flex-direction: column;
          align-items: stretch;
          gap: 0px;
        }
      }
      .about_us_column-5 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 52%;
        margin-left: 0px;
      }
      @media (max-width: 991px) {
        .about_us_column-5 {
          width: 100%;
        }
      }
      .about_us_div-29 {
        align-items: start;
        display: flex;
        flex-direction: column;
        margin: auto 0;
        padding: 0 20px;
      }
      @media (max-width: 991px) {
        .about_us_div-29 {
          max-width: 100%;
          margin-top: 40px;
        }
      }
      .about_us_div-30 {
        color: #000;
        align-self: stretch;
        margin-right: -20px;
        white-space: nowrap;
        font: 600 36px/45px Source Serif Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-30 {
          max-width: 100%;
          white-space: initial;
        }
      }
      .about_us_div-31 {
        color: #848383;
        align-self: stretch;
        margin: 25px -20px 0 0;
        font: 400 20px/24px Lato, sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-31 {
          max-width: 100%;
        }
      }
      .about_us_div-32 {
        border-radius: 12px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12),
          0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
        background-color: #f3f25b;
        display: flex;
        margin-top: 25px;
        width: 242px;
        max-width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 19px 0 0 80px;
      }
      @media (max-width: 991px) {
        .about_us_div-32 {
          padding-left: auto;
        }
      }
      .about_us_div-33 {
        color: #000;
        text-align: center;
        margin: auto 0;
        font: 600 16px/19px Lato, sans-serif;
        padding-bottom: 15px;
      }
      .about_us_img-7 {
        aspect-ratio: 1.79;
        object-fit: contain;
        object-position: center;
        width: 100%;
        filter: drop-shadow(0px -4px 3px rgba(0, 0, 0, 0.03));
        overflow: hidden;
        flex: 1;
      }
      .about_us_column-6 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 48%;
        margin-left: 20px;
      }
      @media (max-width: 991px) {
        .about_us_column-6 {
          width: 100%;
          margin-left: 0;
        }
      }
      .about_us_img-8 {
        aspect-ratio: 1.02;
        object-fit: contain;
        object-position: center;
        width: 100%;
        overflow: hidden;
        flex-grow: 1;
      }
      @media (max-width: 991px) {
        .about_us_img-8 {
          max-width: 100%;
          margin-top: 35px;
        }
      }
      .about_us_div-34 {
        background-color: #fff;
        align-self: stretch;
        display: flex;
        margin-top: 43px;
        width: 100%;
        flex-direction: column;
        padding: 25px 33px 50px 34px;
      }
      @media (max-width: 991px) {
        .about_us_div-34 {
          max-width: 100%;
          margin-top: 40px;
          padding: 0 20px;
        }
      }
      .about_us_div-35 {
        color: #000;
        align-self: center;
        white-space: nowrap;
        font: 600 45px/56px Source Serif Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-35 {
          font-size: 40px;
          line-height: 55px;
          white-space: initial;
        }
      }
      .about_us_img-9 {
        aspect-ratio: 7.1;
        object-fit: contain;
        object-position: center;
        width: 284px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        align-self: center;
        margin-top: 15px;
        max-width: 100%;
      }
      .about_us_div-36 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        margin: 35px 0 29px;
      }
      @media (max-width: 991px) {
        .about_us_div-36 {
          max-width: 100%;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      .about_us_img-10 {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 45px;
        overflow: hidden;
        max-width: 100%;
        margin: auto 0;
      }
      .about_us_img-11 {
        aspect-ratio: 0.81;
        object-fit: contain;
        object-position: center;
        width: 100%;
        overflow: hidden;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: 0%;
      }
      @media (max-width: 991px) {
        .about_us_img-11 {
          max-width: 100%;
        }
      }
      .about_us_img-12 {
        aspect-ratio: 0.9;
        object-fit: contain;
        object-position: center;
        width: 100%;
        overflow: hidden;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: 0%;
      }
      @media (max-width: 991px) {
        .about_us_img-12 {
          max-width: 100%;
        }
      }
      .about_us_img-13 {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 45px;
        overflow: hidden;
        max-width: 100%;
        margin: auto 0;
      }
      .about_us_div-37 {
        color: #000;
        align-self: center;
        margin-top: 119px;
        white-space: nowrap;
        font: 600 45px/56px Source Serif Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-37 {
          font-size: 40px;
          line-height: 55px;
          margin-top: 40px;
          white-space: initial;
        }
      }
      .about_us_img-14 {
        aspect-ratio: 7.33;
        object-fit: contain;
        object-position: center;
        width: 293px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        align-self: center;
        margin-top: 15px;
        max-width: 100%;
      }
      .about_us_div-38 {
        align-self: center;
        margin-top: 20px;
        width: 100%;
        max-width: 1102px;
        padding: 0 20px;
      }
      @media (max-width: 991px) {
        .about_us_div-38 {
          max-width: 100%;
        }
      }
      .about_us_div-39 {
        gap: 20px;
        display: flex;
      }
      @media (max-width: 991px) {
        .about_us_div-39 {
          flex-direction: column;
          align-items: stretch;
          gap: 0px;
        }
      }
      .about_us_column-7 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 33%;
        margin-left: 0px;
      }
      @media (max-width: 991px) {
        .about_us_column-7 {
          width: 100%;
        }
      }
      .about_us_div-40 {
        display: flex;
        flex-direction: column;
      }
      @media (max-width: 991px) {
        .about_us_div-40 {
          margin-top: 40px;
        }
      }
      .about_us_img-15 {
        aspect-ratio: 1.1;
        object-fit: contain;
        object-position: center;
        width: 110px;
        overflow: hidden;
        align-self: center;
        max-width: 100%;
      }
      .about_us_div-41 {
        color: #000;
        align-self: center;
        margin-top: 24px;
        white-space: nowrap;
        font: 600 24px/30px Source Serif Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-41 {
          white-space: initial;
        }
      }
      .about_us_div-42 {
        color: #000;
        text-align: center;
        margin-top: 10px;
        font: 400 18px/22px Lato, sans-serif;
      }
      .about_us_column-8 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 33%;
        margin-left: 20px;
      }
      @media (max-width: 991px) {
        .about_us_column-8 {
          width: 100%;
          margin-left:0;
        }
      }
      .about_us_div-43 {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }
      @media (max-width: 991px) {
        .about_us_div-43 {
          margin-top: 40px;
        }
      }
      .about_us_img-16 {
        aspect-ratio: 1.3;
        object-fit: contain;
        object-position: center;
        width: 130px;
        overflow: hidden;
        align-self: center;
        max-width: 100%;
      }
      .about_us_div-44 {
        color: #000;
        align-self: center;
        margin-top: 24px;
        white-space: nowrap;
        font: 600 24px/30px Source Serif Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-44 {
          white-space: initial;
        }
      }
      .about_us_div-45 {
        color: #000;
        text-align: center;
        margin-top: 10px;
        font: 400 18px/22px Lato, sans-serif;
      }
      .about_us_column-9 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 33%;
        margin-left: 20px;
      }
      @media (max-width: 991px) {
        .about_us_column-9 {
          width: 100%;
          margin-left: 0;
        }
      }
      .about_us_div-46 {
        display: flex;
        flex-direction: column;
      }
      @media (max-width: 991px) {
        .about_us_div-46 {
          margin-top: 40px;
        }
      }
      .about_us_img-17 {
        aspect-ratio: 1.32;
        object-fit: contain;
        object-position: center;
        width: 132px;
        overflow: hidden;
        align-self: center;
        max-width: 100%;
      }
      .about_us_div-47 {
        color: #000;
        align-self: center;
        margin-top: 24px;
        white-space: nowrap;
        font: 600 24px/30px Source Serif Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .about_us_div-47 {
          white-space: initial;
        }
      }
      .about_us_div-48 {
        color: #000;
        text-align: center;
        margin-top: 10px;
        font: 400 18px/22px Lato, sans-serif;
      }
      .about_us_div-49 {
        background-color: #f4ed5e;
        align-self: stretch;
        display: flex;
        min-height: 10px;
        width: 100%;
        flex-direction: column;
        margin: 156px 0 88px;
      }
      @media (max-width: 991px) {
        .about_us_div-49 {
          max-width: 100%;
          margin: 40px 0;
        }
      }
    `}</style>
  </>
  );
}

export default About_Us;

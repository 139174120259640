import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const School_UniformsContext = createContext();

export const School_UniformsContextProvider = ({ children }) => {
  const [school_uniforms, setSchool_Uniforms] = useState([]); // Corrected the state variable name

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the first collection
        const snapshot1 = await db.collection('Uniforms/School/School_Uniforms').get();
        const school_uniformsData1 = snapshot1.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));

        // Fetch data from the second collection
        /*const snapshot2 = await db.collection('Uniforms/Medical/Medical_uniforms').get(); // Replace with your second collection name
        const school_uniformsData2 = snapshot2.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));*/

        /*const snapshot3 = await db.collection('Uniforms/Corporate/Coporate_Uniforms').get(); // Replace with your second collection name
        const school_uniformsData3 = snapshot3.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));*/

        // Combine the data from both collections into one array
        
        //const mergedData = [...school_uniformsData1, ...school_uniformsData2,...school_uniformsData3 ];
        const mergedData = [...school_uniformsData1]
        // Set the state with the merged data
        setSchool_Uniforms(mergedData);
      } catch (error) {
        console.error('Error:', error);
      }
    };


    fetchData();
  }, []);

  return (
    <School_UniformsContext.Provider value={{ school_uniforms }}>
      {children}
    </School_UniformsContext.Provider>
  );
};

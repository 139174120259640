import generateProductPage from './Generate_Product_Page';
import { T_ShirtContext } from './t_shirt_data';
import { IoCall } from 'react-icons/io5';
import React from 'react';
import './Product_Test.css';

const AdditionalElements = () => {
  return (
    <div>
      <div className='page_header_links'>
        <div className='pade_header_page_link_left'>
          <div className='page_header_about_us'>
            About Us
          </div>
          <div className='page_header_writing_customization'>
            T-Shirt Customization
          </div>
          <div className='page_header_shipping_options'>
              Shipping Options
          </div>
        </div>
        <div className='page_header_blank_space'>

        </div>
        <div className='pade_header_page_link_right'>
          <div className='page_header_contact'>
            <IoCall/>
            < div>
              7478004111
            </div>
          </div>
          <div className='page_header_help'>
            Help
          </div>
        </div>

      </div>
      {/* Add your additional elements here */}
      <div className='product_page_heading'>T-Shirts</div>
      <div className='product_page_heading_display'>
        <div className='heading_display_left'>
          <img src = "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
        </div>
        <div className='heading_display_cards'>
          <div className='heading_page_card_display_left'>
            <img src= "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
            <p>Product Name 1</p>
          </div>
          <div className='heading_page_card_display'>
            <img src= "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
            <p>Product Name 1</p>
          </div>
          <div className='heading_page_card_display_right'>
            <img src= "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
            <p>Product Name 1</p>
          </div>
        </div>
        <div className='heading_display_right'>
          <div className='heading_display_right_txt'>Italic Best Seller</div>
          <div className='heading_display_right_contact_div'>
          <a className='heading_display_right_contact_us_bg' href="tel:+917478004111">
          <a className='heading_display_right_contact_us'  href="tel:+917478004111">Contact Us</a>
          </a>
          </div>
        </div>
      </div>
      
    </div>
  );
};


const Product_Formatting = generateProductPage(T_ShirtContext, 't_shirt', 't_shirt');
const T_ShirtPage = () => {
  return (
    <>
      <AdditionalElements />
      <Product_Formatting />
    </>
  );
};


export default T_ShirtPage;

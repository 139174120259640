import React, { useState, useEffect } from 'react';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import { db, storage } from './Firebase';

function Digital_Marketing(props) {

  const [NameError, setNameError] = useState(false);
  const [Name, setName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [contactInfoError, setContactInfoError] = useState(false);
  const [Detail, setDetail] = useState('');
  const [DetailError, setDetailError] = useState(false);

  const validateContactInfo = (value) => {
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return phoneRegex.test(value) || emailRegex.test(value);
  };

  const handleContactInfoChange = (e) => {
    setContactInfo(e.target.value);
    setContactInfoError(!validateContactInfo(e.target.value));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameError(e.target.value.trim() === ''); // Address is mandatory
  };
  const handleDetailChange = (e) => {
    setDetail(e.target.value);
    setDetailError(e.target.value.trim() === ''); // Address is mandatory
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate contact information
    if (!validateContactInfo(contactInfo)) {
      setContactInfoError(true);
      return; // Don't proceed with the submission
    }
    setContactInfoError(false);

    // Validate address
    if (Name.trim() === '') {
      setNameError(true);
      return; // Don't proceed with the submission
    }
    setNameError(false);



    // Save order data only when the file URL is available
    console.log('About to call saveOrderData');
    console.log('About to call saveOrderData Inside');
    saveOrderData();
    window.gtag('event', 'conversion', {'send_to': 'AW-11246127201/mcHGCNDQtPsYEOGQyfIp'});
  };

  const saveOrderData = async () => {
    console.log('saveOrderData function called');
    const ordersRef = db.collection('Digital_Marketing_Submit_Form');

    const orderData = {
      Name: Name,
      Contact: contactInfo,
      Detail: Detail,
    };
    console.log('Order Data:', orderData);
    try {
      // Use add to let Firestore generate a unique document ID
      await ordersRef.add(orderData);

      // Reset fields
      setContactInfo('');
      setDetail('');
      setName('');

      // Optionally, you can show a success message to the user
      alert('Request submitted successfully!');
    } catch (error) {
      // Handle any errors that may occur during data submission
      console.error('Error submitting request:', error);
      alert('Error submitting request. Please try again.');
    }
  };


    
  return (
    <>
      <div className="digital_marketing_div">
        <div className="digital_marketing_div-2" style={{ backgroundImage: 'url(\'https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20Home%20Image.jpeg?alt=media&token=7acbd558-d359-4a0b-9433-92b93452914a\')', backgroundSize: 'cover', backgroundPosition: 'center' }}>


          <div className="digital_marketing_div-9">
            <div className="digital_marketing_div-10">
              Empower Your Institution With
              <br />A Strong Digital Footprint
            </div>
            <div className="digital_marketing_div-11">Get Started</div>
          </div>
        </div>
        <div className="digital_marketing_div-12">
          <div className="digital_marketing_div-13">
            <span style={{ color: 'rgba(17, 17, 17, 1)' }}>Why </span>
            <span  style={{ color: 'rgba(35,187,238,1)' }}  >Digital Marketing?</span>
          </div>
          <div className="digital_marketing_div-14">
            Education industry counts a lot on reputation and dignified brand
            presence. Just like any other industry, the education industry too
            hasn’t been left untouched by the onslaught of digital media and
            digital marketing, with every educational institution, college,
            school or tuition center aggressively using digital tools to create
            a strong brand presence on the Internet. There are numerous learning
            management software applications and online educational websites
            vying for attention and can productively use digital marketing.
            Higher competition from other educational agencies, learning
            software, online learning websites and institutions makes it
            mandatory for you to constantly work on your SEO to draw targeted
            traffic from search engines.
          </div>
          <div className="digital_marketing_div-15">
            “93 percent of students use social media to research a college they
            are interested in and 60 percent have followed or liked a college
            they are considering”
          </div>
          <div className="digital_marketing_div-16">
            <div className="digital_marketing_div-17">
              <div className="digital_marketing_div-18">
                <div className="digital_marketing_column">
                  <div className="digital_marketing_div-19">
                    <img
                      loading="lazy"
                      srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20IMG%202.jpeg?alt=media&token=8898abee-1dca-4680-9fbc-7b3a089b5230&width=100 100w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20IMG%202.jpeg?alt=media&token=8898abee-1dca-4680-9fbc-7b3a089b5230&width=200 200w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20IMG%202.jpeg?alt=media&token=8898abee-1dca-4680-9fbc-7b3a089b5230&width=400 400w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20IMG%202.jpeg?alt=media&token=8898abee-1dca-4680-9fbc-7b3a089b5230&width=800 800w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20IMG%202.jpeg?alt=media&token=8898abee-1dca-4680-9fbc-7b3a089b5230&width=1200 1200w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20IMG%202.jpeg?alt=media&token=8898abee-1dca-4680-9fbc-7b3a089b5230&width=1600 1600w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20IMG%202.jpeg?alt=media&token=8898abee-1dca-4680-9fbc-7b3a089b5230&width=2000 2000w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20IMG%202.jpeg?alt=media&token=8898abee-1dca-4680-9fbc-7b3a089b5230&"
                      className="digital_marketing_img"
                    />
                    <div className="digital_marketing_div-20">
                      Ready to Be a Part
                      <br />
                      of the Digital World?
                    </div>
                    <div className="digital_marketing_div-21">Get Quote</div>
                  </div>
                </div>
                <div className="digital_marketing_column-2">
                  <div className="digital_marketing_div-22">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/175ae599-fb98-4c5f-bea2-d2aee249d783?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/175ae599-fb98-4c5f-bea2-d2aee249d783?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/175ae599-fb98-4c5f-bea2-d2aee249d783?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/175ae599-fb98-4c5f-bea2-d2aee249d783?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/175ae599-fb98-4c5f-bea2-d2aee249d783?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/175ae599-fb98-4c5f-bea2-d2aee249d783?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/175ae599-fb98-4c5f-bea2-d2aee249d783?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/175ae599-fb98-4c5f-bea2-d2aee249d783?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="digital_marketing_img-3"
                    />
                    <div className="digital_marketing_div-23">Web Designing</div>
                    <div className="digital_marketing_div-24">
                      Revamp your schools identity by
                      <br />
                      building customized and responsive
                      <br />
                      websites...
                    </div>
                    <div className="digital_marketing_div-25">Learn More</div>
                  </div>
                </div>
                <div className="digital_marketing_column-3">
                  <div className="digital_marketing_div-26">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3d672a0f-5ac9-48be-969c-fe874a023e9d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d672a0f-5ac9-48be-969c-fe874a023e9d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d672a0f-5ac9-48be-969c-fe874a023e9d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d672a0f-5ac9-48be-969c-fe874a023e9d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d672a0f-5ac9-48be-969c-fe874a023e9d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d672a0f-5ac9-48be-969c-fe874a023e9d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d672a0f-5ac9-48be-969c-fe874a023e9d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d672a0f-5ac9-48be-969c-fe874a023e9d?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="digital_marketing_img-4"
                    />
                    <div className="digital_marketing_div-27">Search Engine Optimization</div>
                    <div className="digital_marketing_div-28">
                      Reach, identify, and convert genuine leads
                      <br />
                      successfully with the SEO techniques
                      <br />
                      carved...
                    </div>
                    <div className="digital_marketing_div-29">Learn More</div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div className="digital_marketing_div-31">
            <div className="digital_marketing_div-32">
              <div className="digital_marketing_column">
                <div className="digital_marketing_div-33">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e457ce83-5829-48b9-9fa2-0cf8539c5117?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e457ce83-5829-48b9-9fa2-0cf8539c5117?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e457ce83-5829-48b9-9fa2-0cf8539c5117?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e457ce83-5829-48b9-9fa2-0cf8539c5117?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e457ce83-5829-48b9-9fa2-0cf8539c5117?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e457ce83-5829-48b9-9fa2-0cf8539c5117?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e457ce83-5829-48b9-9fa2-0cf8539c5117?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e457ce83-5829-48b9-9fa2-0cf8539c5117?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="digital_marketing_img-5"
                  />
                  <div className="digital_marketing_div-34">Social Media Marketing</div>
                  <div className="digital_marketing_div-35">
                    Be active on social media & share the
                    <br />
                    latest achievements & activities of your
                    <br />
                    institute...
                  </div>
                  <div className="digital_marketing_div-36">Learn More</div>
                </div>
              </div>
              <div className="digital_marketing_column-4">
                <div className="digital_marketing_div-37">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6a844780-9964-4117-9604-107772a8da93?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a844780-9964-4117-9604-107772a8da93?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a844780-9964-4117-9604-107772a8da93?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a844780-9964-4117-9604-107772a8da93?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a844780-9964-4117-9604-107772a8da93?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a844780-9964-4117-9604-107772a8da93?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a844780-9964-4117-9604-107772a8da93?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a844780-9964-4117-9604-107772a8da93?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="digital_marketing_img-6"
                  />
                  <div className="digital_marketing_div-38">Inbound Marketing</div>
                  <div className="digital_marketing_div-39">
                    Bridge the communication gap between
                    <br />
                    your school and users by opting for
                    <br />
                    inbound marketing services...
                  </div>
                  <div className="digital_marketing_div-40">Learn More</div>
                </div>
              </div>
              <div className="digital_marketing_column-5">
                <div className="digital_marketing_div-41">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/430aba40-6c8b-4f3a-9881-652e669e43b7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/430aba40-6c8b-4f3a-9881-652e669e43b7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/430aba40-6c8b-4f3a-9881-652e669e43b7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/430aba40-6c8b-4f3a-9881-652e669e43b7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/430aba40-6c8b-4f3a-9881-652e669e43b7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/430aba40-6c8b-4f3a-9881-652e669e43b7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/430aba40-6c8b-4f3a-9881-652e669e43b7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/430aba40-6c8b-4f3a-9881-652e669e43b7?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="digital_marketing_img-7"
                  />
                  <div className="digital_marketing_div-42">Content Marketing</div>
                  <div className="digital_marketing_div-43">
                    Weave your beliefs, mission, and ideology
                    <br />
                    into thoughtful words to engage &<br />
                    enhance the user's experience...
                  </div>{" "}
                  <div className="digital_marketing_div-44">Learn More</div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="digital_marketing_div-45">
          <div className="digital_marketing_div-46">
            <div className="digital_marketing_div-47">
              <span style={{ color: 'rgba(17,17,17,1)' }} >Web </span>
              <span style={{ color: 'rgba(35,187,238,1)' }}  >Designing</span>
            </div>{" "}
            <div className="digital_marketing_div-48" />{" "}
            <div className="digital_marketing_div-49">
              Revamp your school’s identity by building customized intuitive,
              professional, and responsive websites.
            </div>{" "}
            <div className="digital_marketing_div-50">
              <div className="digital_marketing_div-51">
                <div className="digital_marketing_column-6">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4f85b6b0-6d97-4ce3-887f-e270ee559ffb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4f85b6b0-6d97-4ce3-887f-e270ee559ffb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4f85b6b0-6d97-4ce3-887f-e270ee559ffb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4f85b6b0-6d97-4ce3-887f-e270ee559ffb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4f85b6b0-6d97-4ce3-887f-e270ee559ffb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4f85b6b0-6d97-4ce3-887f-e270ee559ffb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4f85b6b0-6d97-4ce3-887f-e270ee559ffb?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4f85b6b0-6d97-4ce3-887f-e270ee559ffb?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="digital_marketing_img-8"
                  />
                </div>{" "}
                <div className="digital_marketing_column-7">
                  <div className="digital_marketing_div-52">
                    <div className="digital_marketing_div-53">
                      Best Template Design, Logo, & Color
                      <br />
                      Scheme to Showcase Your Brand Identity
                    </div>{" "}
                    <div className="digital_marketing_div-54">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/03183082-e792-41be-b9dd-ad8ca11c7f92?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-9"
                      />{" "}
                      <div className="digital_marketing_div-55">
                        Interactive, unique content-rich, fast-loading, SEO
                        driven,
                        <br />
                        and aesthetically appealing websites with proper color
                        <br />
                        coordination to cater to your visitors.
                      </div>
                    </div>{" "}
                    <div className="digital_marketing_div-56">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4d2aed41-16e5-480f-9a66-84231aa2350f?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-10"
                      />{" "}
                      <div className="digital_marketing_div-57">
                        Website customization on the basis of your values,
                        vision,
                        <br />
                        and mission for the educational field.
                      </div>
                    </div>{" "}
                    <div className="digital_marketing_div-58">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/872955a3-b002-4461-8df2-2de9f4cf51df?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-11"
                      />{" "}
                      <div className="digital_marketing_div-59">
                        SEO-optimized structure with utmost data security to
                        <br />
                        deliver high performance on top-notch search engines
                        like
                        <br />
                        Google.
                      </div>
                    </div>{" "}
                    <div className="digital_marketing_div-60">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6bf1710f-a86a-45ce-9294-dee3da35806a?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-12"
                      />{" "}
                      <div className="digital_marketing_div-61">
                        Device responsive designs that reflect your core beliefs
                        <br />
                        ensuring a better engagement with users.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="digital_marketing_div-62">
          <div className="digital_marketing_div-63">
            <div className="digital_marketing_div-64">
              <span style={{ color: 'rgba(17,17,17,1)' }}  >Search Engine</span>
              <span style={{ color: 'rgba(35,187,238,1)' }}  > Optimization</span>
            </div>{" "}
            <div className="digital_marketing_div-65" />{" "}
            <div className="digital_marketing_div-66">
              Reach, identify, and convert genuine leads successfully with the
              SEO techniques carved on the Google latest guidelines.
            </div>{" "}
            <div className="digital_marketing_div-67">
              <div className="digital_marketing_div-68">
                <div className="digital_marketing_column-8">
                  <div className="digital_marketing_div-69">
                    <div className="digital_marketing_div-70">
                      Analyze,Educate, Engage, Manage, and Increase
                      <br />
                      your Traffic
                    </div>{" "}
                    <div className="digital_marketing_div-71">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/699003ed-dc3a-4890-b85d-5c1a622c7198?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-13"
                      />{" "}
                      <div className="digital_marketing_div-72">
                        We enable improve, and optimize your website to help you
                        <br />
                        get top ranking in Google browser
                      </div>
                    </div>{" "}
                    <div className="digital_marketing_div-73">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/8fd8c8d5-9fc1-468f-bfcd-16e01c1ab856?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-14"
                      />{" "}
                      <div className="digital_marketing_div-74">
                        Tactful strategies for content creation, keyword
                        analysis &<br />
                        handling leading social media platforms to more drive
                        effective
                        <br />
                        engagement.
                      </div>
                    </div>{" "}
                    <div className="digital_marketing_div-75">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbeafaac-62e6-4282-b574-3b28cbb0c7b4?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-15"
                      />{" "}
                      <div className="digital_marketing_div-76">
                        Main focus is on increasing relevant traffic and
                        generating
                        <br />
                        conersion leads.
                      </div>
                    </div>{" "}
                    <div className="digital_marketing_div-77">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c1abc0e-6e2c-474f-a507-1347ebaa4c04?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-16"
                      />{" "}
                      <div className="digital_marketing_div-78">
                        Reach at the topmost results of Google search & enhance
                        <br />
                        the “Trust” factor of your educational institution.
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="digital_marketing_column-9">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9a94f14b-457f-4f80-b5cf-90f021bbfc97?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a94f14b-457f-4f80-b5cf-90f021bbfc97?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a94f14b-457f-4f80-b5cf-90f021bbfc97?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a94f14b-457f-4f80-b5cf-90f021bbfc97?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a94f14b-457f-4f80-b5cf-90f021bbfc97?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a94f14b-457f-4f80-b5cf-90f021bbfc97?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a94f14b-457f-4f80-b5cf-90f021bbfc97?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a94f14b-457f-4f80-b5cf-90f021bbfc97?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="digital_marketing_img-17"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="digital_marketing_div-79">
          <div className="digital_marketing_div-80">
            <div className="digital_marketing_column-10">
              <div className="digital_marketing_div-81">
                Create Positive Perception of Your Institution!
              </div>
            </div>{" "}
            <div className="digital_marketing_column-11">
              <div className="digital_marketing_div-82">Get Your Free Proposal</div>
            </div>
          </div>
        </div>{" "}
        <div className="digital_marketing_div-83">
          <div className="digital_marketing_div-84">
            <div className="digital_marketing_div-85">
              <span style={{ color: 'rgba(17,17,17,1)' }}  >Social Media </span>
              <span style={{ color: 'rgba(35,187,238,1)' }}  >Marketing</span>
            </div>{" "}
            <div className="digital_marketing_div-86" />{" "}
            <div className="digital_marketing_div-87">
              Be active on social media & share the latest achievements &
              activities of your school to create positive impact on gen-next
              students.
            </div>{" "}
            <div className="digital_marketing_div-88">
              <div className="digital_marketing_div-89">
                <div className="digital_marketing_column-12">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/00180591-adba-4d21-8c94-11a5a8b3e3c8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/00180591-adba-4d21-8c94-11a5a8b3e3c8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/00180591-adba-4d21-8c94-11a5a8b3e3c8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/00180591-adba-4d21-8c94-11a5a8b3e3c8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/00180591-adba-4d21-8c94-11a5a8b3e3c8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/00180591-adba-4d21-8c94-11a5a8b3e3c8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/00180591-adba-4d21-8c94-11a5a8b3e3c8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/00180591-adba-4d21-8c94-11a5a8b3e3c8?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="digital_marketing_img-18"
                  />
                </div>{" "}
                <div className="digital_marketing_column-13">
                  <div className="digital_marketing_div-90">
                    <div className="digital_marketing_div-91">
                      Establish a strong connection with Key
                      <br />
                      Stakeholders
                    </div>{" "}
                    <div className="digital_marketing_div-92">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/945380e2-7097-4dee-9d16-07e8f48ed5ba?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-19"
                      />{" "}
                      <div className="digital_marketing_div-93">
                        Build & grow schools brand awareness and reach out to
                        <br />
                        people in a quick span.
                      </div>
                    </div>{" "}
                    <div className="digital_marketing_div-94">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/65885d7d-7bfd-4538-b083-0974115759c4?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-20"
                      />{" "}
                      <div className="digital_marketing_div-95">
                        Enticing posts that target the right audience
                        strategically
                        <br />
                        based on demogs like their age, location, and interests.
                      </div>
                    </div>{" "}
                    <div className="digital_marketing_div-96">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e531a0b1-1ca8-4525-a285-6a1f4c0b6672?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-21"
                      />{" "}
                      <div className="digital_marketing_div-97">
                        We focus on creating campaigns, ads & posts to present
                        <br />
                        you as thought leaders in the education
                      </div>
                    </div>{" "}
                    <div className="digital_marketing_div-98">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5c3a00ff-d87f-41d4-ba14-7c8eacaa3179?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-22"
                      />{" "}
                      <div className="digital_marketing_div-99">
                        <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 500, color: 'rgba(82, 82, 82, 1)' }}>
                          We cover all the leading social media platforms like
                          <br />
                        </span>
                        <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 700, color: 'rgba(0, 0, 0, 1)' }}>
                          FaceBook, LinkedIn, Twitter, YouTube etc.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="digital_marketing_div-100">
          <div className="digital_marketing_div-101">
            <span style={{ color: 'rgba(17,17,17,1)' }}  >Content </span>
            <span style={{ color: 'rgba(35,187,238,1)' }}  >Marketing</span>
          </div>{" "}
          <div className="digital_marketing_div-102" />{" "}
          <div className="digital_marketing_div-103">
            {" "}
            change Your beliefs, mission, and ideology into thoughtful words to
            engage & enhance the user's experience.
          </div>
          <div className="digital_marketing_div-104">
            <div className="digital_marketing_div-105">
              <div className="digital_marketing_column-14">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a95dd11f-1fe7-4f35-86c1-309f6f80662d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a95dd11f-1fe7-4f35-86c1-309f6f80662d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a95dd11f-1fe7-4f35-86c1-309f6f80662d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a95dd11f-1fe7-4f35-86c1-309f6f80662d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a95dd11f-1fe7-4f35-86c1-309f6f80662d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a95dd11f-1fe7-4f35-86c1-309f6f80662d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a95dd11f-1fe7-4f35-86c1-309f6f80662d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a95dd11f-1fe7-4f35-86c1-309f6f80662d?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="digital_marketing_img-23"
                />
              </div>
              <div className="digital_marketing_column-15">
                <div className="digital_marketing_div-106">
                  <div className="digital_marketing_div-107">
                    Be the KING of school content with the Right
                    <br />
                    vedios !
                  </div>
                  <div className="digital_marketing_div-108">
                    <div className="digital_marketing_div-109">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/787cfe14-64b5-45ec-a669-17ba66553690?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-24"
                      />
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5e6917ea-d89f-4b5f-8643-ada8a127d60b?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-25"
                      />
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a55f17e-69b2-4808-ba14-904bd96b0359?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="digital_marketing_img-26"
                      />
                    </div>
                    <div className="digital_marketing_div-110">
                      <div className="digital_marketing_div-111">
                        Deliver exceptional content that is entirely free of
                        plagiarism, distinctive, optimized for search engines,
                        and designed to yield results. Our seasoned
                        professionals skillfully create content that resonates
                        with your audience
                      </div>
                      <div className="digital_marketing_div-112">
                        Comprehensive set of content marketing services -
                        strategy
                        <br />
                        development, content writing, editing and reading.
                      </div>
                    </div>
                  </div>
                  <div className="digital_marketing_div-113">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f04acf2-d7bd-475e-806a-4b7c9a8f56da?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="digital_marketing_img-27"
                    />
                    <div className="digital_marketing_div-114">
                      Content creation for - Info-graphics, videos, website,
                      blogs,
                      <br />
                      marketing pages etc.
                    </div>
                  </div>
                  <div className="digital_marketing_div-115">
                    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 500 }}>
                      Robust content publishing & distribution across various
                      <br />
                      social media platforms for{" "}
                    </span>
                    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 700 }}>
                      effective leads.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="digital_marketing_div-116">
            <img
              loading="lazy"
              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20Page%20IMG%203.jpeg?alt=media&token=128f28b4-9358-402e-9796-3b2533183dc9&width=100 100w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20Page%20IMG%203.jpeg?alt=media&token=128f28b4-9358-402e-9796-3b2533183dc9&width=200 200w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20Page%20IMG%203.jpeg?alt=media&token=128f28b4-9358-402e-9796-3b2533183dc9&width=400 400w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20Page%20IMG%203.jpeg?alt=media&token=128f28b4-9358-402e-9796-3b2533183dc9&width=800 800w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20Page%20IMG%203.jpeg?alt=media&token=128f28b4-9358-402e-9796-3b2533183dc9&width=1200 1200w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20Page%20IMG%203.jpeg?alt=media&token=128f28b4-9358-402e-9796-3b2533183dc9&width=1600 1600w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20Page%20IMG%203.jpeg?alt=media&token=128f28b4-9358-402e-9796-3b2533183dc9&width=2000 2000w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FDigital%20Marketing%20Page%20IMG%203.jpeg?alt=media&token=128f28b4-9358-402e-9796-3b2533183dc9&"
              className="digital_marketing_img"
            />
            <div className="digital_marketing_div-117">
              <div className="digital_marketing_div-118">
                <span style={{ color: 'rgba(255,255,255,1)' }} >
                  Why Should Institutes go for
                </span>
                <span style={{ color: 'rgba(35,187,238,1)' }}  >
                  {" "}
                  Digital Marketing?
                </span>
              </div>
              <div className="digital_marketing_div-119" />
              <div className="digital_marketing_div-120">
                <span  style={{ fontFamily: 'Roboto, sans-serif' }}>
                  The best way to reach maximum people in the shortest time
                  possible is -{" "}Digital Marketing! It provides you a globalized platform to share 100% authentic
                  information about your educational institution to all the
                  stakeholders. Speaking from technical-point of view, in 2020’s
                  digital economy, approximately 4.5 billion
                  
                  people, that makes 60% of the global population, falls under
                  the umbrella of active internet users, around 3.8 billion are
                  the social media users, and 5.19 billion
                  
                  people use smartphones. Considering these statistics, building
                  a strong online presence of your educational institution can
                  play a pivotal role in empowering
                  
                  your institutional reputation.
                </span>
              </div>
              <div className="digital_marketing_div-121">
                <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400 }}>
                  In fact,{" "}

                  proper management of digital presence

                  can serve as a catalyst to reach out to potential prospects
                  and improve credibility of your educational institution.
                
                  Therefore, to keep your educational institution ahead and gain
                  a leading edge in the education sector, you must opt for
                  digital marketing services. Keep in mind
                
                  that - People of this generation prefer visiting websites
                  first instead of visiting educational institutions!
                </span>
              </div>
              <div className="digital_marketing_div-122">Get Quote</div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="digital_marketing_div-123">
          <div className="digital_marketing_div-124">
            <div className="digital_marketing_div-125">
            <input
      type="text"
      id="schoolName"
      value={Name}
      onChange={handleNameChange}
      className={NameError ? 'error' : ''}
      placeholder="Enter School name"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: NameError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {NameError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}



            </div>
            <div className="digital_marketing_div-126">
            <input
      type="text"
      id="contactInfo"
      value={contactInfo}
      onChange={handleContactInfoChange}
      className={contactInfoError ? 'error' : ''}
      placeholder="Phone Number or Email (Required)"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: contactInfoError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {contactInfoError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
            </div>
          
            <div className="digital_marketing_div-128" >

            <input
      type="text"
      id="Detail"
      value={Detail}
      onChange={handleDetailChange}
      className={DetailError ? 'error' : ''}
      placeholder="Enter Details"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: DetailError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {DetailError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
            </div>
            <button type = "submit" className="digital_marketing_div-129">Submit</button>
          </div>
        </form>
        <div className="digital_marketing_div-130">
          <div className="digital_marketing_div-131">
            <div className="digital_marketing_div-132">custoking. All Rights Reserved | </div>
            <div className="digital_marketing_div-133">Privacy Policy</div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .digital_marketing_div {
          background-color: #fff;
          display: flex;
          flex-direction: column;
        
        }
        .digital_marketing_div-2 {
          disply: flex;
          flex-direction: column;
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          min-height: 606px;
          width: 100%;
          
        }
        @media (max-width: 991px) {
          .digital_marketing_div-2 {
            max-width: 100%;
          }
        }
        .digital_marketing_img {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .digital_marketing_div-3 {
          position: relative;
          border: 1px solid #080808;
          background-color: #fff;
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 20px;
          padding: 11px 47px 11px 0;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-3 {
            max-width: 100%;
            flex-wrap: wrap;
            padding-right: 20px;
          }
        }
        .digital_marketing_img-2 {
          aspect-ratio: 3.5;
          object-fit: contain;
          object-position: center;
          width: 210px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-4 {
          align-self: center;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-4 {
            justify-content: center;
          }
        }
        .digital_marketing_div-5 {
          justify-content: center;
          color: #9d9d9d;
          align-self: start;
          white-space: nowrap;
          font: 400 16px/50px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-5 {
            white-space: initial;
          }
        }
        .digital_marketing_div-6 {
          justify-content: center;
          color: #9d9d9d;
          align-self: start;
          font: 400 16px/50px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-7 {
          justify-content: center;
          color: #9d9d9d;
          font: 400 16px/50px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-8 {
          justify-content: center;
          color: #9d9d9d;
          align-self: start;
          white-space: nowrap;
          font: 400 16px/50px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-8 {
            white-space: initial;
          }
        }
        .digital_marketing_div-9 {
          position: relative;
          justify-content: center;
          background-color: rgba(35, 187, 238, 0.85);
          align-self: end;
          display: flex;
          width: 555px;
          max-width: 100%;
          flex-direction: column;
          margin: 119px 390px 78px 0;
          padding: 45px 48px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-9 {
            margin: 40px 10px 40px 0;
            padding: 0 20px;
          }
        }
        .digital_marketing_div-10 {
          justify-content: center;
          color: #fff;
          font: 500 34px/45px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-10 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-11 {
          justify-content: center;
          color: #000;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 2px;
          border: 2px solid #fac42e;
          background-color: #fac42e;
          align-self: start;
          margin-top: 17px;
          width: 198px;
          max-width: 100%;
          padding: 22px 20px;
          font: 500 15px/23px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-11 {
            white-space: initial;
          }
        }
        .digital_marketing_div-12 {
          align-self: center;
          display: flex;
          margin-top: 48px;
          width: 100%;
          max-width: 1603px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-12 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .digital_marketing_div-13 {
          justify-content: center;
          color: #23bbee;
          text-align: center;
          text-transform: capitalize;
          align-self: center;
          max-width: 546px;
          font: 700 32px/35px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-14 {
          justify-content: center;
          color: #525252;
          align-self: stretch;
          margin-top: 31px;
          font: 400 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-14 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-15 {
          justify-content: center;
          color: #03c4eb;
          align-self: center;
          max-width: 1074px;
          margin: 39px 0 ;
          font: italic 700 26px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-15 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-16 {
            align-items: center;
          align-self: center;
          display: flex;
          margin-top: 12px;
          width: 100%;
          max-width: 1500px;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-16 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_div-17 {
            display: flex;
            margin: auto; /* Add this line */
            align-self: center;
    
        }
        @media (max-width: 991px) {
          .digital_marketing_div-17 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-18 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-18 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .digital_marketing_column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column {
            width: 100%;
          }
        }
        .digital_marketing_div-19 {
          disply: flex;
          flex-direction: column;
          box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.07);
          overflow: hidden;
          position: relative;
          display: flex;
          aspect-ratio: 1.2589928057553956;
          flex-grow: 1;
          padding: 50px 30px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-19 {
            margin-top: 30px;
            padding: 0 20px;
          }
        }
        .digital_marketing_div-20 {
          position: relative;
          justify-content: center;
          color: #fff;
          text-align: center;
          margin-top: 16px;
          font: 700 32px/35px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-21 {
          position: relative;
          justify-content: center;
          color: #000;
          text-align: center;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 2px;
          border: 2px solid #fac42e;
          background-color: #fac42e;
          align-self: center;
          width: 180px;
          max-width: 100%;
          margin: 25px 0 35px;
          padding: 10px 20px;
          font: 500 15px/17px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-21 {
            white-space: initial;
          }
        }
        .digital_marketing_column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-2 {
            width: 100%;
          }
        }
        .digital_marketing_div-22 {
          border-radius: 5px;
          border: 1px solid #f1f1f1;
          box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.07);
          background-color: #fff;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 25px 71px 25px 25px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-22 {
            margin-top: 30px;
            padding: 0 20px;
          }
        }
        .digital_marketing_img-3 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 50px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .digital_marketing_div-23 {
          justify-content: center;
          color: #333;
          align-self: stretch;
          margin-top: 16px;
          white-space: nowrap;
          font: 400 20px/22px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-23 {
            white-space: initial;
          }
        }
        .digital_marketing_div-24 {
          justify-content: center;
          color: #525252;
          align-self: stretch;
          margin-top: 17px;
          font: 400 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-25 {
          justify-content: center;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          align-items: start;
          border-radius: 2px;
          border: 1px solid #46b8da;
          background-color: #23bbee;
          align-self: stretch;
          margin-top: 16px;
          padding: 12px 14px;
          font: 400 14px/20px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-25 {
            white-space: initial;
          }
        }
        .digital_marketing_column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-3 {
            width: 100%;
          }
        }
        .digital_marketing_div-26 {
          border-radius: 5px;
          border: 1px solid #f1f1f1;
          box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.07);
          background-color: #fff;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 25px 28px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-26 {
            margin-top: 30px;
            padding: 0 20px;
          }
        }
        .digital_marketing_img-4 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 50px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .digital_marketing_div-27 {
          justify-content: center;
          color: #333;
          align-self: stretch;
          margin-top: 16px;
          white-space: nowrap;
          font: 400 20px/22px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-27 {
            white-space: initial;
          }
        }
        .digital_marketing_div-28 {
          justify-content: center;
          color: #525252;
          align-self: stretch;
          margin-top: 17px;
          font: 400 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-29 {
          justify-content: center;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          align-items: start;
          border-radius: 2px;
          border: 1px solid #46b8da;
          background-color: #23bbee;
          align-self: stretch;
          margin-top: 16px;
          padding: 12px 13px;
          font: 400 14px/20px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-29 {
            white-space: initial;
          }
        }
        .digital_marketing_div-30 {
          border-radius: 2px;
          opacity: 0.0484;
          background-color: rgba(0, 0, 0, 0.2);
          align-self: center;
          display: flex;
          width: 40px;
          height: 39px;
          flex-direction: column;
          margin: auto 0;
        }
        .digital_marketing_div-31 {
          align-self: center;
          width: 1160px;
          max-width: 100%;
          margin: 35px 0 ;
        }
        .digital_marketing_div-32 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-32 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .digital_marketing_div-33 {
          border-radius: 5px;
          border: 1px solid #f1f1f1;
          box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.07);
          background-color: #fff;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 25px 48px 25px 25px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-33 {
            margin-top: 30px;
            padding: 0 20px;
          }
        }
        .digital_marketing_img-5 {
          aspect-ratio: 0.98;
          object-fit: contain;
          object-position: center;
          width: 49px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .digital_marketing_div-34 {
          justify-content: center;
          color: #333;
          align-self: stretch;
          margin-top: 16px;
          white-space: nowrap;
          font: 400 20px/22px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-34 {
            white-space: initial;
          }
        }
        .digital_marketing_div-35 {
          justify-content: center;
          color: #525252;
          align-self: stretch;
          margin-top: 17px;
          font: 400 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-36 {
          justify-content: center;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          align-items: start;
          border-radius: 2px;
          border: 1px solid #46b8da;
          background-color: #23bbee;
          align-self: stretch;
          margin-top: 16px;
          padding: 12px 14px;
          font: 400 14px/20px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-36 {
            white-space: initial;
          }
        }
        .digital_marketing_column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-4 {
            width: 100%;
          }
        }
        .digital_marketing_div-37 {
          border-radius: 5px;
          border: 1px solid #f1f1f1;
          box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.07);
          background-color: #fff;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 25px 44px 25px 25px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-37 {
            margin-top: 30px;
            padding: 0 20px;
          }
        }
        .digital_marketing_img-6 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 50px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .digital_marketing_div-38 {
          justify-content: center;
          color: #333;
          align-self: stretch;
          margin-top: 16px;
          white-space: nowrap;
          font: 400 20px/22px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-38 {
            white-space: initial;
          }
        }
        .digital_marketing_div-39 {
          justify-content: center;
          color: #525252;
          align-self: stretch;
          margin-top: 17px;
          font: 400 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-40 {
          justify-content: center;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          align-items: start;
          border-radius: 2px;
          border: 1px solid #46b8da;
          background-color: #23bbee;
          align-self: stretch;
          margin-top: 13px;
          padding: 12px 14px;
          font: 400 14px/20px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-40 {
            white-space: initial;
          }
        }
        .digital_marketing_column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-5 {
            width: 100%;
          }
        }
        .digital_marketing_div-41 {
          border-radius: 5px;
          border: 1px solid #f1f1f1;
          box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.07);
          background-color: #fff;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 25px 31px 25px 25px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-41 {
            margin-top: 30px;
            padding: 0 20px;
          }
        }
        .digital_marketing_img-7 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 50px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .digital_marketing_div-42 {
          justify-content: center;
          color: #333;
          align-self: stretch;
          margin-top: 16px;
          white-space: nowrap;
          font: 400 20px/22px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-42 {
            white-space: initial;
          }
        }
        .digital_marketing_div-43 {
          justify-content: center;
          color: #525252;
          align-self: stretch;
          margin-top: 17px;
          font: 400 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-44 {
          justify-content: center;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          align-items: start;
          border-radius: 2px;
          border: 1px solid #46b8da;
          background-color: #23bbee;
          align-self: stretch;
          margin-top: 13px;
          padding: 12px 14px;
          font: 400 14px/20px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-44 {
            white-space: initial;
          }
        }
        .digital_marketing_div-45 {
          background-color: #fff;
          align-self: stretch;
          display: flex;
          margin-top: 35px;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 50px 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-45 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-46 {
          display: flex;
          width: 1155px;
          max-width: 100%;
          flex-direction: column;
          margin: 14px 0 10px;
        }
        .digital_marketing_div-47 {
          justify-content: center;
          color: #23bbee;
          text-align: center;
          text-transform: capitalize;
          align-self: center;
          white-space: nowrap;
          font: 700 32px/35px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-47 {
            white-space: initial;
          }
        }
        .digital_marketing_div-48 {
          background-color: #23bbee;
          align-self: center;
          display: flex;
          margin-top: 21px;
          width: 50px;
          height: 3px;
          flex-direction: column;
        }
        .digital_marketing_div-49 {
          justify-content: center;
          color: #525252;
          text-align: center;
          align-self: center;
          margin-top: 17px;
          max-width: 808px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-49 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-50 {
          align-self: stretch;
          margin-top: 44px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-50 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .digital_marketing_div-51 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-51 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .digital_marketing_column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 55%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-6 {
            width: 100%;
          }
        }
        .digital_marketing_img-8 {
          aspect-ratio: 1.59;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .digital_marketing_img-8 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .digital_marketing_column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 45%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-7 {
            width: 100%;
          }
        }
        .digital_marketing_div-52 {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 6px 28px 14px 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-52 {
            max-width: 100%;
            margin-top: 40px;
            padding-right: 20px;
          }
        }
        .digital_marketing_div-53 {
          justify-content: center;
          color: #333;
          font: 700 23px/32px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-53 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-54 {
          display: flex;
          margin-top: 29px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-54 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-9 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-55 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-56 {
          display: flex;
          margin-top: 9px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-56 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-10 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-57 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-58 {
          display: flex;
          margin-top: 12px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-58 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-11 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-59 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-60 {
          display: flex;
          margin-top: 9px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-60 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-12 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-61 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-62 {
          background-color: #fff;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 50px 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-62 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-63 {
          display: flex;
          width: 1121px;
          max-width: 100%;
          flex-direction: column;
          margin: 14px 0 10px;
        }
        .digital_marketing_div-64 {
          justify-content: center;
          color: #23bbee;
          text-align: center;
          text-transform: capitalize;
          align-self: center;
          white-space: nowrap;
          font: 700 32px/35px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-64 {
            white-space: initial;
          }
        }
        .digital_marketing_div-65 {
          background-color: #23bbee;
          align-self: center;
          display: flex;
          margin-top: 20px;
          width: 52px;
          height: 3px;
          flex-direction: column;
        }
        .digital_marketing_div-66 {
          justify-content: center;
          color: #525252;
          text-align: center;
          align-self: center;
          margin-top: 17px;
          white-space: nowrap;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-66 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .digital_marketing_div-67 {
          align-self: stretch;
          margin-top: 44px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-67 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .digital_marketing_div-68 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-68 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .digital_marketing_column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 47%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-8 {
            width: 100%;
          }
        }
        .digital_marketing_div-69 {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 6px 0 14px 14px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-69 {
            max-width: 100%;
            margin-top: 25px;
          }
        }
        .digital_marketing_div-70 {
          justify-content: center;
          color: #333;
          font: 700 23px/32px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-70 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-71 {
          display: flex;
          margin-top: 29px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-71 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-13 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-72 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-72 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-73 {
          display: flex;
          margin-top: 9px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-73 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-14 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-74 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-74 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-75 {
          display: flex;
          margin-top: 9px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-75 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-15 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-76 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-76 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-77 {
          display: flex;
          margin-top: 12px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-77 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-16 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-78 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-78 {
            max-width: 100%;
          }
        }
        .digital_marketing_column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 53%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-9 {
            width: 100%;
          }
        }
        .digital_marketing_img-17 {
          aspect-ratio: 1.67;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .digital_marketing_img-17 {
            max-width: 100%;
            margin-top: 25px;
          }
        }
        .digital_marketing_div-79 {
          background-color: #23bbee;
          align-self: center;
          margin-top: 25px;
          width: 1140px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-79 {
            padding-right: 20px;
          }
        }
        .digital_marketing_div-80 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-80 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .digital_marketing_column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 71%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-10 {
            width: 100%;
          }
        }
        .digital_marketing_div-81 {
          justify-content: center;
          color: #fff;
          white-space: nowrap;
          background-color: #032445;
          flex-grow: 1;
          align-items: start;
          width: 100%;
          margin: 0 auto;
          padding: 42px 20px;
          font: 400 24px/26px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-81 {
            max-width: 100%;
            white-space: initial;
            margin-top: 40px;
          }
        }
        .digital_marketing_column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 29%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-11 {
            width: 100%;
          }
        }
        .digital_marketing_div-82 {
          justify-content: center;
          color: #fff;
          text-align: center;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 2px;
          border: 2px solid #fff;
          width: 100%;
          margin: auto;
          padding: 21px 20px;
          font: 500 15px/21px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-82 {
            white-space: initial;
            margin-top: 40px;
          }
        }
        .digital_marketing_div-83 {
          background-color: #fff;
          align-self: stretch;
          display: flex;
          margin-top: 25px;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 50px 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-83 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-84 {
          display: flex;
          width: 1155px;
          max-width: 100%;
          flex-direction: column;
          margin: 14px 0 10px;
        }
        .digital_marketing_div-85 {
          justify-content: center;
          color: #23bbee;
          text-align: center;
          text-transform: capitalize;
          align-self: center;
          white-space: nowrap;
          font: 700 32px/35px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-85 {
            white-space: initial;
          }
        }
        .digital_marketing_div-86 {
          background-color: #23bbee;
          align-self: center;
          display: flex;
          margin-top: 21px;
          width: 50px;
          height: 3px;
          flex-direction: column;
        }
        .digital_marketing_div-87 {
          justify-content: center;
          color: #525252;
          text-align: center;
          align-self: center;
          margin-top: 16px;
          max-width: 1027px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-87 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-88 {
          align-self: stretch;
          margin-top: 44px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-88 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .digital_marketing_div-89 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-89 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .digital_marketing_column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 55%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-12 {
            width: 100%;
          }
        }
        .digital_marketing_img-18 {
          aspect-ratio: 1.66;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .digital_marketing_img-18 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .digital_marketing_column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 45%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-13 {
            width: 100%;
          }
        }
        .digital_marketing_div-90 {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 5px 39px 15px 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-90 {
            max-width: 100%;
            margin-top: 40px;
            padding-right: 20px;
          }
        }
        .digital_marketing_div-91 {
          justify-content: center;
          color: #333;
          font: 700 23px/32px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-91 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-92 {
          display: flex;
          margin-top: 34px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-92 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-19 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-93 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-94 {
          display: flex;
          margin-top: 9px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-94 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-20 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-95 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-96 {
          display: flex;
          margin-top: 9px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-96 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-21 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-97 {
          justify-content: center;
          color: #525252;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-98 {
          display: flex;
          margin-top: 9px;
          align-items: start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-98 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-22 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 15px;
          overflow: hidden;
          max-width: 100%;
        }
        .digital_marketing_div-99 {
          justify-content: center;
          color: #000;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-100 {
          background-color: #fff;
          align-self: stretch;
          z-index: 1;
          display: flex;
          margin-top: -6px;
          width: 100%;
          flex-direction: column;
          padding: 50px 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-100 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-101 {
          justify-content: center;
          color: #23bbee;
          text-align: center;
          text-transform: capitalize;
          align-self: center;
          margin-top: 11px;
          white-space: nowrap;
          font: 700 32px/35px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-101 {
            white-space: initial;
          }
        }
        .digital_marketing_div-102 {
          background-color: #23bbee;
          align-self: center;
          display: flex;
          margin-top: 20px;
          width: 50px;
          height: 3px;
          flex-direction: column;
        }
        .digital_marketing_div-103 {
          justify-content: center;
          color: #525252;
          text-align: center;
          align-self: center;
          margin-top: 17px;
          max-width: 874px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-103 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-104 {
          align-self: center;
          margin-top: 52px;
          width: 1121px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-104 {
            margin-top: 40px;
          }
        }
        .digital_marketing_div-105 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-105 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .digital_marketing_column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 55%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-14 {
            width: 100%;
          }
        }
        .digital_marketing_img-23 {
          aspect-ratio: 1.5;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .digital_marketing_img-23 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .digital_marketing_column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 45%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_column-15 {
            width: 100%;
          }
        }
        .digital_marketing_div-106 {
          opacity: 0.9798;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 6px 14px 0;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-106 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .digital_marketing_div-107 {
          justify-content: center;
          color: #333;
          align-self: stretch;
          font: 700 23px/32px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-107 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-108 {
          align-self: stretch;
          display: flex;
          margin-top: 12px;
          align-items: end;
          justify-content: space-between;
          gap: 3px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-108 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_div-109 {
          display: flex;
          margin-top: 36px;
          flex-basis: 0%;
          flex-direction: column;
          align-items: center;
        }
        .digital_marketing_img-24 {
          aspect-ratio: 22;
          object-fit: contain;
          object-position: center;
          width: 22px;
          overflow: hidden;
        }
        .digital_marketing_img-25 {
          aspect-ratio: 22;
          object-fit: contain;
          object-position: center;
          width: 22px;
          overflow: hidden;
          margin-top: 76px;
        }
        @media (max-width: 991px) {
          .digital_marketing_img-25 {
            margin-top: 40px;
          }
        }
        .digital_marketing_img-26 {
          aspect-ratio: 22;
          object-fit: contain;
          object-position: center;
          width: 22px;
          overflow: hidden;
          margin-top: 52px;
        }
        @media (max-width: 991px) {
          .digital_marketing_img-26 {
            margin-top: 40px;
          }
        }
        .digital_marketing_div-110 {
          align-self: start;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-110 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-111 {
          justify-content: center;
          color: #525252;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-111 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-112 {
          justify-content: center;
          color: #525252;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-112 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-113 {
          align-self: stretch;
          display: flex;
          margin-top: 17px;
          align-items: end;
          justify-content: space-between;
          gap: 3px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-113 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .digital_marketing_img-27 {
          aspect-ratio: 21;
          object-fit: contain;
          object-position: center;
          width: 21px;
          overflow: hidden;
          margin-top: 35px;
          max-width: 100%;
        }
        .digital_marketing_div-114 {
          justify-content: center;
          color: #525252;
          align-self: stretch;
          flex-grow: 1;
          flex-basis: auto;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-114 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-115 {
          justify-content: center;
          color: #525252;
          align-self: center;
          margin-top: 13px;
          max-width: 396px;
          font: 500 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-116 {
          disply: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          min-height: 505px;
          width: 100%;
          margin: 90px 0 43px;
          padding: 50px 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-116 {
            max-width: 100%;
            margin: 40px 0;
          }
        }
        .digital_marketing_div-117 {
          position: relative;
          display: flex;
          width: 1133px;
          max-width: 100%;
          flex-direction: column;
          margin: 14px 0 10px;
        }
        .digital_marketing_div-118 {
          justify-content: center;
          color: #23bbee;
          text-align: center;
          text-transform: capitalize;
          align-self: center;
          white-space: nowrap;
          font: 700 32px/35px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-118 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .digital_marketing_div-119 {
          background-color: #23bbee;
          align-self: center;
          display: flex;
          margin-top: 20px;
          width: 50px;
          height: 3px;
          flex-direction: column;
        }
        .digital_marketing_div-120 {
          justify-content: center;
          color: #fff;
          margin-top: 17px;
          font: italic 400 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-120 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-121 {
          justify-content: center;
          color: #fff;
          margin-top: 30px;
          font: 400 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-121 {
            max-width: 100%;
          }
        }
        .digital_marketing_div-122 {
          justify-content: center;
          color: #000;
          text-align: center;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 2px;
          border: 2px solid #fac42e;
          background-color: #fac42e;
          align-self: center;
          margin-top: 26px;
          width: 180px;
          max-width: 100%;
          padding: 21px 20px;
          font: 500 15px/23px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-122 {
            white-space: initial;
          }
        }
        .digital_marketing_div-123 {
            justify-content: center;
            align-items: center;
            box-shadow: 0px 15px 12px 0px rgba(0, 0, 0, 0.09),
              0px 19px 38px 0px rgba(0, 0, 0, 0.07);
            background-color: rgba(255, 255, 255, 0);
            align-self: center; /* Updated to center */
            display: flex;
            margin: auto; /* Adjust the margin as needed */
            width: 458px;
            max-width: 100%;
            flex-direction: column;
            padding: 39px 20px;
          }
          
        .digital_marketing_div-124 {
          justify-content: center;
          align-items: flex-start;
          background-color: #fff;
          display: flex;
          width: 388px;
          max-width: 100%;
          flex-direction: column;
        }
        .digital_marketing_div-125 {
          justify-content: center;
          color: #999;
          white-space: nowrap;
          align-items: start;
          border-radius: 4px;
          border: 1px solid #f8f8f8;
          box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.08) inset;
          background-color: #f8f8f8;
          align-self: stretch;
          height: 40px;
          font: 400 14px Roboto, sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-125 {
            white-space: initial;
          }
        }
        .digital_marketing_div-126 {
          justify-content: center;
          color: #999;
          white-space: nowrap;
          align-items: start;
          border-radius: 4px;
          border: 1px solid #f8f8f8;
          box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.08) inset;
          background-color: #f8f8f8;
          align-self: stretch;
          margin-top: 15px;
          height:40px;
          font: 400 14px Roboto, sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-126 {
            white-space: initial;
          }
        }
        .digital_marketing_div-127 {
          justify-content: center;
          color: #999;
          white-space: nowrap;
          align-items: start;
          border-radius: 4px;
          border: 1px solid #f8f8f8;
          box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.08) inset;
          background-color: #f8f8f8;
          align-self: stretch;
          margin-top: 15px;
          padding: 12px 13px;
          font: 400 14px Roboto, sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-127 {
            white-space: initial;
          }
        }
        .digital_marketing_div-128 {
          border-radius: 4px;
          border: 1px solid #f8f8f8;
          box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.08) inset;
          background-color: #f8f8f8;
          align-self: stretch;
          display: flex;
          margin-top: 15px;
          height: 114px;
          flex-direction: column;
        }
        .digital_marketing_div-129 {
          justify-content: center;
          color: #000;
          text-align: center;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 2px;
          border: 2px solid #fac42e;
          background-color: #fac42e;
          align-self: start;
          margin-top: 8px;
          width: 180px;
          max-width: 100%;
          padding: 22px 20px;
          font: 500 15px/23px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-129 {
            white-space: initial;
          }
        }
        .digital_marketing_div-130 {
          justify-content: center;
          align-items: center;
          background-color: #23bbee;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          margin: 136px 0 0;
          padding: 35px 20px;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-130 {
            max-width: 100%;
            margin: 40px 0;
          }
        }
        .digital_marketing_div-131 {
          display: flex;
          width: 332px;
          max-width: 100%;
          justify-content: space-between;
          gap: 20px;
        }
        .digital_marketing_div-132 {
          justify-content: center;
          color: #fff;
          text-align: center;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 16px/24px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .digital_marketing_div-133 {
          justify-content: center;
          color: #fff;
          text-align: center;
          align-self: start;
          white-space: nowrap;
          font: 400 14px/21px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .digital_marketing_div-133 {
            white-space: initial;
          }
        }
      `}</style>
    </>
  );
}

export default Digital_Marketing;

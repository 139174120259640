import { db, storage } from './Firebase';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { PiNumberCircleOneBold, PiNumberCircleTwoBold, PiNumberCircleThreeBold, PiNumberCircleFourBold } from 'react-icons/pi';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import { useHistory } from 'react-router-dom';

import './Product_Detail_Page.css';

const  School_Product_Form = () => {
  const shipping_options = [
    { value: 'open', label: 'I am open to all suggestions' },
    { value: 'Home Deliver', label: 'Deliver to my door' },
    { value: 'Freight Forwarder', label: 'I want my order shipped to my freight forwarder' },
    // Add more options as needed
    
  ];
  const location = useLocation();
  const { orderDetails } = location.state || {};
  let { productID } = useParams();
  const [product, setProduct] = useState(null);
  const history = useHistory();
  const [address, setAddress] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [orderQuantity, setOrderQuantity] = useState(1); // default quantity is 1
  const [orderAmount, setOrderAmount] = useState(0);

  const [contactInfoError, setContactInfoError] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState(shipping_options[0].value);
  
  const [addressError, setAddressError] = useState(false);
  const [isFooterFloating, setIsFooterFloating] = useState(false);
  


  useEffect(() => {
    const productRef = db.collection('Product/Products/Writing').doc(productID);

    productRef.get().then((doc) => {
      if (doc.exists) {
        setProduct(doc.data());
      } else {
        setProduct(null);
      }
    });
  }, [productID]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 100; // Adjust this threshold as needed
      const shouldFloat = window.scrollY > scrollThreshold;
      setIsFooterFloating(shouldFloat);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handlePhoneNumberChange = (e) => {
    // Allow only numeric input
    const input = e.target.value.replace(/\D/g, '');

    // Restrict the length to 10 characters
    const limitedInput = input.slice(0, 10);

    setPhoneNumber(limitedInput);

    if (limitedInput.length !== 10) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  const handleFileChange = (e) => {
    if (e && e.target) {
      const file = e.target.files[0];
      setSelectedFile(file);

      // Automatically upload the file to Firebase Storage
      uploadFile(file);
    }
  };

  const uploadFile = (file) => {
    const storageRef = storage.ref(`/Order Uploads/${file.name}`);
    const uploadTask = storageRef.put(file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Progress
      },
      (error) => {
        // Error handling
        console.error(error);
        // Add code to inform the user about the error
      },
      () => {
        // Success, get file URL
        storageRef.getDownloadURL().then((url) => {
          setFileURL(url);
        });
      }
    );
  };

  const handleFileOpen = () => {
    // Trigger download when the user clicks on the uploaded file name
    if (fileURL) {
      window.open(fileURL, '_blank');
    }
  };

  const handleQuantityChange = (e) => {
    const quantity = parseInt(e.target.value, 10) || 1; // ensure it's a number, default to 1
    setOrderQuantity(quantity);
  };

  const handleIncrement = () => {
    setOrderQuantity(orderQuantity + 1);
  };

  const handleDecrement = () => {
    if (orderQuantity > 1) {
      setOrderQuantity(orderQuantity - 1);
    }
  };

  const validateContactInfo = (value) => {
    // Validate if the input is a valid phone number or email address
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return phoneRegex.test(value) || emailRegex.test(value);
  };

  const calculateOrderAmount = (priceMatches, quantity) => {
    if (!priceMatches) return 0;

    if (priceMatches.length === 1) {
      return parseFloat(priceMatches[0].replace(/[₹,]/g, '')) * quantity;
    }

    const [minPrice, maxPrice] = priceMatches.map((price) =>
      parseFloat(price.replace(/[₹,]/g, ''))
    );

    return ((minPrice + maxPrice) / 2) * quantity;
  };

  // Extract price from the product price string
  const priceMatches = product?.price?.match(/₹[\d,.-]+/g);

  useEffect(() => {
    // Calculate the order amount based on quantity and extracted price
    const newOrderAmount = calculateOrderAmount(priceMatches, orderQuantity);
    setOrderAmount(newOrderAmount);
  }, [orderQuantity, priceMatches]);

  const handleContactInfoChange = (e) => {
    setContactInfo(e.target.value);
    setContactInfoError(!validateContactInfo(e.target.value));
  };

  const handleDeliveryOptionChange = (selectedOption) => {
    setDeliveryOption(selectedOption.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setAddressError(e.target.value.trim() === ''); // Address is mandatory
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submit button clicked');

    // Validate contact information
    if (!validateContactInfo(contactInfo)) {
      setContactInfoError(true);
      return; // Don't proceed with the submission
    }
    setContactInfoError(false);

    // Validate address
    if (address.trim() === '') {
      setAddressError(true);
      return; // Don't proceed with the submission
    }
    setAddressError(false);

    // Save order data only when the file URL is available
    console.log('About to call saveOrderData');
  
      console.log('About to call saveOrderData Inside');
      saveOrderData();
    
  };


  const saveOrderData = async () => {
    console.log('saveOrderData function called');
    const ordersRef = db.collection('orders');

    const orderData = {
      productID: productID,
      productName: product.name,
      productPrice: product.price,
      phoneNumber: contactInfo,
      fileURL: fileURL,
      orderQuantity: orderQuantity,
      orderAmount: orderAmount,
      deliveryOption: deliveryOption,
      address: address,
    };
    console.log('Order Data:', orderData); 
    try {
      // Use add to let Firestore generate a unique document ID
      await ordersRef.add(orderData);

      // Reset fields
      setPhoneNumber('');
      setSelectedFile(null);
      setFileURL(null);
      setContactInfo('');
      setDeliveryOption(shipping_options[0].value);
      setAddress('');
      setOrderQuantity(1);
      setOrderAmount(0);

      // Optionally, you can show a success message to the user
      alert('Order submitted successfully!');
      history.push('/Solutions');
    } catch (error) {
      // Handle any errors that may occur during data submission

      console.error('Error submitting order:', error);
      alert('Error submitting order. Please try again.');
    }
  };

  return (
    <div className='order_request_page'>
      <div className='Heading'>

        <h1>Send Order Request</h1>
        <ul>
        {orderDetails &&
          orderDetails.map((product) => (
            <li key={product.id}>
              {product.name} - Quantity: {product.quantity} - Price: ₹{product.price}
              {/* Add other product details as needed */}
            </li>
          ))}
      </ul>
      </div>

      <div className='product_background'>
        <div className='product_detail_title'>Product Details</div>

        {product ? (
          <div className='product_info'>
            <div className='product_img'>
              <img src={product.image} alt={product.name} />
            </div>
            <div className='product_name_container'>
              <div className='product_name'>{product.name}</div>
              <div className='divider-line'></div>
              <div className='product_section'>
                <div className='product_price'>{product.price}</div>
                <div className='quantity_button_container' style={{ width: '300px' }}>
                  <div className='Quantity_Show'> Quantity </div>
                  <div className='quantity-input'>
                    <button className='quantity-btn-left' onClick={handleDecrement}>
                      -
                    </button>
                    <input
                      className='input_number'
                      type='number'
                      value={orderQuantity}
                      onChange={handleQuantityChange}
                    />
                    <button className='quantity-btn-right' onClick={handleIncrement}>
                      +
                    </button>
                  </div>
                </div>
                <div className='total_amount_container'>
                  <div className='total_amount_header'>Total Amount</div>
                  <div className='total_amount'>₹{orderAmount.toFixed(2)}</div>
                  <div className='approx'>(Approx)</div>
                </div>
              </div>
              <div className='divider-line'></div>

              <div className='lower_section'>
                <div className='inpur_section'>
                  <div className='asking_details'>Enter your details for order request</div>
                  <label className='phone_number_label'>
                    <input
                      type='text'
                      value={contactInfo}
                      onChange={handleContactInfoChange}
                      className={contactInfoError ? 'error' : ''}
                      placeholder='Phone Number or Email (Required)'
                      required
                      style={{
                        width: '500px',
                        height: '30px',
                        borderRadius: '5px',
                        border: contactInfoError ? '2px solid red' : '1px solid #ccc',
                        padding: '5px',
                        outline: 'none',
                      }}
                    />
                    {contactInfoError && (
                      <span
                        style={{
                          marginLeft: '5px',
                          color: 'red',
                          height: '10px',
                          width: '10px',
                        }}
                        role='img'
                        aria-label='info'
                      >
                        <HiOutlineInformationCircle />
                      </span>
                    )}
                  </label>
                  <div className='attach_file_text'>
                    Attach supporting files that help us better understand your customized requirements
                  </div>
                  <div className='file-upload-container'>
                    {!selectedFile && (
                      <>
                        <label htmlFor='file-input' className='custom-file-upload'>
                          <i className='fas fa-cloud-upload-alt'></i> Choose File
                        </label>
                        <input
                          id='file-input'
                          type='file'
                          accept='.gif, .jpeg, .png, .tif, .docx, .doc, .xlsx, .xls, .txt, .pdf'
                          onChange={handleFileChange}
                        />
                      </>
                    )}
                    {selectedFile && (
                      <div className='uploaded-file'>
                        <p>
                          <button className='file-link' onClick={handleFileOpen}>
                            {selectedFile.name}
                          </button>
                        </p>
                        <button className='button_2' onClick={() => setSelectedFile(null)}>
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Product not found</p>
        )}
      </div>
      <div className='shippment_details'>
        <div className='shipping_detail_text'>Shipping Details</div>

        <div className='col-xs-12 col-sm-7 example-col'>
          <div className='delivery_option'>Select your delivery preference</div>
          <Select
            className='custom-select-control'
            classNamePrefix='custom-select'
            options={shipping_options}
            defaultValue={shipping_options[0]}
            isSearchable={false}
            onChange={handleDeliveryOptionChange}
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary: '#2684FF',
              },
            })}
          />
        </div>

        <div className='address-input-container'>
          <label htmlFor='address' className='address-label'>
            Enter your address:
          </label>
          <textarea
            id='address'
            className={`address-input ${addressError ? 'error' : ''}`}
            placeholder='Your address...'
            value={address}
            onChange={handleAddressChange}
            style={{
              width: '500px',
              height: '100px', // Adjusted height
              borderRadius: '5px',
              border: addressError ? '2px solid red' : '1px solid #ccc',
              padding: '5px',
              outline: 'none',
            }}
          />
          {addressError && (
            <span
              style={{
                marginLeft: '5px',
                color: 'red',
                height: '10px',
                width: '10px',
              }}
              role='img'
              aria-label='info'
            >
              <HiOutlineInformationCircle />
            </span>
          )}
        </div>
      </div>

      <div className='next_steps'>
        <div className='next_step_title'>Next Step of the Process:-</div>
        <div className='steps'>
          <div className='step'>
            <div className='icon'>
              <PiNumberCircleOneBold />
            </div>
            <div>Submit Order Reqest</div>
          </div>
          <div className='step'>
            <div className='arrow'>
              <HiArrowLongRight />
            </div>
            <div className='icon'>
              <PiNumberCircleTwoBold />
            </div>
            <div>Negotiate Deal with us</div>
          </div>
          <div className='step'>
            <div className='arrow'>
              <HiArrowLongRight />
            </div>
            <div className='icon'>
              <PiNumberCircleThreeBold />
            </div>
            <div>Receive Sample or if necessary</div>
          </div>
          <div className='step'>
            <div className='arrow'>
              <HiArrowLongRight />
            </div>
            <div className='icon'>
              <PiNumberCircleFourBold />
            </div>
            <div>Make payment for bulk order and wait for delivery</div>
          </div>
        </div>
      </div>
      <div className='footer' style={{ position: isFooterFloating ? 'fixed' : 'relative', bottom: 0, width: '100%', textAlign: 'center', padding: '10px', background: '#fff' }}>
        <button type='button' onClick={handleSubmit} className='submit_button_container'>
          <div className='submit_button_text'>
            Submit
          </div>
        </button>
      </div>
    </div>
  );
};

export default  School_Product_Form;

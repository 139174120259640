import React, { useContext } from 'react';
import { EnvelopsContext } from './Envelops_data'; // Import the EnvelopsContext
import './Product_Test.css';

function Envelops() {
  const { envelops } = useContext(EnvelopsContext); // Use EnvelopsContext here
  console.log(envelops);

  return (
    <>
      {envelops.length !== 0 && <h1>Envelops</h1>}
      <div className='products-container'>
        {envelops.length === 0 && <div>slow internet...no products to display</div>}
        {envelops.map(product => (
          <div className='product-card' key={product.ProductID}>
            <div className='product-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-name'>
              {product.ProductName}
            </div>
            <div className='product-name'>
              {product.ProductPrice}
            </div>
            <button className='addcart-btn' >ADD TO CART</button>
          </div>
        ))}
      </div>
    </>
  );
}

export default Envelops;

import React, { useContext, useState } from 'react';
import { BillBooksContext } from './BillBooksData';
import { MedicalUniformsContext } from './Medical_Uniforms_Data';
import { IoCall } from 'react-icons/io5';
import './Product_Test.css';
import { SchoolCategoryContext } from './SchoolCategory_Data';
import { School_Category_UniformContext } from './School_Uniform_Display_Data';
import { School_Category_StationaryContext } from './School_Category_Stationary_Data';
import { Stationary_Display_Context } from './Stationary_Display_Data';
import { ID_Cards_Display_Context } from './ID_Cards_Display_Data';
import { IDCardsContext } from './ID_Cards_Data';
import { School_Category_AccessoriesContext } from './School_Accessories_DisplayData';
import { Linen_Display_Context } from './Linen_Display_Data';
import { Link } from "react-router-dom";
import { Printing_Display_Context } from './Printing_Display_Data';
import { Writing_Supplies_Display_Context } from './Writing_Supplies_Display_Data';
import { HospitalCategoryContext } from './Hospital_Category_Data';




function Hotel_Supplies() {
const{writing_supplies_display } = useContext(Writing_Supplies_Display_Context);
const { billbooks } = useContext(BillBooksContext);
const {medicaluniforms} = useContext(MedicalUniformsContext);
const {schoolcategory} = useContext(SchoolCategoryContext);
const {school_category_stationary} = useContext(Stationary_Display_Context);
const {idcards} = useContext(ID_Cards_Display_Context);
const {school_category_uniform} = useContext(School_Category_UniformContext);
const {school_category_accessories} = useContext(School_Category_AccessoriesContext);
const {linen_display} = useContext(Linen_Display_Context);
const {printing_display} = useContext(Printing_Display_Context);
const {hospitalcategory} = useContext(HospitalCategoryContext);
console.log(printing_display);
console.log(school_category_accessories);
console.log(writing_supplies_display);
console.log(billbooks);
console.log(medicaluniforms);
console.log(schoolcategory);
console.log(school_category_uniform);
console.log(school_category_stationary);
console.log(idcards);
console.log(linen_display);
const [school_category_accessoriesIndex, setSchool_Category_AccessoriesIndex] = useState(0);
const [billbooksIndex, setBillBooksIndex] = useState(0); // State for BillBooks product display
const [medicaluniformsIndex, setMedicalUniformsIndex] = useState(0);
const [schoolcategoryIndex, setSchoolCategory] = useState(0);
const [school_category_uniformIndex, setSchool_Category_UniformIndex] = useState(0);
const [school_category_stationaryIndex, setSchool_Category_StationaryIndex] = useState(0);
const [idcardsIndex, setidcardsIndex] = useState(0);
const [linen_displayIndex, setLinen_DisplayIndex] = useState(0);
/*const billbooksSlideStyle = {
    transform: `translateX(-${billbooksIndex * 5}%)`, // Adjust 'billbooksIndex' as needed
  };

  const medicaluniformsSlideStyle = {
    transform: `translateX(-${medicaluniformsIndex * 5}%)`, // Adjust 'medicaluniformsIndex' as needed
  };
*/

const school_category_accessoriescontainerWidth = 10;
const numschool_category_accessories = school_category_accessories.lenght;
const school_category_accessoriesWidth = school_category_accessoriescontainerWidth/(numschool_category_accessories-1);
const school_category_accessoriesSlideStyle = {
  transform: `translateX(-${school_category_accessoriesIndex *school_category_accessoriesWidth}%)`,
};

const idcardscontainerWidth = 10;
const numidcards = idcards.length;
const idcardsWidth = idcardscontainerWidth/(numidcards -1);
const idcardsSlideStyle = {
  transform: `translateX(-${idcardsIndex *idcardsWidth}%)`,
};


const school_category_stationarycontainerWidth = 10; // Adjust this to the width of your product container
const numschool_category_stationary = school_category_stationary.length; // Replace with the actual number of products
const school_category_stationaryWidth = school_category_stationarycontainerWidth / (numschool_category_stationary -1);


const school_category_stationarySlideStyle = {
    transform: `translateX(-${school_category_stationaryIndex *school_category_stationaryWidth}%)`,
  };
  const medicaluniformscontainerWidth = 10; // Adjust this to the width of your product container
const nummedicaluniforms = medicaluniforms.length; // Replace with the actual number of products
const medicaluniformsWidth = medicaluniformscontainerWidth / (nummedicaluniforms -1);
  

  const medicaluniformsSlideStyle = {
    transform: `translateX(-${medicaluniformsIndex * medicaluniformsWidth}%)`,
  };
const schoolcategorycontainerWidth = 10;
const numschoolcategory = schoolcategory.length;
const schoolcategoryWidth = schoolcategorycontainerWidth/(numschoolcategory -1);
const schoolcategorySlideStyle = {
    transform: `translateX(-${schoolcategoryIndex *schoolcategoryWidth}%)`,
};

const  school_category_uniformcontainerWidth = 10;
const numschool_category_uniform = school_category_uniform.length;
const  school_category_uniformWidth = school_category_uniformcontainerWidth/(numschool_category_uniform-1);
const school_category_uniformSlideStyle = {
    transform: `translateX(-${school_category_uniformIndex *school_category_uniformWidth}%)`,
};


// Function to handle moving to the previous product
/*const prevBillBooksSlide = () => {
    setBillBooksIndex((prevIndex) => (prevIndex === 0 ? billbooks.length - 1 : prevIndex - 1));
  };
  
  const nextBillBooksSlide = () => {
    setBillBooksIndex((prevIndex) => (prevIndex === billbooks.length - 1 ? 0 : prevIndex + 1));
  };  

  const prevMedicalUniformsSlide = () => {
    setMedicalUniformsIndex((prevIndex) => (prevIndex === 0 ? medicaluniforms.length - 1 : prevIndex - 1));
  };
  
  const nextMedicalUniformsSlide = () => {
    setMedicalUniformsIndex((prevIndex) => (prevIndex === medicaluniforms.length - 1 ? 0 : prevIndex + 1));
  };
  */
  const productRedirects = {
    "Uniforms": "/Uniforms",
    "Accessories": "/Accessories",
    "ID-Cards": "/ID-Cards",
    "Writing Supplies": "/Writing_Supplies",
    "Stationary": "/Stationary",
    "Linen":  "/Linen",
    "Printing Supplies": "/Printing_Supplies",
  
  
    // Add more mappings as needed
  };

  const prevSchool_Category_StationarySlide = () => {
    setSchool_Category_StationaryIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1; // Move to the previous product if not at the beginning
      }
      return prevIndex; // Stay at the beginning
    });
  };

  // Function to slide to the next BillBooks product
  const nextSchool_Category_StationarySlide = () => {
    setSchool_Category_StationaryIndex((prevIndex) => {
      if (prevIndex < school_category_stationary.length - 1) {
        return prevIndex + 1; // Move to the next product if not at the end
      }
      return prevIndex; // Stay at the end
    });
  };

  // Function to slide to the previous Medical Uniforms product
  const previdcardsSlide = () => {
    setidcardsIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1; // Move to the previous product if not at the beginning
      }
      return prevIndex; // Stay at the beginning
    });
  };

  // Function to slide to the next Medical Uniforms product
  const nextidcardsSlide = () => {
    setidcardsIndex((prevIndex) => {
      if (prevIndex < medicaluniforms.length - 1) {
        return prevIndex + 1; // Move to the next product if not at the end
      }
      return prevIndex; // Stay at the end
    });
  };

  const prevSchool_Category_UniformSlide = () => {
    setSchool_Category_UniformIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1; // Move to the previous product if not at the beginning
      }
      return prevIndex; // Stay at the beginning
    });
  };

  // Function to slide to the next Medical Uniforms product
  const nextSchool_Category_UniformSlide = () => {
    setSchool_Category_UniformIndex((prevIndex) => {
      if (prevIndex < school_category_uniform.length - 1) {
        return prevIndex + 1; // Move to the next product if not at the end
      }
      return prevIndex; // Stay at the end
    });
  };

  const prevSchool_Category_AccessoriesSlide = () => {
    setSchool_Category_AccessoriesIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1; // Move to the previous product if not at the beginning
      }
      return prevIndex; // Stay at the beginning
    });
  };

  // Function to slide to the next Medical Uniforms product
  const nextSchool_Category_AccessoriesSlide = () => {
    setSchool_Category_AccessoriesIndex((prevIndex) => {
      if (prevIndex < school_category_accessories.length - 1) {
        return prevIndex + 1; // Move to the next product if not at the end
      }
      return prevIndex; // Stay at the end
    });
  };






return (
<div >
<div className='page_header_links'>
        <div className='pade_header_page_link_left'>
          <div className='page_header_about_us'>
            About Us
          </div>
          <div className='page_header_writing_customization'>
            Writing Customization
          </div>
          <div className='page_header_shipping_options'>
              Shipping Options
          </div>
        </div>
        <div className='page_header_blank_space'>

        </div>
        <div className='pade_header_page_link_right'>
          <div className='page_header_contact'>
            <IoCall/>
            < div>
              7478004111
            </div>
          </div>
          <div className='page_header_help'>
            Help
          </div>
        </div>

      </div>
{billbooks.length !== 0 && 
<section className='page-name'>
</section>
}
<div className = 'category_display'>
<div className='category_heading' align= 'center'>
Hostel Supply Categories
</div>
<div className='category-card-container' align = 'center'>
{hospitalcategory.map(product => (
      productRedirects[product.ProductName] ? (
        <Link to={productRedirects[product.ProductName]} className='category-card' key={product.ProductID}>
          <div className='category-img'>
            <img src={product.ProductImg} alt="not found" />
          </div>
          <div className='category-name'>
            {product.ProductName}
          </div>
        </Link>
      ) : (
        <div className='category-card' key={product.ProductID} onClick={() => alert(`No page defined for ${product.ProductName}`)}>
          <div className='category-img'>
            <img src={product.ProductImg} alt="not found" />
          </div>
          <div className='category-name'>
            {product.ProductName}
          </div>
        </div>
      )
    ))}
</div>
</div>
<div className='product_display'>
<div className='product_heading'>
Hotel Uniforms
</div>
<div className='show_more'>
  <Link to = "/Uniforms" >
  Show More
  </Link>
</div>
<div className='divider-line'></div>
<div className='product-sliding-container'>
{/* "Prev" button */}

{/* Products container */}
<div className='product-slides' >
{school_category_uniform.map((product, index) => (
  
<Link to={`/SchoolUniform/${product.ProductID}`} className={`product-sliding-card ${index === school_category_uniformIndex ? 'active' : ''}`} key={product.ProductID}>
<div className='product-sliding-img'>
<img src={product.ProductImg} alt="not found" />
</div>
<div className='product-sliding-name ellipsis'>
{product.ProductName}
</div>
<div className='product-sliding-price'>{product.ProductPrice}</div>
</Link>

))}
</div>
{/* "Next" button */}

</div>

</div>

<div className='product_display'>
  <div className='product_heading'>
  Hotel Stationary
  </div>
  <div className='show_more'>
  <Link to = "/Stationary" >
  Show More
  </Link>
</div>
<div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides' >
      {school_category_stationary.map((product, index) => (
      <Link to={`/Stationary/${product.ProductID}`} className={`product-sliding-card ${index === school_category_stationaryIndex ? 'active' : ''}`} key={product.ProductID}>
        <div className='product-sliding-img'>
          <img src={product.ProductImg} alt="not found" />
        </div>
        <div className='product-sliding-name ellipsis'>
                {product.ProductName}
        </div>
        <div className='product-sliding-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>
      {/* "Next" button */}

  </div>

</div>
<div className='product_display'>
  <div className='product_heading'>
  Hostel ID Cards
  </div>
  <div className='show_more'>
  <Link to = "/ID-Cards" >
  Show More
  </Link>
</div>
<div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides' >
      {idcards.map((product, index) => (
        
        <Link to={`/ID-Cards/${product.ProductID}`}  className={`product-sliding-card ${index === idcardsIndex ? 'active' : ''}`} key={product.ProductID}>
            <div className='product-sliding-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-sliding-name ellipsis'>
                {product.ProductName}
        </div>
        <div className='product-sliding-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>
      {/* "Next" button */}

  </div>

</div>
<div className='product_display'>
  <div className='product_heading'>
  Hotel Linen
  </div>
  <div className='show_more'>
  <Link to = "/Linen" >
  Show More
  </Link>
</div>
<div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides' >
      {linen_display.map((product, index) => (
        
        <Link to={`/Linen/${product.ProductID}`}  className={`product-sliding-card ${index === idcardsIndex ? 'active' : ''}`} key={product.ProductID}>
            <div className='product-sliding-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-sliding-name ellipsis'>
                {product.ProductName}
        </div>
        <div className='product-sliding-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>
      {/* "Next" button */}

  </div>

</div>

<div className='product_display'>
  <div className='product_heading'>
  Hotel Accessories
  </div>
  <div className='show_more'>
  <Link to = "/Accessories" >
  Show More
  </Link>

</div>
<div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides' >
      {school_category_accessories.map((product, index) => (
        
        <Link to={`/Accessories/${product.ProductID}`}  className={`product-sliding-card ${index === idcardsIndex ? 'active' : ''}`} key={product.ProductID}>
            <div className='product-sliding-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-sliding-name ellipsis'>
                {product.ProductName}
        </div>
        <div className='product-sliding-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>
      {/* "Next" button */}

  </div>

</div>
<div className='product_display'>
  <div className='product_heading'>
  Hotel Printing Supplies
  </div>
  <div className='show_more'>
  <Link to = "/Printing_Supplies" >
  Show More
  </Link>
</div>
<div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides' >
      {printing_display.map((product, index) => (
        
        <Link to={`/Printing_Supplies/${product.ProductID}`}  className={`product-sliding-card ${index === idcardsIndex ? 'active' : ''}`} key={product.ProductID}>
            <div className='product-sliding-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-sliding-name ellipsis'>
                {product.ProductName}
        </div>
        <div className='product-sliding-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>

  </div>

</div>
<div className='product_display'>
  <div className='product_heading'>
  Hotel Writing Supplies
  </div>
  <div className='show_more'>
  <Link to = "/Writing_Supplies" >
  Show More
  </Link>
</div>
<div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides' >
      {writing_supplies_display.map((product, index) => (
        
        <Link to={`/Writing_Supplies/${product.ProductID}`}  className={`product-sliding-card ${index === idcardsIndex ? 'active' : ''}`} key={product.ProductID}>
            <div className='product-sliding-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-sliding-name ellipsis'>
                {product.ProductName}
        </div>
        <div className='product-sliding-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>

  </div>

</div>



</div>
)
}

export default Hotel_Supplies;

import React, { useState } from 'react';
import './UPIPaymentGateway.css';

const UPIPaymentGateway = () => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [id, setId] = useState('');
    const [showQR, setShowQR] = useState(false);
    const [qrCodeSrc, setQrCodeSrc] = useState('');

    const generateQRCode = () => {
        setShowQR(true);
        const link = `upi://pay?pa=nktnikhil@oksbi%26am=200%26tn=${number}`;
        const upi = `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${link}`;
        console.log(upi);
        setQrCodeSrc(upi); // Set the QR code source
    };

    const handleDownload = () => {
        if (number !== '' && name !== '' && email !== '' && id !== '') {
            // Perform download action
            // You can send data to server or perform other actions here
        } else {
            alert("Fill all fields correctly");
        }
    };

    return (
        <section>
            <h1>ECOMMERCE WEBSITE CODE</h1>
            <div className="form">
                <div className="flex m20">
                    <label>Full Name*</label>
                    <input type="text" name="name" placeholder="Full Name" className="m10 name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="flex m20">
                    <label>Whatsapp Number(With ISD)*</label>
                    <input type="text" name="phone" placeholder="Ex- +919064973840" className="m10 number" value={number} onChange={(e) => setNumber(e.target.value)} />
                </div>
                <div className="flex m20">
                    <label>Email*</label>
                    <input type="email" name="email" placeholder="Email" className="m10 email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="flex m20">
                    <button className="generate_qr" onClick={generateQRCode}>NEXT</button>
                </div>
            </div>
            {showQR && (
                <div className="qr_code m20">
                    <p>Scan the QR Code with any UPI App and pay the amount then download the source code.</p>
                    <center><img src={qrCodeSrc} alt="QR CODE" className="get_qr m10" /></center> {/* Set src attribute dynamically */}
                    
                    <div className="flex m20">
                        <label>UTR/REFERENCE/TRANSACTION ID**</label>
                        <input type="number" name="email" placeholder="UTR/REFERENCE/TRANSACTION ID**" className="m10 id" value={id} onChange={(e) => setId(e.target.value)} />
                    </div>
                    <button className="download_now m20" onClick={handleDownload}>Download Now</button>
                </div>
            )}
        </section>
    );
};

export default UPIPaymentGateway;

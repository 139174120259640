import React, { useContext } from 'react';
import { DeskOrganizeContext } from './DeskOrganize_Data'; // Updated import statement
import './Product_Test.css';

function DeskOrganize() {
  const { deskorganize } = useContext(DeskOrganizeContext); // Corrected context variable name
  console.log(deskorganize);

  return (
    <div>
      {deskorganize.length !== 0 && (
        <section className='page-name'>
          <h1 className='h1'>Desk Organize</h1>
          <h2 className='h1_sub'>Discover Our Products</h2>
        </section>
      )}

      <div className='category_display'>
        <div className='category_heading' align='center'>
          Source By Categories
        </div>
        <div className='category-card-container' align='center'>
          {deskorganize.map(product => (
            <div className='category-card' key={product.ProductID}>
              <div className='category-img'>
                <img src={product.ProductImg} alt="not found" />
              </div>
              <div className='category-name'>
                {product.ProductName}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='products-container'>
        {deskorganize.length === 0 && <div>Slow internet...no products to display</div>}
        {deskorganize.map(product => (
          <div className='product-card' key={product.ProductID}>
            <div className='product-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-name'>
              {product.ProductName}
            </div>
            <div className='product-name'>
              {product.ProductPrice}
            </div>
            <button className='addcart-btn' >ADD TO CART</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DeskOrganize;

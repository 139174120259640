import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const Linen_Display_Context = createContext();

export const Linen_Display_ContextProvider = ({ children }) => {
  const [linen_display, setLinen_Display] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Product/Products/Linen').get();
        const linen_display_Data = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));

        // Shuffle the array randomly
        const shuffledData = shuffleArray(linen_display_Data);

        // Get the first 10 items (or less if the array has fewer than 10 items)
        const randomItems = shuffledData.slice(0, 10);

        setLinen_Display(randomItems);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  // Function to shuffle an array randomly
  const shuffleArray = (array) => {
    let currentIndex = array.length;
    let randomIndex, tempValue;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      tempValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = tempValue;
    }

    return array;
  };

  return (
    <Linen_Display_Context.Provider value={{ linen_display }}>
      {children}
    </Linen_Display_Context.Provider>
  );
};

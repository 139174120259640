import React, { useState } from 'react';
import { useHistory ,useLocation} from 'react-router-dom';
import './Glenbard.css';

const GlenBardPage = () => {
  const history = useHistory();
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [classError, setClassError] = useState(false);
  const [languageError, setLanguageError] = useState(false);

  // Define classes and languages data
  const classes = ['Nursery', 'Jr KG', 'Sr KG', 'Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 6', 'Class 7'];
  const languages = {
    'Nursery': ['English'],
    'Jr KG': ['English'],
    'Sr KG': ['English'],
    'Class 1': ['English', 'URDU', 'TELUGU'],
    'Class 2': ['English', 'URDU', 'TELUGU'],
    'Class 3': ['English', 'URDU', 'TELUGU'],
    'Class 4': ['English', 'URDU', 'TELUGU'],
    'Class 5': ['English', 'URDU', 'TELUGU'],
    'Class 6': ['English', 'URDU', 'TELUGU'],
    'Class 7': ['English', 'URDU', 'TELUGU']
  };

  const handleClassChange = (e) => {
    const selectedClass = e.target.value;
    setSelectedClass(selectedClass);
    setSelectedLanguage(''); // Reset selected language when class changes
    setClassError(selectedClass === '');
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    setLanguageError(e.target.value === '');
  };

  const handleConfirm = () => {
    setClassError(selectedClass === '');
    setLanguageError(selectedLanguage === '');
    if (selectedClass && selectedLanguage) {
      history.push(`/Glenbard/${selectedClass}/${selectedLanguage}`, { selectedClass, selectedLanguage });
    } else {
      alert('Please select both class and language.');
    }
  };
  

  return (
    <div className='Glenbard_Page'>
    <div className='glenbard_form_section'>
      <h1 className='glenbard_page_heading'>Select Class</h1>
      <h2 className='glenbard_page_subheading'>Select your class and first language</h2>
      <div className={classError ? 'error' : 'glenbard_class_selection'}>
        <label>Class*</label>
        <select value={selectedClass} onChange={handleClassChange} required className='glenbard_select_class_dropdown'>
          <option value="">-- Select Class --</option>
          {/* Populate options dynamically based on available classes */}
          {classes.map((cls, index) => (
            <option key={index} value={cls}>{cls}</option>
          ))}
        </select>
      </div>
      <div className={languageError ? 'error' : 'glenbard_class_selection'}>
        <label>Select Language*</label>
        <select value={selectedLanguage} onChange={handleLanguageChange} required className='glenbard_select_class_dropdown'>
          <option value="">-- Select Language --</option>
          {/* Populate options dynamically based on languages available for the selected class */}
          {selectedClass && languages[selectedClass].map((lang, index) => (
            <option key={index} value={lang}>{lang}</option>
          ))}
        </select>
      </div>
      <button onClick={handleConfirm} className='glenbard_confirm_button'>Confirm</button>
    </div>
    </div>
  );
};

export default GlenBardPage;

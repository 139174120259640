import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const Banner_SignageContext = createContext();

export const Banner_SignageContextProvider = ({ children }) => {
  const [banner_signage, setBannerSignage] = useState([]); // Corrected the state variable name

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Stationary/Banners&Signage/Banners').get();
        const banner_signageData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));
        setBannerSignage(banner_signageData); // Corrected the state setter function
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Banner_SignageContext.Provider value={{ banner_signage }}>
      {children}
    </Banner_SignageContext.Provider>
  );
};

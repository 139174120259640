
import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const Writing_SuppliesContext = createContext();

export const Writing_SuppliesContextProvider = ({ children }) => {
  const [writing_supplies, setWriting_Supplies] = useState([]); // Corrected the state variable name

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Product/Products/Writing').get();
        const writing_suppliesData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));
        setWriting_Supplies(writing_suppliesData); // Corrected the state setter function
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Writing_SuppliesContext.Provider value={{ writing_supplies }}>
      {children}
    </Writing_SuppliesContext.Provider>
  );
};
import React from 'react';
import './App.css';
import Header from './Header';
import { Helmet } from 'react-helmet';
import Home from './Home';
import Login from "./Login";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Checkout from "./Checkout";
import ProductDetailPage from "./School_Uniform_Form"; 
import { MedicalUniformsContextProvider } from "./Medical_Uniforms_Data";
import { EnvelopsContextProvider } from "./Envelops_data";
import { BusinessCardsContextProvider } from './business_card_data'; 
import { LetterHeadsContextProvider } from "./letter_heads_data";
import BusinessCards from "./business_cards";
import Envelops from "./Envelops";
import LetterHeads from "./letter_heads";
import BillBooks from "./School_Supplies";
import { BillBooksContextProvider } from "./BillBooksData";
import { StandeesContextProvider } from "./StandeesData";
import Standees from "./Standees";
import { IDCardsContextProvider } from "./ID_Cards_Data";
import IDCards from "./IDCards";
import { CalendarsContextProvider } from "./Calendars_Data";
import Calendars from "./Calendars";
import MedicalUniforms from "./Product_Test";
import { Banner_SignageContextProvider } from "./Banners&Signage_Data";
import BannerSignage from "./Banner_Signage";
import { DeskItemsContextProvider } from "./DeskItems_Data";
import DeskItems from "./DeskItems";
import { DeskOrganizeContextProvider } from "./DeskOrganize_Data";
import DeskOrganize from "./DeskOrganize";
import { School_UniformsContextProvider } from "./School_Uniforms_Data";
import School_Uniforms from "./School_Uniforms";
import { SchoolCategoryContextProvider } from "./SchoolCategory_Data";
import { School_Category_UniformContext, School_Category_UniformContextProvider } from "./School_Uniform_Display_Data";
import { School_Category_StationaryContextProvider } from "./School_Category_Stationary_Data";
import { School_Category_AccessoriesContextProvider } from "./School_Accessories_DisplayData";
import School_Category_Stationary from "./School_Stationary_Page";
import School_Accessories from "./School_Acessories";
import School_Stationary_Form from "./School_Stationary_Form";
import Corporate_Supplies from "./Corporate_Supplies";
import ID_Cards_Form from "./ID-Cards-Form";
import Linen_Form from './Linen_Form';
import Acessories_Form from "./Accessories_Form";
import Hospital_Supplies from "./Hospital_Supplies";
import Hotel_Supplies from "./Hotel_Supplies";
import Printing_Supplies_Form from "./Printing_Supplies_Form";
import { Linen_Display_ContextProvider } from "./Linen_Display_Data";
import { LinenContextProvider } from "./Linen_Data";
import Writing_Supplies_Form from "./Writing_Supplies_Form";
import Linen from "./Linen.js";
import {  PrintingContextProvider } from "./Printing_Data";
import Printing_Supplies from "./Printing_Supplies";
import { Promotional_GiftContextProvider } from "./Promotional_Gift_Data";
import Promotional_Gift from "./Promotional_Gift";
import { Stationary_Display_ContextProvider } from "./Stationary_Display_Data";
import { ID_Cards_Display_ContextProvider } from "./ID_Cards_Display_Data";
import { Writing__Supplies_Display_ContextProvider } from "./Writing_Supplies_Display_Data";
import { Promotional_Gift_Display_ContextProvider } from "./Promotional_Gift_Display_Data";
import { Printing_Display_ContextProvider } from "./Printing_Display_Data";
import { Writing_SuppliesContextProvider } from "./Writing_Supplies_Data";
import Writing_Supplies from "./Writing_Supplies";
import Promotional_Gift_Form from "./Promotional_Gift_Form";
import T_Shirt_Form from "./t_shirt_Form";
import { T_ShirtContextProvider } from "./t_shirt_data";
import T_ShirtPage from "./t_shirt";
import { T_Shirt_Display_ContextProvider } from "./t_shirt_display_data";
import { CorporateCategoryContextProvider } from "./Corporate_Category_Data";
import { HospitalCategoryContextProvider } from "./Hospital_Category_Data";
import Accessories from "./School_Acessories";
import { Accessories_Data_ContextProvider } from "./Accessories_Data";
import Design_Page from "./Design_Services_Page";
import Partner_Page from "./Partner_Page";
import ProductSearch from "./Product_Search";
import { SearchProvider } from './SearchContext';
import Writing_Supplies_Detail from "./Writing_Supplies_Details";
import OneStopSolution from "./OneStopSolution";
import Design from "./Design_Sample";
import Uniform_Detail from "./Uniform_Detail_Page";
import Promotional_Detail from "./Promotional_Detail";
import Printing_Detail from "./Printing_Details";
import Linen_Detail from "./Linen_Details";
import Accessories_Detail from "./Accessories_Detail";
import ID_Card_Detail from "./ID_Card_Details";
import T_Shirt_Detail from "./T-Shirt_Detail_Page";
import Stationary_Supplies_Detail from "./Stationary_Supplies_Details";
import Clothing from "./Clothing_Customization";
import Art from "./Art";
import Web_design from "./Web_design";
import Book_Design from "./Book_Design";
import Uniform_Form from "./School_Uniform_Form";
import MyComponent from "./Test_code";
import Business_Design from "./Business_design";
import School_Section_Page from "./School_Section_Page";
import School_Second_Page from "./School_Second_Page";
import School_Product_Form from "./Product_Forms_School";
import School_Uniform_Static_Details from "./School_Uniform_Static_Details";
import Submit_Request_Form from "./Submit_Request_Form";
import About_Us from "./About_Us";
import Digital_Marketing from "./Digital_Marketing";
import School_Services from "./School_Services";
import GlenBardPage from './Glenbard.js';
import GlenbardDetailsPage from './Glenbard_Detail_Page.js';
import UPIPaymentGateway from './UPIPaymentGateway.js';


function App() {
  const hreflangIndia = { hreflang: 'en-IN', href: 'https://www.custoking.com/' };



  return (
    <Router>
      <SearchProvider>
      <div className="App">
     {/* <div className="mobile-message">
            <p>This website is not available on mobile devices.</p>
  </div>*/}
          <Helmet>
          <link rel="alternate" {...hreflangIndia} />
        </Helmet>

        <Switch>
        <Route path="/Glenbard/:selectedClass/:selectedLanguage" component={GlenbardDetailsPage} />
        <Route path="/Glenbard">
          <Header />
            <GlenBardPage />
          </Route>
          
        <Route path="/Writing_Supplies/:productID">
        <Header />
          <Writing__Supplies_Display_ContextProvider>
            <Stationary_Display_ContextProvider>
            <Writing_Supplies_Detail/>
            </Stationary_Display_ContextProvider>
            </Writing__Supplies_Display_ContextProvider>
          </Route>
          <Route path="/Writing_Supplies_Request/:productID">
            <Writing_Supplies_Form />
          </Route>
      {  /*  <Route path="/checkout">
            <Checkout />
          </Route> */}
          <Route path="/School_Uniform_Details">
          <Header />
            <School_Uniform_Static_Details />
          </Route>
          <Route path="/School_Services">
          
            <School_Services />
          </Route>
          <Route path="/Digital_Marketing">
          <Header />
            <Digital_Marketing />
          </Route>
          <Route path="/Web_Design">
          <Header />
          <Web_design />
            </Route>
            <Route path="/About_Us">
            <Header />
          <About_Us />
            </Route>
            <Route path="/Logo_Identity">
            <Header />
            <OneStopSolution/>
          </Route>
            <Route path="/Business_Design">
            <Header />
            <Business_Design />
            </Route>
            <Route path="/Apparel_Design">
            <Header />
            <Clothing/>
          </Route>

            <Route path="/ill">
          <Art />
            </Route>
            <Route path="/Art">
          <Art />
            </Route>
            <Route path="/UPI">
          <UPIPaymentGateway />
            </Route>


            <Route path="/Second_Page">
          <School_Second_Page />
            </Route>
            <Route path="/Book_Design">
          <Book_Design />
            </Route>
            <Route path="/Submit_Request_Form" component={Submit_Request_Form} />
      
         
          <Route path="/Customization">
          <Header />
            <Design_Page/>
          </Route>
     {/*     <Route path="/Test">
            <MyComponent/>
          </Route> */}
          <Route path="/product-search">
          <Header />
            <ProductSearch/>
          </Route>
          <Route path="/PartnerProgram">
          <Header />
            <Partner_Page/>.
          </Route>
          <Route path="/School_Product_Info">
            <School_Product_Form/>.
          </Route>
          


          <Route path="/Design">
            <Design/>
          </Route>
     
           
          
          <Route path="/Form/:productID">

            <Writing_Supplies_Form/>
          </Route>
       {/*   <Route path="/Standees">
            <StandeesContextProvider>
              <Standees />
            </StandeesContextProvider>
          </Route>*/}
          <Route path="/Banners&Signage">
            <Banner_SignageContextProvider>
              <BannerSignage />
            </Banner_SignageContextProvider>
          </Route>
          <Route path="/DeskItems">
            <DeskItemsContextProvider>
              <DeskItems />
            </DeskItemsContextProvider>
          </Route>
          <Route path="/Uniforms">
            <School_UniformsContextProvider>
              <School_Uniforms />
            </School_UniformsContextProvider>
          </Route>
          <Route path="/SchoolUniform/:productID">
          <Header />
          <School_Category_UniformContextProvider>
          <T_Shirt_Display_ContextProvider>
            <Uniform_Detail/>
            </T_Shirt_Display_ContextProvider>
            </School_Category_UniformContextProvider>
          </Route>
          <Route path="/SchoolUniform_Request/:productID">

            <Uniform_Form />
          </Route>
          <Route path="/School Uniform">
            <School_UniformsContextProvider>
              <School_Uniforms />
            </School_UniformsContextProvider>
          </Route>
          <Route path="/Promotional_Gifts/:productID">
          <Header />
            <School_Category_AccessoriesContextProvider>
            <Promotional_Gift_Display_ContextProvider>
            <Promotional_Detail/>
            </Promotional_Gift_Display_ContextProvider>
            </School_Category_AccessoriesContextProvider>
          </Route>
          <Route path="/Promotional_Gifts_Request/:productID">
            <Promotional_Gift_Form />
          </Route>
          
          <Route path="/Promotional_Gifts">

          <Header />
            <Promotional_GiftContextProvider>
              <Promotional_Gift />
            </Promotional_GiftContextProvider>
          </Route>
          <Route path="/DeskOrganize">
            <DeskOrganizeContextProvider>
              <DeskOrganize />
            </DeskOrganizeContextProvider>
          </Route>
          <Route path="/BusinessCards">
            <BusinessCardsContextProvider>
              <BusinessCards />
            </BusinessCardsContextProvider>
          </Route>
          <Route path="/Writing_Supplies/:productID">
          <Header />
            <Stationary_Display_ContextProvider>
            <Writing__Supplies_Display_ContextProvider>
            <Writing_Supplies_Detail/>
            </Writing__Supplies_Display_ContextProvider>
            </Stationary_Display_ContextProvider>
          </Route>

          <Route path="/Writing_Supplies">
          <Header />
            <Writing_SuppliesContextProvider>
              <Writing_Supplies />
            </Writing_SuppliesContextProvider>
          </Route>
          <Route path="/Printing_Supplies/:productID">
          <Header />
            <ID_Cards_Display_ContextProvider>
            <Printing_Display_ContextProvider>
            <Printing_Detail/>
            </Printing_Display_ContextProvider>
            </ID_Cards_Display_ContextProvider>
          </Route>
          <Route path="/Printing_Supplies_Request/:productID">
            <Printing_Supplies_Form />
          </Route>
          <Route path="/Printing_Supplies">
            <PrintingContextProvider>
              <Printing_Supplies />
            </PrintingContextProvider>
          </Route>
          <Route path="/Linen/:productID">
        <School_Category_UniformContextProvider>
            <Linen_Display_ContextProvider>
            <Linen_Detail/>
            </Linen_Display_ContextProvider>
            </School_Category_UniformContextProvider>
          </Route>
          <Route path="/Linen_Request/:productID">
            <Linen_Form />
          </Route>
          <Route path="/Linen">
            <LinenContextProvider>
              <Linen />
            </LinenContextProvider>
          </Route>
          <Route path="/Accessories/:productID">
          <Header />
            <Promotional_Gift_Display_ContextProvider>
            <School_Category_AccessoriesContextProvider>
            <Accessories_Detail/>
            </School_Category_AccessoriesContextProvider>
            </Promotional_Gift_Display_ContextProvider>
          </Route>
          <Route path="/Accessories_Request/:productID">
            <Acessories_Form />
          </Route>
          <Route path="/Accessories">
          <Header />
            <Accessories_Data_ContextProvider>
            <Accessories/>
            </Accessories_Data_ContextProvider>
          </Route>

        

          <Route path="/ID-Cards/:productID">
          <Header />
          <Stationary_Display_ContextProvider>
            <Printing_Display_ContextProvider>
            <ID_Cards_Display_ContextProvider>
            <ID_Card_Detail/>
            </ID_Cards_Display_ContextProvider>
            </Printing_Display_ContextProvider>
               </Stationary_Display_ContextProvider>
          </Route>
          <Route path="/ID-Cards_Request/:productID">
            <ID_Cards_Form />
          </Route>
          <Route path="/ID-Cards">
          <Header />
            <IDCardsContextProvider>
              <IDCards />
            </IDCardsContextProvider>
          </Route>
         {/*<Route path="/t_shirt/:productID">
            <School_Category_UniformContextProvider>
            <T_Shirt_Display_ContextProvider>
            <T_Shirt_Detail/>
            </T_Shirt_Display_ContextProvider>
            </School_Category_UniformContextProvider>
  </Route>
          <Route path="/t_shirt">
            <T_ShirtContextProvider>
              <T_ShirtPage />
            </T_ShirtContextProvider>
          </Route>
          <Route path="/t_shirt_Request/:productID">
            <T_Shirt_Form />
          </Route>
          */} 
          <Route path="/Stationary/:productID">
          <Header />
            <Writing__Supplies_Display_ContextProvider>
            <Stationary_Display_ContextProvider>
            <Stationary_Supplies_Detail/>
            </Stationary_Display_ContextProvider>
            </Writing__Supplies_Display_ContextProvider>
          </Route>
          <Route path="/Stationary_Request/:productID">
            <School_Stationary_Form />
          </Route>
          <Route path="/Stationary">
          <Header />
            
          <School_Category_StationaryContextProvider>
              <School_Category_Stationary/>
              </School_Category_StationaryContextProvider>
        
  
          </Route>

         
          <Route path="/School Supplies">
            <IDCardsContextProvider>
              <School_Category_StationaryContextProvider>
            <Promotional_Gift_Display_ContextProvider>
            <School_Category_AccessoriesContextProvider>
              <Writing__Supplies_Display_ContextProvider>
              <ID_Cards_Display_ContextProvider>
                <Stationary_Display_ContextProvider>
                  <BillBooksContextProvider>
                    <School_Category_UniformContextProvider>
                      <MedicalUniformsContextProvider>
                        <SchoolCategoryContextProvider>
                          <BillBooks />
                        </SchoolCategoryContextProvider>
                      </MedicalUniformsContextProvider>
                    </School_Category_UniformContextProvider>
                  </BillBooksContextProvider>
                  </Stationary_Display_ContextProvider>
                  </ID_Cards_Display_ContextProvider>
                  </Writing__Supplies_Display_ContextProvider>
            </School_Category_AccessoriesContextProvider>
            </Promotional_Gift_Display_ContextProvider>
            </School_Category_StationaryContextProvider>
            </IDCardsContextProvider>
          </Route>
          <Route path="/Corporate_Supplies">
            <CorporateCategoryContextProvider>
            <T_Shirt_Display_ContextProvider>
            <Printing_Display_ContextProvider>
            <Promotional_Gift_Display_ContextProvider>
            <Writing__Supplies_Display_ContextProvider>
          <Stationary_Display_ContextProvider>
            <School_Category_AccessoriesContextProvider>
              <ID_Cards_Display_ContextProvider>
                <School_Category_StationaryContextProvider>
                  <BillBooksContextProvider>
                    <School_Category_UniformContextProvider>
                      <MedicalUniformsContextProvider>
                        <SchoolCategoryContextProvider>
                          <Corporate_Supplies />
                        </SchoolCategoryContextProvider>
                      </MedicalUniformsContextProvider>
                    </School_Category_UniformContextProvider>
                  </BillBooksContextProvider>
                </School_Category_StationaryContextProvider>
                </ID_Cards_Display_ContextProvider>
            </School_Category_AccessoriesContextProvider>
            </Stationary_Display_ContextProvider>
            </Writing__Supplies_Display_ContextProvider>
            </Promotional_Gift_Display_ContextProvider>
            </Printing_Display_ContextProvider>
            </T_Shirt_Display_ContextProvider>
            </CorporateCategoryContextProvider>
          </Route>
          <Route path="/Hospital_Supplies">
            <HospitalCategoryContextProvider>
            <Writing__Supplies_Display_ContextProvider>
            <Printing_Display_ContextProvider>
          <ID_Cards_Display_ContextProvider>
            <Stationary_Display_ContextProvider>
            <Linen_Display_ContextProvider>
            <School_Category_AccessoriesContextProvider>
              <IDCardsContextProvider>
                <School_Category_StationaryContextProvider>
                  <BillBooksContextProvider>
                    <School_Category_UniformContextProvider>
                      <MedicalUniformsContextProvider>
                        <SchoolCategoryContextProvider>
                          <Hospital_Supplies />
                        </SchoolCategoryContextProvider>
                      </MedicalUniformsContextProvider>
                    </School_Category_UniformContextProvider>
                  </BillBooksContextProvider>
                </School_Category_StationaryContextProvider>
              </IDCardsContextProvider>
            </School_Category_AccessoriesContextProvider>
            </Linen_Display_ContextProvider>
            </Stationary_Display_ContextProvider>
            </ID_Cards_Display_ContextProvider>
            </Printing_Display_ContextProvider>
            </Writing__Supplies_Display_ContextProvider>
            </HospitalCategoryContextProvider>
          </Route>
          <Route path="/Hotel_Supplies">
          <HospitalCategoryContextProvider>
            <Writing__Supplies_Display_ContextProvider>
            <Printing_Display_ContextProvider>
          <ID_Cards_Display_ContextProvider>
            <Stationary_Display_ContextProvider>
            <Linen_Display_ContextProvider>
            <School_Category_AccessoriesContextProvider>
              <IDCardsContextProvider>
                <School_Category_StationaryContextProvider>
                  <BillBooksContextProvider>
                    <School_Category_UniformContextProvider>
                      <MedicalUniformsContextProvider>
                        <SchoolCategoryContextProvider>
                          <Hotel_Supplies />
                        </SchoolCategoryContextProvider>
                      </MedicalUniformsContextProvider>
                    </School_Category_UniformContextProvider>
                  </BillBooksContextProvider>
                </School_Category_StationaryContextProvider>
              </IDCardsContextProvider>
            </School_Category_AccessoriesContextProvider>
            </Linen_Display_ContextProvider>
            </Stationary_Display_ContextProvider>
            </ID_Cards_Display_ContextProvider>
            </Printing_Display_ContextProvider>
            </Writing__Supplies_Display_ContextProvider>
            </HospitalCategoryContextProvider>
          </Route>


          <Route path="/Envelops">
            <EnvelopsContextProvider>
              <Envelops />
            </EnvelopsContextProvider>
          </Route>
          <Route path="/MedicalUniforms">
            <MedicalUniformsContextProvider>
              <MedicalUniforms />
            </MedicalUniformsContextProvider>
          </Route>
          <Route path="/Calendars">
            <CalendarsContextProvider>
              <Calendars />
            </CalendarsContextProvider>
          </Route>
          <Route path="/Letterheads">
            <LetterHeadsContextProvider>
              <LetterHeads />
            </LetterHeadsContextProvider>
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/">
          <Header />
          <School_Section_Page />
            </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>
      </SearchProvider>
    </Router>
  );
}

export default App;

// EnvelopsContext.js
import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const Promotional_GiftContext = createContext();

export const Promotional_GiftContextProvider = ({ children }) => {
  const [promotional_gift, setPromotional_Gift] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Product/Products/Promotional_Gifts').get();
        const promotional_giftData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));
        setPromotional_Gift(promotional_giftData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Promotional_GiftContext.Provider value={{ promotional_gift }}>
      {children}
    </Promotional_GiftContext.Provider>
  );
};
// EnvelopsContext.js

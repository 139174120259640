import React, { useContext } from 'react';
import { BusinessCardsContext } from './business_card_data'; // Import the BusinessCardsContext
import './Product_Test.css';

function BusinessCards() {
  const { businesscards } = useContext(BusinessCardsContext);
  console.log(businesscards);

  return (
    <div >
    {businesscards.length !== 0 && 
    <section className='page-name'>
    <h1 className='h1'>Business Cards</h1>
    <h2 className='h1_sub'>Discover Our Products</h2>
    </section>
    
    }
    <div className = 'category_display'>
      <div className='category_heading' align= 'center'>
        Source By Categories
      </div>
      <div className='category-card-container' align = 'center'>
      {businesscards.map(product => (
        <div className='category-card' key={product.ProductID}>
          <div className='category-img'>
            <img src={product.ProductImg} alt="not found" />
          </div>
          <div className='category-name'>
            {product.ProductName}
          </div>
        </div>
      ))}
      </div>
    </div>
      <div className='products-container'>
        {businesscards.length === 0 && <div>slow internet...no products to display</div>}
        {businesscards.map(product => (
          <div className='product-card' key={product.ProductID}>
            <div className='product-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-name'>
              {product.ProductName}
            </div>
            <div className='product-name'>
              {product.ProductPrice}
            </div>
            <button className='addcart-btn' >ADD TO CART</button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BusinessCards;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { useHistory } from 'react-router-dom';
import { db, storage } from './Firebase';
import { Helmet } from 'react-helmet';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import ReactGA from 'react-ga';

function Partner_Page() {
  const [contactInfo, setContactInfo] = useState('');
  const [contactInfoError, setContactInfoError] = useState(false);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const validateContactInfo = (value) => {
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return phoneRegex.test(value) || emailRegex.test(value);
  };

  const handleContactInfoChange = (e) => {
    setContactInfo(e.target.value);
    setContactInfoError(!validateContactInfo(e.target.value));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate contact information
    if (!validateContactInfo(contactInfo)) {
      setContactInfoError(true);
      return; // Don't proceed with the submission
    }
    setContactInfoError(false);

    // Validate address



    // Save order data only when the file URL is available
    console.log('About to call saveOrderData');
    console.log('About to call saveOrderData Inside');
    saveOrderData();
    window.gtag('event', 'conversion', {'send_to': 'AW-11246127201/mcHGCNDQtPsYEOGQyfIp'});
  };

  const saveOrderData = async () => {
    console.log('saveOrderData function called');
    const ordersRef = db.collection('HELP');

    const orderData = {
    
      Contact: contactInfo,

    };
    console.log('Order Data:', orderData);
    try {
      // Use add to let Firestore generate a unique document ID
      await ordersRef.add(orderData);

      // Reset fields
      setContactInfo('');
    

      // Optionally, you can show a success message to the user
      alert('Submitted successfully!');
    } catch (error) {
      // Handle any errors that may occur during data submission
      console.error('Error submitting:', error);
      alert('Error submitting. Please try again.');
    }
  };
  return (
    <>
          <Helmet>
        <title>Custoking Partner Program: Elevate School Experience with Exclusive Benefits</title>
        <meta name="description" content="Explore the advantages of joining Custoking's Partner Program. Unlock exclusive benefits, collaborative opportunities, and a pathway to success in the realm of school necessities. Partner with us for a rewarding journey" />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div className="partner_div">
        <div className="partner_div-2">
          <img
            loading="lazy"
            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FPartner%20Background%20IMAGE.png?alt=media&token=0f9b77c3-98ea-4482-ad89-fb951fb4bd59"
            className="partner_img"
          />
          <div className="partner_div-3">
            <div className="partner_div-4" id = 'Fill'>
              <img
                loading="lazy"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FWhite%20Logo.png?alt=media&token=ff0a1d37-e07c-4b93-996f-0e75d9c8c581"
                className="partner_img-2"
              />
              <div className="partner_div-5">
                Partner with Custoking – Your one stop solution
              </div>
              <div className="partner_div-6">
                <div className="partner_div-7">LIMITED TIME OFFER</div>
                <div className="partner_div-8" />
              </div>
              <div className="partner_div-9">Register at 0% charge</div>
              <div className="partner_div-10">for hassle free journey</div>
              <div className="partner_div-11">
                <div className="partner_div-12">+ benefits worth ₹15,000</div>
                <div className="partner_div-13">*T&C apply</div>
              </div>
            </div>
            <div className="partner_div-14">
              <div className="partner_div-15">
                <div className="partner_div-16">Need Help?</div>
                <div className="partner_div-17">Contact Us: +91-747800411</div>
                <div className="partner_div-18">FAQs</div>
              </div>
              <form className="partner_div-19" onSubmit={handleSubmit}>
              
                <div className="partner_div-20">Submit Request</div>
                <div className="partner_div-21">Contact Details</div>
                <div className="partner_div-22" >
                <input
                type="text"
                id="contactInfo"
                value={contactInfo}
                onChange={handleContactInfoChange}
                className={contactInfoError ? 'error' : ''}
                placeholder="Phone Number or Email (Required)"
                required
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '5px',
                  border: contactInfoError ? '2px solid red' : '1px solid #ccc',
                  padding: '5px',
                  outline: 'none',
                }}
              />
              {contactInfoError && (
                <span
                  style={{
                    marginLeft: '5px',
                    color: 'red',
                    height: '10px',
                    width: '10px',
                  }}
                  role="img"
                  aria-label="info"
                >
                  <HiOutlineInformationCircle />
                </span>
              )}

                </div>
                <button type="submit" className="partner_div-23">
                  <div   className="partner_div-24">Submit</div>
                </button>
                </form>
              
            </div>
          </div>
        </div>
        <div className="partner_div-25">
          <div className="partner_div-26">
            <div className="partner_column">
              <div className="partner_div-27">
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FPartner%20Image%201.png?alt=media&token=651d151e-dc9d-4fee-ac89-c38e2618e4f0"
                  className="partner_img-3"
                />
                <div className="partner_div-28">Enhance School Image</div>
              </div>
            </div>
            <div className="partner_column-2">
              <div className="partner_div-29">
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FPartner%20Image%202.png?alt=media&token=2b298f3d-2383-402d-81f6-5d10605d7cc9"
                  className="partner_img-4"
                />
                <div className="partner_div-30">Quality Products</div>
              </div>
            </div>
            <div className="partner_column-3">
              <div className="partner_div-31">
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FPartner%20Image%203.png?alt=media&token=beb1cbae-6644-4448-a310-9e80b50e150c"
                  className="partner_img-5"
                />
                <div className="partner_div-32">Access to the large customized products</div>
              </div>
            </div>
            <div className="partner_column-4">
              <div className="partner_div-33">
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FPartner%20Image%204.png?alt=media&token=f2c5fd7e-9ad4-4d2b-8371-643cd773ec0f"
                  className="partner_img-6"
                />
                <div className="partner_div-34">Increase your visibility</div>
              </div>
            </div>
          </div>
        </div>
        <div className="partner_div-35">
          <div className="partner_div-36">
            <div className="partner_div-37">Get started in 3 easy steps</div>
            <div className="partner_div-38">
              <div className="partner_div-39">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/20fe1756-df2b-4685-ae20-8c19cf542bf1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/20fe1756-df2b-4685-ae20-8c19cf542bf1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/20fe1756-df2b-4685-ae20-8c19cf542bf1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/20fe1756-df2b-4685-ae20-8c19cf542bf1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/20fe1756-df2b-4685-ae20-8c19cf542bf1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/20fe1756-df2b-4685-ae20-8c19cf542bf1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/20fe1756-df2b-4685-ae20-8c19cf542bf1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/20fe1756-df2b-4685-ae20-8c19cf542bf1?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="partner_img-7"
                />
                <div className="partner_div-40">Sign Up</div>
                <div className="partner_div-41">
                  Start with filling up the partner form
                </div>
              </div>
              <div className="partner_div-42">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6cc40bf0-df55-48bf-8f98-fbe772f4d209?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cc40bf0-df55-48bf-8f98-fbe772f4d209?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cc40bf0-df55-48bf-8f98-fbe772f4d209?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cc40bf0-df55-48bf-8f98-fbe772f4d209?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cc40bf0-df55-48bf-8f98-fbe772f4d209?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cc40bf0-df55-48bf-8f98-fbe772f4d209?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cc40bf0-df55-48bf-8f98-fbe772f4d209?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cc40bf0-df55-48bf-8f98-fbe772f4d209?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="partner_img-8"
                />
                <div className="partner_div-43">Set Up</div>
                <div className="partner_div-44">
                  Have a discovery call to gather requirements
                </div>
              </div>
              <div className="partner_div-45">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6d8db3c7-2883-4a05-b194-434a4980c1f3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d8db3c7-2883-4a05-b194-434a4980c1f3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d8db3c7-2883-4a05-b194-434a4980c1f3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d8db3c7-2883-4a05-b194-434a4980c1f3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d8db3c7-2883-4a05-b194-434a4980c1f3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d8db3c7-2883-4a05-b194-434a4980c1f3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d8db3c7-2883-4a05-b194-434a4980c1f3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d8db3c7-2883-4a05-b194-434a4980c1f3?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="partner_img-9"
                />
                <div className="partner_div-46">Partner Moment</div>
                <div className="partner_div-47">Build a partnership to streamline products and services</div>
              </div>
            </div>
          </div>
        </div>
        <div className="partner_div-48">
          <div className="partner_div-49">
            <div className="partner_div-50">Become Custoking partner today</div>
            <a  href="#Fill" style={{ textDecoration: 'none' }} className="partner_div-51">
              <div className="partner_div-52">Get Started</div>
            </a>
          </div>
          <div className="partner_div-53">
            <div className="partner_div-54">
              <div className="partner_column-5">
                <div className="partner_div-55">
                  <div className="partner_div-56">
                    “Three words - customization, quality and one stop solution! This sums up
                    Custoking's service offering.”
                  </div>{" "}
                  <div className="partner_div-57">
                    <img
                      loading="lazy"
                      srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FNBHS.png?alt=media&token=4173bc62-b63a-4d1c-8bc9-8bf2a75c139e&width=100 100w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FNBHS.png?alt=media&token=4173bc62-b63a-4d1c-8bc9-8bf2a75c139e&width=200 200w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FNBHS.png?alt=media&token=4173bc62-b63a-4d1c-8bc9-8bf2a75c139e&width=400 400w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FNBHS.png?alt=media&token=4173bc62-b63a-4d1c-8bc9-8bf2a75c139e&width=800 800w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FNBHS.png?alt=media&token=4173bc62-b63a-4d1c-8bc9-8bf2a75c139e&width=1200 1200w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FNBHS.png?alt=media&token=4173bc62-b63a-4d1c-8bc9-8bf2a75c139e&width=1600 1600w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FNBHS.png?alt=media&token=4173bc62-b63a-4d1c-8bc9-8bf2a75c139e&width=2000 2000w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FNBHS.png?alt=media&token=4173bc62-b63a-4d1c-8bc9-8bf2a75c139e"
                      className="partner_img-10"
                    />{" "}
                    <div className="partner_div-58">
                      <div className="partner_div-59">U Kiran, Correspondent</div>{" "}
                      <div className="partner_div-60">New Bloom High School</div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="partner_column-6">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6d58d678-09ae-43ac-a2ec-197728085895?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d58d678-09ae-43ac-a2ec-197728085895?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d58d678-09ae-43ac-a2ec-197728085895?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d58d678-09ae-43ac-a2ec-197728085895?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d58d678-09ae-43ac-a2ec-197728085895?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d58d678-09ae-43ac-a2ec-197728085895?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d58d678-09ae-43ac-a2ec-197728085895?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d58d678-09ae-43ac-a2ec-197728085895?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="partner_img-11"
                />
              </div>
            </div>
          </div>{" "}
          <div className="partner_div-61">
            <div className="partner_div-62">
              <div className="partner_column-7">
                <div className="partner_div-63">
                  <div className="partner_div-64">Get services that help you grow</div>{" "}
                  <div className="partner_div-65" />{" "}
                  <div className="partner_div-66">
                    <div className="partner_div-67">
                      <div className="partner_column-8">
                        <div className="partner_div-68">
                          <div className="partner_div-69">

                            <div className="partner_column-10">
                              <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6d645a44-793a-4006-8775-1893b6dec91d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d645a44-793a-4006-8775-1893b6dec91d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d645a44-793a-4006-8775-1893b6dec91d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d645a44-793a-4006-8775-1893b6dec91d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d645a44-793a-4006-8775-1893b6dec91d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d645a44-793a-4006-8775-1893b6dec91d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d645a44-793a-4006-8775-1893b6dec91d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d645a44-793a-4006-8775-1893b6dec91d?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                                className="partner_img-13"
                              />
                            </div>{" "}
                            <div className="partner_column-11">
                              <div className="partner_div-71">
                                <div className="partner_div-72"> Order Supplies   </div>{" "}
                                <div className="partner_div-73">
                                  Get all school supplies at one place
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                
                    </div>
                  </div>{" "}


                  <div className="partner_div-66">
                    <div className="partner_div-67">
                      <div className="partner_column-8">
                        <div className="partner_div-68">
                          <div className="partner_div-69">

                            <div className="partner_column-10">
                            <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/708ef2e3-cf29-42ae-b8c1-7287e9387477?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/708ef2e3-cf29-42ae-b8c1-7287e9387477?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/708ef2e3-cf29-42ae-b8c1-7287e9387477?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/708ef2e3-cf29-42ae-b8c1-7287e9387477?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/708ef2e3-cf29-42ae-b8c1-7287e9387477?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/708ef2e3-cf29-42ae-b8c1-7287e9387477?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/708ef2e3-cf29-42ae-b8c1-7287e9387477?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/708ef2e3-cf29-42ae-b8c1-7287e9387477?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                            className="partner_img-13"
                          />
                            </div>{" "}
                            <div className="partner_column-11">
                              <div className="partner_div-71">
                                <div className="partner_div-72"> Enter Customizations   </div>{" "}
                                <div className="partner_div-73">
                                Provide the customization details for your order
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                
                    </div>
                  </div>{" "}

                  <div className="partner_div-66">
                    <div className="partner_div-67">
                      <div className="partner_column-8">
                        <div className="partner_div-68">
                          <div className="partner_div-69">

                            <div className="partner_column-10">
                            <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f1e6ed3a-e2f3-470e-9a9c-6d32816c0b45?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1e6ed3a-e2f3-470e-9a9c-6d32816c0b45?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1e6ed3a-e2f3-470e-9a9c-6d32816c0b45?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1e6ed3a-e2f3-470e-9a9c-6d32816c0b45?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1e6ed3a-e2f3-470e-9a9c-6d32816c0b45?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1e6ed3a-e2f3-470e-9a9c-6d32816c0b45?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1e6ed3a-e2f3-470e-9a9c-6d32816c0b45?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1e6ed3a-e2f3-470e-9a9c-6d32816c0b45?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                            className="partner_img-13"
                          />{" "}
                            </div>{" "}
                            <div className="partner_column-11">
                              <div className="partner_div-71">
                                <div className="partner_div-72"> View Order Summary  </div>{" "}
                                <div className="partner_div-73">
                                Get an overview of your order
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                
                    </div>
                  </div>{" "}

                  <div className="partner_div-66">
                    <div className="partner_div-67">
                      <div className="partner_column-8">
                        <div className="partner_div-68">
                          <div className="partner_div-69">

                            <div className="partner_column-10">
                            <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/588f8b99-f4bd-428b-b442-cb77ecefe329?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/588f8b99-f4bd-428b-b442-cb77ecefe329?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/588f8b99-f4bd-428b-b442-cb77ecefe329?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/588f8b99-f4bd-428b-b442-cb77ecefe329?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/588f8b99-f4bd-428b-b442-cb77ecefe329?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/588f8b99-f4bd-428b-b442-cb77ecefe329?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/588f8b99-f4bd-428b-b442-cb77ecefe329?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/588f8b99-f4bd-428b-b442-cb77ecefe329?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                            className="partner_img-13"
                          />
                            </div>{" "}
                            <div className="partner_column-11">
                              <div className="partner_div-71">
                                <div className="partner_div-72"> Place Request   </div>{" "}
                                <div className="partner_div-73">
                                Place order request for all school needs at one place
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                
                    </div>
                  </div>{" "}
            
                  
                </div>
              </div>{" "}
              <div className="partner_column-15">
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2Fgrowth.jpg?alt=media&token=f01a22b8-df6c-43ba-a921-75f743752a22&width=100 100w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2Fgrowth.jpg?alt=media&token=f01a22b8-df6c-43ba-a921-75f743752a22&width=200 200w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2Fgrowth.jpg?alt=media&token=f01a22b8-df6c-43ba-a921-75f743752a22&width=400 400w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2Fgrowth.jpg?alt=media&token=f01a22b8-df6c-43ba-a921-75f743752a22&width=800 800w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2Fgrowth.jpg?alt=media&token=f01a22b8-df6c-43ba-a921-75f743752a22&width=1200 1200w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2Fgrowth.jpg?alt=media&token=f01a22b8-df6c-43ba-a921-75f743752a22&width=1600 1600w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2Fgrowth.jpg?alt=media&token=f01a22b8-df6c-43ba-a921-75f743752a22&width=2000 2000w, https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2Fgrowth.jpg?alt=media&token=f01a22b8-df6c-43ba-a921-75f743752a22"
                  className="partner_img-16"
                />
              </div>
            </div>
          </div>{" "}
          <div className="partner_div-86">
            <div className="partner_div-87">
              <div className="partner_column-16">
                <div className="partner_div-88">
                  <div className="partner_div-89">
                    <div className="partner_column-17">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6a880df4-b70c-4c6b-8a1a-890461507666?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a880df4-b70c-4c6b-8a1a-890461507666?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a880df4-b70c-4c6b-8a1a-890461507666?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a880df4-b70c-4c6b-8a1a-890461507666?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a880df4-b70c-4c6b-8a1a-890461507666?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a880df4-b70c-4c6b-8a1a-890461507666?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a880df4-b70c-4c6b-8a1a-890461507666?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6a880df4-b70c-4c6b-8a1a-890461507666?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="partner_img-17"
                      />
                    </div>{" "}
                    <div className="partner_column-18">
                      <div className="partner_div-90">
                        <div className="partner_div-91">Quality</div>{" "}
                        <div className="partner_div-92">Parents Satisfaction</div>{" "}
                        <div className="partner_div-93">
                          Get high quality products and services delivered
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="partner_column-19">
                <div className="partner_div-94">
                  <div className="partner_div-95">
                    <div className="partner_column-20">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7b63963a-d4b0-4bbf-836a-d897c8003bae?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b63963a-d4b0-4bbf-836a-d897c8003bae?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b63963a-d4b0-4bbf-836a-d897c8003bae?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b63963a-d4b0-4bbf-836a-d897c8003bae?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b63963a-d4b0-4bbf-836a-d897c8003bae?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b63963a-d4b0-4bbf-836a-d897c8003bae?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b63963a-d4b0-4bbf-836a-d897c8003bae?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b63963a-d4b0-4bbf-836a-d897c8003bae?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="partner_img-18"
                      />
                    </div>{" "}
                    <div className="partner_column-21">
                      <div className="partner_div-96">
                        <div className="partner_div-97">Premium</div>{" "}
                        <div className="partner_div-98">Enhance Image</div>{" "}
                        <div className="partner_div-99">
                          Boost your admissions and brand with our high quality products and services.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="partner_div-100">
            <div className="partner_div-101">Become Custoking partner today</div>{" "}
            <a  href="#Fill" style={{ textDecoration: 'none' }} className="partner_div-102">
              <div className="partner_div-103">Get Started</div>
            </a>
          </div>{" "}
          <div className="partner_div-104">Questions?</div>{" "}
          <div className="partner_div-105">
            <div className="partner_div-106">
              What is the minimum order size of uniforms?
            </div>{" "}
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fefd7805-dd3b-4da0-9d80-b0c47e1a61b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fefd7805-dd3b-4da0-9d80-b0c47e1a61b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fefd7805-dd3b-4da0-9d80-b0c47e1a61b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fefd7805-dd3b-4da0-9d80-b0c47e1a61b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fefd7805-dd3b-4da0-9d80-b0c47e1a61b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fefd7805-dd3b-4da0-9d80-b0c47e1a61b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fefd7805-dd3b-4da0-9d80-b0c47e1a61b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fefd7805-dd3b-4da0-9d80-b0c47e1a61b8?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="partner_img-19"
            />
          </div>{" "}
          <div className="partner_div-107">
            <div className="partner_div-108">
              <div className="partner_column-22">
                <div className="partner_div-109">
                  <div className="partner_div-110">-</div>{" "}
                
                </div>
              </div>{" "}
              <div className="partner_column-23">
                <div className="partner_div-116">
                  <div className="partner_div-117">
                    Choose a quantity as low as 20 Pieces
                  </div>{" "}
         
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="partner_div-123" />{" "}
          <div className="partner_div-124">
            <div className="partner_div-125">
              What is the payment method?
            </div>{" "}
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6cac0f33-e89d-41f5-be55-4b4d9d3edbfa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cac0f33-e89d-41f5-be55-4b4d9d3edbfa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cac0f33-e89d-41f5-be55-4b4d9d3edbfa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cac0f33-e89d-41f5-be55-4b4d9d3edbfa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cac0f33-e89d-41f5-be55-4b4d9d3edbfa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cac0f33-e89d-41f5-be55-4b4d9d3edbfa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cac0f33-e89d-41f5-be55-4b4d9d3edbfa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6cac0f33-e89d-41f5-be55-4b4d9d3edbfa?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="partner_img-20"
            />
          </div>{" "}
          <div className="partner_div-126" />{" "}
          <div className="partner_div-127">
            <div className="partner_div-128">
              What are the benefits and policies?
            </div>{" "}
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/57c05de9-1c95-4191-900a-f41e6f3a27b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/57c05de9-1c95-4191-900a-f41e6f3a27b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/57c05de9-1c95-4191-900a-f41e6f3a27b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/57c05de9-1c95-4191-900a-f41e6f3a27b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/57c05de9-1c95-4191-900a-f41e6f3a27b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/57c05de9-1c95-4191-900a-f41e6f3a27b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/57c05de9-1c95-4191-900a-f41e6f3a27b8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/57c05de9-1c95-4191-900a-f41e6f3a27b8?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="partner_img-21"
            />
          </div>{" "}
          <div className="partner_div-129" />{" "}
          <div className="partner_div-130">
            <div className="partner_div-131">
            What is the refund policy?
            </div>{" "}
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3714233c-b093-4f30-85ee-0a593d573cc7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3714233c-b093-4f30-85ee-0a593d573cc7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3714233c-b093-4f30-85ee-0a593d573cc7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3714233c-b093-4f30-85ee-0a593d573cc7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3714233c-b093-4f30-85ee-0a593d573cc7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3714233c-b093-4f30-85ee-0a593d573cc7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3714233c-b093-4f30-85ee-0a593d573cc7?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3714233c-b093-4f30-85ee-0a593d573cc7?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="partner_img-22"
            />
          </div>{" "}
          <div className="partner_div-132" />{" "}
          <div className="partner_div-133">
            <div className="partner_div-134">
            Who should I contact if I need help & support in getting onboarded?
            </div>{" "}
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/43c747ff-af86-406e-9823-0b393894165f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/43c747ff-af86-406e-9823-0b393894165f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/43c747ff-af86-406e-9823-0b393894165f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/43c747ff-af86-406e-9823-0b393894165f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/43c747ff-af86-406e-9823-0b393894165f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/43c747ff-af86-406e-9823-0b393894165f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/43c747ff-af86-406e-9823-0b393894165f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/43c747ff-af86-406e-9823-0b393894165f?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="partner_img-23"
            />
          </div>{" "}
          <div className="partner_div-135" />{" "}
          <div className="partner_div-136">
            <div className="partner_div-137">
            What all marketing material and admissions support will be provided?
            </div>{" "}
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/891ce028-d9d7-4c77-aa70-582e3cae6928?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/891ce028-d9d7-4c77-aa70-582e3cae6928?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/891ce028-d9d7-4c77-aa70-582e3cae6928?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/891ce028-d9d7-4c77-aa70-582e3cae6928?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/891ce028-d9d7-4c77-aa70-582e3cae6928?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/891ce028-d9d7-4c77-aa70-582e3cae6928?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/891ce028-d9d7-4c77-aa70-582e3cae6928?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/891ce028-d9d7-4c77-aa70-582e3cae6928?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="partner_img-24"
            />
          </div>{" "}
          <div className="partner_div-138" />{" "}
          <div className="partner_div-139">
            <div className="partner_div-140">More questions?</div>{" "}
            <div className="partner_div-141">See Partner Support</div>
          </div>
        </div>{" "}




        <div className="school_landing_div-402">

  
          <div className="school_landing_div-409">
            <div className="school_landing_div-410">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                Customer services
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
                Help Center
                <br />
                Report abuse
                <br />
                File a case
                <br />
                Piolicies & rules
                <br />
                Get paid for your feedback
              </span>
            </div>
            <div className="school_landing_div-411">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                About Us
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                About Custoking.com
                <br />
                About Custoking Group
                <br />
                Sitemap
                <br />
                Custoking.com Blog
              </span>
            </div>
            <div className="school_landing_div-412">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                Source on Custoking.com
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                Resources
                <br />
                All categories
                <br />
                Request for Quotation
                <br />
                Ready to Ship
                <br />
                Buyer partners
                <br />
                Custoking.com Select
              </span>
            </div>
            <div className="school_landing_div-413">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                Partner with Custoking.com
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                Supplier memberships
                <br />
                Learning Center
                <br />
                Partner Program
              </span>
            </div>
            <div className="school_landing_div-414">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                Trade services
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                Trade Assuarance
                <br />
                Business identity
                <br />
                Logistics services
                <br />
                Production Monitoring & Inspespection
                <br />
                Services
                <br />
                Letter of Credit
              </span>
            </div>
          </div>
       
      


          <div className="school_landing_div-430">
            Browse Alphabetically Onetouch | Showroom | Country Search | Affliate
          </div>
          <div className="school_landing_div-431">
            Product Listing Policy-Intellectual Property Protection-Privacy
            Policy-Terms of Use-User Information Legal Enquiry Guide
          </div>
    
        </div>




      </div>{" "}
      <style jsx>{`
        .partner_div {
          background-color: #fff;
          display: flex;
          flex-direction: column;
        }
        .partner_div-2 {
          disply: flex;
          flex-direction: column;
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          min-height: 781px;
          width: 100%;

        }
        @media (max-width: 991px) {
          .partner_div-2 {
            max-width: 100%;
          }
        }
        .partner_img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .partner_div-3 {
          position: relative;
          align-self: center;
          display: flex;
          margin-bottom: -33px;
          width: 100%;
          max-width: 1414px;
          align-items: flex-start;
          justify-content: space-between;
          margin-top:100px;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-3 {
            max-width: 100%;
            margin-bottom: 10px;
            flex-wrap: wrap;
            padding:10px;
          }
        }
        .partner_div-4 {
          align-self: start;
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-4 {
            max-width: 100%;
          }
        }
        .partner_img-2 {
          aspect-ratio: 0.66;
          object-fit: contain;
          object-position: center;
          width: 61px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .partner_div-5 {
          color: #fff;
          letter-spacing: 0.351px;
          margin-top: 21px;
          font: 400 28px/153% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-5 {
            max-width: 100%;
          }
        }
        .partner_div-6 {
          align-self: start;
          display: flex;
          margin-top: 55px;
          width: 510px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-6 {
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .partner_div-7 {
          color: #fff;
          letter-spacing: 1px;
          font: 400 21px/121% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-8 {
          background-color: rgba(255, 255, 255, 0.45);
          align-self: center;
          display: flex;
          width: 245px;
          height: 1px;
          flex-direction: column;
          margin: auto 0;
        }
        .partner_div-9 {
          color: #f15700;
          letter-spacing: 0.438px;
          margin-top: 35px;
          font: 400 43px/125% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-9 {
            max-width: 100%;
          }
        }
        .partner_div-10 {
          color: #fff;
          letter-spacing: 0.438px;
          margin-top: 10px;
          font: 400 44px/122% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-10 {
            max-width: 100%;
          }
        }
        .partner_div-11 {
          align-self: start;
          display: flex;
          margin-top: 22px;
          width: 510px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-11 {
            flex-wrap: wrap;
          }
        }
        .partner_div-12 {
          color: rgba(255, 255, 255, 0.45);
          align-self: stretch;
          max-width: 315px;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 26px/146% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-13 {
          color: rgba(255, 255, 255, 0.45);
          align-self: center;
          white-space: nowrap;
          margin: auto 0;
          font: 400 17px/224% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-13 {
            white-space: initial;
          }
        }
        .partner_div-14 {
          align-self: start;
          display: flex;
          margin-top: 11px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-14 {
            max-width: 100%;
          }
        }
        .partner_div-15 {
          align-self: end;
          display: flex;
          width: 471px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 0px;
        }
        @media (max-width: 991px) {
          .partner_div-15 {
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .partner_div-16 {
          color: #fff;
          font: 400 20px/190% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-17 {
          color: #fff;
          align-self: start;
          max-width: 291px;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 20px/190% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-18 {
          color: #fff;
          align-self: center;
          white-space: nowrap;
          margin: auto 0;
          font: 400 17px/224% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-18 {
            white-space: initial;
          }
        }
        .partner_div-19 {
          border-radius: 6px;
          border: 1px solid #e6e6e6;
          box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
          background-color: #fff;
          align-self: end;
          display: flex;
          margin-top: 114px;
          width: 353px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
          padding: 18px 20px 27px;
          gap:5px;
        }
        @media (max-width: 991px) {
          .partner_div-19 {
            margin-top: 40px;
            align-self: center;
          }
        }
        .partner_div-20 {
          color: rgba(0, 0, 0, 0.85);
          align-self: stretch;
          margin-top: 9px;
          width: 100%;
          font: 400 26px/65% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-20 {
            margin: 0 2px 0 1px;
          }
        }
        .partner_div-21 {
          color: rgba(0, 0, 0, 0.85);
          letter-spacing: 0.5px;
          align-self: stretch;
          margin-top: 13px;
          white-space: nowrap;
          font: 400 13px/159% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-21 {
            white-space: initial;
            margin: 0 2px 0 1px;
          }
        }
        .partner_div-22 {
          border-radius: 10px;
          border: 1px solid #d9d9d9;
          background-color: #fff;
          align-self: stretch;
          display: flex;
          margin-top: 19px;
          width: 100%;
          height: 45px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-22 {
            margin: 0 2px 0 1px;
          }
        }
        .partner_div-23 {
          border-radius: 4px;
          border: 0px solid #fff;
          box-shadow: 0px -3px 0px 0px #d4d5d9;
   background: linear-gradient(180deg, #127fff 0%, #0067ff 100%);
          align-self: stretch;
          display: flex;
          margin-top: 21px;
          width: 100%;
          flex-direction: column;
          padding: 16px 20px;
        }
        @media (max-width: 991px) {
          .partner_div-23 {
            margin: 0 2px 0 1px;
          }
        }
        .partner_div-24 {
          color: #fff;
          text-align: center;
          letter-spacing: 0.214px;
          text-transform: capitalize;
          align-self: center;
          white-space: nowrap;
          font: 400 19px/150% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-24 {
            white-space: initial;
          }
        }
        .partner_div-25 {
          align-self: center;
          margin-top: 97px;
          width: 100%;
          max-width: 1402px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .partner_div-25 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-26 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-26 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 29%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column {
            width: 100%;
            align-self:center;
            align-items:center;

          }
        }
        .partner_div-27 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-27 {
            margin-top: 40px;
          }
        }
        .partner_img-3 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 119px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
        }
        .partner_div-28 {
          color: #0a1422;
          text-align: center;
          letter-spacing: 0.285px;
          margin-top: 21px;
          font: 400 26px/146% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 27%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-2 {
            width: 100%;
            margin-left: 0;
          }
        }
        .partner_div-29 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-29 {
            margin-top: 40px;
          }
        }
        .partner_img-4 {
          aspect-ratio: 0.98;
          object-fit: contain;
          object-position: center;
          width: 117px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
        }
        .partner_div-30 {
          color: #0a1422;
          text-align: center;
          letter-spacing: 0.285px;
          margin-top: 21px;
          font: 400 29px/131% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 29%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-3 {
            width: 100%;
            margin-left: 0;
          }
        }
        .partner_div-31 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-31 {
            margin-top: 40px;
          }
        }
        .partner_img-5 {
          aspect-ratio: 0.97;
          object-fit: contain;
          object-position: center;
          width: 115px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
        }
        .partner_div-32 {
          color: #0a1422;
          text-align: center;
          letter-spacing: 0.285px;
          margin-top: 22px;
          font: 400 27px/141% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 14%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-4 {
            width: 100%;
            margin-left: 0;
          }
        }
        .partner_div-33 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-33 {
            margin-top: 40px;
          }
        }
        .partner_img-6 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 119px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
        }
        .partner_div-34 {
          color: #0a1422;
          text-align: center;
          letter-spacing: 0.285px;
          margin-top: 23px;
          font: 400 29px/131% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-35 {
          background-color: rgba(230, 195, 255, 0.7);
          align-self: stretch;
          display: flex;
          margin-top: 105px;
          width: 100%;
          flex-direction: column;
          padding: 41px 20px 64px;
        }
        @media (max-width: 991px) {
          .partner_div-35 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-36 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 1351px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-36 {
            max-width: 100%;
          }
        }
        .partner_div-37 {
          color: rgba(0, 0, 0, 0.85);
          margin-top: 4px;
          font: 400 52px/91% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-37 {
            max-width: 100%;
            font-size: 40px;
            text-align:center;
          }
        }
        .partner_div-38 {
          align-self: center;
          display: flex;
          margin-top: 54px;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-38 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
          }
        }
        .partner_div-39 {
          align-self: start;
          display: flex;
          flex-direction: column;
        }
        .partner_img-7 {
          aspect-ratio: 0.97;
          object-fit: contain;
          object-position: center;
          width: 37px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .partner_img-7 {
            align-self: center;
          }
        }
        .partner_div-40 {
          color: #2e3033;
          align-self: start;
          margin-top: 5px;
          white-space: nowrap;
          font: 400 38px/150% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-40 {
            white-space: wrap;
            text-align:center;
            align-self: center;
          }
        }
        .partner_div-41 {
          color: #2e3033;
          margin-top: 27px;
          font: 400 18px/159% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-42 {
          align-self: start;
          display: flex;
          flex-direction: column;
        }
        .partner_img-8 {
          aspect-ratio: 0.97;
          object-fit: contain;
          object-position: center;
          width: 37px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .partner_img-8 {
            align-self: center;
          }
        }
        .partner_div-43 {
          color: #2e3033;
          align-self: start;
          margin-top: 7px;
          white-space: nowrap;
          font: 400 39px/146% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-43 {
            white-space: wrap;
            align-self: center;

          }
        }
        .partner_div-44 {
          color: #2e3033;
          margin-top: 27px;
          font: 400 21px/136% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-44 {
            white-space: wrap;
            text-align:center;
            align-self: center;

          }
        }
        .partner_div-45 {
          align-self: start;
          display: flex;
          flex-direction: column;
        }
        .partner_img-9 {
          aspect-ratio: 0.97;
          object-fit: contain;
          object-position: center;
          width: 37px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .partner_img-9 {
          
            text-align:center;
            align-self: center;

          }
        }
        .partner_div-46 {
          color: #2e3033;
          align-self: start;
          margin-top: 6px;
          white-space: nowrap;
          font: 400 40px/143% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-46 {
            white-space: wrap;
            align-self: center;

          }
        }
        .partner_div-47 {
          color: #2e3033;
          margin-top: 33px;
          font: 400 18px/159% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-47 {
            white-space: wrap;
            align-self: center;
            text-align:center;

          }
        }
        .partner_div-48 {
          align-self: center;
          display: flex;
          margin-top: 98px;
          width: 100%;
          max-width: 1465px;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .partner_div-48 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-49 {
          border: 3px solid #e6e6e6;
          background-color: #fff;
          align-self: center;
          display: flex;
          margin-left: -20px;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 40px 52px;
        }
        @media (max-width: 991px) {
          .partner_div-49 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .partner_div-50 {
          color: #2e3033;
          align-self: center;
          max-width: 679px;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
          font: 400 39px/122% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-50 {
            max-width: 100%;
            text-align:center;
          }
        }
        .partner_div-51 {
          border-radius: 4px;
          border: 0px solid #d9d9d9;
          box-shadow: 0px -3px 0px 0px #fc352b;
          background-color: #fc5530;
          align-self: center;
          display: flex;
          width: 333px;
          max-width: 100%;
          flex-direction: column;
          margin: auto 0;
          padding: 10px 20px;
        }
        .partner_div-52 {
          color: #fff;
          text-align: center;
          letter-spacing: 0.285px;
          text-transform: capitalize;
          align-self: center;
          white-space: nowrap;
          font: 400 25px/152% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-52 {
            white-space: initial;
          }
        }
        .partner_div-53 {
          align-self: center;
          margin-top: 117px;
          width: 100%;
          max-width: 1376px;
        }
        @media (max-width: 991px) {
          .partner_div-53 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-54 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-54 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 54%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column-5 {
            width: 100%;
          }
        }
        .partner_div-55 {
          display: flex;
          margin-top: 14px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-55 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-56 {
          color: #2e3033;
          font: 400 56px/144% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-56 {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .partner_div-57 {
          align-self: start;
          display: flex;
          width: 396px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          margin: 14px 0 0 10px;
        }
        .partner_img-10 {
          aspect-ratio: 0.99;
          object-fit: contain;
          object-position: center;
          width: 103px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .partner_div-58 {
          align-self: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
        }
        .partner_div-59 {
          color: #2e3033;
          font: 400 18px/159% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-60 {
          color: #2e3033;
          margin-top: 6px;
          font: 400 18px/159% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 46%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-6 {
            width: 100%;
            margin-left: 0;
          }
        }
        .partner_img-11 {
          aspect-ratio: 1.45;
          object-fit: contain;
          object-position: center;
          width: 585px;
          overflow: hidden;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .partner_img-11 {
            margin-top: 40px;
          }
        }
        .partner_div-61 {
          align-self: stretch;
          margin-top: 142px;
        }
        @media (max-width: 991px) {
          .partner_div-61 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-62 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-62 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 58%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column-7 {
            width: 100%;
          }
        }
        .partner_div-63 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 796px;
        }
        @media (max-width: 991px) {
          .partner_div-63 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-64 {
          color: #2e3033;
          font: 400 48px/99% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-64 {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .partner_div-65 {
      
          align-self: stretch;
          display: flex;
          margin-top: 76px;
          width: 100%;
          height: 3px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-65 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-66 {
          margin-top: 40px;
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .partner_div-66 {
            max-width: 100%;
          }
        }
        .partner_div-67 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-67 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 100%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column-8 {
            width: 100%;
          }
        }
        .partner_div-68 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .partner_div-68 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-69 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-69 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 1%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column-9 {
            width: 100%;
          }
        }
        .partner_div-70 {
          background-color: rgba(230, 195, 255, 0.8);
          display: flex;
          width: 4px;
          height: 146px;
          flex-direction: column;
          margin: 0 auto;
        }
        @media (max-width: 991px) {
          .partner_div-70 {
            margin-top: 40px;
          }
        }
        .partner_column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 11%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-10 {
            width:30%;
            margin-left: 0;
            align-self:center;
          }
        }
        .partner_img-12 {
          aspect-ratio: 1.18;
          object-fit: contain;
          object-position: center;
          width: 59px;
          overflow: hidden;
          max-width: 100%;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .partner_img-12 {
            margin-top: 40px;
          }
        }
        .partner_column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 88%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-11 {
            width: 100%;
            margin-left:0;
          }
        }
        .partner_div-71 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .partner_div-71 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-72 {
          color: #0a1422;
          align-self: stretch;
          white-space: nowrap;
          font: 400 26px/146% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-72 {
            max-width: 100%;
            align-self:center;
            white-space: initial;
            text-align:center;

          }
        }
        .partner_div-73 {
          color: #3e4152;
          margin-top: 13px;
          font: 400 14px/155% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-73 {
            max-width: 100%;
            align-self:center;
            white-space: initial;
            text-align:center;
          }
        }
        .partner_column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 0%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-12 {
            width: 100%;
          }
        }
        .partner_div-74 {
          background-color: #f0f0f0;
          display: flex;
          width: 3px;
          height: 146px;
          flex-direction: column;
          margin: 0 auto;
        }
        @media (max-width: 991px) {
          .partner_div-74 {
            margin-top: 40px;
          }
        }
        .partner_div-75 {
          background-color: #f0f0f0;
          align-self: stretch;
          display: flex;
          width: 100%;
          height: 3px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-75 {
            max-width: 100%;
          }
        }
        .partner_div-76 {
          align-self: center;
          margin-top: 37px;
          width: 648px;
          max-width: 100%;
        }
        .partner_div-77 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-77 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 11%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column-13 {
            width: 100%;
          }
        }
        .partner_div-78 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-78 {
            margin-top: 40px;
          }
        }
        .partner_img-13 {
          aspect-ratio: 1.22;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .partner_img-13 {
            align-self:center;

          }
        }
        .partner_img-14 {
          aspect-ratio: 1.22;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 96px;
        }
        @media (max-width: 991px) {
          .partner_img-14 {
            margin-top: 40px;
          }
        }
        .partner_img-15 {
          aspect-ratio: 1.22;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 96px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .partner_img-15 {
            margin-top: 40px;
          }
        }
        .partner_column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 89%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-14 {
            width: 100%;
          }
        }
        .partner_div-79 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-79 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-80 {
          color: #0a1422;
          align-self: stretch;
          white-space: nowrap;
          font: 400 25px/152% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-80 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .partner_div-81 {
          color: #3e4152;
          margin-top: 9px;
          font: 400 17px/127% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-81 {
            max-width: 100%;
          }
        }
        .partner_div-82 {
          color: #0a1422;
          align-self: stretch;
          margin-top: 60px;
          white-space: nowrap;
          font: 400 25px/152% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-82 {
            max-width: 100%;
            margin-top: 40px;
            white-space: initial;
          }
        }
        .partner_div-83 {
          color: #3e4152;
          margin-top: 8px;
          font: 400 14px/155% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-83 {
            max-width: 100%;
          }
        }
        .partner_div-84 {
          color: #0a1422;
          align-self: stretch;
          margin-top: 82px;
          white-space: nowrap;
          font: 400 27px/141% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-84 {
            max-width: 100%;
            margin-top: 40px;
            white-space: initial;
          }
        }
        .partner_div-85 {
          color: #3e4152;
          margin-top: 14px;
          font: 400 17px/127% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-85 {
            max-width: 100%;
          }
        }
        .partner_column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 42%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-15 {
            width: 100%;
            margin-left: 0;
          }
        }
        .partner_img-16 {
          aspect-ratio: 1.08;
          object-fit: contain;
          object-position: center;
          width: 583px;
          overflow: hidden;
          margin-top: 105px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .partner_img-16 {
            margin-top: 40px;
          }
        }
        .partner_div-86 {
          align-self: stretch;
          margin-top: 156px;
        }
        @media (max-width: 991px) {
          .partner_div-86 {
            max-width: 100%;
            margin: 40px 1px 0 0;
          }
        }
        .partner_div-87 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-87 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column-16 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column-16 {
            width: 100%;
          }
        }
        .partner_div-88 {
          border-radius: 6px;
          background-color: #faf2ff;
          flex-grow: 1;
          padding-right: 22px;
          width: 100%;
          margin: 0 auto;
        }
        @media (max-width: 991px) {
          .partner_div-88 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-89 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-89 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column-17 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 35%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column-17 {
            width: 100%;
          }
        }
        .partner_img-17 {
          aspect-ratio: 1.09;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .partner_img-17 {
            margin-top: 18px;
          }
        }
        .partner_column-18 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 65%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-18 {
            width: 100%;
          }
        }
        .partner_div-90 {
          display: flex;
          margin-top: 35px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-90 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-91 {
          color: #0a1422;
          letter-spacing: 2.568px;
          text-transform: uppercase;
          align-self: stretch;
          white-space: nowrap;
          font: 400 13px/146% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-91 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .partner_div-92 {
          color: #0a1422;
          font: 400 25px/152% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-92 {
            max-width: 100%;
          }
        }
        .partner_div-93 {
          color: #3e4152;
          margin-top: 19px;
          font: 400 17px/127% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-93 {
            max-width: 100%;
          }
        }
        .partner_column-19 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-19 {
            width: 100%;
            margin-left: 0;
          }
        }
        .partner_div-94 {
          border-radius: 6px;
          background-color: #faf2ff;
          flex-grow: 1;
          padding-right: 4px;
          width: 100%;
          margin: 0 auto;
        }
        @media (max-width: 991px) {
          .partner_div-94 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-95 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-95 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column-20 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column-20 {
            width: 100%;
          }
        }
        .partner_img-18 {
          aspect-ratio: 1.04;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .partner_img-18 {
            margin-top: 19px;
          }
        }
        .partner_column-21 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 67%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-21 {
            width: 100%;
          }
        }
        .partner_div-96 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .partner_div-96 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-97 {
          color: #0a1422;
          letter-spacing: 2.568px;
          text-transform: uppercase;
          align-self: stretch;
          white-space: nowrap;
          font: 400 12px/159% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-97 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .partner_div-98 {
          color: #0a1422;
          align-self: stretch;
          white-space: nowrap;
          font: 400 25px/152% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-98 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .partner_div-99 {
          color: #3e4152;
          margin-top: 24px;
          font: 400 17px/127% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-99 {
            max-width: 100%;
          }
        }
        .partner_div-100 {
          border: 3px solid #e6e6e6;
          background-color: #fff;
          align-self: center;
          display: flex;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          margin: 146px 0 0 -20px;
          padding: 40px 52px;
        }
        @media (max-width: 991px) {
          .partner_div-100 {
            max-width: 100%;
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .partner_div-101 {
          color: #2e3033;
          align-self: center;
          max-width: 679px;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
          font: 400 39px/122% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-101 {
            max-width: 100%;
            text-align:center;
          }
        }
        .partner_div-102 {
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          box-shadow: 0px -3px 0px 0px #fc352b;
          background-color: #fc5530;
          align-self: center;
          display: flex;
          width: 333px;
          max-width: 100%;
          flex-direction: column;
          margin: auto 0;
          padding: 10px 20px;
        }
        .partner_div-103 {
          color: #fff;
          text-align: center;
          letter-spacing: 0.285px;
          text-transform: capitalize;
          align-self: center;
          white-space: nowrap;
          font: 400 25px/152% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-103 {
            white-space: initial;
          }
        }
        .partner_div-104 {
          color: #2e3033;
          align-self: start;
          white-space: nowrap;
          margin: 144px 0 0 18px;
          font: 400 42px/101% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-104 {
            white-space: initial;
            margin: 40px 0 0 10px;
          }
        }
        .partner_div-105 {
          align-self: center;
          display: flex;
          margin-top: 139px;
          width: 100%;
          max-width: 1376px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-105 {
            max-width: 100%;
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .partner_div-106 {
          color: #df3429;
          align-self: center;
          max-width: 934px;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-106 {
            max-width: 100%;
          }
        }
        .partner_img-19 {
          aspect-ratio: 0.93;
          object-fit: contain;
          object-position: center;
          width: 25px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .partner_div-107 {
          align-self: start;
          width: 553px;
          max-width: 100%;
          margin: 24px 0 0 36px;
        }
        @media (max-width: 991px) {
          .partner_div-107 {
            max-width: 100%;
            margin:auto 0;
          }
        }
        .partner_div-108 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .partner_div-108 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .partner_column-22 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 1%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .partner_column-22 {
            width: 100%;
          }
        }
        .partner_div-109 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .partner_div-109 {
            margin-top: 32px;
          }
        }
        .partner_div-110 {
          color: #0a1422;
          font: 400 25px/136% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-111 {
          color: #0a1422;
          margin-top: 23px;
          font: 400 25px/136% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-112 {
          color: #0a1422;
          margin-top: 23px;
          font: 400 25px/136% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-113 {
          color: #0a1422;
          margin-top: 23px;
          font: 400 25px/136% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-114 {
          color: #0a1422;
          margin-top: 23px;
          font: 400 25px/136% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-115 {
          color: #0a1422;
          margin-top: 23px;
          font: 400 25px/136% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_column-23 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 99%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .partner_column-23 {
            width: 100%;
          }
        }
        .partner_div-116 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-116 {
            max-width: 100%;
            margin-top: 23px;
          }
        }
        .partner_div-117 {
          color: #0a1422;
          align-self: stretch;
          white-space: nowrap;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-117 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .partner_div-118 {
          color: #0a1422;
          align-self: stretch;
          white-space: nowrap;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-118 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .partner_div-119 {
          color: #0a1422;
          align-self: stretch;
          margin-top: 6px;
          white-space: nowrap;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-119 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .partner_div-120 {
          color: #0a1422;
          align-self: stretch;
          margin-top: 6px;
          white-space: nowrap;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-120 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .partner_div-121 {
          color: #0a1422;
          margin-top: 6px;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-121 {
            max-width: 100%;
          }
        }
        .partner_div-122 {
          color: #0a1422;
          align-self: stretch;
          white-space: nowrap;
          font: 400 21px/162% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-122 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .partner_div-123 {
          background-color: #d3d3d3;
          align-self: stretch;
          display: flex;
          margin-top: 24px;
          width: 100%;
          height: 1px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-123 {
            max-width: 100%;
          }
        }
        .partner_div-124 {
          align-self: center;
          display: flex;
          margin-top: 33px;
          width: 100%;
          max-width: 1376px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-124 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .partner_div-125 {
          color: #df3429;
          align-self: start;
          max-width: 1079px;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-125 {
            max-width: 100%;
          }
        }
        .partner_img-20 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .partner_div-126 {
          background-color: #d3d3d3;
          align-self: stretch;
          display: flex;
          margin-top: 39px;
          width: 100%;
          height: 1px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-126 {
            max-width: 100%;
          }
        }
        .partner_div-127 {
          align-self: center;
          display: flex;
          margin-top: 33px;
          width: 100%;
          max-width: 1376px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-127 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .partner_div-128 {
          color: #df3429;
          align-self: start;
          max-width: 969px;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-128 {
            max-width: 100%;
          }
        }
        .partner_img-21 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .partner_div-129 {
          background-color: #d3d3d3;
          align-self: stretch;
          display: flex;
          margin-top: 39px;
          width: 100%;
          height: 1px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-129 {
            max-width: 100%;
          }
        }
        .partner_div-130 {
          align-self: center;
          display: flex;
          margin-top: 32px;
          width: 100%;
          max-width: 1376px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-130 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .partner_div-131 {
          color: #df3429;
          align-self: start;
          max-width: 825px;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-131 {
            max-width: 100%;
          }
        }
        .partner_img-22 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .partner_div-132 {
          background-color: #d3d3d3;
          align-self: stretch;
          display: flex;
          margin-top: 39px;
          width: 100%;
          height: 1px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-132 {
            max-width: 100%;
          }
        }
        .partner_div-133 {
          align-self: center;
          display: flex;
          margin-top: 33px;
          width: 100%;
          max-width: 1376px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-133 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .partner_div-134 {
          color: #df3429;
          align-self: start;
          max-width: 635px;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-134 {
            max-width: 100%;
          }
        }
        .partner_img-23 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .partner_div-135 {
          background-color: #d3d3d3;
          align-self: stretch;
          display: flex;
          margin-top: 39px;
          width: 100%;
          height: 1px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-135 {
            max-width: 100%;
          }
        }
        .partner_div-136 {
          align-self: center;
          display: flex;
          margin-top: 32px;
          width: 100%;
          max-width: 1376px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-136 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .partner_div-137 {
          color: #df3429;
          align-self: start;
          max-width: 895px;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-137 {
            max-width: 100%;
          }
        }
        .partner_img-24 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .partner_div-138 {
          background-color: #d3d3d3;
          align-self: stretch;
          display: flex;
          margin-top: 39px;
          width: 100%;
          height: 1px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-138 {
            max-width: 100%;
          }
        }
        .partner_div-139 {
          align-self: start;
          display: flex;
          width: 457px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          margin: 55px 0 0 18px;
        }
        @media (max-width: 991px) {
          .partner_div-139 {
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .partner_div-140 {
          color: #000;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-141 {
          color: #df3429;
          align-self: stretch;
          white-space: nowrap;
          font: 400 22px/154% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-141 {
            white-space: initial;
          }
        }
        .partner_div-142 {
          background-color: #000;
          align-self: stretch;
          display: flex;
          margin-top: 142px;
          width: 100%;
          flex-direction: column;
          padding: 56px 20px 27px;
        }
        @media (max-width: 991px) {
          .partner_div-142 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-143 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 1071px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-143 {
            max-width: 100%;
          }
        }
        .partner_div-144 {
          align-self: center;
          display: flex;
          margin-left: -83px;
          width: 369px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        .partner_div-145 {
          align-self: start;
          display: flex;
          flex-direction: column;
        }
        .partner_div-146 {
          color: #fff;
          align-self: stretch;
          white-space: nowrap;
          font: 400 18px/130% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-146 {
            white-space: initial;
          }
        }
        .partner_div-147 {
          color: #ccc;
          align-self: stretch;
          margin-top: 24px;
          white-space: nowrap;
          font: 400 12px/186% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-147 {
            white-space: initial;
          }
        }
        .partner_div-148 {
          color: #ccc;
          align-self: stretch;
          margin-top: 10px;
          white-space: nowrap;
          font: 400 12px/186% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-148 {
            white-space: initial;
          }
        }
        .partner_div-149 {
          color: #ccc;
          align-self: stretch;
          margin-top: 10px;
          white-space: nowrap;
          font: 400 13px/172% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-149 {
            white-space: initial;
          }
        }
        .partner_div-150 {
          color: #ccc;
          align-self: stretch;
          margin-top: 8px;
          white-space: nowrap;
          font: 400 12px/186% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-150 {
            white-space: initial;
          }
        }
        .partner_div-151 {
          align-self: start;
          display: flex;
          flex-direction: column;
        }
        .partner_div-152 {
          color: #fff;
          align-self: stretch;
          white-space: nowrap;
          font: 400 19px/123% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-152 {
            white-space: initial;
          }
        }
        .partner_div-153 {
          color: #ccc;
          align-self: stretch;
          margin-top: 26px;
          white-space: nowrap;
          font: 400 14px/159% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-153 {
            white-space: initial;
          }
        }
        .partner_div-154 {
          align-self: stretch;
          display: flex;
          margin-top: 11px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 0px;
        }
        @media (max-width: 991px) {
          .partner_div-154 {
            justify-content: center;
          }
        }
        .partner_div-155 {
          color: #ccc;
          align-self: start;
          font: 400 14px/159% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-156 {
          color: #ccc;
          align-self: start;
          font: 400 15px/149% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-157 {
          color: #ccc;
          align-self: start;
          white-space: nowrap;
          font: 400 13px/172% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-157 {
            white-space: initial;
          }
        }
        .partner_div-158 {
          color: #ccc;
          align-self: stretch;
          margin-top: 4px;
          white-space: nowrap;
          font: 400 12px/186% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .partner_div-158 {
            white-space: initial;
          }
        }
        .partner_div-159 {
          background-color: #323f45;
          align-self: stretch;
          display: flex;
          margin-top: 44px;
          width: 100%;
          height: 1px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .partner_div-159 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .partner_div-160 {
          align-self: center;
          display: flex;
          margin-top: 9px;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .partner_div-160 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .partner_img-25 {
          aspect-ratio: 2.81;
          object-fit: contain;
          object-position: center;
          width: 211px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .partner_div-161 {
          color: #fff;
          text-align: center;
          margin: auto 0;
          font: 400 15px/125% Montserrat, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .partner_div-162 {
          align-self: center;
          display: flex;
          align-items: flex-start;
          gap: 19px;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .partner_div-162 {
            justify-content: center;
          }
        }
        .partner_div-163 {
          border: 1px solid #ccc;
          display: flex;
          height: 14px;
          flex-direction: column;
          flex: 1;
        }
        .partner_div-164 {
          border: 1px solid #ccc;
          display: flex;
          height: 14px;
          flex-direction: column;
          flex: 1;
        }
        .partner_div-165 {
          border: 1px solid #ccc;
          display: flex;
          height: 14px;
          flex-direction: column;
          flex: 1;
        }
        .partner_div-166 {
          border: 1px solid #ccc;
          display: flex;
          height: 14px;
          flex-direction: column;
          flex: 1;
        }
      `}</style>{" "}
    </>
  );
}

export default Partner_Page;
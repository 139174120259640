import React, { useContext } from 'react';
import { LetterHeadsContext } from './letter_heads_data'; // Import the LetterHeadsContext

function LetterHeads() {
  const { letterheads } = useContext(LetterHeadsContext); // Use LetterHeadsContext to access letterheads data
  console.log(letterheads);

  return (
    <>
      {letterheads.length !== 0 && <h1>Letterheads</h1>}
      <div className='products-container'>
        {letterheads.length === 0 && <div>slow internet...no products to display</div>}
        {letterheads.map(product => (
          <div className='product-card' key={product.ProductID}>
            <div className='product-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-name'>
              {product.ProductName}
            </div>
            <div className='product-name'>
              {product.ProductPrice}
            </div>
            <button className='addcart-btn' >ADD TO CART</button>
          </div>
        ))}
      </div>
    </>
  );
}

export default LetterHeads;

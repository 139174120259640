import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const SchoolCategoryContext = createContext();

export const SchoolCategoryContextProvider = ({ children }) => {
  const [schoolcategory, setSchoolCategory] = useState([]); // Corrected the state variable name

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('School Categories').get();
        const schoolcategoryData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().Image,
          ProductName: doc.data().Name,
        }));
        setSchoolCategory(schoolcategoryData); // Corrected the state setter function
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <SchoolCategoryContext.Provider value={{ schoolcategory }}>
      {children}
    </SchoolCategoryContext.Provider>
  );
};

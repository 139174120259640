import React, { createContext } from 'react';
import firebase from 'firebase/compat/app';
import { db } from './Firebase';

export const MedicalUniformsContext = createContext();

export class MedicalUniformsContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      medicaluniforms: [],
    };

    this.fetchMedicalUniforms();
  }

  fetchMedicalUniforms() {
    const prevMedicalUniforms = this.state.medicaluniforms;
    db.collection('Uniforms/Medical/Medical_uniforms').onSnapshot(snapshot => {
      let changes = snapshot.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          prevMedicalUniforms.push({
            ProductID: change.doc.id,
            ProductImg: change.doc.data().image,
            ProductName: change.doc.data().name,
            ProductPrice: change.doc.data().price,
          });
        }
        this.setState({
          medicaluniforms: prevMedicalUniforms,
        });
      });
    });
  }

  render() {
    return (
      <MedicalUniformsContext.Provider value={{ medicaluniforms: [...this.state.medicaluniforms] }}>
        {this.props.children}
      </MedicalUniformsContext.Provider>
    );
  }
}

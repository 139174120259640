/*import React from 'react'
import './Header.css'
import SearchIcon from '@mui/icons-material/Search';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {Link} from 'react-router-dom'
import { useStateValue } from './StateProvider';


function Header() {  
  const [{basket}, dispatch] = useStateValue();
    return (
      <div className="header">
        <Link to = "/">
          <img className="header__logo"
            src="http://pngimg.com/uploads/amazon/amazon_PNG11.png"
          />
        </Link>
  
        <div className="header__search">
          <input className="header__searchInput" type="text" />
          <SearchIcon className="header__searchIcon" />
        </div>
  
        <div className="header__nav">
          <Link to = '/login'>
            <div className="header__option">
              <span className="header__optionLineOne">Hello Guest</span>
              <span className="header__optionLineTwo">Sign In</span>
            </div>
            </Link>
          
  
          
            <div className="header__option">
              <span className="header__optionLineOne">Returns</span>
              <span className="header__optionLineTwo">& Orders</span>
            </div>
          
          
  
          <div className="header__option">
            <span className="header__optionLineOne">Your</span>
            <span className="header__optionLineTwo">Prime</span>
          </div>
  
          <Link to = "/checkout">
          <div className="header__optionBasket">
              <ShoppingCartOutlinedIcon  />
              <span className="header__optionLineTwo header__basketCount">
                {basket?.length}
              </span>
            </div>
          </Link>
            
          
        </div>
      </div>
    );
  }
  
  export default Header;*/
/*
  
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Link } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import './Header.css'

function Header() {
  const [{ basket }, dispatch] = useStateValue();
  const [searchInput, setSearchInput] = useState('');
  const history = useHistory();

  const handleSearch = (e) => {
    e.preventDefault();

    // Redirect to the product search page with the search query
    history.push(`/product-search?query=${encodeURIComponent(searchInput)}`);
  };

  return (
    <div className="header">
      <Link to="/">
        <img
          className="header__logo"
          src="http://pngimg.com/uploads/amazon/amazon_PNG11.png"
          alt="Amazon Logo"
        />
      </Link>

      <form onSubmit={handleSearch}>
        <div className="header__search">
          <input
            className="header__searchInput"
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <SearchIcon className="header__searchIcon" />
        </div>
      </form>

      <div className="header__nav">
        <Link to="/login">
          <div className="header__option">
            <span className="header__optionLineOne">Hello Guest</span>
            <span className="header__optionLineTwo">Sign In</span>
          </div>
        </Link>

        <div className="header__option">
          <span className="header__optionLineOne">Returns</span>
          <span className="header__optionLineTwo">& Orders</span>
        </div>

        <div className="header__option">
          <span className="header__optionLineOne">Your</span>
          <span className="header__optionLineTwo">Prime</span>
        </div>

        <Link to="/checkout">
          <div className="header__optionBasket">
            <ShoppingCartOutlinedIcon />
            <span className="header__optionLineTwo header__basketCount">
              {basket?.length}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Header;
*/
/*
import React from 'react';
import './Header.css';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Link } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import { useSearch } from './SearchContext';

function Header() {
  const [{ basket }, dispatch] = useStateValue();
  const { setSearchInput } = useSearch();

  const handleSearch = (e) => {
    e.preventDefault();
    // Set the search input in the context
    // Optionally, you can redirect to the search page here
    // history.push('/Search');
  };

  return (
    <div className="header">
      <Link to="/">
        <img
          className="header__logo"
          src="http://pngimg.com/uploads/amazon/amazon_PNG11.png"
          alt="Amazon Logo"
        />
      </Link>

      <form className="header__search" onSubmit={handleSearch}>
        <input
          className="header__searchInput"
          type="text"
          placeholder="Search products"
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <SearchIcon className="header__searchIcon" />
      </form>

      <div className="header__nav">
        <Link to="/login">
          <div className="header__option">
            <span className="header__optionLineOne">Hello Guest</span>
            <span className="header__optionLineTwo">Sign In</span>
          </div>
        </Link>

        <div className="header__option">
          <span className="header__optionLineOne">Returns</span>
          <span className="header__optionLineTwo">& Orders</span>
        </div>

        <div className="header__option">
          <span className="header__optionLineOne">Your</span>
          <span className="header__optionLineTwo">Prime</span>
        </div>

        <Link to="/checkout">
          <div className="header__optionBasket">
            <ShoppingCartOutlinedIcon />
            <span className="header__optionLineTwo header__basketCount">
              {basket?.length}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Header;
*/

import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useHistory } from 'react-router-dom';
import './Header.css';
import { useStateValue } from './StateProvider';
import {GrInstagram} from 'react-icons/gr';
import {CgFormatSlash} from 'react-icons/cg';
import {SiLinkedin} from 'react-icons/si';
import { HiMiniInformationCircle } from "react-icons/hi2";
import { SiCmake } from "react-icons/si";
import { AiFillMessage } from "react-icons/ai";
import { FaHandshakeSimple } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

function Header() {
  const [searchInput, setSearchInput] = useState('');
  const [pagesContainerVisible, setPagesContainerVisible] = useState(false);
  const [{ basket }, dispatch] = useStateValue();
  const history = useHistory();

  const handleSearch = (e) => {
    e.preventDefault(); // Prevent the form from submitting
    // Redirect to the ProductSearch page with the search input as a query parameter
    history.push(`/product-search?search=${searchInput}`);
  };
  const togglePagesContainer = () => {
    setPagesContainerVisible(!pagesContainerVisible);
  };
  return (
    <div className="header">

     <div className='logo_search_container'>

      <Link to="/">
        <img className="header__logo" src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FCusto%20King.jpeg?alt=media&token=55c1b851-7aef-48a4-948d-d1955c2169fa&_gl=1*1o2phvi*_ga*MTg4NzM1MTY3OC4xNjk4NjY4MjI1*_ga_CW55HF8NVT*MTY5ODgxMDYxMi42LjEuMTY5ODgxMDY1My4xOS4wLjA." alt="Custoking" />
      </Link>

      <form className="header__search" onSubmit={handleSearch}>
        <input
          className="header__searchInput"
          type="text"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search products"
        />
        <button type="submit" className="header__searchIcon" aria-label= "search producs">
          <SearchIcon />
        </button>
      </form>
      </div>
      
     
      <div className='pages_container'>
        <Link to = '/About_Us' style={{ textDecoration: 'none'}}  className='pages'>
        <HiMiniInformationCircle style={{ fontSize: '25px' }} />
          About
        </Link>

        <Link to = "/Apparel_Design"style={{ textDecoration: 'none'}}  className='pages'>
        <SiCmake style={{ fontSize: '25px' }} />
          Customization
        </Link>

        <div className='pages'>
        <AiFillMessage style={{ fontSize: '25px' }}  />
          Support
        </div>
        <Link to = "/PartnerProgram" style={{ textDecoration: 'none'}}  className='pages'>
        <FaHandshakeSimple style={{ fontSize: '25px' }} />
          Partner 
        </Link>
        <div className='social_pages'>
          <SiLinkedin/>
        </div>
        <a href="https://www.instagram.com/custoking?utm_source=qr&igsh=MWQ0bnkyYTVnZDQ0Ng==" aria-label="Explore the instagram page of custoking" target="_blank" rel="noopener noreferrer" className='social_pages'>

        <GrInstagram/>
        
        </a>
        <a href="https://www.facebook.com/profile.php?id=61554183542980&mibextid=ZbWKwL" aria-label="Redrect to Facebook Page of Custoking" target="_blank" rel="noopener noreferrer" className='social_pages'>

        <FaFacebook />

</a>

    
        </div>
        

    </div>
  );
}

export default Header;

import React, { useState, useEffect } from 'react';
import { db, storage } from './Firebase';
import Slider from 'react-slick';

import firebase from './Firebase';
import { analytics } from './Firebase';
import 'slick-carousel/slick/slick.css';
import { Link } from "react-router-dom";
import 'slick-carousel/slick/slick-theme.css';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import {GrNext , GrPrevious} from 'react-icons/gr';
import { Helmet } from 'react-helmet';
import './School_Section_Page.css';
import ReactGA from 'react-ga';





const images = [
    'https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FHome%20Poster%201.png?alt=media&token=8cf96575-31a3-4774-a371-49669c65c49d',
     'https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FHome%20Poster%202.png?alt=media&token=6c76d39b-ee5e-4ac3-ae7c-a8e069135db2',
    'https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FHome%20Poster%203.png?alt=media&token=a4a7effd-31fa-4347-b793-da86f94e833e',
    'https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FHome%20Poster%204.png?alt=media&token=15be432e-154b-4e51-80cd-ebd643ea05e1'
  ];


  const destinations = [
    '/School_Uniform_Details',
    '/Stationary',
    '/School',
    '/School_Uniform_Details'
  ];

  


function School_Section_Page() {

  const trace = firebase.performance().trace('my_custom_trace');

  /*useEffect(() => {
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

    // Redirect to the mobile version if on a mobile device
    if (isMobile) {
      window.location.href = '/School_Services'; // Replace with the actual URL for your mobile version
    }
  }, []);*/

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const [sectionVisible, setSectionVisible] = useState(1);
  const [loadedSections, setLoadedSections] = useState([]);


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const section1 = document.getElementById('section1');
      const section2 = document.getElementById('section2');
      const section3 = document.getElementById('section3');
      const section4 = document.getElementById('section4');

      if (section1 && scrollPosition > section1.offsetTop && !loadedSections.includes(1)) {
        setSectionVisible(1);
        setLoadedSections([...loadedSections, 1]);
      }

      if (section2 && scrollPosition > section2.offsetTop && !loadedSections.includes(2)) {
        setSectionVisible(2);
        setLoadedSections([...loadedSections, 2]);
      }

      if (section3 && scrollPosition > section3.offsetTop && !loadedSections.includes(3)) {
        setSectionVisible(3);
        setLoadedSections([...loadedSections, 3]);
      }

      if (section4 && scrollPosition > section4.offsetTop && !loadedSections.includes(4)) {
        setSectionVisible(4);
        setLoadedSections([...loadedSections, 4]);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loadedSections]);











  const [RequirementError, setRequirementError] = useState(false);
    const [Requirement, setRequirement] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [contactInfoError, setContactInfoError] = useState(false);
    const [orderQuantity, setorderQuantity] = useState(30);
    const [loading, setLoading] = useState(true);

    const [QuantityError, setQuantityError] = useState(false);
  
    const validateContactInfo = (value) => {
      const phoneRegex = /^\d{10}$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      return phoneRegex.test(value) || emailRegex.test(value);
    };
  
    const handleContactInfoChange = (e) => {
      setContactInfo(e.target.value);
      setContactInfoError(!validateContactInfo(e.target.value));
    };
  
    const handleRequirementChange = (e) => {
      setRequirement(e.target.value);
      setRequirementError(e.target.value.trim() === ''); // Address is mandatory
    };
    const handleQuantityChange = (e) => {
      setorderQuantity(e.target.value);
      setQuantityError(e.target.value.trim() === ''); // Address is mandatory
    };
    
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      // Validate contact information
      if (!validateContactInfo(contactInfo)) {
        setContactInfoError(true);
        return; // Don't proceed with the submission
      }
      setContactInfoError(false);
  
      // Validate address
      if (Requirement.trim() === '') {
        setRequirementError(true);
        return; // Don't proceed with the submission
      }
      setRequirementError(false);

      if (orderQuantity.toString().trim() === '') {
        setQuantityError(true);
        return; // Don't proceed with the submission
      }
      
      setQuantityError(false);
  
      // Save order data only when the file URL is available
      console.log('About to call saveOrderData');
      console.log('About to call saveOrderData Inside');
      saveOrderData();

    };
  
    const saveOrderData = async () => {
      console.log('saveOrderData function called');
      const ordersRef = db.collection('RFQ');
  
      const orderData = {
        Requirement: Requirement,
        Contact: contactInfo,
        orderQuantity: orderQuantity,
      };
      console.log('Order Data:', orderData);
      try {
        // Use add to let Firestore generate a unique document ID
        await ordersRef.add(orderData);
  
        // Reset fields
        setContactInfo('');
        setRequirement('');
        setorderQuantity(30);
  
        // Optionally, you can show a success message to the user
        alert('Request submitted successfully!');
      } catch (error) {
        // Handle any errors that may occur during data submission
        console.error('Error submitting request:', error);
        alert('Error submitting request. Please try again.');
      }
    };
  
    const NextArrow = ({ onClick }) => (
      <button className="arrow_next" onClick={onClick} aria-label = "next poster">
        <GrNext />
      </button>
    );
  
    const PrevArrow = ({ onClick }) => (
      <button className="arrow_prev" onClick={onClick} aria-label = "previous poster">
        <GrPrevious />
      </button>
    );
    const [showDots, setShowDots] = useState(true);

    useEffect(() => {
      const handleResize = () => {
        setShowDots(window.innerWidth > 768); // Adjust the threshold as needed
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Set initial state based on window width
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const settings = {
      dots: showDots,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      autoplay: true, // Enable autoplay
      autoplaySpeed: 3000,
    };


    const [promotional_1, setPromotional_1] = useState({});
    const [promotional_2, setPromotional_2] = useState({});
    const [promotional_3, setPromotional_3] = useState({});
    const [promotional_4, setPromotional_4] = useState({});
    const [promotional_5, setPromotional_5] = useState({});
    const [promotional_6, setPromotional_6] = useState({});
    const [promotional_7, setPromotional_7] = useState({});
    const [promotional_8, setPromotional_8] = useState({});
    const [promotional_9, setPromotional_9] = useState({});
    const [promotional_10, setPromotional_10] = useState({});
    const [promotional_11, setPromotional_11] = useState({});
    const [promotional_12, setPromotional_12] = useState({});
    useEffect(() => {
      const fetchProduct = async (collectionName, productId, setProduct) => {
        const collectionRef = db.collection(collectionName);
        const doc = await collectionRef.doc(productId).get();
  
        if (doc.exists) {
          // Extract product data
          const productData = {
            id: doc.id,
            name: doc.data().name,
            // Add more fields as needed
            price: doc.data().price,
            image: doc.data().image,
          };
  
          // Update state with the new product
          setProduct(productData);
        } else {
          console.log('No such document!');
        }
      };
     // Fetch products from different collections and with different IDs
fetchProduct('Product/Products/Promotional_Gifts', '0KznHxtVCNxJCqLOcVL2', setPromotional_1);
fetchProduct('Product/Products/Promotional_Gifts', '8RlKADR4nbawBIw0gt3x', setPromotional_2);
fetchProduct('Product/Products/Promotional_Gifts', 'BRQJlFCkQpLIWqzaYOSb', setPromotional_3);
fetchProduct('Product/Products/Promotional_Gifts', 'ISdsr4WSCMKn3pgqCsKv', setPromotional_4);
fetchProduct('Product/Products/Promotional_Gifts', 'ZFmjPbiXXOhwQwovxhjX', setPromotional_5);
fetchProduct('Product/Products/Promotional_Gifts', 'ctdnqzhmQka6UTVgSdVr', setPromotional_6);
fetchProduct('Product/Products/Promotional_Gifts', 'gPmSpaiXnev7xUfzwxCh', setPromotional_7);
fetchProduct('Product/Products/Promotional_Gifts', 'wA1YFqHuU3pwEIq5FawF', setPromotional_8);
fetchProduct('Product/Products/Promotional_Gifts', 'hrJuP23KNw3j5URYx5Gv', setPromotional_9);
fetchProduct('Product/Products/Promotional_Gifts', 'wxJu0Cb9geQUUPaeTn6E', setPromotional_10);
fetchProduct('Product/Products/Promotional_Gifts', 'OfI49rO6ihXKdFccGf8B', setPromotional_11);
fetchProduct('Product/Products/Promotional_Gifts', 'yrciFdWrM1JZ9vAr6Kht', setPromotional_12);


}, []); 




useEffect(() => {
  const fetchData = async () => {
    const screenStartTime = performance.now();

    // Simulate component logic
    await new Promise(resolve => setTimeout(resolve, 1000));

    const screenEndTime = performance.now();
    const screenTime = screenEndTime - screenStartTime;

    // Log screen time
    firebase.analytics().logEvent('screen_time', { screen_name: 'School_Home_Page', time: screenTime });

    const loadingStartTime = performance.now();

    // Simulate asynchronous operation (e.g., API call)
    await new Promise(resolve => setTimeout(resolve, 2000));

    const loadingEndTime = performance.now();
    const loadingTime = loadingEndTime - loadingStartTime;

    // Log loading time
    firebase.analytics().logEvent('loading_time', { component: 'School_Home_Page', time: loadingTime });

    setLoading(false);
  };

  fetchData();
}, []);

if (loading) {
  return <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Adjust the height based on your needs
  }}>Loading...</div>;
}










  return (

    <>
        <>
      {/* Other content of your component */}
      <Helmet>
        <title>Custoking - Customized Solutions for Every School's Unique Needs</title>
        <meta name="description" content="Explore a wide range of school necessities at CustoKing. From uniforms to ID cards, we provide customized solutions to meet the unique needs of every school" />
        {/* Add more meta tags as needed 
        <meta name="viewport" content="width=device-width, initial-scale=0.8, maximum-scale=0.9, user-scalable=no" />
        */}
      
        
      </Helmet>
    </>
 

      <div className="school_landing_div">
        
        <div className="school_landing_div-12">
        <div id="section1" className={`section ${sectionVisible === 1 ? 'active' : 'inactive'}`}>
          <div className="school_landing_div-2">
          <div className="school_landing_div-3">
            <div className="school_landing_div-4">

              <div className="school_landing_div-5">

                <h2 className="school_landing_div-8">One stop solution for all school necessities</h2>
               {/*<div className="school_landing_div-9">Best School Deals....</div> */} 
              </div>
     
            </div>
            <Link to ='/PartnerProgram' style={{ textDecoration: 'none' }}  className="school_landing_div-11">View more</Link>
          </div>
        </div>
        <div className="school_landing_div-15">
                <h1 className="school_landing_div-16">School Necessities</h1>
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRectangle%2070.png?alt=media&token=e6c52ebf-1d10-4cf0-addf-40a805b163be&width=100 100w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRectangle%2070.png?alt=media&token=e6c52ebf-1d10-4cf0-addf-40a805b163be&width=200 200w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRectangle%2070.png?alt=media&token=e6c52ebf-1d10-4cf0-addf-40a805b163be&width=400 400w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRectangle%2070.png?alt=media&token=e6c52ebf-1d10-4cf0-addf-40a805b163be&width=800 800w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRectangle%2070.png?alt=media&token=e6c52ebf-1d10-4cf0-addf-40a805b163be&width=1200 1200w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRectangle%2070.png?alt=media&token=e6c52ebf-1d10-4cf0-addf-40a805b163be&width=1600 1600w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRectangle%2070.png?alt=media&token=e6c52ebf-1d10-4cf0-addf-40a805b163be&width=2000 2000w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRectangle%2070.png?alt=media&token=e6c52ebf-1d10-4cf0-addf-40a805b163be  "
                     className="school_landing_img-3"
                     alt="Underline"
                />
              </div>
          <div className="school_landing_div-21" />

          <div className="school_landing_div-29">
            <div className="school_landing_div-30">
              <img
                loading="lazy"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2Fimage%205.png?alt=media&token=c3860b58-ccbd-4f97-9cd0-5b4b048bcb5b&width=100 100w  , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2Fimage%205.png?alt=media&token=c3860b58-ccbd-4f97-9cd0-5b4b048bcb5b&width=200 200w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2Fimage%205.png?alt=media&token=c3860b58-ccbd-4f97-9cd0-5b4b048bcb5b&width=400 400w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2Fimage%205.png?alt=media&token=c3860b58-ccbd-4f97-9cd0-5b4b048bcb5b&width=800 800w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2Fimage%205.png?alt=media&token=c3860b58-ccbd-4f97-9cd0-5b4b048bcb5b&width=1200 1200w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2Fimage%205.png?alt=media&token=c3860b58-ccbd-4f97-9cd0-5b4b048bcb5b&width=1600 1600w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2Fimage%205.png?alt=media&token=c3860b58-ccbd-4f97-9cd0-5b4b048bcb5b&width=2000 2000w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2Fimage%205.png?alt=media&token=c3860b58-ccbd-4f97-9cd0-5b4b048bcb5b"
                className="school_landing_img-15"
                alt="Custoking Guide"

              />
              <h4 className="school_landing_div-31">
                See more about Custoking’s guide to one stop school customised
                supplies{" "}
              </h4>
            </div>

          </div>
          <div className="school_landing_div-34">
            <img
              loading="lazy"
              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FSuper%20Benefits%20Tag.svg?alt=media&token=3cfe9b14-c333-4392-b3f9-bdbec51011c0&width=100 100w   , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FSuper%20Benefits%20Tag.svg?alt=media&token=3cfe9b14-c333-4392-b3f9-bdbec51011c0&width=200 200w   , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FSuper%20Benefits%20Tag.svg?alt=media&token=3cfe9b14-c333-4392-b3f9-bdbec51011c0&width=400 400w   , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FSuper%20Benefits%20Tag.svg?alt=media&token=3cfe9b14-c333-4392-b3f9-bdbec51011c0&width=800 800w   , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FSuper%20Benefits%20Tag.svg?alt=media&token=3cfe9b14-c333-4392-b3f9-bdbec51011c0&width=1200 1200w   , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FSuper%20Benefits%20Tag.svg?alt=media&token=3cfe9b14-c333-4392-b3f9-bdbec51011c0&width=1600 1600w   , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FSuper%20Benefits%20Tag.svg?alt=media&token=3cfe9b14-c333-4392-b3f9-bdbec51011c0&width=2000 2000w   , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FSuper%20Benefits%20Tag.svg?alt=media&token=3cfe9b14-c333-4392-b3f9-bdbec51011c0"
              className="school_landing_img-17"
              alt="Custoking Benefits"
            />
            <div className="school_landing_div-35">
              <img
                loading="lazy"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FClock.png?alt=media&token=4cb9fc72-0b02-4288-9672-8dfa97db539e&width=100 100w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FClock.png?alt=media&token=4cb9fc72-0b02-4288-9672-8dfa97db539e&width=200 200w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FClock.png?alt=media&token=4cb9fc72-0b02-4288-9672-8dfa97db539e&width=400 400w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FClock.png?alt=media&token=4cb9fc72-0b02-4288-9672-8dfa97db539e&width=800 800w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FClock.png?alt=media&token=4cb9fc72-0b02-4288-9672-8dfa97db539e&width=1200 1200w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FClock.png?alt=media&token=4cb9fc72-0b02-4288-9672-8dfa97db539e&width=1600 1600w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FClock.png?alt=media&token=4cb9fc72-0b02-4288-9672-8dfa97db539e&width=2000 2000w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FClock.png?alt=media&token=4cb9fc72-0b02-4288-9672-8dfa97db539e"
                 className="school_landing_img-18"
                 alt="Timely Delivery"
              />
              <div className="school_landing_div-36">One stop school solution</div>
            </div>
            <div className="school_landing_div-37">
              <img
                loading="lazy"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2F625afb4c-482e-429b-9819-84d2c5f2a8e0.png?alt=media&token=e6bba8a6-3c7c-4474-bd29-c95949b77093&width=100 100w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2F625afb4c-482e-429b-9819-84d2c5f2a8e0.png?alt=media&token=e6bba8a6-3c7c-4474-bd29-c95949b77093&width=200 200w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2F625afb4c-482e-429b-9819-84d2c5f2a8e0.png?alt=media&token=e6bba8a6-3c7c-4474-bd29-c95949b77093&width=400 400w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2F625afb4c-482e-429b-9819-84d2c5f2a8e0.png?alt=media&token=e6bba8a6-3c7c-4474-bd29-c95949b77093&width=800 800w ,https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2F625afb4c-482e-429b-9819-84d2c5f2a8e0.png?alt=media&token=e6bba8a6-3c7c-4474-bd29-c95949b77093&width=1200 1200w ,  https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2F625afb4c-482e-429b-9819-84d2c5f2a8e0.png?alt=media&token=e6bba8a6-3c7c-4474-bd29-c95949b77093&width=1600 1600w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2F625afb4c-482e-429b-9819-84d2c5f2a8e0.png?alt=media&token=e6bba8a6-3c7c-4474-bd29-c95949b77093&width=2000 2000w , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2F625afb4c-482e-429b-9819-84d2c5f2a8e0.png?alt=media&token=e6bba8a6-3c7c-4474-bd29-c95949b77093"
                      className="school_landing_img-19"
                      alt="Customized Products"
              />
              <div className="school_landing_div-38">
                Customisations Available
                <br />
              </div>
            </div>
            <div className="school_landing_div-39">
              <img
                loading="lazy"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fstar%20logog.png?alt=media&token=3df84229-bf99-4151-886f-4d7392d5ab87&width=100 100w  , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fstar%20logog.png?alt=media&token=3df84229-bf99-4151-886f-4d7392d5ab87&width=200 200w  , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fstar%20logog.png?alt=media&token=3df84229-bf99-4151-886f-4d7392d5ab87&width=400 400w  , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fstar%20logog.png?alt=media&token=3df84229-bf99-4151-886f-4d7392d5ab87&width=800 800w  , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fstar%20logog.png?alt=media&token=3df84229-bf99-4151-886f-4d7392d5ab87&width=1200 1200w  , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fstar%20logog.png?alt=media&token=3df84229-bf99-4151-886f-4d7392d5ab87&width=1600 1600w  , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fstar%20logog.png?alt=media&token=3df84229-bf99-4151-886f-4d7392d5ab87&width=2000 2000w  , https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fstar%20logog.png?alt=media&token=3df84229-bf99-4151-886f-4d7392d5ab87"  className="school_landing_img-20"
                alt="Quality Products"
              />
              <div className="school_landing_div-40">
                10,000 featured
                <br />
                products/ Custom Options
                <br />
              </div>
            </div>
            <Link to = '/PartnerProgram' style={{ textDecoration: 'none' }}  className="school_landing_div-41">Explore Partner Benefits</Link>
          </div>
          <div className="school_landing_div-42">
            <div className="school_landing_div-43">
              <div className="school_landing_column">
                <div className="school_landing_div-44">
                  <div className="school_landing_div-45">
                    <div className="school_landing_div-46">
                      <div className="school_landing_div-47">School Needs</div>
                      <a className="school_landing_div-48" href="#School_Uniform" style={{ textDecoration: 'none' }}>
                        <img
                          loading="lazy"
                          srcSet="
                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FUniform%20Icons.png?alt=media&token=f6b5fea2-844d-4c7e-8288-922ac158e3a3 100w,
                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FUniform%20Icons.png?alt=media&token=f6b5fea2-844d-4c7e-8288-922ac158e3a3&width=200 200w,
                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FUniform%20Icons.png?alt=media&token=f6b5fea2-844d-4c7e-8288-922ac158e3a3&width=400 400w,
                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FUniform%20Icons.png?alt=media&token=f6b5fea2-844d-4c7e-8288-922ac158e3a3&width=800 800w
                          "
                          className="school_landing_img-21"
                          alt="Quality School Uniform"
                        />
                        <div className="school_landing_div-49">School Uniforms</div>
                      </a>

                      <a className="school_landing_div-48" href="#Writing_Supplies" style={{ textDecoration: 'none' }}>
                        <img
                          loading="lazy"
                          srcSet="
                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%20from%202023-11-17%2016-53-01.png?alt=media&token=4a28c24a-74b1-45ba-8d1a-8534a2934838 100w,
                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%20from%202023-11-17%2016-53-01.png?alt=media&token=4a28c24a-74b1-45ba-8d1a-8534a2934838&width=200 200w,
                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%20from%202023-11-17%2016-53-01.png?alt=media&token=4a28c24a-74b1-45ba-8d1a-8534a2934838&width=400 400w,
                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%20from%202023-11-17%2016-53-01.png?alt=media&token=4a28c24a-74b1-45ba-8d1a-8534a2934838&width=800 800w
                          "
                          className="school_landing_img-21"
                          alt="Customized Stationary Items"
                        />
                        <div className="school_landing_div-49">School Stationary</div>
                      </a>

                    </div>

                  </div>
                  <div className="school_landing_div-53">
                  <a className="school_landing_div-54" href="#Writing_Supplies" style={{ textDecoration: 'none' }}>
                  <div className="school_landing_div-55">
                    <img
                      loading="lazy"
                      srcSet="
                        https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FSchool%20Notebook%20Vector.webp?alt=media&token=5d5e380c-72df-44b1-9deb-d37690c052ce 100w,
                        https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FSchool%20Notebook%20Vector.webp?alt=media&token=5d5e380c-72df-44b1-9deb-d37690c052ce&width=200 200w,
                        https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FSchool%20Notebook%20Vector.webp?alt=media&token=5d5e380c-72df-44b1-9deb-d37690c052ce&width=400 400w,
                        https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FSchool%20Notebook%20Vector.webp?alt=media&token=5d5e380c-72df-44b1-9deb-d37690c052ce&width=800 800w
                      "
                      className="school_landing_img-25"
                      alt="Customized Writing Items"
                    />
                  </div>
                  <div className="school_landing_div-56">School Writing Supplies</div>
                </a>

                   

                  </div>
                  <div className="school_landing_div-57">
                    <div className="school_landing_div-58">
                    <a className="school_landing_div-59" href="#ID_Cards" style={{ textDecoration: 'none' }}>
                      <img
                        loading="lazy"
                        srcSet="
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FID%20Card%20Vector.jpg?alt=media&token=f8a2b27c-6344-4498-b777-482d30b64304 100w,
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FID%20Card%20Vector.jpg?alt=media&token=f8a2b27c-6344-4498-b777-482d30b64304&width=200 200w,
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FID%20Card%20Vector.jpg?alt=media&token=f8a2b27c-6344-4498-b777-482d30b64304&width=400 400w,
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FID%20Card%20Vector.jpg?alt=media&token=f8a2b27c-6344-4498-b777-482d30b64304&width=800 800w
                        "
                        className="school_landing_img-27"
                        alt="ID Cards"
                      />
                      <div className="school_landing_div-60">School Id Cards</div>
                    </a>

                      
                    </div>

                  </div>
                  <div className="school_landing_div-64">
                    <a className="school_landing_div-65"  href = '#ID_Cards' style={{ textDecoration: 'none' }}>
                    <img
                        loading="lazy"
                        srcSet="
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fschool-bag-line-art_157839-596.jpg?alt=media&token=32c0e070-3943-40fe-afde-75202f41776c 100w,
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fschool-bag-line-art_157839-596.jpg?alt=media&token=32c0e070-3943-40fe-afde-75202f41776c&width=200 200w,
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fschool-bag-line-art_157839-596.jpg?alt=media&token=32c0e070-3943-40fe-afde-75202f41776c&width=400 400w,
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fschool-bag-line-art_157839-596.jpg?alt=media&token=32c0e070-3943-40fe-afde-75202f41776c&width=800 800w
                        "
                        className="school_landing_img-31"
                        alt="Customized School Bags"
                      />

                      <div className="school_landing_div-66">School Bags and accessories</div>
                    </a>

                  </div>
                  <div className="school_landing_div-64">
                    <a className="school_landing_div-65" href = '#Marketing' style={{ textDecoration: 'none' }}>
                    <img
                        loading="lazy"
                        srcSet="
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%20from%202023-11-17%2015-17-26.png?alt=media&token=0559dd37-a6ad-446e-af13-0ff5335b65f4 100w,
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%20from%202023-11-17%2015-17-26.png?alt=media&token=0559dd37-a6ad-446e-af13-0ff5335b65f4&width=200 200w,
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%20from%202023-11-17%2015-17-26.png?alt=media&token=0559dd37-a6ad-446e-af13-0ff5335b65f4&width=400 400w,
                          https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%20from%202023-11-17%2015-17-26.png?alt=media&token=0559dd37-a6ad-446e-af13-0ff5335b65f4&width=800 800w
                        "
                        className="school_landing_img-31"
                        alt="Digital Marketing Services"
                      />

                      <div className="school_landing_div-66">Digital Marketing </div>
                    </a>

                  </div>
                  <a className="school_landing_div-67" href = '#School_Services' style={{ textDecoration: 'none' }}>
                  <img
                      loading="lazy"
                      srcSet="
                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FCamera%20Vector.jpg?alt=media&token=7b8cda69-e924-41b9-b46a-c9446708e478 100w,
                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FCamera%20Vector.jpg?alt=media&token=7b8cda69-e924-41b9-b46a-c9446708e478&width=200 200w,
                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FCamera%20Vector.jpg?alt=media&token=7b8cda69-e924-41b9-b46a-c9446708e478&width=400 400w,
                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FCamera%20Vector.jpg?alt=media&token=7b8cda69-e924-41b9-b46a-c9446708e478&width=800 800w
                      "
                      className="school_landing_img-31"
                      alt="Photography Services"
                    />

                    <div className="school_landing_div-68">
                      Photography and Printing Services
                    </div>

                  </a>
                </div>
              </div>
              <div className="school_landing_column-3">
                      <Slider {...settings}>
      {images.map((imageUrl, index) => (
        <Link to={destinations[index]} key={index}>
          <div style={{ position: 'relative', textAlign: 'center' }}>
            <img
              loading="lazy"
              srcSet={imageUrl}
              className="school_landing_img-35"
              alt={`Slide ${index + 1}`}
            />
          </div>
        </Link>
      ))}
    </Slider>

                      </div>
                      <div className="school_landing_column-4">
                        <div className="school_landing_div-72">
                          <div className="school_landing_div-73">
                            Partner with us to enjoy school benefits
                            <br/>
                            Click on Partner with us to onboard and enjoy benefits
                          </div>
                          <Link to = "/PartnerProgram" style={{ textDecoration: 'none' }}>
                          <div className="school_landing_div-74">Partner With Us</div>
                          </Link>

                          <a  href="tel:+917478004111" style={{ textDecoration: 'none' }} className="school_landing_div-75">Contact Us-: 7478004111</a>
                          
                          <div className="school_landing_div-76">
                          
                            <div className="school_landing_div-77">Partner Benefits  </div>
                            <Link to = "/PartnerProgram" style={{ textDecoration: 'none' }} aria-label="Read more about partner benefits">
                            <img
                                            loading="lazy"
                                            srcSet="
                                              https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRight_Arrow.svg?alt=media&token=70eed26d-492f-4a47-9318-95ca8773004f 100w,
                                              https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRight_Arrow.svg?alt=media&token=70eed26d-492f-4a47-9318-95ca8773004f&width=200 200w,
                                              https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRight_Arrow.svg?alt=media&token=70eed26d-492f-4a47-9318-95ca8773004f&width=400 400w,
                                              https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FRight_Arrow.svg?alt=media&token=70eed26d-492f-4a47-9318-95ca8773004f&width=800 800w
                                            "
                                            className="school_landing_img-36"
                                            alt="Navigate to Partner Banefits Page"
                                          />

                            </Link>
                      
                          </div>
                          
                          
                          <img
                                          loading="lazy"
                                          srcSet="
                                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FDiscount.png?alt=media&token=2f0001ee-13bf-4a78-b875-d02c55fe226b 100w,
                                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FDiscount.png?alt=media&token=2f0001ee-13bf-4a78-b875-d02c55fe226b&width=200 200w,
                                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FDiscount.png?alt=media&token=2f0001ee-13bf-4a78-b875-d02c55fe226b&width=400 400w,
                                            https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FDiscount.png?alt=media&token=2f0001ee-13bf-4a78-b875-d02c55fe226b&width=800 800w
                                          "
                                          className="school_landing_img-37"
                                          alt="Avail Discounts of school supplies"
                                        />

                          <div className="school_landing_div-78">
                            <div className="school_landing_div-79">
                              Get free photography services for all school
                              events
                            </div>
                            <img
                                    loading="lazy"
                                    srcSet="
                                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FCurrency.png?alt=media&token=d1b1acfc-08be-4c28-9d92-167220a6b923 100w,
                                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FCurrency.png?alt=media&token=d1b1acfc-08be-4c28-9d92-167220a6b923&width=200 200w,
                                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FCurrency.png?alt=media&token=d1b1acfc-08be-4c28-9d92-167220a6b923&width=400 400w,
                                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FCurrency.png?alt=media&token=d1b1acfc-08be-4c28-9d92-167220a6b923&width=800 800w
                                    "
                                    className="school_landing_img-38"
                                    alt="Photography Events"
                                  />

                          </div>
                        </div>
                      </div>

            </div>
          </div>
          </div>
          <div id="section2" className={`section ${sectionVisible === 2 ? 'active' : 'inactive'}`}>
          <div className="school_landing_div-88" id = 'ID_Cards'>
            <div className="school_landing_div-89">
              <div className="school_landing_column-5">
                <div className="school_landing_div-90">
                  <div className="school_landing_div-91">
                    Custoking’s School sourcing
                    <br />
                    guide
                  </div>
                  <div className="school_landing_div-92">
                    How to enjoy reduced-price products, custom options, and
                    flexible payments this month.
                  </div>
                  <Link to = "/PartnerProgram" style={{ textDecoration: 'none' }} className="school_landing_div-93">Partner With Us</Link>
                </div>
              </div>
              <div className="school_landing_column-6">
                <div className="school_landing_div-94">
                  <div className="school_landing_div-95">
                    <div className="school_landing_column-7">
                      <div className="school_landing_div-96">
                        <div className="school_landing_div-97">
                          Select School Supplies Required
                        </div>
                        <div className="school_landing_div-98">
                          <div className="school_landing_div-99">
                          <img
                              loading="lazy"
                              srcSet="
                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FUniform_1.png?alt=media&token=1d673d36-c5ed-424d-bb04-fe0c92193a95 100w,
                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FUniform_1.png?alt=media&token=1d673d36-c5ed-424d-bb04-fe0c92193a95&width=200 200w,
                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FUniform_1.png?alt=media&token=1d673d36-c5ed-424d-bb04-fe0c92193a95&width=400 400w,
                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FUniform_1.png?alt=media&token=1d673d36-c5ed-424d-bb04-fe0c92193a95&width=800 800w
                              "
                              className="school_landing_img-39"
                              alt="School Uniforms"
                            />

                          </div>
                          <div className="school_landing_div-100">
                          <img
                      loading="lazy"
                      srcSet="
                        https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FStationary_1.png?alt=media&token=2f0d0b95-e898-4ea6-b4b9-5649462a49d1 100w,
                        https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FStationary_1.png?alt=media&token=2f0d0b95-e898-4ea6-b4b9-5649462a49d1&width=200 200w,
                        https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FStationary_1.png?alt=media&token=2f0d0b95-e898-4ea6-b4b9-5649462a49d1&width=400 400w,
                        https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FStationary_1.png?alt=media&token=2f0d0b95-e898-4ea6-b4b9-5649462a49d1&width=800 800w
                      "
                      className="school_landing_img-40"
                      alt="School Stationary"
                    />

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="school_landing_column-8">
                      <div className="school_landing_div-101">
                        <div className="school_landing_div-102">
                          Tell us your customisations{" "}
                        </div>
                        <div className="school_landing_div-103">
                          <div className="school_landing_div-104">
                          <img
                                loading="lazy"
                                srcSet="
                                  https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FHand_Stitching.png?alt=media&token=53c51db2-6ea2-46e8-bc6f-f8586c725658 100w,
                                  https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FHand_Stitching.png?alt=media&token=53c51db2-6ea2-46e8-bc6f-f8586c725658&width=200 200w,
                                  https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FHand_Stitching.png?alt=media&token=53c51db2-6ea2-46e8-bc6f-f8586c725658&width=400 400w,
                                  https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FHand_Stitching.png?alt=media&token=53c51db2-6ea2-46e8-bc6f-f8586c725658&width=800 800w
                                "
                                className="school_landing_img-41"
                                alt="Embroided Logo"
                              />

                          </div>
                          <div className="school_landing_div-104">
                          <img
                                  loading="lazy"
                                  srcSet="
                                    https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FHand_Stitching.png?alt=media&token=53c51db2-6ea2-46e8-bc6f-f8586c725658 100w,
                                    https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FHand_Stitching.png?alt=media&token=53c51db2-6ea2-46e8-bc6f-f8586c725658&width=200 200w,
                                    https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FHand_Stitching.png?alt=media&token=53c51db2-6ea2-46e8-bc6f-f8586c725658&width=400 400w,
                                    https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FHand_Stitching.png?alt=media&token=53c51db2-6ea2-46e8-bc6f-f8586c725658&width=800 800w
                                  "
                                  className="school_landing_img-41"
                                  alt="School Logo"
                                />

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="school_landing_column-9">
                      <div className="school_landing_div-105">
                        <div className="school_landing_div-106">
                          Avail Great Discounts and Services
                        </div>
                        <div className="school_landing_div-107">
                        <div className="school_landing_div-104">
                        <img
                            loading="lazy"
                            srcSet="
                              https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2F20%25%20Discount.png?alt=media&token=ca8a15ed-f0c0-4b80-bdc2-084a4a700cb9 100w,
                              https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2F20%25%20Discount.png?alt=media&token=ca8a15ed-f0c0-4b80-bdc2-084a4a700cb9&width=200 200w,
                              https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2F20%25%20Discount.png?alt=media&token=ca8a15ed-f0c0-4b80-bdc2-084a4a700cb9&width=400 400w,
                              https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2F20%25%20Discount.png?alt=media&token=ca8a15ed-f0c0-4b80-bdc2-084a4a700cb9&width=800 800w
                            "
                            className="school_landing_img-43"
                            alt="Discounted Products"
                          />

                          </div>
                          <div className="school_landing_div-104">
                          <img
                                  loading="lazy"
                                  srcSet="
                                    https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FHand%20Camera.png?alt=media&token=d74c6ac4-2a17-4d8a-b5cd-6c47ba178b11 100w,
                                    https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FHand%20Camera.png?alt=media&token=d74c6ac4-2a17-4d8a-b5cd-6c47ba178b11&width=200 200w,
                                    https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FHand%20Camera.png?alt=media&token=d74c6ac4-2a17-4d8a-b5cd-6c47ba178b11&width=400 400w,
                                    https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FHand%20Camera.png?alt=media&token=d74c6ac4-2a17-4d8a-b5cd-6c47ba178b11&width=800 800w
                                  "
                                  className="school_landing_img-44"
                                  alt="Photographers"
                                />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="school_landing_div-108">
            <div className="school_landing_div-109">
              <div className="school_landing_column-10">
                <Link to = '/School_Uniform_Details' style={{ textDecoration: 'none' }} className="school_landing_div-110">
                  <div className="school_landing_div-111">
                    <div className="school_landing_column-11">
                      <div className="school_landing_div-112">
                        <div className="school_landing_div-113">
                        <img
                              loading="lazy"
                              srcSet="
                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FUniform%20Icon.png?alt=media&token=981445c2-fd61-4629-93f1-1ba18e3e78ad 100w,
                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FUniform%20Icon.png?alt=media&token=981445c2-fd61-4629-93f1-1ba18e3e78ad&width=200 200w,
                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FUniform%20Icon.png?alt=media&token=981445c2-fd61-4629-93f1-1ba18e3e78ad&width=400 400w,
                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FUniform%20Icon.png?alt=media&token=981445c2-fd61-4629-93f1-1ba18e3e78ad&width=800 800w
                              "
                              className="school_landing_img-45"
                              alt="School Uniform Set Icon"
                            />

                          <div className="school_landing_div-114">School Uniforms</div>
                        </div>
                        <div className="school_landing_div-115">
                          <div className="school_landing_div-116">
                          <img
                                              loading="lazy"
                                              srcSet="
                                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStudent%20Image.png?alt=media&token=384dd449-e90d-4cb1-8c86-e36c27109e76 100w,
                                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStudent%20Image.png?alt=media&token=384dd449-e90d-4cb1-8c86-e36c27109e76&width=200 200w,
                                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStudent%20Image.png?alt=media&token=384dd449-e90d-4cb1-8c86-e36c27109e76&width=400 400w,
                                                https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStudent%20Image.png?alt=media&token=384dd449-e90d-4cb1-8c86-e36c27109e76&width=800 800w
                                              "
                                              className="school_landing_img-46"
                                              alt="School Uniform Shop"
                                            />

                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                                Rs 650
                                <br />
                              </span>
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                                Trending Now
                              </span>
                            </div>
                          </div>
                          <div className="school_landing_div-116">
                          <img
                                    loading="lazy"
                                    srcSet="
                                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStudent_2.png?alt=media&token=be7699d3-3b9e-4f95-9d4b-80d092bd4a89 100w,
                                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStudent_2.png?alt=media&token=be7699d3-3b9e-4f95-9d4b-80d092bd4a89&width=200 200w,
                                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStudent_2.png?alt=media&token=be7699d3-3b9e-4f95-9d4b-80d092bd4a89&width=400 400w,
                                      https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStudent_2.png?alt=media&token=be7699d3-3b9e-4f95-9d4b-80d092bd4a89&width=800 800w
                                    "
                                    className="school_landing_img-47"
                                    alt="School Uniform Bulk"
                                  />

                            <div className="school_landing_div-119">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>   
                                Rs 320.11
                                <br />
                              </span>
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                                Trending Now
                              </span>
                            </div>
                          </div>
                          
                      <div className="school_landing_div-116">
                      <img
                            loading="lazy"
                                srcSet="
                                  https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FStudent_3.png?alt=media&token=0db35ac7-c4a9-4b78-898d-1d61778bd85e 100w,
                                  https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FStudent_3.png?alt=media&token=0db35ac7-c4a9-4b78-898d-1d61778bd85e&width=200 200w,
                                  https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FStudent_3.png?alt=media&token=0db35ac7-c4a9-4b78-898d-1d61778bd85e&width=400 400w,
                                  https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FStudent_3.png?alt=media&token=0db35ac7-c4a9-4b78-898d-1d61778bd85e&width=800 800w
                                "
                                className="school_landing_img-47"
                                alt="School Uniform Manufacturer"
                              />

                        <div className="school_landing_div-119">
                          <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                            Rs 142.28
                            <br />
                          </span>
                          <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                            Trending Now
                          </span>
                        </div>
                      </div>
                    
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </Link>
              </div>
              <Link to = '/ID-Cards'  className="school_landing_column-13"  style={{ textDecoration: 'none' }}>
                <div className="school_landing_div-122">
                <div className="school_landing_div-113">
                          <img
                            loading="lazy"
                            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FID%20Card%20Icon.png?alt=media&token=0d5000de-540e-4ed2-b75a-20232fafabbb"  className="school_landing_img-49"
                            alt="Student ID Cards"
                          />
                          <div className="school_landing_div-114">ID Cards</div>

                        </div>
                  <div className="school_landing_div-115">
                    
                      <div className="school_landing_div-116">
                        
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FID%20Card%201.png?alt=media&token=ff796230-67a1-4975-acd9-5e6ffade2c1a"  className="school_landing_img-46"
                          alt="Teacher ID Cards"
                        />
                        <div className="school_landing_div-117">
                          <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                            Rs 50
                            <br />
                          </span>
                          <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                            1 Piece{" "}
                          </span>
                        </div>
                      </div>
                    
                    
                      <div className="school_landing_div-116">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FID_Card%202.png?alt=media&token=8efff1dc-9a59-4364-9c17-15b3df1e13aa"  className="school_landing_img-46"
                          alt="Bulk ID Cards"
                        />
                        <div className="school_landing_div-117">
                          <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                            Rs 45
                            <br />
                          </span>
                          <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                            1 Piece
                          </span>
                        </div>
                      </div>
                    
                
                      <div className="school_landing_div-116">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FID%20Card%203.png?alt=media&token=f3baf71c-d85b-4af6-9d80-517b0b7ecaaf"  className="school_landing_img-46"
                          alt="Multicolored Lynard"
                        />
                        <div className="school_landing_div-117">
                          <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                            Rs 250
                            <br />
                          </span>
                          <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                            2 Piece
                          </span>
                        </div>
                      </div>
                    </div>
                  
                </div>
              </Link>
              <div className="school_landing_column-17">
                <Link to = '/Stationary'  className="school_landing_div-132" style={{ textDecoration: 'none' }}>
                    <div className="school_landing_div-135">
                          <img
                            loading="lazy"
                            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fstationary%20icon.png?alt=media&token=74cc6de7-98bb-4aea-b931-d8b65e322d01"  className="school_landing_img-53"
                            alt="School Stationary"
                          />
                          <div className="school_landing_div-136">Stationary</div>
                    </div>
                    <div className="school_landing_div-115">
            
                      <div className="school_landing_div-116">
                        
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStationary%201.png?alt=media&token=187792c1-a28d-479b-bb3b-b4382ac2a455"  className="school_landing_img-46"
                          alt="Writing Material"
                        />

                        <div className="school_landing_div-137">
                          Rs 400 <br />
                        </div>
                      </div>
                    
                    
                      <div className="school_landing_div-116">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStationary%202.png?alt=media&token=472936a2-cdad-40a3-acf8-bef0d2e9cb05" className="school_landing_img-46"
                          alt="Pen Set"
                        />
                        <div className="school_landing_div-139">
                          Rs 210
                          <br />
                        </div>
                      </div>
                    
        
                      <div className="school_landing_div-116">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FStationary%203.png?alt=media&token=93df3f59-7cf3-4b47-b70d-2b02ebf93b47"  className="school_landing_img-46"  alt="Writing Set"
                        />
                        <div className="school_landing_div-141">
                          Rs 1,657.46
                          <br />
                        </div>
                      </div>
                    
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="school_landing_div-142" id = 'Marketing'>
            <div className="school_landing_div-143">
              <div className="school_landing_column-10">
              <div className="school_landing_div-110">
              <div className="school_landing_div-111">
              <div className="school_landing_column-11">
                  <Link  to = '/Accessories'  className="school_landing_div-112" style={{ textDecoration: 'none' }}>
                    <div className="school_landing_div-113">
                          <img
                            loading="lazy"
                            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FAccessories%20Icon.png?alt=media&token=91e0646a-16af-4a29-ba47-562feec684a2"  className="school_landing_img-45"
                            alt="School Accessories"
                          />
                          <div className="school_landing_div-114">School Accessories</div>
                    </div>
                    <div className="school_landing_div-115">
                    <div className="school_landing_div-116">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Acessories%20Display%2FCharts.webp?alt=media&token=1008e1ca-8e20-43c6-962a-c8bfa455ba75"  className="school_landing_img-46"
                              alt="Chart Papers"
                            />
                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                              Rs 20
                                <br />
                              </span>
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                              1 Piece
                              </span>
                            </div>
                          </div>
                          
                          <div className="school_landing_div-116">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Acessories%20Display%2FSchool%20House%20Captain%20Badges.jpg?alt=media&token=b619bb81-064a-4421-8c7f-ca3ef70cbd20"      className="school_landing_img-46" 
                              alt="School Badges"
                            />
                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                              Rs 650
                                <br />
                              </span>
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                              1 Piece
                              </span>
                            </div>
                          </div>
                          <div className="school_landing_div-116">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Acessories%20Display%2FWater%20Bottle.jpg?alt=media&token=d0ead11c-c339-461d-80ff-7a338872e71b"  className="school_landing_img-46"
                              alt="Water Bottle"
                            />
                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                              Rs 260.84
                                <br />
                              </span>
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                              1 Piece
                              </span>
                            </div>
                          </div>






                    </div>

                
                
                
                
                
                
                
                
                
                
                </Link>
                </div>
                </div>
                </div>
             </div>
             <div className="school_landing_column-10">
              <div className="school_landing_div-110">
              <div className="school_landing_div-111">
              <div className="school_landing_column-11">
                  <div className="school_landing_div-112" >
                  <div className="school_landing_div-113">
                          <img
                            loading="lazy"
                            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FTeacher%20Acessories%20Icon.png?alt=media&token=a1f6c48f-2148-411a-8af4-458d8b0cf5fc"  className="school_landing_img-45"
                            alt="School Accessories"
                          />
                          <div className="school_landing_div-114">Teacher Accessories</div>
                    </div>
                    <div className="school_landing_div-115">
                    <div className="school_landing_div-116">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FOfficePapers%2Fcarbonless%20paper%20ream%20NCR%20paper%20in%20roll%20or%20sheet.jpg?alt=media&token=191caf96-5c2a-4cf6-a00b-6cdf4dfdb997"  className="school_landing_img-46"
                              alt="School Registers"
                            />
                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                              Rs 130.42
                                <br />
                              </span>
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                              10 Piece
                              </span>
                            </div>
                          </div>
                          
                          <div className="school_landing_div-116">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FAnswer%20Sheets.webp?alt=media&token=f3b8421e-ebab-4037-a39a-8a060614ad8e"  className="school_landing_img-46"
                              alt="Answer Sheets"
                            />
                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                              Rs 448.16
                                <br />
                              </span>
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                              100 Piece
                              </span>
                            </div>
                          </div>
                          <div className="school_landing_div-116">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FWholesale%20Offset%20Paper%207.5%20x%207.5%20Stationery%20Office%20Sticky%20Notes%20Custom.jpg?alt=media&token=0f7406fb-415d-43da-8343-4315a17543ca" className="school_landing_img-46"
                              alt="Folders"
                            />
                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                              Rs 111.45
                                <br />
                              </span>
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 400}}>
                              25 Piece
                              </span>
                            </div>
                          </div>






                    </div>

                
                
                
                
                
                
                
                
                
                
                </div>
                </div>
                </div>
                </div>
             </div>


             <div className="school_landing_column-10">
              <div className="school_landing_div-110">
              <div className="school_landing_div-111">
              <div className="school_landing_column-11">
                  <Link  to = '/Writing_Supplies' className="school_landing_div-112"  style={{ textDecoration: 'none' }}>
                  <div className="school_landing_div-113">
                          <img
                            loading="lazy"
                            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FWriting%20Supplies%20Logo.png?alt=media&token=419658cd-2f88-41a3-a9e0-3ff12e8a1c26" className="school_landing_img-45"
                            alt="School Writing Supplies"
                          />
                          <div className="school_landing_div-114">School Writing Supplies</div>
                    </div>
                    <div className="school_landing_div-115">
                    <div className="school_landing_div-116">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FAffordable%20A5%20soft%20cover%20notebooks%2C%20custom-printed%20and%20gilded%2C%20perfect%20for%20stylish%20journaling.jpg?alt=media&token=54c39228-2792-4308-b51e-55bd69cdd1c3"
                              className="school_landing_img-46"
                              alt="School Diaries"
                            />
                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                              Rs 250
                                <br />
                              </span>

                            </div>
                          </div>
                          
                          <div className="school_landing_div-116">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FCustom%20Logo%202023%20Spiritual%20Daily%20Manifest%20Journal%20Diary%20Planner%20and%20Notebook%20Inspiring%20Manifestation%20Journal%20Book.jpg?alt=media&token=57f4146c-559d-4c38-bf25-25cabc5b8e92"
                              className="school_landing_img-46"
                              alt="School Notebooks"
                            />
                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                              Rs 213.41
                                <br />
                              </span>
                         
                            </div>
                          </div>
                          <div className="school_landing_div-116">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FCustom%20Logo%20Undated%20Wide%20Ruled%20Paper%20Office%20Spiral%20Writing%20Notebooks%20Note%20Book%20for%20Student.jpg?alt=media&token=1dc300fe-82d8-4774-af42-378e8c6580fb"
                              className="school_landing_img-46"
                              alt="Customized Notebooks"
                            />
                            <div className="school_landing_div-117">
                              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 700}}>
                              Rs 527.96
                                <br />
                              </span>
                              
                            </div>
                          </div>






                    </div>

                
                
                
                
                
                
                
                
                
                
                </Link>
                </div>
                </div>
                </div>
             </div>

              





            </div>
          </div>
          <div className="school_landing_div-178">
            <div className="school_landing_div-179">
            <Link to = "/Digital_Marketing" style={{ textDecoration: 'none' }} className="school_landing_column-28">
                <div className="school_landing_div-180">
                  <div className="school_landing_div-181">Digital Marketing Services</div>
                  <div className="school_landing_div-182">
                    Provides most effective digital marketing services with the
                    promise to increase admission by 20%
                  </div>
                  <div className="school_landing_div-183">
                    <div className="school_landing_div-184">
                      <div className="school_landing_column-29">
                        <div className="school_landing_div-185">
                          <div className="school_landing_div-186">
                            <div className="school_landing_div-187">Digital Marketing</div>
                            <div className="school_landing_div-188">
                              <img
                                loading="lazy"
                                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2F2022_04_DIGITAL-MARKETING-TOOLS-1.jpg?alt=media&token=acf8e6ea-6f03-4c73-8830-e84d67156f93" className="school_landing_img-69"
                                alt="Marketing Services"
                              />
                              <img
                                loading="lazy"
                                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FDigital%20Marketing.jpg?alt=media&token=abfb1ffe-d172-4230-a944-f05612dcff2e"  className="school_landing_img-70"
                                alt="Digital Marketing to boost admissions"
                              />
                            </div>
                          </div>
                      
                        </div>
                      </div>
                      <div className="school_landing_column-30">
                        <div className="school_landing_div-189">
                          <div className="school_landing_div-190">
                            Marketing Flyers / Results Banners{" "}
                          </div>
                          <div className="school_landing_div-191">
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FFlyers.jpg?alt=media&token=ae8a5bcb-f371-40e8-b479-3e7d680f16ac"  className="school_landing_img-72"
                              alt="Pamphlets"
                            />
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FBanners%26Signage%2FAdvertisement%20Billboard%20Flex%20Mesh%20Banner%20Custom%20Pvc%20Vinyl%20Banner%20For%20Event.jpg?alt=media&token=1b38515c-2c20-475d-9c08-ebd4a6654b29"  className="school_landing_img-73"
                              alt="Brochures"
                            />
                            <img
                              loading="lazy"
                              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FBanners%26Signage%2FChina%20digital%20signage%20and%20displays%20indoor%20advertising%20display%20suppliers%20LED%20display%20screen%20for%20shops.jpg?alt=media&token=21cbbc1d-6dcd-4414-946f-1c31e5d072d4"  className="school_landing_img-74"
                              alt="Banners"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="school_landing_column-31">
                <div className="school_landing_div-192">
                  <div className="school_landing_div-193">Ready-to-ship products</div>
                  <div className="school_landing_div-194">
                    Source from 15 million products that are ready to ship, and
                    
                    leave the facility within 15 days.{" "}
                  </div>
                  <div className="school_landing_div-195">
                    <div className="school_landing_div-196">
                      <div className="school_landing_column-32">
                        <div className="school_landing_div-197">
                          <div className="school_landing_div-198">
                            <div className="school_landing_div-199">Fast dispatch</div>
                            <div className="school_landing_div-200">
                              <img
                                loading="lazy"
                                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FFast%20Dispatch.png?alt=media&token=6170e770-99b5-4d90-be36-dc04d23bb723"  className="school_landing_img-75"
                                alt="Timely Delivery"
                              />
                              <img
                                loading="lazy"
                                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fdispatch%202.png?alt=media&token=f1d35c6e-6058-4c31-8878-1330b93e38e4" className="school_landing_img-76"
                                alt="School Delivery"
                              />
                            </div>
                          </div>
                  
                        </div>
                      </div>
                      <div className="school_landing_column-33">
                        <div className="school_landing_div-201">
                          <div className="school_landing_div-202">
                            <div className="school_landing_div-203">Weakly deals</div>
                            <div className="school_landing_div-204">
                              <img
                                loading="lazy"
                                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FWeekly%20Offer.png?alt=media&token=63b64051-cd15-4b2c-8c80-7d1108e1bd23" className="school_landing_img-78"
                                alt="Great Discounts"
                              />
                              <img
                                loading="lazy"
                                alt="Weekly Deals"
                                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FWeekly%20offer%202.png?alt=media&token=aef05e31-e5ec-4978-bb1e-d225d87aef4f" className="school_landing_img-79"
                              />
                            </div>
                          </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>


          <div id="section3" className={`section ${sectionVisible === 3 ? 'active' : 'inactive'}`}>
          <div className="school_landing_div-205" id = 'School_Uniform'>
            <div className="school_landing_div-206">School Uniforms</div>
            <div className="school_landing_div-207" />
            <a className="school_landing_div-208"  href = '#RFQ' style={{ textDecoration: 'none' }}>Request for Samples</a>
          </div>
          <Link to = "/Second_Page" style={{ textDecoration: 'none' }} className="school_landing_div-209">
            <div className="school_landing_div-210">
              
              <div className="school_landing_div-211">Source now</div>
            </div>
            <div className="school_landing_div-212">
              <div className="school_landing_div-213">
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Ftick%20icon.png?alt=media&token=586747ef-9290-4b12-87f1-fb9b4887914c"  className="school_landing_img-81"
                  alt="Quality Products for every school"
                />
                <div className="school_landing_div-214">Premium Mafatlal Fabric</div>
              </div>
              <div className="school_landing_div-215">Premium Mafatlal and Valji Fabrics</div>
              <img
                loading="lazy"
                alt="Premium fabric for school uniforms"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FFabric.png?alt=media&token=f202d478-7dc9-4aa7-819c-65bf2732f4f6"  className="school_landing_img-82"
              />
              <div className="school_landing_div-216">In House - Quality Stitching</div>
              <img
                loading="lazy"
                alt="Quality Stitching"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fsewing%20machine.png?alt=media&token=4cb30ea1-5fcf-4e4a-9397-176bc73b145b"  className="school_landing_img-83"
              />
            </div>
            <div className="school_landing_div-217" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Uniform Accessories </div>
              <div className="school_landing_div-220">
                <div className="school_landing_div-221">School Tie and Belts</div>
                <img
                  loading="lazy"
                  alt="Customized School Tie and Belt for schools"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Ftie%20and%20belt.png?alt=media&token=8d58e719-e9b5-46d0-928f-09f78f8af4eb" className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">Customisations</div>
              <img
                loading="lazy"
                alt="School Logo on the uniforms"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FHand_Stitching.png?alt=media&token=53c51db2-6ea2-46e8-bc6f-f8586c725658"  className="school_landing_img-85"
              />
            </div>
            <div className="school_landing_div-223" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Events Uniforms </div>
              <div className="school_landing_div-220">
                <div className="school_landing_div-221">Annual Day/ Traditional Dresses</div>
                <img
                  loading="lazy"
                  alt="Event dress for school events"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool_Uniforms%2FAnnual%20Day%20Uniform.jpg?alt=media&token=6396b4dd-fc5a-42f3-9046-d582959d6eaa"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">White Uniform</div>
              <img
                loading="lazy"
                alt="White school uniform"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FWhite%20Uniforms.png?alt=media&token=3002cfc6-ebdd-4265-91e9-3a54753946ce"  className="school_landing_img-85"
              />
            </div>
            <div className="school_landing_div-223" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Sports Uniforms </div>
              <div className="school_landing_div-220">
               
                <img
                  loading="lazy"
                  alt="school sports uniform"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool_Uniforms%2FSports%20Uniform.jpg?alt=media&token=1a9ff936-dfb1-47d5-a911-917c98989bc7"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">Track Pants/ Suits</div>
              <img
                loading="lazy"
                alt="school track pants and suits for sports events"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool_Uniforms%2FTrack%20Suits.webp?alt=media&token=95ccc328-c400-4beb-8733-c84e146e604d"
                className="school_landing_img-85"
              />
            </div>
          </Link>
          <div className="school_landing_div-236" id = 'Writing_Supplies'>
            <div className="school_landing_div-237">Writing Supplies</div>
            <div className="school_landing_div-238" />
          </div>
          <Link to = "/Second_Page" style={{ textDecoration: 'none' }} className="school_landing_div-209">
          <div className="school_landing_div-240">

<div className="school_landing_div-241">Source now</div>
</div>
            <div className="school_landing_div-212">
              <div className="school_landing_div-213">
                <img
                  loading="lazy"
                  alt="Quality Products"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Ftick%20icon.png?alt=media&token=586747ef-9290-4b12-87f1-fb9b4887914c" className="school_landing_img-81"
                />
                <div className="school_landing_div-214">Premium OEM Factories</div>
              </div>
              <div className="school_landing_div-215">Well-made notebooks with variety of options</div>
              <img
                loading="lazy"
                alt="Notebooks"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FWholesale%20A6%20Budget%20Binder%20Set%20Cash%20Envelope%20Budget%20System%20Binder%20Planners%20With%20Expense%20Budget%20Sheets%20And%20Label%20Sticker.jpg?alt=media&token=5798af7e-a269-4874-8e46-95a0cf5e1640"
                  className="school_landing_img-82"
              />
              <div className="school_landing_div-216">Delivered Duty Paid (DDP)</div>
              <div className="school_landing_div-215">Shipping and import duties</div>
              <img
                loading="lazy"
                alt="Timely Delivery"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fdispatch%202.png?alt=media&token=f1d35c6e-6058-4c31-8878-1330b93e38e4"  className="school_landing_img-83"
              />
            </div>
            <div className="school_landing_div-217" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Notebooks</div>
              <div className="school_landing_div-220">
                <div className="school_landing_div-221">King and Crown Size Notebooks</div>
                <img
                  loading="lazy"
                  alt="Buy Customized school notebooks"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FExplore%20our%20customizable%20Kraft%20paper%20notebooks%3A%20A4%20car%20line%20books%2C%20B5%20student%20notes%2C%20and%20diaries.%20Personalize%20with%20custom%20logos%20for%20a%20unique%20touch.jpg?alt=media&token=e98210f3-49fa-43c2-abae-823ee8004efc"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">Customized Notebooks</div>
              <img
                loading="lazy"
                alt="Buy Customized school diaries"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FWholesale%20custom%20A5%20PU%20leather%20notebooks%20with%20elastic%20strap.%20Personalize%20with%20your%20brand%20logo.%20Ideal%20for%20journals%2C%20diaries%2C%20and%20notetaking..jpg?alt=media&token=0a9c79fb-7f2e-4eb1-8ccf-a9d9d9b1ad6a"
                className="school_landing_img-85"
              />
            </div>
            <div className="school_landing_div-223" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Answer Sheets</div>
              <div className="school_landing_div-220">
                <div className="school_landing_div-221">Customized Answer Sheets</div>
                <img
                  loading="lazy"
                  alt="Buy Examination Sheets"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FAnswer%20Sheets.webp?alt=media&token=f3b8421e-ebab-4037-a39a-8a060614ad8e"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">OMR Answer Sheets</div>
              <img
                loading="lazy"
                alt="Buy Customized school OMR Sheets"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FScreenshot%20from%202023-11-17%2016-01-29.png?alt=media&token=2fe4d43e-4f62-49b0-93e8-9b6db94daaa6"
                className="school_landing_img-85"
              />
            </div>
            <div className="school_landing_div-223" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Student Diaries</div>
              <div className="school_landing_div-220">
               
                <img
                  loading="lazy"
                  alt="School Diaries"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FStudent%20Diaries.jpg?alt=media&token=4eec3d51-8949-48fc-8454-073e11f14d1e"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">Teacher Diaries</div>
              <img
                loading="lazy"
                alt="Teacher Diaries"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FAttendance.png?alt=media&token=0ef7ce8e-f7a6-4790-8e35-a59ad89d21ec"
                className="school_landing_img-85"
              />
            </div>
          </Link>
          <div className="school_landing_div-269">
            <div className="school_landing_div-270">School Stationary & Accessories</div>
            <div className="school_landing_div-271" />
          </div>
          <Link to = "/Second_Page" style={{ textDecoration: 'none' }} className="school_landing_div-209">
          <div className="school_landing_div-2401">

<div className="school_landing_div-241">Source now</div>
</div>
            <div className="school_landing_div-212">
              <div className="school_landing_div-213">
                <img
                  loading="lazy"
                  alt="Quality Products"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Ftick%20icon.png?alt=media&token=dfdb7bbf-1f76-451b-86de-57b4622241cf"  className="school_landing_img-81"
                />
                <div className="school_landing_div-214">Premium Quality</div>
              </div>
              <div className="school_landing_div-215">Variety of  Quality Products</div>
              <img
                loading="lazy"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2FDiscover%20our%202023%20hot-selling%20school%20items%3A%20customized%2C%20high-quality%20office%20stationery%20gift%20sets%2C%20perfect%20for%20students%20and%20professionals%20alike.jpg?alt=media&token=f010883a-2380-4bb7-a043-e8238e897a66"
                  className="school_landing_img-82"
                  alt="Buy all school accessories at one place"
              />
              <div className="school_landing_div-216">Delivered Duty Paid (DDP)</div>
              <div className="school_landing_div-215">Shipping and import duties</div>
              <img
                loading="lazy"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fdispatch%202.png?alt=media&token=f1d35c6e-6058-4c31-8878-1330b93e38e4" className="school_landing_img-83"
                alt="Timely Delivery"
              />
            </div>
            <div className="school_landing_div-217" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Awards and Recognition</div>
              <div className="school_landing_div-220">
                <div className="school_landing_div-221">Trophies & Medals</div>
                <img
                  loading="lazy"
                  alt="Buy Event Trophies and Medals with customization"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Acessories%20Display%2FTrophies%20and%20Medals.png?alt=media&token=e364fd93-45d7-43b7-8e2d-3c980053240b"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">Certificates</div>
              <img
                loading="lazy"
                alt="Buy Customized Certificates"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Acessories%20Display%2FScreenshot%20from%202023-11-17%2016-31-35.png?alt=media&token=203e3f2c-3a8e-42e6-b369-f108392d2952"
                className="school_landing_img-85"
              />
            </div>
            <div className="school_landing_div-223" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Events Items </div>
              <div className="school_landing_div-220">
                <div className="school_landing_div-221">Badges</div>
                <img
                  loading="lazy"
                  alt="School Badges"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Acessories%20Display%2FSchool%20House%20Captain%20Badges.jpg?alt=media&token=b619bb81-064a-4421-8c7f-ca3ef70cbd20"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">Charts and Color Sets</div>
              <img
                loading="lazy"
                alt="Students Drawing Set"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Acessories%20Display%2FCharts.webp?alt=media&token=1008e1ca-8e20-43c6-962a-c8bfa455ba75"
                className="school_landing_img-85"
              />
            </div>
            <div className="school_landing_div-223" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Customized School Bags </div>
              <div className="school_landing_div-220">
               
                <img
                  loading="lazy"
                  alt="Buy Cutomized School Bags with school logo"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Acessories%20Display%2Fcustomise-school-bag-for-all-classes-.jpg?alt=media&token=87e4ba5d-6641-4b1d-9146-c4b1cbb9975b"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">ID-Cards</div>
              <img
                loading="lazy"
                alt="School ID Cards"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FID_Cards%2FStandard%20-%20ID%20Card%20and%20Lanyard%20Combo.jpg?alt=media&token=f298439e-ca59-4a23-b4f7-42bbfde3ca4b"
                className="school_landing_img-85"
              />
            </div>
          </Link>




          <div className="school_landing_div-301" id = 'RFQ'>
            <img
              loading="lazy"
              alt="Call us for Quotation"
              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FRFQ.png?alt=media&token=85a9af04-6d89-408c-9dea-d7aaafeab053"  className="school_landing_img-108"
            />
            <div className="school_landing_div-302">Request for Quotation</div>
            <div className="school_landing_div-303">Customization services</div>

            <div className="school_landing_div-304" />
            <div className="school_landing_div-305" />
          </div>
          <div className="school_landing_div-306">
            <div className="school_landing_div-307">
              <div className="school_landing_column-44">
                <div className="school_landing_div-308">

                  <div className="school_landing_div-309">
                    <div className="school_landing_div-310">Source smarter with RFQ</div>
                    <div className="school_landing_div-311">
                      <div className="school_landing_div-312">
                        <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 700}}>
                          3074000 +<br />
                        </span>
                        <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                          RFQs
                        </span>
                      </div>
                      <div className="school_landing_div-313">
                        <span style={{ fontFamily: 'Roboto, -apple-system, Roboto, Helvetica, sans-serif', fontWeight: 700 }}>
    21h
    <br/>
                        </span>
                        <span style={{ fontFamily: 'Roboto, -apple-system, Roboto, Helvetica, sans-serif', fontWeight: 500 }}>
    Average response time
                       </span>
                     </div>
                    </div>
                    <Link to = '/PartnerProgram' style={{ textDecoration: 'none' }}  className="school_landing_div-314">
                      <div className="school_landing_div-315">View more</div>
          
                    </Link>
                  </div>
                  <div className="school_landing_div-316">
                    <div className="school_landing_div-317">
                      <div className="school_landing_div-318">
                        170000 +<br />
                        Customization
                      </div>
                    { /* <div className="school_landing_div-319">
                        <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 700}}>
                          7652
                          <br />
                        </span>
                        <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                          Industries
                        </span>
        </div> */}
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="school_landing_column-45">
              <div className="school_landing_div-321">
  <div className="school_landing_div-322">One request, multiple quotes</div>
  
  <form onSubmit={handleSubmit}>
  <div className="school_landing_div-323">
    <input
      type="text"
      id="productName"
      value={Requirement}
      onChange={handleRequirementChange}
      className={RequirementError ? 'error' : ''}
      placeholder="Enter Product name"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: RequirementError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {RequirementError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
  </div>

  <div className="school_landing_div-324">
    <div className="school_landing_div-325">
      <input
        type="number"
        placeholder="Enter Quantity"
        value={orderQuantity}
        onChange={(e) => setorderQuantity(e.target.value)}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '5px',
          border: QuantityError ? '2px solid red' : '1px solid #ccc',
          padding: '5px',
          outline: 'none',
        }}
      /> {/* Closing parenthesis added here */}

      {QuantityError && (
        <span
          style={{
            marginLeft: '5px',
            color: 'red',
            height: '10px',
            width: '10px',
          }}
          role="img"
          aria-label="info"
        >
          <HiOutlineInformationCircle />
        </span>
      )}
       <div className="school_landing_div-327">Piece/Pieces</div>
    </div>
   
  </div>

  <div className="school_landing_div-323">
    <input
      type="text"
      id="contactInfo"
      value={contactInfo}
      onChange={handleContactInfoChange}
      className={contactInfoError ? 'error' : ''}
      placeholder="Phone Number or Email (Required)"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: contactInfoError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {contactInfoError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
  </div>

  <button type="submit" className="school_landing_div-328">
    Request for Quotation
  </button>
</form>

</div>












              </div>
            </div>
          </div>
          </div>
          <div id="section4" className={`section ${sectionVisible === 4 ? 'active' : 'inactive'}`}>
          <div className="school_landing_div-329">
            <div className="school_landing_div-330">
              Promotional Gifting for all occasions{" "}
            </div>
            <div className="school_landing_div-331" />
          </div>
          <div className="school_landing_div-332">
            <Link to   ={`/Promotional_Gifts/${promotional_1.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"

                alt={promotional_1.name} 
                srcSet={promotional_1.image}
                className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 ,  display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_1.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500 }}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550 }}>
                {promotional_1.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_2.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet={promotional_2.image}
                alt={promotional_2.name} 
                className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 , height: '80px', display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis', height: '80px'}}>
                  {promotional_2.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                  {promotional_2.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_3.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet={promotional_3.image}
                alt={promotional_3.name} 
                className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 , height: '80px', display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_3.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_3.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_4.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet={promotional_4.image}
                alt={promotional_4.name} 
                className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 , height: '80px',  display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_4.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_4.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_5.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet={promotional_5.image}
                alt={promotional_5.name} 
                className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 , height: '80px',  display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_5.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_5.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_6.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet={promotional_6.image}
                alt={promotional_6.name} 
                className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 , height: '80px',  display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_6.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_6.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
          </div>
          <div className="school_landing_div-345">
          <Link to   ={`/Promotional_Gifts/${promotional_7.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet= {promotional_7.image}
                alt={promotional_7.name} 
                className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 , height: '80px',  display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_7.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_7.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_8.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet= {promotional_8.image}
                alt={promotional_8.name} 
                className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 ,height: '80px',   display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_8.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_8.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_9.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet= {promotional_9.image}
                alt={promotional_9.name} 
                 className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 , height: '80px',  display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_9.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_9.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_10.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet={promotional_10.image}
                alt={promotional_10.name} 
                className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 , height: '80px',  display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_10.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_10.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_11.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet= {promotional_11.image}
                alt={promotional_11.name} 
                 className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 , height: '80px',  display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_11.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_11.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
            <Link to   ={`/Promotional_Gifts/${promotional_12.id}`}  style={{ textDecoration: 'none' }} className="school_landing_div-333">
              <img
                loading="lazy"
                srcSet={promotional_12.image}
                alt={promotional_12.name} 
                  className="school_landing_img-111"
              />
              <div className="school_landing_div-334">
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400 ,height: '80px',   display: '-webkit-box', WebkitLineClamp: 4,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                {promotional_12.name}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 500}}>
                  <br />
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 550}}>
                {promotional_12.price}
                  <br />
                </span>
                <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                  1 piece (min. order)
                </span>
              </div>
            </Link>
          </div>
          <div className="school_landing_div-358" id = 'School_Services'>
            <div className="school_landing_div-359">School services</div>
            <div className="school_landing_div-360" />
            <a className="school_landing_div-361" href="tel:+917478004111" style={{ textDecoration: 'none' }}>Call @ 7478004111</a>
          </div>
          <div className="school_landing_div-362">
            <div className="school_landing_div-363">
              <div className="school_landing_column-46">
                <div className="school_landing_div-364">
                  <img
                    loading="lazy"
                    alt="School Promotional Items" 
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fresult%20display.png?alt=media&token=6daca89b-0024-47d7-b337-652fdd02a97e"  className="school_landing_img-123"
                  />
                  <div className="school_landing_div-365">
                    <div className="school_landing_div-366">
                      <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 500, color: 'rgba(0, 0, 0, 1)' }}>
                        Results Display
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, color: 'rgba(118, 109, 109, 1)' }}>
                        ORDER PROTECTION
                        <br />
                      </span>
                    </div>
                    <img
                      loading="lazy"
                      alt="Best Design Results Display" 
                      srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fdollar%20king%20logo.png?alt=media&token=248a738c-dab8-4a8d-b4cf-fdd6ea1a88f7"  className="school_landing_img-124"
                    />
                  </div>
                </div>
              </div>
              <div className="school_landing_column-47">
                <div className="school_landing_div-367">
                  <img
                    loading="lazy"
                    alt="Boost School Admissions with us" 
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FMarketing%20Material.png?alt=media&token=2cabe19a-91ee-41c0-9fa3-54b8a92e2925"  className="school_landing_img-125"
                  />
                  <div className="school_landing_div-368">
                    <div className="school_landing_div-369">
                      <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 500, color: 'rgba(0, 0, 0, 1)' }}>
                        Marketing Materials
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, color: 'rgba(118, 109, 109, 1)' }}>
                        PAYMENT SOLUTION
                        <br />
                      </span>
                    </div>
                    <img
                      loading="lazy"
                      alt="Buy Marketing Material For School with us" 
                      srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fmarketing%20Material%20logo.png?alt=media&token=1a07651a-eb2d-40fa-a503-fb176878648d" className="school_landing_img-126"
                    />
                  </div>
                </div>
              </div>
              <div className="school_landing_column-48">
                <div className="school_landing_div-370">
                  <img
                    loading="lazy"
                    alt="Get School Costumes on Rent From Custoking" 
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fcostume%20logo.png?alt=media&token=7c818ec6-e913-4460-a305-86862b0db6ba"  className="school_landing_img-127"
                  />
                  <div className="school_landing_div-371">
                    <div className="school_landing_div-372">
                      <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 500, color: 'rgba(0, 0, 0, 1)' }}>
                        Costumes on Rent
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, color: 'rgba(118, 109, 109, 1)' }}>
                        INSPECTION
                        <br />
                      </span>
                    </div>
                    <img
                      loading="lazy"
                      alt="Organize school events with us" 
                      srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Ffolder_search.png?alt=media&token=57a7272a-7c85-46a3-a8b2-f4bc3cce0441"  className="school_landing_img-128"
                    />
                  </div>
                </div>
              </div>
              <div className="school_landing_column-49">
                <div className="school_landing_div-373">
                  <img
                    loading="lazy"
                    alt="Organize school events with us" 
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FEvent%20Management.png?alt=media&token=87301050-b25f-402e-9900-0473855dafb9"  className="school_landing_img-129"
                  />
                  <div className="school_landing_div-374">
                    <div className="school_landing_div-375">
                      <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 500, color: 'rgba(0, 0, 0, 1)' }}>
                        Event Management
                        <br />
                      </span>
                      <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, color: 'rgba(118, 109, 109, 1)' }}>
                        LOGISTIC SERVICE
                        <br />
                      </span>
                    </div>
                    <img
                      loading="lazy"
                      alt="Get event supplies from us" 
                      srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FEvent%20Management%20Logo.png?alt=media&token=cf5291a5-1904-4ab4-ad2b-294723b58383"  className="school_landing_img-130"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="school_landing_div-376">
            <div className="school_landing_div-377">Our partner schools are in these countries</div>
            <div className="school_landing_div-378" />
          </div>
        </div>
        </div>
        <div className="school_landing_div-379">
          <div className="school_landing_div-380">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8c7305e5-44d0-4818-b6dc-5ebed16f883a?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="school_landing_img-131"
              alt="Japan Flag"
            />
            <div className="school_landing_div-381">Japan</div>
          </div>
          <div className="school_landing_div-382">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdacf51a-7742-4cae-8ea9-3f34d55e537c?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              alt="USA Flag"
              className="school_landing_img-132"
            />
            <div className="school_landing_div-383">USA</div>
          </div>
          <div className="school_landing_div-384">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/50a46f6b-8d2d-4715-9a2b-19b6bcfec8e6?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              alt="Italy Flag"
              className="school_landing_img-133"
            />
            <div className="school_landing_div-385">Italy</div>
          </div>
          <div className="school_landing_div-386">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/936b7a40-9027-4755-829e-a30028afb0a1?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="school_landing_img-134"
              alt="Turkey Flag"
            />
            <div className="school_landing_div-387">Turkey</div>
          </div>
          <div className="school_landing_div-388">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f366547f-95fd-4293-a8b1-e4e0f245220e?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="school_landing_img-135"
              alt="Malaysia Flag"
            />
            <div className="school_landing_div-389">Malaysia </div>
          </div>
          <div className="school_landing_div-390">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7653e3ff-644c-4866-86bd-ff40eb927cbd?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="school_landing_img-136"
              alt="South Korea Flag"
            />
            <div className="school_landing_div-391">South Korea</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/beed1276-8671-4d84-923a-ee7c10dacdc0?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="school_landing_img-137"
              alt="Vietnam Flag"
            />
            <div className="school_landing_div-392">Vietnam</div>
          </div>
          <div className="school_landing_div-393">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f681d3fe-7624-4c3d-accc-138be798d8c3?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="school_landing_img-138"
              alt="Russia Flag"
            />
            <div className="school_landing_div-394">Russia</div>
          </div>
          <div className="school_landing_div-395">More</div>
        </div>
        <div className="school_landing_div-396">
          <div className="school_landing_div-397">

            <div className="school_landing_div-398">Join Partner Program</div>
          </div>
          <Link to ='/PartnerProgram' style={{ textDecoration: 'none' }}  className="school_landing_div-399">

        
            <div className="school_landing_div-401">View more</div>
          </Link>
        </div>
        <div className="school_landing_div-402">

  
<div className="school_landing_div-409">
  <div className="school_landing_div-410">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Customer services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
      Help Center
      <br />
      Report abuse
      <br />
      File a case
      <br />
      Piolicies & rules
      <br />
      Get paid for your feedback
    </span>
  </div>
  <div className="school_landing_div-411">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      About Us
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      About Custoking.com
      <br />
      About Custoking Group
      <br />
      Sitemap
      <br />
      Custoking.com Blog
    </span>
  </div>
  <div className="school_landing_div-412">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Source on Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Resources
      <br />
      All categories
      <br />
      Request for Quotation
      <br />
      Ready to Ship
      <br />
      Buyer partners
      <br />
      Custoking.com Select
    </span>
  </div>
  <div className="school_landing_div-413">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Partner with Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Supplier memberships
      <br />
      Learning Center
      <br />
      Partner Program
    </span>
  </div>
  <div className="school_landing_div-414">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Trade services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Trade Assuarance
      <br />
      Business identity
      <br />
      Logistics services
      <br />
      Production Monitoring & Inspespection
      <br />
      Services
      <br />
      Letter of Credit
    </span>
  </div>
</div>



<div className="school_landing_div-430">
  Browse Alphabetically Onetouch | Showroom | Country Search | Affliate
</div>
<div className="school_landing_div-431">
  Product Listing Policy-Intellectual Property Protection-Privacy
  Policy-Terms of Use-User Information Legal Enquiry Guide
</div>


</div>

      </div>



    </>
  );
}

export default School_Section_Page;

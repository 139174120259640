import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const School_Category_StationaryContext = createContext();

export const School_Category_StationaryContextProvider = ({ children }) => {
  const [school_category_stationary, setSchool_Category_Stationary] = useState([]); // Corrected the state variable name

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('School Section/Category Display/Stationary').get();
        const school_category_stationaryData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));
        setSchool_Category_Stationary(school_category_stationaryData); // Corrected the state setter function
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <School_Category_StationaryContext.Provider value={{ school_category_stationary }}>
      {children}
    </School_Category_StationaryContext.Provider>
  );
};

import * as React from "react";

function Book_Design() {
  return (
    <>
      <div className="book_design_div">
        <div className="book_design_div-2">
          <div className="book_design_div-3">
            <div className="book_design_div-4">One stop solution for all your needs!</div>
            <div className="book_design_div-5">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e2e1ef8b-6b9a-4662-8a63-d5910fc12803?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="book_design_img"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc44d696-fb8f-4673-a314-5e1974662de9?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="book_design_img"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4b5f74e8-9633-4340-a3c0-dd0a27468b6b?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="book_design_img-2"
              />
            </div>
          </div>
          <div className="book_design_div-6">
            <div className="book_design_div-7">
              <div className="book_design_div-8">Your work</div>
              <div className="book_design_div-9">Designers</div>
              <div className="book_design_div-10">Categories</div>
              <div className="book_design_div-11">Inspiration</div>
            </div>
          </div>
        </div>
        <div className="book_design_div-12">
          <div className="book_design_div-13">What do you need designed?</div>
          <div className="book_design_div-14">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7fa98baa-b29d-4405-aaa0-5194cd94e659?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fa98baa-b29d-4405-aaa0-5194cd94e659?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fa98baa-b29d-4405-aaa0-5194cd94e659?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fa98baa-b29d-4405-aaa0-5194cd94e659?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fa98baa-b29d-4405-aaa0-5194cd94e659?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fa98baa-b29d-4405-aaa0-5194cd94e659?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fa98baa-b29d-4405-aaa0-5194cd94e659?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7fa98baa-b29d-4405-aaa0-5194cd94e659?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="book_design_img-3"
            />
            <div className="book_design_div-15">Logo & identity </div>
            <div className="book_design_div-16">Web & app design</div>
            <div className="book_design_div-17">Business & advertising</div>
            <div className="book_design_div-18">Clothing & merchandise</div>
          </div>
        </div>
        <div className="book_design_div-19">
          <div className="book_design_div-20">
            <div className="book_design_div-21">
              <div className="column">
                <div className="book_design_div-22">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ac41efcb-f911-46f3-985a-b5133baec497?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ac41efcb-f911-46f3-985a-b5133baec497?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ac41efcb-f911-46f3-985a-b5133baec497?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ac41efcb-f911-46f3-985a-b5133baec497?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ac41efcb-f911-46f3-985a-b5133baec497?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ac41efcb-f911-46f3-985a-b5133baec497?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ac41efcb-f911-46f3-985a-b5133baec497?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ac41efcb-f911-46f3-985a-b5133baec497?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="book_design_img-4"
                  />
                  <div className="book_design_div-23">Book cover</div>
                  <div className="book_design_div-24">
                    A book cover design <br />
                    worth 100,000 words
                  </div>
                </div>
              </div>
              <div className="book_design_column-2">
                <div className="book_design_div-25">
                  <div className="book_design_div-26">
                    <div className="book_design_column-3">
                      <div className="book_design_div-27" />
                    </div>
                    <div className="book_design_column-4">
                      <div className="book_design_div-28">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ef1e2c69-0669-4b0f-a589-d0747fdfd603?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef1e2c69-0669-4b0f-a589-d0747fdfd603?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef1e2c69-0669-4b0f-a589-d0747fdfd603?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef1e2c69-0669-4b0f-a589-d0747fdfd603?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef1e2c69-0669-4b0f-a589-d0747fdfd603?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef1e2c69-0669-4b0f-a589-d0747fdfd603?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef1e2c69-0669-4b0f-a589-d0747fdfd603?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ef1e2c69-0669-4b0f-a589-d0747fdfd603?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="book_design_img-5"
                        />
                        <div className="book_design_div-29">
                          Interior book <br />
                          design
                        </div>
                        <div className="book_design_div-30">
                          Interior book design that keeps readers engaged
                        </div>
                      </div>
                    </div>
                    <div className="book_design_column-5">
                      <div className="book_design_div-31" />
                    </div>
                    <div className="book_design_column-6">
                      <div className="book_design_div-32">
                        <div className="book_design_div-33">
                          <div className="book_design_div-34">
                            <div className="book_design_column-7">
                              <div className="book_design_div-35">
                                <img
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/cdb7f63b-0d39-4abd-afed-b1f9bcb75476?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cdb7f63b-0d39-4abd-afed-b1f9bcb75476?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cdb7f63b-0d39-4abd-afed-b1f9bcb75476?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cdb7f63b-0d39-4abd-afed-b1f9bcb75476?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cdb7f63b-0d39-4abd-afed-b1f9bcb75476?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cdb7f63b-0d39-4abd-afed-b1f9bcb75476?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cdb7f63b-0d39-4abd-afed-b1f9bcb75476?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/cdb7f63b-0d39-4abd-afed-b1f9bcb75476?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                                  className="book_design_img-6"
                                />
                                <div className="book_design_div-36">eBook Cover</div>
                              </div>
                            </div>
                            <div className="book_design_column-8">
                              <div className="book_design_div-37">
                                <img
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0dc68702-9e0e-4ab9-bf50-c3c4b9de01a9?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0dc68702-9e0e-4ab9-bf50-c3c4b9de01a9?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0dc68702-9e0e-4ab9-bf50-c3c4b9de01a9?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0dc68702-9e0e-4ab9-bf50-c3c4b9de01a9?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0dc68702-9e0e-4ab9-bf50-c3c4b9de01a9?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0dc68702-9e0e-4ab9-bf50-c3c4b9de01a9?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0dc68702-9e0e-4ab9-bf50-c3c4b9de01a9?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0dc68702-9e0e-4ab9-bf50-c3c4b9de01a9?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                                  className="book_design_img-7"
                                />
                                <div className="book_design_div-38">Magazine cover</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="book_design_div-39">
                          <div className="book_design_div-40">
                            An ebook cover that gets
                            <br />
                            downloads
                          </div>
                          <div className="book_design_div-41">
                            A magazine cover with all the right features
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="book_design_div-42">
            <div className="book_design_div-43">
              <div className="book_design_column-9">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1eeffd84-4f92-4fe9-8b56-33545d24c5bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1eeffd84-4f92-4fe9-8b56-33545d24c5bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1eeffd84-4f92-4fe9-8b56-33545d24c5bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1eeffd84-4f92-4fe9-8b56-33545d24c5bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1eeffd84-4f92-4fe9-8b56-33545d24c5bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1eeffd84-4f92-4fe9-8b56-33545d24c5bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1eeffd84-4f92-4fe9-8b56-33545d24c5bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1eeffd84-4f92-4fe9-8b56-33545d24c5bd?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="book_design_img-8"
                />
              </div>
              <div className="book_design_column-10">
                <div className="book_design_div-44">
                  <div className="book_design_div-45">
                    <div className="book_design_column-11">
                      <div className="book_design_div-46">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4ae60636-7792-416f-bdcc-30172c72ff4e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ae60636-7792-416f-bdcc-30172c72ff4e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ae60636-7792-416f-bdcc-30172c72ff4e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ae60636-7792-416f-bdcc-30172c72ff4e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ae60636-7792-416f-bdcc-30172c72ff4e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ae60636-7792-416f-bdcc-30172c72ff4e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ae60636-7792-416f-bdcc-30172c72ff4e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ae60636-7792-416f-bdcc-30172c72ff4e?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="book_design_img-9"
                        />
                        <div className="book_design_div-47">Book Layout</div>
                        <div className="book_design_div-48">
                          A book layout that keeps pages turning
                        </div>
                      </div>
                    </div>
                    <div className="book_design_column-12">
                      <div className="book_design_div-49">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/00bdad9a-7c56-42f7-a563-9e0d1f7ae6d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/00bdad9a-7c56-42f7-a563-9e0d1f7ae6d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/00bdad9a-7c56-42f7-a563-9e0d1f7ae6d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/00bdad9a-7c56-42f7-a563-9e0d1f7ae6d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/00bdad9a-7c56-42f7-a563-9e0d1f7ae6d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/00bdad9a-7c56-42f7-a563-9e0d1f7ae6d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/00bdad9a-7c56-42f7-a563-9e0d1f7ae6d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/00bdad9a-7c56-42f7-a563-9e0d1f7ae6d4?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="book_design_img-10"
                        />
                        <div className="book_design_div-50">
                          Other book or
                          <br />
                          magazine
                        </div>
                        <div className="book_design_div-51">
                          Book and magazine designs that don’t quite fit a genre
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="book_design_div-52">
          <div className="book_design_div-53">
            Still haven’t found what you’re looking for? Search and you shall
            find
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b2c0b859-eb03-4c4d-b7a2-acc4cd0c5ec8?apiKey=db6f3fe714c542fe811ae992d63ea686&"
            className="book_design_img-11"
          />
        </div>
        <div className="book_design_div-54">
          <div className="book_design_div-55">
            <div className="book_design_div-56">
              <div className="book_design_column-13">
                <div className="book_design_div-57">
                  <div className="book_design_div-58">
                    <div className="book_design_column-14">
                      <div className="book_design_div-59">
                        <div className="book_design_div-60">Company</div>
                        <div className="book_design_div-61">About</div>
                        <div className="book_design_div-62">Contact</div>
                        <div className="book_design_div-63">Careers</div>
                        <div className="book_design_div-64">Team</div>
                        <div className="book_design_div-65">Press releases</div>
                        <div className="book_design_div-66">In the media</div>
                        <div className="book_design_div-67">Testimonals</div>
                      </div>
                    </div>
                    <div className="book_design_column-15">
                      <div className="book_design_div-68">
                        <div className="book_design_div-69">Design services</div>
                        <div className="book_design_div-70">Design contests</div>
                        <div className="book_design_div-71">1-to-1 projects</div>
                        <div className="book_design_div-72">Find a designer</div>
                        <div className="book_design_div-73">Discover inspiration</div>
                        <div className="book_design_div-74">Pricing</div>
                        <div className="book_design_div-75">Agencies</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="book_design_column-16">
                <div className="book_design_div-76">
                  <div className="book_design_div-77">
                    <div className="book_design_column-17">
                      <div className="book_design_div-78">
                        <div className="book_design_div-79">Get a design</div>
                        <div className="book_design_div-80">Logo design</div>
                        <div className="book_design_div-81">Business card</div>
                        <div className="book_design_div-82">Web page design</div>
                        <div className="book_design_div-83">Brand guide</div>
                        <div className="book_design_div-84">Packaging design</div>
                        <div className="book_design_div-85">T-Shirt design</div>
                        <div className="book_design_div-86">Book cover design</div>
                        <div className="book_design_div-87">Browse all categories</div>
                      </div>
                    </div>
                    <div className="book_design_column-18">
                      <div className="book_design_div-88">
                        <div className="book_design_div-89">Resources</div>
                        <div className="book_design_div-90">Become a designer</div>
                        <div className="book_design_div-91">Blog</div>
                        <div className="book_design_div-92">Awards</div>
                        <div className="book_design_div-93">Affiliates</div>
                        <div className="book_design_div-94">Logo - ideas</div>
                        <div className="book_design_div-95">T-shirt ideas</div>
                        <div className="book_design_div-96">Designer resources</div>
                        <div className="book_design_div-97">Featured Partners</div>
                        <div className="book_design_div-98">Help</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="book_design_div-99">
            <div className="book_design_div-100">Help?</div>
          </div>
          <div className="book_design_div-101" />
        </div>
      </div>
      <style jsx>{`
        .book_design_div {
          background-color: #fff;
          display: flex;
          flex-direction: column;
        }
        .book_design_div-2 {
          align-self: stretch;
          display: flex;
          margin-top: 38px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .book_design_div-2 {
            max-width: 100%;
          }
        }
        .book_design_div-3 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 993px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .book_design_div-3 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .book_design_div-4 {
          color: #000;
          text-align: center;
          align-self: stretch;
          max-width: 1038px;
          flex-grow: 1;
          flex-basis: auto;
          font: 300 36px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-4 {
            max-width: 100%;
          }
        }
        .book_design_div-5 {
          align-self: stretch;
          display: flex;
          width: 185px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .book_design_div-5 {
            justify-content: center;
          }
        }
        .book_design_img {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .book_design_img-2 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .book_design_div-6 {
          background-color: #d9d9d9;
          align-self: center;
          display: flex;
          margin-top: 27px;
          width: 100%;
          max-width: 1511px;
          flex-grow: 1;
          flex-direction: column;
          padding: 77px 20px 58px;
        }
        @media (max-width: 991px) {
          .book_design_div-6 {
            max-width: 100%;
          }
        }
        .book_design_div-7 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 992px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .book_design_div-7 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .book_design_div-8 {
          color: #000;
          text-align: center;
          align-self: center;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
          font: 600 36px Inter, sans-serif;
        }
        .book_design_div-9 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .book_design_div-10 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .book_design_div-11 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .book_design_div-12 {
          align-self: center;
          display: flex;
          margin-top: 129px;
          width: 100%;
          max-width: 1395px;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .book_design_div-12 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_div-13 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 569px;
          font: 600 36px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-13 {
            max-width: 100%;
          }
        }
        .book_design_div-14 {
          align-self: center;
          display: flex;
          width: 100%;
          align-items: flex-start;
          gap: 14px;
          margin: 49px 0 0 -20px;
        }
        @media (max-width: 991px) {
          .book_design_div-14 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
          }
        }
        .book_design_img-3 {
          aspect-ratio: 0.87;
          object-fit: cover;
          object-position: center;
          width: 99px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .book_design_div-15 {
          color: #000;
          text-align: center;
          align-self: stretch;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 255px;
          max-width: 100%;
          padding: 43px 19px 43px 20px;
          font: 600 30px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-15 {
            padding-left: 1px;
          }
        }
        @media (max-width: 640px) {
          .book_design_div-15 {
            width: 311px;
          }
        }
        .book_design_div-16 {
          color: #000;
          text-align: center;
          align-self: stretch;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 282px;
          max-width: 100%;
          padding: 46px 13px 39px 12px;
          font: 600 30px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .book_design_div-16 {
            width: 318px;
          }
        }
        .book_design_div-17 {
          color: #000;
          text-align: center;
          align-self: stretch;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 336px;
          max-width: 100%;
          padding: 46px 5px 39px;
          font: 600 30px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .book_design_div-17 {
            width: 315px;
          }
        }
        .book_design_div-18 {
          color: #000;
          text-align: center;
          align-self: stretch;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 355px;
          max-width: 100%;
          padding: 46px 6px 39px 5px;
          font: 600 30px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .book_design_div-18 {
            width: 319px;
          }
        }
        .book_design_div-19 {
          align-self: center;
          display: flex;
          margin-top: 362px;
          width: 100%;
          max-width: 1231px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .book_design_div-19 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_div-20 {
          align-self: center;
          width: 100%;
          max-width: 1153px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .book_design_div-20 {
            max-width: 100%;
          }
        }
        .book_design_div-21 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .book_design_div-21 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 20%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .book_design_div-22 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .book_design_div-22 {
            margin-top: 40px;
          }
        }
        .book_design_img-4 {
          aspect-ratio: 1.15;
          object-fit: cover;
          object-position: center;
          width: 109px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .book_design_div-23 {
          color: #000;
          align-self: start;
          margin-top: 39px;
          font: 600 27px Inter, sans-serif;
        }
        .book_design_div-24 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 74px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-24 {
            margin-top: 40px;
          }
        }
        .book_design_column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 80%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-2 {
            width: 100%;
          }
        }
        .book_design_div-25 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .book_design_div-25 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_div-26 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .book_design_div-26 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .book_design_column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 0%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .book_design_column-3 {
            width: 100%;
          }
        }
        .book_design_div-27 {
          background-color: #000;
          width: 1px;
          height: 459px;
          margin: 0 auto;
        }
        @media (max-width: 991px) {
          .book_design_div-27 {
            margin-top: 16px;
          }
        }
        .book_design_column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 32%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-4 {
            width: 100%;
          }
        }
        .book_design_div-28 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .book_design_div-28 {
            margin-top: 40px;
          }
        }
        .book_design_img-5 {
          aspect-ratio: 1.1;
          object-fit: cover;
          object-position: center;
          width: 128px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .book_design_div-29 {
          color: #000;
          align-self: start;
          margin-top: 26px;
          font: 600 27px Inter, sans-serif;
        }
        .book_design_div-30 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 37px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .book_design_column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 0%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-5 {
            width: 100%;
          }
        }
        .book_design_div-31 {
          background-color: #000;
          width: 1px;
          height: 459px;
          margin: 0 auto;
        }
        @media (max-width: 991px) {
          .book_design_div-31 {
            margin-top: 16px;
          }
        }
        .book_design_column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 68%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-6 {
            width: 100%;
          }
        }
        .book_design_div-32 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .book_design_div-32 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_div-33 {
          align-self: start;
          width: 510px;
          max-width: 100%;
        }
        .book_design_div-34 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .book_design_div-34 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .book_design_column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .book_design_column-7 {
            width: 100%;
          }
        }
        .book_design_div-35 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .book_design_div-35 {
            margin-top: 40px;
          }
        }
        .book_design_img-6 {
          aspect-ratio: 1.02;
          object-fit: cover;
          object-position: center;
          width: 122px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .book_design_div-36 {
          color: #000;
          align-self: start;
          margin: 18px 0 0 12px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-36 {
            margin-left: 10px;
          }
        }
        .book_design_column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-8 {
            width: 100%;
          }
        }
        .book_design_div-37 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .book_design_div-37 {
            margin-top: 40px;
          }
        }
        .book_design_img-7 {
          aspect-ratio: 1.06;
          object-fit: cover;
          object-position: center;
          width: 119px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .book_design_div-38 {
          color: #000;
          align-self: start;
          margin-top: 31px;
          font: 600 27px Inter, sans-serif;
        }
        .book_design_div-39 {
          align-self: start;
          display: flex;
          margin-top: 71px;
          width: 551px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .book_design_div-39 {
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .book_design_div-40 {
          color: #7a7a7a;
          align-self: start;
          max-width: 290px;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .book_design_div-41 {
          color: #7a7a7a;
          align-self: start;
          max-width: 290px;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .book_design_div-42 {
          align-self: stretch;
          margin-top: 49px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .book_design_div-42 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_div-43 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .book_design_div-43 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .book_design_column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .book_design_column-9 {
            width: 100%;
          }
        }
        .book_design_img-8 {
          aspect-ratio: 0.82;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .book_design_img-8 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-10 {
            width: 100%;
          }
        }
        .book_design_div-44 {
          margin-top: 76px;
        }
        @media (max-width: 991px) {
          .book_design_div-44 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_div-45 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .book_design_div-45 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .book_design_column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .book_design_column-11 {
            width: 100%;
          }
        }
        .book_design_div-46 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .book_design_div-46 {
            margin-top: 40px;
          }
        }
        .book_design_img-9 {
          aspect-ratio: 1.24;
          object-fit: cover;
          object-position: center;
          width: 131px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .book_design_div-47 {
          color: #000;
          align-self: start;
          margin: 35px 0 0 17px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-47 {
            margin-left: 10px;
          }
        }
        .book_design_div-48 {
          color: #7a7a7a;
          align-self: start;
          margin: 66px 0 0 12px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-48 {
            margin: 40px 0 0 10px;
          }
        }
        .book_design_column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-12 {
            width: 100%;
          }
        }
        .book_design_div-49 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .book_design_div-49 {
            margin-top: 40px;
          }
        }
        .book_design_img-10 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 112px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .book_design_div-50 {
          color: #000;
          align-self: start;
          max-width: 279px;
          margin: 32px 0 0 13px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-50 {
            margin-left: 10px;
          }
        }
        .book_design_div-51 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 32px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-51 {
            margin-left: 8px;
          }
        }
        .book_design_div-52 {
          align-self: center;
          display: flex;
          margin-top: 1487px;
          width: 100%;
          max-width: 1115px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .book_design_div-52 {
            max-width: 100%;
            margin-top: 40px;
            flex-wrap: wrap;
          }
        }
        .book_design_div-53 {
          color: #000;
          align-self: start;
          margin-top: 7px;
          max-width: 1177px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 32px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-53 {
            max-width: 100%;
          }
        }
        .book_design_img-11 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 39px;
          fill: #fff;
          stroke-width: 3px;
          stroke: #000;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .book_design_div-54 {
          background-color: #f3f2f0;
          align-self: stretch;
          display: flex;
          margin-top: 170px;
          width: 100%;
          flex-direction: column;
          padding: 75px 20px 187px;
        }
        @media (max-width: 991px) {
          .book_design_div-54 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_div-55 {
          align-self: center;
          width: 100%;
          max-width: 1176px;
        }
        @media (max-width: 991px) {
          .book_design_div-55 {
            max-width: 100%;
          }
        }
        .book_design_div-56 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .book_design_div-56 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .book_design_column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .book_design_column-13 {
            width: 100%;
          }
        }
        .book_design_div-57 {
        }
        @media (max-width: 991px) {
          .book_design_div-57 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_div-58 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .book_design_div-58 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .book_design_column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 47%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .book_design_column-14 {
            width: 100%;
          }
        }
        .book_design_div-59 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .book_design_div-59 {
            margin-top: 40px;
          }
        }
        .book_design_div-60 {
          color: #000;
          align-self: start;
          width: 242px;
          font: 600 28px Inter, sans-serif;
        }
        .book_design_div-61 {
          color: #000;
          align-self: start;
          margin-top: 41px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-61 {
            margin-top: 40px;
          }
        }
        .book_design_div-62 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-63 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-64 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-65 {
          color: #000;
          margin-top: 33px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-66 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-67 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 53%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-15 {
            width: 100%;
          }
        }
        .book_design_div-68 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .book_design_div-68 {
            margin-top: 40px;
          }
        }
        .book_design_div-69 {
          color: #000;
          align-self: start;
          width: 241px;
          font: 600 28px Inter, sans-serif;
        }
        .book_design_div-70 {
          color: #000;
          align-self: start;
          margin-top: 40px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-71 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-72 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-73 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          text-wrap: nowrap;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-73 {
            text-wrap: initial;
          }
        }
        .book_design_div-74 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-75 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_column-16 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-16 {
            width: 100%;
          }
        }
        .book_design_div-76 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .book_design_div-76 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .book_design_div-77 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .book_design_div-77 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .book_design_column-17 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .book_design_column-17 {
            width: 100%;
          }
        }
        .book_design_div-78 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .book_design_div-78 {
            margin-top: 40px;
          }
        }
        .book_design_div-79 {
          color: #000;
          align-self: start;
          width: 241px;
          font: 600 28px Inter, sans-serif;
        }
        .book_design_div-80 {
          color: #000;
          align-self: start;
          margin-top: 42px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-80 {
            margin-top: 40px;
          }
        }
        .book_design_div-81 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-82 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-83 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-84 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-85 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-86 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-87 {
          color: #000;
          margin-top: 28px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_column-18 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .book_design_column-18 {
            width: 100%;
          }
        }
        .book_design_div-88 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .book_design_div-88 {
            margin-top: 40px;
          }
        }
        .book_design_div-89 {
          color: #000;
          align-self: start;
          width: 242px;
          font: 600 28px Inter, sans-serif;
        }
        .book_design_div-90 {
          color: #000;
          align-self: start;
          margin-top: 48px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .book_design_div-90 {
            margin-top: 40px;
          }
        }
        .book_design_div-91 {
          color: #000;
          align-self: start;
          margin-top: 29px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-92 {
          color: #000;
          align-self: start;
          margin-top: 26px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-93 {
          color: #000;
          align-self: start;
          margin-top: 31px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-94 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-95 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-96 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-97 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-98 {
          color: #000;
          align-self: start;
          margin-top: 26px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .book_design_div-99 {
          border-radius: 30px;
          background-color: rgba(0, 0, 0, 0.76);
          align-self: start;
          display: flex;
          margin-top: 50px;
          width: 194px;
          max-width: 100%;
          flex-direction: column;
          padding: 18px 20px 11px;
        }
        @media (max-width: 991px) {
          .book_design_div-99 {
            margin: 40px 0 0 -3px;
          }
        }
        .book_design_div-100 {
          color: #fff;
          align-self: center;
          width: 100%;
          font: 600 40px Inter, sans-serif;
        }
        .book_design_div-101 {
          background-color: #000;
          align-self: stretch;
          min-height: 1px;
          width: 100%;
          margin: 27px -20px -37px 0;
        }
        @media (max-width: 991px) {
          .book_design_div-101 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
      `}</style>
    </>
  );
}

export default Book_Design;

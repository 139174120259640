

import { db, storage } from './Firebase';
import React, { useContext, useState , useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { PiNumberCircleOneBold, PiNumberCircleTwoBold, PiNumberCircleThreeBold, PiNumberCircleFourBold } from 'react-icons/pi';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import {GiCheckMark} from 'react-icons/gi';
import { AiFillStar, AiOutlineStar,AiOutlineHeart } from 'react-icons/ai';
import {MdOutlineVerifiedUser} from 'react-icons/md';
import {BsHandThumbsUp} from 'react-icons/bs';
import {TbWorldCheck,TbPointFilled} from 'react-icons/tb';
import { Stationary_Display_Context } from './Stationary_Display_Data';
import { Link } from "react-router-dom";
import {BsChevronRight} from "react-icons/bs";
import { IoCall } from 'react-icons/io5';

import { ID_Cards_Display_Context } from './ID_Cards_Display_Data';
import { Printing_Display_Context } from './Printing_Display_Data';

import './AI_Test.css';

const ID_Card_Detail = () => {
  let { productID } = useParams();
  const [product, setProduct] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [orderQuantity, setOrderQuantity] = useState(1); // default quantity is 1
  const [orderAmount, setOrderAmount] = useState(0);
  const [rating, setRating] = useState(null);
  const {school_category_stationary} = useContext(Stationary_Display_Context );
  const {idcards} = useContext(ID_Cards_Display_Context);
const {printing_display} = useContext(Printing_Display_Context);
  
const generateStars = (rating) => {
  const fullStars = Math.floor(rating);
  const decimalPart = rating - fullStars;
  const starArray = [];

  for (let i = 0; i < fullStars; i++) {
    starArray.push(<AiFillStar key={i} className="star" />);
  }

  if (decimalPart > 0) {
    if (decimalPart >= 0.75) {
      starArray.push(<AiFillStar key="full-star" className="star" />);
    } else if (decimalPart >= 0.25) {
      starArray.push(<AiOutlineStar key="half-star" className="star" />);
    }
  }

  return starArray;
};

  useEffect(() => {
    const productRef = db.collection('Stationary/cards/ID_Cards').doc(productID);

    productRef.get().then((doc) => {
      if (doc.exists) {
        setProduct(doc.data());
      } else {
        setProduct(null);
      }
    });
  }, [productID]);

  const handlePhoneNumberChange = (e) => {
    // Allow only numeric input
    const input = e.target.value.replace(/\D/g, '');

    // Restrict the length to 10 characters
    const limitedInput = input.slice(0, 10);

    setPhoneNumber(limitedInput);

    if (limitedInput.length !== 10) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  const handleFileChange = (e) => {
    if (e && e.target) {
      const file = e.target.files[0];
      setSelectedFile(file);

      // Automatically upload the file to Firebase Storage
      uploadFile(file);
    }
  };

  const uploadFile = (file) => {
    const storageRef = storage.ref(`/Order Uploads/${file.name}`);
    const uploadTask = storageRef.put(file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Progress
      },
      (error) => {
        // Error handling
        console.error(error);
        // Add code to inform the user about the error
      },
      () => {
        // Success, get file URL
        storageRef.getDownloadURL().then((url) => {
          setFileURL(url);
        });
      }
    );
  };

  const handleFileOpen = () => {
    // Trigger download when the user clicks on the uploaded file name
    if (fileURL) {

      window.open(fileURL, '_blank');
    }
  };

  const handleQuantityChange = (e) => {
    const quantity = parseInt(e.target.value, 10) || 1; // ensure it's a number, default to 1
    setOrderQuantity(quantity);
  };

  const handleIncrement = () => {
    setOrderQuantity(orderQuantity + 1);
  };

  const handleDecrement = () => {
    if (orderQuantity > 1) {
      setOrderQuantity(orderQuantity - 1);
    }
  };

  const calculateOrderAmount = (priceMatches) => {
    if (!priceMatches) return { original: 0, lower: 0, lowest: 0 };
  
    if (priceMatches.length === 1) {
      const originalPrice = parseFloat(priceMatches[0].replace(/[₹,]/g, ''));
      const lowerPrice = originalPrice * 0.93; // 7% lower
      const lowestPrice = originalPrice * 0.85; // 15% lower
      return { original: originalPrice, lower: lowerPrice, lowest: lowestPrice };
    }
  
    const [minPrice, maxPrice] = priceMatches.map((price) =>
      parseFloat(price.replace(/[₹,]/g, ''))
    );
  
    const originalPrice = maxPrice;
    const lowerPrice = (minPrice + maxPrice) / 2;
    const lowestPrice = minPrice;
  
    return { original: originalPrice, lower: lowerPrice, lowest: lowestPrice };
  };
  
  

  // Extract price from the product price string
  const priceMatches = product?.price?.match(/₹[\d,.-]+/g);
  const { original, lower, lowest } = calculateOrderAmount(priceMatches);
  useEffect(() => {
    // Calculate the order amount based on quantity and extracted price
    if (rating === null) {
      const newRating = Math.random() * (5 - 3.5) + 3.5;
      setRating(newRating);
    }
    const newOrderAmount = calculateOrderAmount(priceMatches, orderQuantity);
    setOrderAmount(newOrderAmount);
  }, [orderQuantity, priceMatches,rating]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate phone number
    if (!phoneNumber || phoneNumber.length !== 10) {
      setPhoneNumberError(true);
      return; // Don't proceed with the submission
    }
    setPhoneNumberError(false);

    // Save order data only when the file URL is available
    if (fileURL) {
      saveOrderData();
    }
  };

  const saveOrderData = () => {
    const ordersRef = db.collection('orders');

    const orderData = {
      productID: productID,
      productName: product.name,
      productPrice: product.price, // Add product price to order data
      phoneNumber: phoneNumber,
      fileURL: fileURL,
      orderQuantity: orderQuantity,
      orderAmount: orderAmount,
    };

    // Use add to let Firestore generate a unique document ID
    ordersRef.add(orderData);

    // Reset fields
    setPhoneNumber('');
    setSelectedFile(null);
    setFileURL(null);
  };

  return (
       <>
        {product ? (
      <div className="div">
    
    
        <div className="div-12">
          <div className="div-13">
            <div className="div-14">
              <div className="column">
                <div className="div-15">
                  <img
                    loading="lazy"
                    srcSet={product.image}
                    className="img-6"
                  />

                </div>
              </div>
              <div className="column-2">
                <div className="div-16">
                  <div className="div-17">
                    <div className="div-18">
                      <div className="column-3">
                        <div className="div-19">
                          <div className="div-20">
                            <div className='img-8'><GiCheckMark/></div>
                        
                            <div className="div-21">Available</div>
                          </div>
                          <div className="div-22">
                          {product.name}
                          </div>
                          <div className="div-23">
                          <div className="img-9">{generateStars(rating)}</div>
                          <div className="div-24">{rating.toFixed(1)}</div>

                           
                          </div>
                          <div className='img-14'>
                            <div className='max_amount_container'>
                              <div className='max_price'>₹{original.toFixed(2)}</div>
                              <div className='max_price_quantity'>50-100 pcs</div>
                            </div>
                            <div className='avg_amount_container'>
                              <div className='avg_price'>₹{lower.toFixed(2)}</div>
                              <div className='avg_price_quantity'>100-500 pcs</div>
                            </div><div className='min_amount_container'>
                              <div className='min_price'>₹{lowest.toFixed(2)}</div>
                              <div className='min_price_quantity'>500+ pcs</div>
                            </div>
                          </div>

                          <div className="div-27">
                            <div className="div-28">Price: </div>
                            <div className="div-29">Negotiable</div>
                          </div>
                          <div className="div-30" />
                          <div className="div-31">
                            <div className="div-32">
                              <div className="div-33">Type: </div>
                              <div className="div-34">Material: </div>
                              <div className="div-35">Design: </div>
                            </div>
                            <div className="div-36">
                              <div className="div-37">ID-Cards</div>
                              <div className="div-38">Plastic Material</div>
                              <div className="div-39">Custom</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column-4">
                        <div className="div-40">
                          <div className="div-41">
                            <div className="div-42">
                              <div className="div-43">R</div>
                              <div className="div-44">
                                <div className="div-45">Raise</div>
                                <div className="div-46">
                                  Product Request
                                </div>
                              </div>
                            </div>
                            <div className="div-47" />
                            <div className="div-48">
                              <div className = "img-15"><BsHandThumbsUp/></div>

                              <div className="div-49">Best Quality</div>
                            </div>
                            <div className="div-50">
                            <div className = "img-16"><MdOutlineVerifiedUser/></div>

                              <div className="div-51">Verified Products</div>
                            </div>
                            <div className="div-52">
                              <div className='img-17'><TbWorldCheck/></div>

                              <div className="div-53">Indiiawide shipping</div>
                            </div>
                            <Link to={`/ID-Cards_Request/${productID}`}  className="div-54">
                              <div className="div-55">Send inquiry</div>
                            </Link>
                         
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-60" />
                  <div className="div-61">
                    <div className="div-62">
                      <div className="div-63">Customization: </div>
                      <div className="div-64">Design: </div>
                      <div className="div-65">Support: </div>
                    </div>
                    <div className="div-66">
                      <div className="div-67">
                        Customized logo and design
                      </div>
                      <div className="div-68">Custom</div>
                      <div className="div-69">Available </div>
                    </div>
                  </div>
                  <div className="div-70" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-71">
          <div className="div-72">
            <div className="column-5">
              <div className="div-73">

                <div className="div-74">
                  <div className="div-75">
                    <div className="div-76">Description</div>
                    
                
                  </div>
                </div>
                <div className="div-81">

                  {product.description}
                </div>
                <div className="div-82">
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-128">
                  <div className='img-20'><TbPointFilled/></div>

                  <div className="div-129">Best Quality</div>
                </div>
                <div className="div-130">
                  <div className = "img-21"><TbPointFilled/></div>

                  <div className="div-131">
                    Durable
                  </div>
                </div>
                <div className="div-132">
                  <div className='img-22'><TbPointFilled/></div>

                  <div className="div-133">
                    Thick Material
                  </div>
                </div>

              </div>
            </div>
            <div className="column-6">
            <div className="div-136">
              

<div className='stationary_description_product-list'>
<div className="div-137">You may like</div>
{school_category_stationary.slice(0, 5).map((product, index) => (
    <Link to={`/Stationary/${product.ProductID}`} className={`stationary_description_product-list-card`} key={product.ProductID}>
      <div className='div-139'>
        <img src={product.ProductImg} alt="not found" className="img-24"/>
      </div>
      <div className='stationary_description_product-list-details'>
        <div className='div-141 right_ellipsis'>
          {product.ProductName}
        </div>
        <div className='div-142'>{product.ProductPrice}</div>
      </div>
    </Link>
  ))}
</div>


            </div>
          </div>
          </div>
        </div>{" "}
        
        <div className='div-151'>
  <div className='div-154'>
  Related Products
  </div>

  <div className='writing_description_divider-line'></div>
  < div className='writing_description_product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='writing_category-card-container' align = 'center' >
      {idcards.slice(0, 6).map((product, index) => (
        
        <Link to={`/ID-Cards/${product.ProductID}`}  className={'writing_category-card'} key={product.ProductID}>
            <div className='writing_category-img'>
              <img src={product.ProductImg} alt="not found" className="writing_category-img_part" />
            </div>
          <div className='writing_category-name bottom_ellipsis'>
            {product.ProductName}
        </div>
        <div className='writing_category-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>

      {/* "Next" button */}

  </div>

</div>
  

<div className="school_landing_div-402">

  
<div className="school_landing_div-409">
  <div className="school_landing_div-410">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Customer services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
      Help Center
      <br />
      Report abuse
      <br />
      File a case
      <br />
      Piolicies & rules
      <br />
      Get paid for your feedback
    </span>
  </div>
  <div className="school_landing_div-411">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      About Us
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      About Custoking.com
      <br />
      About Custoking Group
      <br />
      Sitemap
      <br />
      Custoking.com Blog
    </span>
  </div>
  <div className="school_landing_div-412">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Source on Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Resources
      <br />
      All categories
      <br />
      Request for Quotation
      <br />
      Ready to Ship
      <br />
      Buyer partners
      <br />
      Custoking.com Select
    </span>
  </div>
  <div className="school_landing_div-413">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Partner with Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Supplier memberships
      <br />
      Learning Center
      <br />
      Partner Program
    </span>
  </div>
  <div className="school_landing_div-414">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Trade services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Trade Assuarance
      <br />
      Business identity
      <br />
      Logistics services
      <br />
      Production Monitoring & Inspespection
      <br />
      Services
      <br />
      Letter of Credit
    </span>
  </div>
</div>




<div className="school_landing_div-430">
  Browse Alphabetically Onetouch | Showroom | Country Search | Affliate
</div>
<div className="school_landing_div-431">
  Product Listing Policy-Intellectual Property Protection-Privacy
  Policy-Terms of Use-User Information Legal Enquiry Guide
</div>

</div>

      </div>
       ) : (
        <p>Product not found</p>
      )}
    </>
  );
};

export default ID_Card_Detail;





import generateProductPage from './Generate_Product_Page';
import { Promotional_GiftContext } from './Promotional_Gift_Data';
import { Accessories_Data_Context } from './Accessories_Data';
import { IoCall } from 'react-icons/io5';
import React, { useEffect } from 'react';
import './Product_Test.css';
    import { Helmet } from 'react-helmet';
    import ReactGA from 'react-ga';
    



const AdditionalElements = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
        <>
      <Helmet>
        <title>Accessories</title>
        <meta name="description" content="Get all school accessories here" />
        {/* Add more meta tags as needed */}
      </Helmet>
      {/* Other content of your component */}
    </>
      {/* Add your additional elements here */}
      <h1 className='product_page_heading'>Accessories</h1>
      <div className='product_page_heading_display'>
        <div className='heading_display_left'>
          <img src = "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
        </div>
        <div className='heading_display_cards'>
          <div className='heading_page_card_display_left'>
            <img src= "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
            <p>Product Name 1</p>
          </div>
          <div className='heading_page_card_display'>
            <img src= "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
            <p>Product Name 1</p>
          </div>
          <div className='heading_page_card_display_right'>
            <img src= "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
            <p>Product Name 1</p>
          </div>
        </div>
        <div className='heading_display_right'>
          <div className='heading_display_right_txt'>Italic Best Seller</div>
          <div className='heading_display_right_contact_div'>
          <a className='heading_display_right_contact_us_bg' href="tel:+917478004111">
          <a className='heading_display_right_contact_us'  href="tel:+917478004111">Contact Us</a>
          </a>
          </div>
        </div>
      </div>
      
    </div>
  );
};


const Product_Formatting = generateProductPage(Accessories_Data_Context , 'Accessories', 'accessories_data');
const Accessories = () => {
  return (
    <>
      <AdditionalElements />
      <Product_Formatting />
    </>
  );
};


export default Accessories;




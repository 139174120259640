import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const IDCardsContext = createContext();

export const IDCardsContextProvider = ({ children }) => {
  const [idcards, setIDCards] = useState([]); // Corrected the state variable name

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Stationary/cards/ID_Cards').get();
        const idcardsData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));
        setIDCards(idcardsData); // Corrected the state setter function
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <IDCardsContext.Provider value={{ idcards }}>
      {children}
    </IDCardsContext.Provider>
  );
};

import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase'; // Make sure to import your Firebase configuration

export const BillBooksContext = createContext();

export const BillBooksContextProvider = ({ children }) => {
  const [billbooks, setBillBooks] = useState([]);
  const [error, setError] = useState(null); // Add an error state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Stationary/BillBooks/bill-book').get();
        const billbooksData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));
        setBillBooks(billbooksData);
      } catch (error) {
        console.error('Error fetching Bill Books data:', error);
        setError(error); // Set the error state to the caught error
      }
    };

    fetchData();
  }, []);

  // Check if there's an error and log it
  useEffect(() => {
    if (error) {
      console.error('Bill Books context error:', error);
    }
  }, [error]);

  return (
    <BillBooksContext.Provider value={{ billbooks }}>
      {children}
    </BillBooksContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { useHistory } from 'react-router-dom';
import { db, storage } from './Firebase';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

const productsData = [
  {
    id: 1,
    name: 'School Uniforms',
    description: 'Color: Customized, Material: Cotton/Synthetic',
    seller: 'CustoKing',
    price: 500,
    image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/fa3925f4-91a4-4a2c-bf52-14c9d2635e01?apiKey=db6f3fe714c542fe811ae992d63ea686',
    required: true,
    redirectLink: '/School_Uniform_Details', // Replace with your desired link
  },
  {
    id: 2,
    name: 'School Writing Supplies',
    description: 'Size: Custom, Color: All available, Material: Polyster',
    seller: 'CustoKing',
    price: 45,
    image: 'https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2FA6%20A7%20PU%20Portable%20Pocket%20Elastic%20Belt%20Notebook%20Stationery%20Bullets%20Journal%20Mini%20Leather%20Notebook.jpg?alt=media&token=69b9fb05-c506-401c-9728-990ebad46727',
    required: true,
    redirectLink: '/Writing_Supplies', // Replace with your desired link
  },
  {
    id: 3,
    name: 'School ID Cards',
    description: 'Size: medium, Color: Customized, Material: Plastic/PVC',
    seller: 'CustoKing',
    price: 30,
    image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/3065d3ac-8f3e-449a-8aec-db07e5a5f480?apiKey=db6f3fe714c542fe811ae992d63ea686',
    required: true,
    redirectLink: '/ID-Cards', // Replace with your desired link
  },
  // Add more products as needed
];

function School_Second_Page() {

/*
  useEffect(() => {
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

    // Redirect to the mobile version if on a mobile device
    if (isMobile) {
      window.location.href = '/School_Services'; // Replace with the actual URL for your mobile version
    }
  }, []);*/

  const [products, setProducts] = React.useState(productsData);
  const [customizationCost, setCustomizationCost] = useState(0);
  const history = useHistory();
  const [contactInfo, setContactInfo] = useState('');
  const [contactInfoError, setContactInfoError] = useState(false);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const toggleRequired = (productId) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, required: !product.required } : product
      )
    );
  };
  const validateContactInfo = (value) => {
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return phoneRegex.test(value) || emailRegex.test(value);
  };

  const handleContactInfoChange = (e) => {
    setContactInfo(e.target.value);
    setContactInfoError(!validateContactInfo(e.target.value));
  };


  // Rest of your component code...

  useEffect(() => {
    // Recalculate customization cost when component mounts
    const newCustomizationCost = products
      .filter((product) => product.required)
      .reduce((acc, product) => {
        const productCustomizationCost =
          product.dropdownValue === 'customized' ? 20 * (product.quantity || 1) : 0;
        return acc + productCustomizationCost;
      }, 0);

    setCustomizationCost(newCustomizationCost);
  }, [products]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const totalCost = products
  .filter((product) => product.required)
  .reduce((acc, product) => {
    const quantity = product.quantity || 10; // Use default quantity (10) if not specified
    return acc + product.price * quantity;
  }, 0);
  const totalPrice = totalCost ;
  const handlePlaceOrder = () => {
    // Extract only the required products
    const requiredProducts = products.filter((product) => product.required );
  
    // Create an array of objects with product details
    const orderDetails = requiredProducts.map((product) => ({
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: product.quantity || 10,
      description: product.description,
      image: product.image,





      // Add other relevant product details as needed
    }));
    console.log('Order Details to push:', orderDetails);
    // Use react-router to navigate to the Writing_Supplies_Form page with the order details
    history.push({
      pathname: '/Submit_Request_Form',
      state: { orderDetails: orderDetails },
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate contact information
    if (!validateContactInfo(contactInfo)) {
      setContactInfoError(true);
      return; // Don't proceed with the submission
    }
    setContactInfoError(false);

    // Validate address



    // Save order data only when the file URL is available
    console.log('About to call saveOrderData');
    console.log('About to call saveOrderData Inside');
    saveOrderData();
    window.gtag('event', 'conversion', {'send_to': 'AW-11246127201/mcHGCNDQtPsYEOGQyfIp'});
  };

  const saveOrderData = async () => {
    console.log('saveOrderData function called');
    const ordersRef = db.collection('HELP');

    const orderData = {
    
      Contact: contactInfo,

    };
    console.log('Order Data:', orderData);
    try {
      // Use add to let Firestore generate a unique document ID
      await ordersRef.add(orderData);

      // Reset fields
      setContactInfo('');
    

      // Optionally, you can show a success message to the user
      alert('Submitted successfully!');
    } catch (error) {
      // Handle any errors that may occur during data submission
      console.error('Error submitting:', error);
      alert('Error submitting. Please try again.');
    }
  };
  

  


  return (
    <>
            <>
      {/* Other content of your component */}
      <Helmet>
        <title>Quotation</title>
        <meta name="description" content="Request for Quatation of School Supplies" />
        {/* Add more meta tags as needed */}
      </Helmet>
    </>
      <div className="school_second_page_div">

        <div className="school_second_page_div-2">
          <h1 className="school_second_page_div-3">School Supplies Quotation</h1>
          <div className="school_second_page_div-4">
            <div className="school_second_page_div-5">
              <div className="school_second_page_column">
                <div className="school_second_page_div-6">
                  
                {products.map((product) => (
        <div key={product.id} className="school_second_page_div-7">
          <div className="school_second_page_div-8">
            <div className="school_second_page_div-9">
              <img
                loading="lazy"
                src={product.image}
                className="school_second_page_img-2"
              />
              <div className="school_second_page_div-10">
                <div className="school_second_page_div-11">{product.name}</div>
                <div className="school_second_page_div-12">
                  {product.description}
                  <br />
                  Seller: {product.seller}
                </div>
              </div>
            </div>
            <div className="school_second_page_div-13">
              <div className="school_second_page_div-14">{`₹${product.price}`}</div>
              {product.required ? (
                <div>
                  <div className="school_second_page_quantity">
                  <input
  type="number"
  value={product.quantity === undefined ? 10 : product.quantity}
  onChange={(e) => {
    const quantity = parseInt(e.target.value, 10);
    setProducts((prevProducts) =>
      prevProducts.map((prevProduct) =>
        prevProduct.id === product.id
          ? { ...prevProduct, quantity: isNaN(quantity) ? undefined : Math.max(0, quantity) }
          : prevProduct
      )
    );
  }}
/>

                    <span> pieces</span>
                  </div>

                </div>
              ) : (
                <div className="school_second_page_quantity-disabled">
                  <span> </span>
                </div>
              )}
            </div>
          </div>
          <div className="school_second_page_div-15">
            <div
              className="school_second_page_div-16"
              onClick={() => toggleRequired(product.id)}
              style={{ cursor: 'pointer' }}
            >
              {product.required ? 'Required' : 'Not Required'}
            </div>
            {/* Use Link instead of a tag */}
            <Link to={product.redirectLink} style={{ textDecoration: 'none' }} className="school_second_page_div-17" target="_blank">
              View More
            </Link>
          </div>
        </div>
      ))}

                  <div className="school_second_page_div-40">
                    <Link to = "/" style={{ textDecoration: 'none' }} className="school_second_page_div-41">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/86164800-3776-4321-a0c1-aa07ca75d0e1?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="school_second_page_img-8"
                      />
                      <div className="school_second_page_div-42">Back to home</div>
                    </Link>
            {/*        <div className="school_second_page_div-43" onClick={handlePlaceOrder}>Submit</div> */}
                  </div>
                </div>
              </div>
              <div className="school_second_page_column-2">
                <div className="school_second_page_div-44">
                  <div className="school_second_page_div-45">

                    <div className="school_second_page_div-46">Already a Member</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/8dab5a89-1da5-4e8a-8d79-8ad97192e3fe?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="school_second_page_img-10"
                    />
                  </div>
                  <a className="school_second_page_div-47" href = "#Submit_Details" style={{ textDecoration: 'none' }} >Help?</a>
                  <div className="school_second_page_div-48">

                    <div className="school_second_page_div-49">

                      <div className="school_second_page_div-50">Requested Items</div>
                      <div className="school_second_page_div-51">{products.filter((product) => product.required).length}</div>
                    </div>
                    <div className="school_second_page_div-52">
                      <div className="school_second_page_div-53">
                        <div className="school_second_page_div-54">Approximate Price</div>
                        <div className="school_second_page_div-55">Discount</div>
                      </div>
                      <div className="school_second_page_div-56">
                        <div className="school_second_page_div-57">{`₹${totalCost.toFixed(2)}`}</div>
                        <div className="school_second_page_div-58">{`₹0`}</div>
                      </div>
                    </div>
                    <div className="school_second_page_div-59" />
                    <div className="school_second_page_div-60">
                      <div className="school_second_page_div-61">Sub Total</div>
                      <div className="school_second_page_div-62">{`₹${totalPrice.toFixed(2)}`}</div>
                    </div>
                    <button className="school_second_page_div-63"  onClick={handlePlaceOrder}>Place Order Request</button>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="school_second_page_div-65">
            <div className="school_second_page_div-66">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/de70e22b-b637-4e3a-b5c8-9d268e79824a?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="school_second_page_img-17"
              />
              <div className="school_second_page_div-67">
                <div className="school_second_page_div-68">Secure payment</div>
                <div className="school_second_page_div-69">Have you ever finally just </div>
              </div>
            </div>
            <div className="school_second_page_div-70">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/88ee5fd4-4aec-4890-81bb-bd3a45efed59?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="school_second_page_img-18"
              />
              <div className="school_second_page_div-71">
                <div className="school_second_page_div-72">Customer support</div>
                <div className="school_second_page_div-73">Have you ever finally just </div>
              </div>
            </div>
            <div className="school_second_page_div-74">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/65123d40-1fbd-448f-88fa-55b53110f3a3?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="school_second_page_img-19"
              />
              <div className="school_second_page_div-75">
                <div className="school_second_page_div-76">Free delivery</div>
                <div className="school_second_page_div-77">Have you ever finally just</div>
              </div>
            </div>
          </div>
          <div className="school_second_page_div-78" id  = "Submit_Details">
            <div className="school_second_page_div-79">Explore Our Products</div>
            <div className="school_second_page_div-80">
              <div className="school_second_page_div-81">
                <div className="school_second_page_column-3">
                  <div className="school_second_page_div-82">
                    <div className="school_second_page_div-83">
                    <Link to = '/School_Uniform_Details' style={{ textDecoration: 'none' }} className="school_second_page_div-84">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Uniform%20Display%2FBleached%20White%20School%20Uniform%20Shirt%20for%20Boys.jpg?alt=media&token=a00869ec-2f64-4790-84e2-a1de9a93dee4"
                          className="school_second_page_img-20"
                        />
                      </Link>
                    </div>
                    <div className="school_second_page_div-85">₹380 - ₹800</div>
                    <div className="school_second_page_div-86">Uniforms</div>

                  </div>
                </div>
                <div className="school_second_page_column-4">
                  <div className="school_second_page_div-89">
                    <div className="school_second_page_div-90">
                    <Link to = '/Writing_Supplies' style={{ textDecoration: 'none' }}  className="school_second_page_div-91">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FWriting_Supplies%2FCustom%20Logo%20Undated%20Wide%20Ruled%20Paper%20Office%20Spiral%20Writing%20Notebooks%20Note%20Book%20for%20Student.jpg?alt=media&token=1dc300fe-82d8-4774-af42-378e8c6580fb"
                          className="school_second_page_img-22"
                        />
                      </Link>
                    </div>
                    <div className="school_second_page_div-92">₹30 - ₹450</div>
                    <div className="school_second_page_div-93">Writing Supplies</div>

                  </div>
                </div>
                <div className="school_second_page_column-5">
                  <div className="school_second_page_div-95">
                    <div className="school_second_page_div-90">
                    <Link to = '/Stationary' style={{ textDecoration: 'none' }} className="school_second_page_div-91">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Stationary%20Display%2FExplore%20our%20wholesale%20Italic%20stationery%20collection%20featuring%20new%20promotional%20sets%20of%20sticky%20notes%20and%20memo%20pads.jpg?alt=media&token=eea67469-2a77-4f25-86d2-2ae1e62972ec"
                          className="school_second_page_img-22"
                        />
                      </Link>
                    </div>
                    <div className="school_second_page_div-98">₹5 - ₹100</div>
                    <div className="school_second_page_div-99">Stationary </div>

                  </div>
                </div>
                <div className="school_second_page_column-6">
                  <div className="school_second_page_div-102">
                    <div className="school_second_page_div-90">
                    <Link to = '/Accessories' style={{ textDecoration: 'none' }} className="school_second_page_div-91">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Category%20Acessories%20Display%2Fcustomise-school-bag-for-all-classes-.jpg?alt=media&token=87e4ba5d-6641-4b1d-9146-c4b1cbb9975b"
                          className="school_second_page_img-22"
                        />
                      </Link>
                    </div>
                    <div className="school_second_page_div-105">₹40 - ₹450</div>
                    <div className="school_second_page_div-106">Accessories</div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
          <div className='school_second_page_contact_us'>
            <div className='school_second_page_contact_us_message'>
              Submit Contact Details
            </div>
            <div className='school_second_page_contact_us_subheading'>
              Our executives will contact you
            </div>
            <div className="school_second_page_contact_us_input">
              <input
                type="text"
                id="contactInfo"
                value={contactInfo}
                onChange={handleContactInfoChange}
                className={contactInfoError ? 'error' : ''}
                placeholder="Phone Number or Email (Required)"
                required
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '5px',
                  border: contactInfoError ? '2px solid red' : '1px solid #ccc',
                  padding: '5px',
                  outline: 'none',
                }}
              />
              {contactInfoError && (
                <span
                  style={{
                    marginLeft: '5px',
                    color: 'red',
                    height: '10px',
                    width: '10px',
                  }}
                  role="img"
                  aria-label="info"
                >
                  <HiOutlineInformationCircle />
                </span>
              )}
            </div>
            <button type="submit" className="school_second_page_contact_us_submit" >
              Submit
            </button>
          </div>
          </form>


        </div>




        <div className="school_landing_div-402">

  
          <div className="school_landing_div-409">
            <div className="school_landing_div-410">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                Customer services
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
                Help Center
                <br />
                Report abuse
                <br />
                File a case
                <br />
                Piolicies & rules
                <br />
                Get paid for your feedback
              </span>
            </div>
            <div className="school_landing_div-411">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                About Us
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                About Custoking.com
                <br />
                About Custoking Group
                <br />
                Sitemap
                <br />
                Custoking.com Blog
              </span>
            </div>
            <div className="school_landing_div-412">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                Source on Custoking.com
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                Resources
                <br />
                All categories
                <br />
                Request for Quotation
                <br />
                Ready to Ship
                <br />
                Buyer partners
                <br />
                Custoking.com Select
              </span>
            </div>
            <div className="school_landing_div-413">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                Partner with Custoking.com
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                Supplier memberships
                <br />
                Learning Center
                <br />
                Partner Program
              </span>
            </div>
            <div className="school_landing_div-414">
              <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
                Trade services
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                <br />
              </span>
              <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
                Trade Assuarance
                <br />
                Business identity
                <br />
                Logistics services
                <br />
                Production Monitoring & Inspespection
                <br />
                Services
                <br />
                Letter of Credit
              </span>
            </div>
          </div>
       
      


          <div className="school_landing_div-430">
            Browse Alphabetically Onetouch | Showroom | Country Search | Affliate
          </div>
          <div className="school_landing_div-431">
            Product Listing Policy-Intellectual Property Protection-Privacy
            Policy-Terms of Use-User Information Legal Enquiry Guide
          </div>
    
        </div>






        
      </div>
      <style jsx>{`
        .school_second_page_div {
          background-color: var(--gray-100, #f7fafc);
          display: flex;
          flex-direction: column;
        }
        .school_landing_div-402 {
          border: 1px solid #fff;
          background-color: #445268;
          align-self: start;
          display: flex;
          margin-top: 31px;
          width: 100%;
          flex-direction: column;
          padding: 50px 29px 34px 80px;
        }
        @media (max-width: 991px) {
          .school_landing_div-402 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .school_landing_div-403 {
          color: #fff;
          letter-spacing: 0.8px;
          align-self: center;
          margin-top: 6px;
          white-space: nowrap;
          font: 400 16px/62.5% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-403 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-404 {
          align-self: center;
          display: flex;
          margin-top: 16px;
          width: 436px;
          max-width: 100%;
          align-items: flex-start;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_landing_div-404 {
            flex-wrap: wrap;
          }
        }
        .school_landing_div-405 {
          align-self: start;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
          width: 300px;
        }
        .school_landing_div-406 {
          color: #adb1b9;
          letter-spacing: 0.9px;
          align-self: stretch;
          white-space: nowrap;
          border: 1px solid rgba(255, 255, 255, 0.6);
          background-color: #313d51;
          width: 100%;
          padding: 14px 80px 14px 10px;
          font: 400 18px/56% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-406 {
            white-space: initial;
            padding-right: 20px;
          }
        }
        .school_landing_div-407 {
          color: #adb1b9;
          letter-spacing: 0.575px;
          align-self: stretch;
          margin-top: 9px;
          white-space: nowrap;
          font: 400 11.5px/87% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-407 {
            white-space: initial;
          }
        }
        .school_landing_div-408 {
          color: #fff;
          letter-spacing: 0.7px;
          align-self: start;
          white-space: nowrap;
          border: 1px solid rgba(255, 255, 255, 0.6);
          background-color: #313d51;
          width: 116px;
          max-width: 100%;
          padding: 15px 26px 15px 24px;
          font: 400 14px/71% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-408 {
            white-space: initial;
            padding: 0 20px;
          }
        }
        .school_landing_div-409 {
          align-self: center;
          display: flex;
          margin-top: 32px;
          width: 100%;
          max-width: 1351px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_landing_div-409 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_landing_div-410 {
          color: #fff;
          letter-spacing: 0.9px;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-411 {
          color: #fff;
          letter-spacing: 0.9px;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-412 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: start;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-413 {
          color: #fff;
          letter-spacing: 0.9px;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-414 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: start;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-415 {
          background-color: #364358;
          align-self: center;
          margin-top: 37px;
          width: 1220px;
          height: 1px;
        }
        @media (max-width: 991px) {
          .school_landing_div-415 {
            max-width: 100%;
          }
        }
        .school_landing_div-416 {
          align-self: center;
          display: flex;
          margin-top: 18px;
          width: 100%;
          max-width: 1166px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_landing_div-416 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .school_landing_div-417 {
          align-self: start;
          display: flex;
          margin-top: 4px;
          align-items: flex-start;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .school_landing_div-417 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_landing_div-418 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin: auto 0;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-419 {
          justify-content: space-between;
          align-items: flex-start;
          border-radius: 5px;
          background-color: #999;
          align-self: stretch;
          display: flex;
          width: 190px;
          max-width: 100%;
          gap: 10px;
          padding: 5px 15px 5px 5px;
        }
        .school_landing_img-140 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 35px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_landing_div-420 {
          color: #fff;
          letter-spacing: 0.8px;
          font: 700 16px/18px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-421 {
          justify-content: space-between;
          align-items: flex-start;
          border-radius: 5px;
          background-color: #a4c639;
          align-self: stretch;
          display: flex;
          width: 185px;
          max-width: 100%;
          gap: 5px;
          padding: 5px 15px 5px 5px;
        }
        .school_landing_img-141 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 35px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_landing_div-422 {
          color: #fff;
          letter-spacing: 0.8px;
          font: 700 16px/18px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-423 {
          align-self: start;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 4px;
        }
        @media (max-width: 991px) {
          .school_landing_div-423 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_landing_div-424 {
          color: #fff;
          letter-spacing: 0.9px;
          margin: auto 0;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_img-142 {
          aspect-ratio: 0.92;
          object-fit: contain;
          object-position: center;
          width: 55px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_landing_div-425 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin: auto 0;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_img-143 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_img-144 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_img-145 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_img-146 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_img-147 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_div-426 {
          align-self: center;
          display: flex;
          margin-top: 46px;
          width: 100%;
          max-width: 1057px;
          align-items: flex-start;
          gap: 18px;
        }
        @media (max-width: 991px) {
          .school_landing_div-426 {
            max-width: 100%;
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .school_landing_img-148 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(222, 196, 196, 0);
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .school_landing_div-427 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: start;
          white-space: nowrap;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-427 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-428 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: end;
          white-space: nowrap;
          margin: 4px 30px 0 0;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-428 {
            max-width: 100%;
            margin-right: 10px;
            white-space: initial;
          }
        }
        .school_landing_div-429 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin-left: 52px;
          white-space: nowrap;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-429 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-430 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin-top: 9px;
          white-space: nowrap;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-430 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-431 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin-top: 16px;
          white-space: nowrap;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-431 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-432 {
          align-self: center;
          display: flex;
          margin-top: 29px;
          width: 805px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_landing_div-432 {
            flex-wrap: wrap;
          }
        }
        .school_second_page_img {
          aspect-ratio: 16.74;
          object-fit: contain;
          object-position: center;
          width: 100%;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .school_second_page_img {
            max-width: 100%;
          }
        }
        .school_second_page_contact_us {
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          background-color: #fff;
          align-self: stretch;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width:400px;
          margin: 30px auto;
          padding: 25px 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_contact_us {
            max-width: 100%;
          }
        }
        .school_second_page_contact_us_message {
          color: #292626;
          align-self: start;
        
          display: flex;
          
          font: 700 26px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_contact_us_message {
            white-space: initial;
          }
        }
        .school_second_page_contact_us_submit {
          color: #fff;
          letter-spacing: 0.7px;
          align-self: center;
          white-space: nowrap;
          border-radius: 10px;
          background-color: #0175fe;
          margin-top: 20px;
        
          width: 100%;
          padding: 15px;
          text-align: center;
          font: 700 14px/71% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_contact_us_submit {
            white-space: initial;
            padding: 20px 20px;
          }
        }
        .school_second_page_contact_us_subheading
        {
          margin-top: 5px;
          font: 500 15px/102% Roboto, -apple-system, Roboto, Helvetica,
          sans-serif;
        }
        .school_second_page_contact_us_input {
          color: #c6c4cb;
          letter-spacing: 0.9px;
          align-self: center;
          white-space: nowrap;
          border-radius: 5px;

          margin-top: 27px;
          width: 451px;
          max-width: 100%;
    
          height: 40px;
          font: 500 18px/56% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_contact_us_input {
            white-space: initial;
            padding-right: 20px;
          }
        }
        .school_second_page_div-2 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 1180px;
          flex-direction: column;
          margin: 34px 0 40px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-2 {
            max-width: 100%;
            margin-bottom: 40px;
          }
        }
        .school_second_page_div-3 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-right: -20px;
          white-space: nowrap;
          font: 600 24px/133% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-3 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_second_page_div-4 {
          align-self: stretch;
          margin: 25px -20px 0 0;
        }
        @media (max-width: 991px) {
          .school_second_page_div-4 {
            max-width: 100%;
          }
        }
        .school_second_page_div-5 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_second_page_div-5 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_second_page_column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 76%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_second_page_column {
            width: 100%;
          }
        }
        .school_second_page_div-6 {
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          background-color: var(--base-color-white, #fff);
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 22px 18px 22px 19px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-6 {
            max-width: 100%;
            margin-top: 20px;
          }
        }
        .school_second_page_div-7 {
          border-bottom: 1px solid var(--gray-300, #dee2e7);
          align-self: stretch;
          display: flex;
          padding-bottom: 20px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_second_page_div-7 {
            max-width: 100%;
          }
        }
        .school_second_page_div-8 {
          align-self: start;
          display: flex;
          width: 843px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-8 {
            flex-wrap: wrap;
          }
        }
        .school_second_page_div-9 {
          align-self: start;
          display: flex;
          align-items: flex-start;
          gap: 10px;
        }
        .school_second_page_img-2 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 80px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .school_second_page_div-10 {
          align-self: start;
          display: flex;
          margin-top: 5px;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
        }
        .school_second_page_div-11 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          font: 500 16px/137.5% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-12 {
          color: var(--gray-500, #8b96a5);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-top: 9px;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-13 {
          align-self: start;
          display: flex;
          margin-top: 10px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_second_page_div-13 {
            flex-direction:row;
            width:100%;
            gap:150px;

          }
        }
        .school_second_page_div-14 {
          color: var(--base-color-dark, #1c1c1c);
          text-align: right;
          font-feature-settings: "clig" off, "liga" off;
          align-self: end;
          white-space: nowrap;
          font: 500 16px/137.5% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-14 {
            white-space: initial;
            align-self:start;
          }
        }
        .school_second_page_img-3 {
          aspect-ratio: 3.08;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
          overflow: hidden;
          align-self: end;
          margin-top: 16px;
          flex-grow: 1;
        }
        .school_second_page_div-15 {
          align-self: start;
          display: flex;
          width: 177px;
          max-width: 100%;
          padding-right: 1px;
          align-items: flex-start;
          gap: 14px;
          margin: 6px 0 0 88px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-15 {
            margin-left: 10px;
            width:100%;
            margin:10px 0 0 0 ;
          }
        }
        .school_second_page_div-16 {
          color: #fa3434;
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
          background-color: var(--white, #fff);
          flex: 1;
          padding: 7px 10px;
          font: 500 13px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-16 {
            white-space: initial;
          }
        }
        .school_second_page_div-17 {
          color: var(--primary, #0d6efd);
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
          background-color: var(--white, #fff);
          flex: 1;
          padding: 7px 10px;
          font: 500 13px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-17 {
            white-space: initial;
          }
        }
        .school_second_page_div-18 {
          border-bottom: 1px solid var(--gray-300, #dee2e7);
          align-self: stretch;
          display: flex;
          margin-top: 20px;
          padding-bottom: 20px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_second_page_div-18 {
            max-width: 100%;
          }
        }
        .school_second_page_div-19 {
          align-self: start;
          display: flex;
          width: 843px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-19 {
            flex-wrap: wrap;
          }
        }
        .school_second_page_div-20 {
          align-self: start;
          display: flex;
          align-items: flex-start;
          gap: 10px;
        }
        .school_second_page_img-4 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 80px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_second_page_div-21 {
          align-self: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
        }
        .school_second_page_div-22 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          font: 500 16px/137.5% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-23 {
          color: var(--gray-500, #8b96a5);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-top: 9px;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-24 {
          align-self: start;
          display: flex;
          margin-top: 10px;
          flex-direction: column;
        }
        .school_second_page_div-25 {
          color: var(--base-color-dark, #1c1c1c);
          text-align: right;
          font-feature-settings: "clig" off, "liga" off;
          align-self: end;
          white-space: nowrap;
          font: 500 16px/137.5% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-25 {
            white-space: initial;
          }
        }
        .school_second_page_img-5 {
          aspect-ratio: 3.08;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
          overflow: hidden;
          align-self: end;
          margin-top: 16px;
          flex-grow: 1;
        }
        .school_second_page_div-26 {
          align-self: start;
          display: flex;
          width: 177px;
          max-width: 100%;
          padding-right: 1px;
          align-items: flex-start;
          gap: 14px;
          margin: 6px 0 0 88px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-26 {
            margin-left: 10px;
          }
        }
        .school_second_page_div-27 {
          color: #fa3434;
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
          background-color: var(--white, #fff);
          flex: 1;
          padding: 7px 10px;
          font: 500 13px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-27 {
            white-space: initial;
          }
        }
        .school_second_page_div-28 {
          color: var(--primary, #0d6efd);
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
          background-color: var(--white, #fff);
          flex: 1;
          padding: 7px 10px;
          font: 500 13px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-28 {
            white-space: initial;
          }
        }
        .school_second_page_div-29 {
          border-bottom: 1px solid var(--gray-300, #dee2e7);
          align-self: stretch;
          display: flex;
          margin-top: 20px;
          padding-bottom: 20px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_second_page_div-29 {
            max-width: 100%;
          }
        }
        .school_second_page_div-30 {
          align-self: start;
          display: flex;
          width: 843px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-30 {
            flex-wrap: wrap;
          }
        }
        .school_second_page_div-31 {
          align-self: start;
          display: flex;
          align-items: flex-start;
          gap: 10px;
        }
        .school_second_page_img-6 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 80px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .school_second_page_div-32 {
          align-self: start;
          display: flex;
          margin-top: 5px;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
        }
        .school_second_page_div-33 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          font: 500 16px/137.5% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-34 {
          color: var(--gray-500, #8b96a5);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-top: 9px;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-35 {
          align-self: start;
          display: flex;
          margin-top: 10px;
          flex-direction: column;
        }
        .school_second_page_div-36 {
          color: var(--base-color-dark, #1c1c1c);
          text-align: right;
          font-feature-settings: "clig" off, "liga" off;
          align-self: end;
          white-space: nowrap;
          font: 500 16px/137.5% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-36 {
            white-space: initial;
          }
        }
        .school_second_page_img-7 {
          aspect-ratio: 3.08;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
          overflow: hidden;
          align-self: end;
          margin-top: 16px;
          flex-grow: 1;
        }
        .school_second_page_div-37 {
          align-self: start;
          display: flex;
          width: 177px;
          max-width: 100%;
          padding-right: 1px;
          align-items: flex-start;
          gap: 14px;
          margin: 6px 0 0 88px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-37 {
            margin-left: 10px;
          }
        }
        .school_second_page_div-38 {
          color: #fa3434;
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
          background-color: var(--white, #fff);
          flex: 1;
          padding: 7px 10px;
          font: 500 13px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-38 {
            white-space: initial;
          }
        }
        .school_second_page_div-39 {
          color: var(--primary, #0d6efd);
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
          background-color: var(--white, #fff);
          flex: 1;
          padding: 7px 10px;
          font: 500 13px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-39 {
            white-space: initial;
          }
        }
        .school_second_page_div-40 {
          align-self: stretch;
          display: flex;
          margin-top: 20px;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-40 {
            flex-wrap: wrap;
          }
        }
        .school_second_page_div-41 {
          align-items: flex-start;
          border-radius: 6px;
          background: linear-gradient(180deg, #127fff 0%, #0067ff 100%);
          align-self: stretch;
          display: flex;
          width: 159px;
          max-width: 100%;
          justify-content: space-between;
          gap: 14px;
          padding: 9px 14px 9px 9px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-41 {
           width:170px;
          }
        }
        .school_second_page_img-8 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 22px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .school_second_page_div-42 {
          color: var(--white, #fff);
          font-feature-settings: "clig" off, "liga" off;
          align-self: start;
          white-space: nowrap;
          font: 500 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-42 {
            white-space: initial;
          }
        }
        .school_second_page_div-43 {
          color: var(--primary, #0d6efd);
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          background-color: var(--white, #fff);
          width: 110px;
          max-width: 100%;
          padding: 11px 28px;
          font: 500 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-43 {
            white-space: initial;
            padding: 0 20px;
          }
        }
        .school_second_page_column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 24%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_column-2 {
            width: 100%;
            margin-left:0;
          }
        }
        .school_second_page_div-44 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_second_page_div-44 {
            margin-top: 20px;
          }
        }
        .school_second_page_div-45 {
          disply: flex;
          flex-direction: column;
          fill: #fff;
          stroke-width: 1px;
          stroke: var(--gray-300, #dee2e7);
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          aspect-ratio: 2.5454545454545454;
          width: 100%;
          padding: 23px 16px;
          background:white;
        }
        .school_second_page_img-9 {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .school_second_page_div-46 {
          position: relative;
          color: var(--gray-600, #505050);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          font: 400 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-46 {
            white-space: initial;
          }
        }
        .school_second_page_img-10 {
          aspect-ratio: 6.2;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 10px;
        }
        .school_second_page_div-47 {
          color: var(--white, #fff);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          border-radius: 0.5rem;
          background-color: #0175fe;
          margin-top: 19px;
          width: 100%;
          padding: 10px;
          font: 600 16px Inter, sans-serif;
          text-align: center;
        }
        .school_second_page_div-48 {
          disply: flex;
          flex-direction: column;
          fill: #fff;
          stroke-width: 1px;
          stroke: var(--gray-300, #dee2e7);
          filter: drop-shadow(0px 4px 10px rgba(56, 56, 56, 0.1));
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          aspect-ratio: 0.9271523178807947;
          margin-top: 29px;
          flex-grow: 1;
          width: 100%;
          padding: 24px 16px;
          background:white;
        }
        .school_second_page_img-11 {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .school_second_page_div-49 {
          position: relative;
          align-self: stretch;
          display: flex;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        .school_second_page_div-50 {
          color: var(--gray-600, #505050);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-51 {
          color: var(--gray-600, #505050);
          text-align: right;
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: start;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-51 {
            white-space: initial;
          }
        }
        .school_second_page_div-52 {
          position: relative;
          align-self: stretch;
          display: flex;
          margin-top: 13px;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        .school_second_page_div-53 {
          align-self: stretch;
          display: flex;
          flex-direction: column;
        }
        .school_second_page_div-54 {
          color: var(--gray-600, #505050);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-54 {
            white-space: initial;
          }
        }
        .school_second_page_div-55 {
          color: var(--gray-600, #505050);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-top: 6px;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-55 {
            white-space: initial;
          }
        }
        .school_second_page_div-56 {
          align-self: stretch;
          display: flex;
          flex-direction: column;
        }
        .school_second_page_div-57 {
          color: #fa3434;
          text-align: right;
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-57 {
            white-space: initial;
          }
        }
        .school_second_page_div-58 {
          color: #00b517;
          text-align: right;
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-top: 7px;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-58 {
            white-space: initial;
          }
        }
        .school_second_page_div-59 {
          position: relative;
          background-color: #e4e4e4;
          align-self: stretch;
          display: flex;
          margin-top: 22px;
          width: 100%;
          height: 1px;
          flex-direction: column;
        }
        .school_second_page_div-60 {
          position: relative;
          align-self: stretch;
          display: flex;
          margin-top: 22px;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        .school_second_page_div-61 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          align-self: start;
          margin-top: 4px;
          font: 600 16px Inter, sans-serif;
        }
        .school_second_page_div-62 {
          color: var(--dark, #1c1c1c);
          text-align: right;
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: start;
          white-space: nowrap;
          font: 600 20px/140% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-62 {
            white-space: initial;
          }
        }
        .school_second_page_div-63 {
          position: relative;
          color: var(--white, #fff);
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: var(--green, #00b517);
          margin-top: 27px;
          width: 100%;
          padding: 16px 35px 16px 36px;
          font: 500 18px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-63 {
            white-space: initial;
            padding: 20px 20px;
          }
        }
        .school_second_page_div-64 {
          position: relative;
          align-self: center;
          display: flex;
          margin-top: 18px;
          width: 202px;
          max-width: 100%;
          align-items: flex-start;
          gap: 8px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-64 {
            justify-content: center;
          }
        }
        .school_second_page_img-12 {
          aspect-ratio: 1.55;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .school_second_page_img-13 {
          aspect-ratio: 1.55;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .school_second_page_img-14 {
          aspect-ratio: 1.55;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .school_second_page_img-15 {
          aspect-ratio: 1.55;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .school_second_page_img-16 {
          aspect-ratio: 1.55;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .school_second_page_div-65 {
          align-self: center;
          display: flex;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          margin: 29px 0 0 -20px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-65 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_second_page_div-66 {
          align-self: stretch;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 10px;
        }
        .school_second_page_img-17 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 48px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_second_page_div-67 {
          align-self: center;
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        .school_second_page_div-68 {
          color: var(--base-color-dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-69 {
          color: var(--base-color-gray-400, #a9acb0);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-69 {
            white-space: initial;
          }
        }
        .school_second_page_div-70 {
          align-self: stretch;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 10px;
        }
        .school_second_page_img-18 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 48px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_second_page_div-71 {
          align-self: center;
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        .school_second_page_div-72 {
          color: var(--base-color-dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-73 {
          color: var(--base-color-gray-400, #a9acb0);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-73 {
            white-space: initial;
          }
        }
        .school_second_page_div-74 {
          align-self: stretch;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 10px;
        }
        .school_second_page_img-19 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 48px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_second_page_div-75 {
          align-self: center;
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        .school_second_page_div-76 {
          color: var(--base-color-dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_second_page_div-77 {
          color: var(--base-color-gray-400, #a9acb0);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-77 {
            white-space: initial;
          }
        }
        .school_second_page_div-78 {
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          background-color: #fff;
          align-self: stretch;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          margin: 30px -20px 0 0;
          padding: 25px 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-78 {
            max-width: 100%;
          }
        }
        .school_second_page_div-79 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          white-space: nowrap;
          font: 600 20px/140% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-79 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_second_page_div-80 {
          align-self: stretch;
          margin-top: 25px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-80 {
            max-width: 100%;
          }
        }
        .school_second_page_div-81 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_second_page_div-81 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_second_page_column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_second_page_column-3 {
            width: 100%;
          }
        }
        .school_second_page_div-82 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_second_page_div-82 {
            margin-top: 20px;
          }
        }
        .school_second_page_div-83 {
          border-radius: 6px;
          background-color: #eee;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 8px 23px 8px 22px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-83 {
            padding: 0 20px;
          }
        }
        .school_second_page_div-84 {
          justify-content: center;
          align-items: center;
          mix-blend-mode: multiply;
          background-color: #fff;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 16px 16px 16px 15px;
        }
        .school_second_page_img-20 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        .school_second_page_div-85 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          margin-top: 13px;
          white-space: nowrap;
          font: 600 18px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-85 {
            white-space: initial;
            text-align:center;
            align-self:center;
          }
        }
        .school_second_page_div-86 {
          color: var(--base-color-gray-800, #606060);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-top: 10px;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-86 {
            white-space: initial;
            text-align:center;
            align-self:center;
          }
        }
        .school_second_page_div-87 {
          align-items: flex-start;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          background-color: var(--white, #fff);
          align-self: stretch;
          display: flex;
          margin-top: 42px;
          width: 100%;
          justify-content: space-between;
          gap: 10px;
          padding: 9px 14px 9px 9px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-87 {
            margin-top: 40px;
          }
        }
        .school_second_page_img-21 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 22px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .school_second_page_div-88 {
          color: var(--primary, #0d6efd);
          font-feature-settings: "clig" off, "liga" off;
          align-self: start;
          white-space: nowrap;
          font: 500 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-88 {
            white-space: initial;
          }
        }
        .school_second_page_column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_column-4 {
            width: 100%;
            margin-left:0;
          }
        }
        .school_second_page_div-89 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_second_page_div-89 {
            margin-top: 20px;
          }
        }
        .school_second_page_div-90 {
          border-radius: 6px;
          background-color: #eee;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 8px 23px 8px 22px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-90 {
            padding: 0 20px;
          }
        }
        .school_second_page_div-91 {
          justify-content: center;
          align-items: center;
          mix-blend-mode: multiply;
          background-color: #fff;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 14px 8px;
        }
        .school_second_page_img-22 {
          aspect-ratio: 1.06;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        .school_second_page_div-92 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          margin-top: 13px;
          white-space: nowrap;
          font: 600 18px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-92 {
            white-space: initial;
            text-align:center;
          }
        }
        .school_second_page_div-93 {
          color: var(--base-color-gray-800, #606060);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-top: 10px;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-93 {
            white-space: initial;
            text-align:center;
          }
        }
        .school_second_page_div-94 {
          color: var(--primary, #0d6efd);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          white-space: nowrap;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          background-color: var(--white, #fff);
          margin-top: 39px;
          width: 100%;
          flex-grow: 1;
          padding: 11px 0 11px 23px;
          font: 500 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-94 {
            white-space: initial;
            padding-left: 20px;
          }
        }
        .school_second_page_column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_column-5 {
            width: 100%;
            margin-left:0;
          }
        }
        .school_second_page_div-95 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_second_page_div-95 {
            margin-top: 20px;
          }
        }
        .school_second_page_div-96 {
          border-radius: 6px;
          background-color: #eee;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 8px 23px 8px 22px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-96 {
            padding: 0 20px;
          }
        }
        .school_second_page_div-97 {
          justify-content: center;
          align-items: center;
          mix-blend-mode: multiply;
          background-color: #fff;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 15px 31px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-97 {
            padding: 0 20px;
          }
        }
        .school_second_page_img-23 {
          aspect-ratio: 0.84;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        .school_second_page_div-98 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          margin-top: 13px;
          white-space: nowrap;
          font: 600 18px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-98 {
            white-space: initial;
            text-align:center;
          }
        }
        .school_second_page_div-99 {
          color: var(--base-color-gray-800, #606060);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-top: 10px;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-99 {
            white-space: initial;
            text-align:center;
          }
        }
        .school_second_page_div-100 {
          align-items: flex-start;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          background-color: var(--white, #fff);
          align-self: stretch;
          display: flex;
          margin-top: auto;
          width: 100%;
          justify-content: space-between;
          gap: 10px;
          padding: 9px 14px 9px 9px;
        }
        .school_second_page_img-24 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 22px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .school_second_page_div-101 {
          color: var(--primary, #0d6efd);
          font-feature-settings: "clig" off, "liga" off;
          align-self: start;
          white-space: nowrap;
          font: 500 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-101 {
            white-space: initial;
          }
        }
        .school_second_page_column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_column-6 {
            width: 100%;
            margin-left:0;
          }
        }
        .school_second_page_div-102 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_second_page_div-102 {
            margin-top: 20px;
          }
        }
        .school_second_page_div-103 {
          border-radius: 6px;
          background-color: #eee;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 8px 23px 8px 22px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-103 {
            padding: 0 20px;
          }
        }
        .school_second_page_div-104 {
          justify-content: center;
          align-items: center;
          mix-blend-mode: multiply;
          background-color: #fff;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 33px 11px;
        }
        .school_second_page_img-25 {
          aspect-ratio: 1.27;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        .school_second_page_div-105 {
          color: var(--dark, #1c1c1c);
          font-feature-settings: "clig" off, "liga" off;
          align-self: stretch;
          margin-top: 13px;
          white-space: nowrap;
          font: 600 18px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-105 {
            white-space: initial;
            text-align:center;
          }
        }
        .school_second_page_div-106 {
          color: var(--base-color-gray-800, #606060);
          font-feature-settings: "clig" off, "liga" off;
          letter-spacing: -0.2px;
          align-self: stretch;
          margin-top: 10px;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-106 {
            white-space: initial;
            text-align:center;
          }
        }
        .school_second_page_div-107 {
          align-items: flex-start;
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          background-color: var(--white, #fff);
          align-self: stretch;
          display: flex;
          margin-top: 42px;
          width: 100%;
          justify-content: space-between;
          gap: 10px;
          padding: 9px 14px 9px 9px;
        }
        @media (max-width: 991px) {
          .school_second_page_div-107 {
            margin-top: 40px;
          }
        }
        .school_second_page_img-26 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 22px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .school_second_page_div-108 {
          color: var(--primary, #0d6efd);
          font-feature-settings: "clig" off, "liga" off;
          align-self: start;
          white-space: nowrap;
          font: 500 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_div-108 {
            white-space: initial;
          }
        }
      `}</style>
    </>
  );
}

export default School_Second_Page;

// EnvelopsContext.js
import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const LinenContext = createContext();

export const LinenContextProvider = ({ children }) => {
  const [linen, setLinen] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Product/Products/Linen').get();
        const linenData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));
        setLinen(linenData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <LinenContext.Provider value={{ linen }}>
      {children}
    </LinenContext.Provider>
  );
};

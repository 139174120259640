

import { db, storage } from './Firebase';
import React, { useContext, useState , useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { PiNumberCircleOneBold, PiNumberCircleTwoBold, PiNumberCircleThreeBold, PiNumberCircleFourBold } from 'react-icons/pi';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import {GiCheckMark} from 'react-icons/gi';
import { AiFillStar, AiOutlineStar,AiOutlineHeart } from 'react-icons/ai';
import {MdOutlineVerifiedUser} from 'react-icons/md';
import {BsHandThumbsUp} from 'react-icons/bs';
import {TbWorldCheck,TbPointFilled} from 'react-icons/tb';

import { Link } from "react-router-dom";
import {BsChevronRight} from "react-icons/bs";
import { IoCall } from 'react-icons/io5';

import { School_Category_UniformContext } from './School_Uniform_Display_Data';
import { T_Shirt_Display_Context } from './t_shirt_display_data';


import './AI_Test.css';

const Uniform_Detail = () => {
  let { productID } = useParams();
  const [product, setProduct] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [orderQuantity, setOrderQuantity] = useState(1); // default quantity is 1
  const [orderAmount, setOrderAmount] = useState(0);
  const [rating, setRating] = useState(null);

  const {school_category_uniform} = useContext(School_Category_UniformContext);
  const {t_shirt_display} = useContext(T_Shirt_Display_Context);
  
const generateStars = (rating) => {
  const fullStars = Math.floor(rating);
  const decimalPart = rating - fullStars;
  const starArray = [];

  for (let i = 0; i < fullStars; i++) {
    starArray.push(<AiFillStar key={i} className="star" />);
  }

  if (decimalPart > 0) {
    if (decimalPart >= 0.75) {
      starArray.push(<AiFillStar key="full-star" className="star" />);
    } else if (decimalPart >= 0.25) {
      starArray.push(<AiOutlineStar key="half-star" className="star" />);
    }
  }

  return starArray;
};

  useEffect(() => {
    const productRef = db.collection('Uniforms/School/School_Uniforms').doc(productID);

    productRef.get().then((doc) => {
      if (doc.exists) {
        setProduct(doc.data());
      } else {
        setProduct(null);
      }
    });
  }, [productID]);

  const handlePhoneNumberChange = (e) => {
    // Allow only numeric input
    const input = e.target.value.replace(/\D/g, '');

    // Restrict the length to 10 characters
    const limitedInput = input.slice(0, 10);

    setPhoneNumber(limitedInput);

    if (limitedInput.length !== 10) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  const handleFileChange = (e) => {
    if (e && e.target) {
      const file = e.target.files[0];
      setSelectedFile(file);

      // Automatically upload the file to Firebase Storage
      uploadFile(file);
    }
  };

  const uploadFile = (file) => {
    const storageRef = storage.ref(`/Order Uploads/${file.name}`);
    const uploadTask = storageRef.put(file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Progress
      },
      (error) => {
        // Error handling
        console.error(error);
        // Add code to inform the user about the error
      },
      () => {
        // Success, get file URL
        storageRef.getDownloadURL().then((url) => {
          setFileURL(url);
        });
      }
    );
  };

  const handleFileOpen = () => {
    // Trigger download when the user clicks on the uploaded file name
    if (fileURL) {

      window.open(fileURL, '_blank');
    }
  };

  const handleQuantityChange = (e) => {
    const quantity = parseInt(e.target.value, 10) || 1; // ensure it's a number, default to 1
    setOrderQuantity(quantity);
  };

  const handleIncrement = () => {
    setOrderQuantity(orderQuantity + 1);
  };

  const handleDecrement = () => {
    if (orderQuantity > 1) {
      setOrderQuantity(orderQuantity - 1);
    }
  };

  const calculateOrderAmount = (priceMatches) => {
    if (!priceMatches) return { original: 0, lower: 0, lowest: 0 };
  
    if (priceMatches.length === 1) {
      const originalPrice = parseFloat(priceMatches[0].replace(/[₹,]/g, ''));
      const lowerPrice = originalPrice * 0.93; // 7% lower
      const lowestPrice = originalPrice * 0.85; // 15% lower
      return { original: originalPrice, lower: lowerPrice, lowest: lowestPrice };
    }
  
    const [minPrice, maxPrice] = priceMatches.map((price) =>
      parseFloat(price.replace(/[₹,]/g, ''))
    );
  
    const originalPrice = maxPrice;
    const lowerPrice = (minPrice + maxPrice) / 2;
    const lowestPrice = minPrice;
  
    return { original: originalPrice, lower: lowerPrice, lowest: lowestPrice };
  };
  
  

  // Extract price from the product price string
  const priceMatches = product?.price?.match(/₹[\d,.-]+/g);
  const { original, lower, lowest } = calculateOrderAmount(priceMatches);
  useEffect(() => {
    // Calculate the order amount based on quantity and extracted price
    if (rating === null) {
      const newRating = Math.random() * (5 - 3.5) + 3.5;
      setRating(newRating);
    }
    const newOrderAmount = calculateOrderAmount(priceMatches, orderQuantity);
    setOrderAmount(newOrderAmount);
  }, [orderQuantity, priceMatches,rating]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate phone number
    if (!phoneNumber || phoneNumber.length !== 10) {
      setPhoneNumberError(true);
      return; // Don't proceed with the submission
    }
    setPhoneNumberError(false);

    // Save order data only when the file URL is available
    if (fileURL) {
      saveOrderData();
    }
  };

  const saveOrderData = () => {
    const ordersRef = db.collection('orders');

    const orderData = {
      productID: productID,
      productName: product.name,
      productPrice: product.price, // Add product price to order data
      phoneNumber: phoneNumber,
      fileURL: fileURL,
      orderQuantity: orderQuantity,
      orderAmount: orderAmount,
    };

    // Use add to let Firestore generate a unique document ID
    ordersRef.add(orderData);

    // Reset fields
    setPhoneNumber('');
    setSelectedFile(null);
    setFileURL(null);
  };

  return (
       <>
        {product ? (
      <div className="div">
        <div className="div-2">
          <img
            loading="lazy"
            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FDigital%20Marketing.png?alt=media&token=b5e0477f-9c8b-461a-8cf3-c00c5a5ace78&_gl=1*vxe1pf*_ga*MTExODEzNjg2Ny4xNjk4MTMzMTU1*_ga_CW55HF8NVT*MTY5ODEzMzE1OS4xLjEuMTY5ODEzMzMyMC40NS4wLjA."
            className="img"
          />
        </div>
        <div className="div-3">
          <div className="div-4">
            <img
              loading="lazy"
              srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FCorporate%20Gifting.png?alt=media&token=78f1d4f2-7d18-42fe-a2aa-00421fac8087&_gl=1*1setq17*_ga*MTExODEzNjg2Ny4xNjk4MTMzMTU1*_ga_CW55HF8NVT*MTY5ODEzMzE1OS4xLjEuMTY5ODEzMzQ1OS41Mi4wLjA."
              className="img-2"
            />
            <div className="div-5" />
            <div className="div-6" />
            

      
          </div></div>











        
        <div className='page_header_links'>
        <div className='pade_header_page_link_left'>
          <div className='page_header_about_us'>
            About Us
          </div>
          <div className='page_header_writing_customization'>
            Writing Customization
          </div>
          <div className='page_header_shipping_options'>
              Shipping Options
          </div>
        </div>
        <div className='page_header_blank_space'>

        </div>
        <div className='pade_header_page_link_right'>
          <div className='page_header_contact'>
            <IoCall/>
            < div>
              7478004111
            </div>
          </div>
          <div className='page_header_help'>
            Help
          </div>
        </div>
        </div>
        <div className="div-7">
          <div className="div-8">Home</div>
          <div className = "img-3"><BsChevronRight/></div>

          <div className="div-9">Clothings</div>
          <div className = "img-4"><BsChevronRight/></div>

          <div className="div-10">Men’s</div>
          <div className = "img-5"><BsChevronRight/></div>
          
          <div className="div-11">Summer clothing</div>
        </div>
        <div className="div-12">
          <div className="div-13">
            <div className="div-14">
              <div className="column">
                <div className="div-15">
                  <img
                    loading="lazy"
                    srcSet={product.image}
                    className="img-6"
                  />

                </div>
              </div>
              <div className="column-2">
                <div className="div-16">
                  <div className="div-17">
                    <div className="div-18">
                      <div className="column-3">
                        <div className="div-19">
                          <div className="div-20">
                            <div className='img-8'><GiCheckMark/></div>
                        
                            <div className="div-21">Available</div>
                          </div>
                          <div className="div-22">
                          {product.name}
                          </div>
                          <div className="div-23">
                          <div className="img-9">{generateStars(rating)}</div>
                          <div className="div-24">{rating.toFixed(1)}</div>

                           
                          </div>
                          <div className='img-14'>
                            <div className='max_amount_container'>
                              <div className='max_price'>₹{original.toFixed(2)}</div>
                              <div className='max_price_quantity'>50-100 pcs</div>
                            </div>
                            <div className='avg_amount_container'>
                              <div className='avg_price'>₹{lower.toFixed(2)}</div>
                              <div className='avg_price_quantity'>100-500 pcs</div>
                            </div><div className='min_amount_container'>
                              <div className='min_price'>₹{lowest.toFixed(2)}</div>
                              <div className='min_price_quantity'>500+ pcs</div>
                            </div>
                          </div>

                          <div className="div-27">
                            <div className="div-28">Price: </div>
                            <div className="div-29">Negotiable</div>
                          </div>
                          <div className="div-30" />
                          <div className="div-31">
                            <div className="div-32">
                              <div className="div-33">Type: </div>
                              <div className="div-34">Material: </div>
                              <div className="div-35">Design: </div>
                            </div>
                            <div className="div-36">
                              <div className="div-37">Classic shoes</div>
                              <div className="div-38">Plastic material</div>
                              <div className="div-39">Modern nice</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column-4">
                        <div className="div-40">
                          <div className="div-41">
                            <div className="div-42">
                              <div className="div-43">R</div>
                              <div className="div-44">
                                <div className="div-45">Raise</div>
                                <div className="div-46">
                                  Product Request
                                </div>
                              </div>
                            </div>
                            <div className="div-47" />
                            <div className="div-48">
                              <div className = "img-15"><BsHandThumbsUp/></div>

                              <div className="div-49">Best Quality</div>
                            </div>
                            <div className="div-50">
                            <div className = "img-16"><MdOutlineVerifiedUser/></div>

                              <div className="div-51">Verified Products</div>
                            </div>
                            <div className="div-52">
                              <div className='img-17'><TbWorldCheck/></div>

                              <div className="div-53">Indiiawide shipping</div>
                            </div>
                            <Link to={`/SchoolUniform_Request/${productID}`}  className="div-54">
                              <div className="div-55">Send inquiry</div>
                            </Link>
                            <div className="div-56">
                              <div className="div-57">Seller’s profile</div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-60" />
                  <div className="div-61">
                    <div className="div-62">
                      <div className="div-63">Customization: </div>
                      <div className="div-64">Protection: </div>
                      <div className="div-65">Warranty: </div>
                    </div>
                    <div className="div-66">
                      <div className="div-67">
                        Customized logo and design custom packages
                      </div>
                      <div className="div-68">Refund Policy</div>
                      <div className="div-69">2 years full warranty </div>
                    </div>
                  </div>
                  <div className="div-70" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-71">
          <div className="div-72">
            <div className="column-5">
              <div className="div-73">

                <div className="div-74">
                  <div className="div-75">
                    <div className="div-76">Description</div>
                    
                
                  </div>
                </div>
                <div className="div-81">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet,
                  consectetur adipisicing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,{" "}
                  <br />
                  Quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur.{" "}
                  {product.description}
                </div>
                <div className="div-82">
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-83">
                    <div className="div-84">
                      <div className="div-85">Model</div>
                      <div className="div-86">
                        <div className="div-87">
                          <div className="div-88" />
                          <div className="div-89">
                            <div className="div-90" />
                            <div className="div-91">#8786867</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-128">
                  <div className='img-20'><TbPointFilled/></div>

                  <div className="div-129">Some great feature name here</div>
                </div>
                <div className="div-130">
                  <div className = "img-21"><TbPointFilled/></div>

                  <div className="div-131">
                    Lorem ipsum dolor sit amet, consectetur{" "}
                  </div>
                </div>
                <div className="div-132">
                  <div className='img-22'><TbPointFilled/></div>

                  <div className="div-133">
                    Duis aute irure dolor in reprehenderit
                  </div>
                </div>
                <div className="div-134">
                <div className='img-23'><TbPointFilled/></div>

                  <div className="div-135">Some great feature name here</div>
                </div>
              </div>
            </div>
            <div className="column-6">
            <div className="div-136">
              

<div className='stationary_description_product-list'>
<div className="div-137">You may like</div>
  {t_shirt_display.slice(0, 5).map((product, index) => (
    <Link to={`/t_shirt/${product.ProductID}`} className={`stationary_description_product-list-card`} key={product.ProductID}>
      <div className='div-139'>
        <img src={product.ProductImg} alt="not found" className="img-24"/>
      </div>
      <div className='stationary_description_product-list-details'>
        <div className='div-141 right_ellipsis'>
          {product.ProductName}
        </div>
        <div className='div-142'>{product.ProductPrice}</div>
      </div>
    </Link>
  ))}
</div>


            </div>
          </div>
          </div>
        </div>{" "}
        
        <div className='div-151'>
  <div className='div-154'>
  Related Products
  </div>

  <div className='writing_description_divider-line'></div>
  < div className='writing_description_product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='writing_category-card-container' align = 'center' >
      {school_category_uniform.slice(0, 6).map((product, index) => (
        
        <Link to={`/SchoolUniform/${product.ProductID}`}  className={'writing_category-card'} key={product.ProductID}>
            <div className='writing_category-img'>
              <img src={product.ProductImg} alt="not found" className="writing_category-img_part" />
            </div>
          <div className='writing_category-name bottom_ellipsis'>
            {product.ProductName}
        </div>
        <div className='writing_category-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>

      {/* "Next" button */}

  </div>

</div>
        <div className="div-182">
          
          <div className="div-183">
            <img
              loading="lazy"
              srcSet="..."
              className="img-35"
            />{" "}
            <div className="div-184">Shop now</div>
          </div>
        </div>{" "}
        <div className="div-185">
          <div className="div-186">
            <div className="div-187">
              <div className="column-7">
                <div className="div-188">
                  <div className="div-189">
                    <div className="column-8">
                      <div className="div-190">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="img-36"
                        />{" "}
                        <div className="div-191">
                          Best information about the company gies here but now
                          lorem ipsum is
                        </div>{" "}
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="img-37"
                        />
                      </div>
                    </div>{" "}
                    <div className="column-9">
                      <div className="div-192">
                        <div className="div-193">About</div>{" "}
                        <div className="div-194">About Us</div>{" "}
                        <div className="div-195">Find store</div>{" "}
                        <div className="div-196">Categories</div>{" "}
                        <div className="div-197">Blogs</div>
                      </div>
                    </div>{" "}
                    <div className="column-10">
                      <div className="div-198">
                        <div className="div-199">Partnership</div>{" "}
                        <div className="div-200">About Us</div>{" "}
                        <div className="div-201">Find store</div>{" "}
                        <div className="div-202">Categories</div>{" "}
                        <div className="div-203">Blogs</div>
                      </div>
                    </div>{" "}
                    <div className="column-11">
                      <div className="div-204">
                        <div className="div-205">Information</div>{" "}
                        <div className="div-206">Help Center</div>{" "}
                        <div className="div-207">Money Refund</div>{" "}
                        <div className="div-208">Shipping</div>{" "}
                        <div className="div-209">Contact us</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="column-12">
                <div className="div-210">
                  <div className="div-211">
                    <div className="div-212">For users</div>{" "}
                    <div className="div-213">Login</div>{" "}
                    <div className="div-214">Register</div>{" "}
                    <div className="div-215">Settings</div>{" "}
                    <div className="div-216">My Orders</div>
                  </div>{" "}
                  <div className="div-217">
                    <div className="div-218">Get app</div>{" "}
                    <img
                      loading="lazy"
                      srcSet="..."
                      className="img-38"
                    />{" "}
                    <img
                      loading="lazy"
                      srcSet="..."
                      className="img-39"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="div-219">
            <div className="div-220">
              <div className="div-221">© 2023 Ecommerce. </div>{" "}
              <div className="div-222">
                <img
                  loading="lazy"
                  srcSet="..."
                  className="img-40"
                />{" "}
                <div className="div-223">English</div>{" "}
                <img
                  loading="lazy"
                  srcSet="..."
                  className="img-41"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
       ) : (
        <p>Product not found</p>
      )}
    </>
  );
};

export default Uniform_Detail;


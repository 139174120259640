import React, { useState, useEffect } from "react";
import "./Home.css";
import Product from "./Product";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const bg1 =
  "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F13.png?alt=media&token=d3ff9d9b-d65f-46b7-9efd-3da358150b6c";
const bg2 =
  "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FEnhance%20your%20Organisations%20Identity%20viz%20Elegant%20Supply.png?alt=media&token=555f72d8-4bce-4eb3-9caa-08c37e7571e6";


function Home() {
  const [activeImageNum, setCurrent] = useState(0);

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrent(activeImageNum === 2 ? 0 : activeImageNum + 1);
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrent(activeImageNum === 0 ? 2 : activeImageNum - 1);
  };

  // Automatic slide change
  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, [activeImageNum]);

  const imageStyles = {
   width: "100%", // Set the width to adjust the image size
   height: '500px', // Maintain aspect ratio
 };

  return (
    <div className="home">
      <div className="home__container">
      <div className="carousel-container">
        <Carousel
         
          selectedItem={activeImageNum}
          onChange={setCurrent}
          showStatus={false} // Hide status indicator
          showIndicators={false} // Hide slide indicators
          showThumbs={false} // Hide thumbnail images
          autoPlay={false} // Auto play is handled by useEffect for custom interval
        >
          <div>
            <img src={bg1} alt="Slider" style={imageStyles}  />
          </div>
          <div>
            <img src={bg2} alt="Slider" style={imageStyles} />
          </div>
          
        </Carousel>

        </div>
        <div className="home_page_text">
          Tagline
        </div>

        <div className="home__row" style={{ marginTop: "500px" }} >
          <Link to="/School Supplies">
            <div  className= 'Sections'>
               <    img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FSchool%20Card.png?alt=media&token=db91bff8-d094-441a-b7aa-45426692c3fb&_gl=1*1vxr12d*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0ODkxMjUuNjAuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </div>
          </Link>
          <Link to="/Corporate_Supplies">
            <div  className= 'Sections'>
               <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FCorporate%20Card.png?alt=media&token=dd6673dd-ad4e-4987-aad1-e24352b637a1&_gl=1*eh190m*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0ODkxODQuMS4wLjA." alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </div>
            </Link>
            <Link to="/Hospital_Supplies">
            <div className= 'Sections'>
               <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FHospital%20Cards.png?alt=media&token=d60d22c2-d843-4058-aefd-67451385a375&_gl=1*1yi2pkw*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0ODkyMjQuMjUuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </div>
            </Link>
            <Link to="/Hotel_Supplies">
            <div className= 'Sections'>
               <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FRestaurant%20Card.png?alt=media&token=281631cb-f074-4976-a26c-2d5d2df9e955&_gl=1*196sjqw*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0ODkyNjMuNjAuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </div>

            </Link>
        </div>
        <div className="second_advert">
          <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FMake_Customized_Bulk_Order.png?alt=media&token=274ace5c-7e11-4ce8-870a-24835abaa923&_gl=1*1g1d43n*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0OTAwMzcuMjQuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
        </div>
        <div className = "page_cards"style={{ marginTop: "20px" }}>
          <div className="page_card_left">
            <Link to = "/Printing_Supplies">
            <div className="card_left_top" >
              <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Images%2FPrint%20and%20Packaging.png?alt=media&token=5679bf86-9541-4126-88fb-fc2cb2973b47&_gl=1*1xasmxo*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NjI2MjI1MC4xNC4xLjE2OTYyNjQzNzQuNjAuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' } }/>
            </div>
            </Link>
            <Link to= "/Writing_Supplies">
            <div className="card_left_middle" >
              <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Images%2FNotebooks.png?alt=media&token=3121229a-b6dc-465d-9543-a53145adf730&_gl=1*o2txd8*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NjI2MjI1MC4xNC4xLjE2OTYyNjQ0NTkuNjAuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </div>
            </Link>
            <div className="card_left_down" >
              <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Images%2FServices.png?alt=media&token=1f7de8b4-a22e-4930-aeb0-026b448f6ef3&_gl=1*1tba3f1*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzEyNjk1MC4zMS4xLjE2OTcxMjc0MjIuNjAuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' } }/>
            </div>
            
            
          </div>
          <div className="page_card_middle">
            <Link to = "/Uniforms">
            <div className="card_middle_top">
          <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Images%2FUniforms.png?alt=media&token=ed23a4c3-64bb-48aa-9c1d-e982003bd470&_gl=1*693zli*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NjI2MjI1MC4xNC4xLjE2OTYyNjQyMTMuNDEuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
          </div>
          </Link>
            <div className="page_card_middle_middle">
              <div className="card_middle_middle_left">
              <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Categories%2FMain%20Page%20Vector%2FSmall%20Business.jpg?alt=media&token=25931ea9-fa98-44d3-927f-d520cacbd6e8" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
              </div>
              <div className="card_middle_middle_right">
                <Link to = "/Promotional_Gifts">
                <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Images%2FGifts.png?alt=media&token=f8ab33d2-5a70-41a5-bd2e-3d890758f955&_gl=1*1nd0ny5*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzEyNjk1MC4zMS4xLjE2OTcxMjgwMjcuMjEuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                </Link>
              </div>
            </div>
            <Link to = "/Linen">
            <div className="card_middle_down">
              <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Images%2FLinen.png?alt=media&token=974f33d1-9462-417f-8150-03af15a94b3a&_gl=1*6mzt61*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NjI3MDc0MC4xNi4xLjE2OTYyNzA5MDIuNjAuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </div> 
            </Link>
          </div>
          <div className="page_card_right">
              <div className="card_right_top">
                <Link to = "/t_shirt">
                <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Images%2FT-Shirts.png?alt=media&token=d99f5a6a-dae4-4abe-8c58-accf942d758d&_gl=1*vnxrxb*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzEyNjk1MC4zMS4xLjE2OTcxMjc1NjYuNjAuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                </Link>
              </div>
              <div className="card_right_middle">
                <Link to= "/Stationary">
                <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Images%2FStationary.png?alt=media&token=405ebe25-f5b0-4df6-9343-e82383435085&_gl=1*6yk1cb*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzEyNjk1MC4zMS4xLjE2OTcxMjc3NzAuNjAuMC4w" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                </Link>
              </div>
            <div className="card_right_down">
              <img src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool%20Categories%2FMain%20Page%20Vector%2FSmall%20Business.jpg?alt=media&token=25931ea9-fa98-44d3-927f-d520cacbd6e8" alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </div>
          </div>
        </div>

        
        
      </div>
    </div>
  );
}

export default Home;

import React, { useState, useEffect } from 'react';
import { useLocation , useHistory } from 'react-router-dom';
import { db} from './Firebase';
import firebase from './Firebase';
import './Glenbard_Detail_Page.css';


const  GlenbardDetailsPage = () => {
  const location = useLocation();
  const selectedClass = location.state.selectedClass;
  const selectedLanguage = location.state.selectedLanguage;
  const history = useHistory();
  

  const [studentDetails, setStudentDetails] = useState({
    fullName: '',
    fathersName: '',
    studentClass: '',
    phoneNumber: '',
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle buy now button click
  const handleBuyNow = () => {
    // Submit student details and order
    // This is where you would handle the submission of student details and order
    // You can use the studentDetails state and the calculated totals
    console.log('Student Details:', studentDetails);
    console.log('Order Total:', calculateTotal(books) + calculateTotal(notebooks) + calculateStationaryTotal());
    alert('Order has been placed.');
    history.push('/Glenbard'); //
    // Add your submission logic here
  };
  const [books, setBooks] = useState([]);
  const [notebooks, setNotebooks] = useState([]);
  const [stationary, setStationary] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const firestore = firebase.firestore();
      
      // Fetch books
      const booksSnapshot = await db.collection(`/School_Product/Glenbard/Class_Language_Data/${selectedClass}/${selectedLanguage}/books/book`).get();
      const booksData = booksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setBooks(booksData);
      
      // Fetch notebooks
      const notebooksSnapshot = await db.collection(`/School_Product/Glenbard/Class_Language_Data/${selectedClass}/${selectedLanguage}/notebooks/notebook`).get();
      const notebooksData = notebooksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNotebooks(notebooksData);
      
      // Fetch stationary
      const stationarySnapshot = await db.collection(`/School_Product/Glenbard/Class_Language_Data/${selectedClass}/${selectedLanguage}/stationary/stationary`).get();
      const stationaryData = stationarySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), isSelected: true })); // Set default isSelected to true
      setStationary(stationaryData);
    };

    fetchData();
  }, [selectedClass, selectedLanguage]);

  // Function to handle stationary selection
  const handleStationaryChange = (id) => {
    const updatedStationary = stationary.map(item => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return item;
    });
    setStationary(updatedStationary);
  };

  // Calculate total price for selected stationary items only
  const calculateStationaryTotal = () => {
    const selectedStationary = stationary.filter(item => item.isSelected);
    return selectedStationary.reduce((total, item) => total + (item.quantity || 0) * item.price, 0);
  };

  // Calculate total price
  const calculateTotal = (items) => {
    return items.reduce((total, item) => total + (item.quantity || 0) * item.price, 0);
  };

  return (
    <div className='glenbard_page'>

      <div className='glenbard_heading_section'>
        <div className='glenbard_logo_section'>
          <img src='https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FGlenbard%2FGlenbard%20Logo.jpg?alt=media&token=0e869703-bd43-4f3d-8e9f-e25cceb7cb50'/>

        </div>
        <div className='glenbard_school_details'>
          <div className='glenbard_school_name'>
            GLENBARD INTERNATIONAL SCHOOL - Custoking Partner
          </div>
          <div className='glenbard_school_name_subheading'>
            CBSE Curriculum - Hyderabad
          </div>
        </div>
      </div>
      < div className='glenbard_details_section'>
    <div className='glenbard_table_section'>

      <h3 className='table_heading'>Books</h3>
      <table className='book-table'>
  <thead>
    <tr>
      <th>Name</th>
      <th>Price</th>
      <th>Quantity</th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody>
    {books.map(book => (
      <tr key={book.id}>
        <td>{book.name}</td>
        <td>₹ {book.price}</td>
        <td>{book.quantity}</td>
        <td>₹ {book.quantity ? book.quantity * book.price : 0}</td>
      </tr>
    ))}
    <tr>
      <td colSpan="3">Total:</td>
      <td>
      ₹ {books.reduce((total, book) => total + (book.quantity || 0) * book.price, 0)}
      </td>
    </tr>
  </tbody>
</table>


      <h3 className='table_heading'>Notebooks</h3>
      <table className="notebook-table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Price</th>
      <th>Quantity</th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody>
    {notebooks.map(notebook => (
      <tr key={notebook.id}>
        <td>{notebook.name}</td>
        <td>₹ {notebook.price}</td>
        <td>{notebook.quantity}</td>
        <td>₹ {notebook.quantity ? notebook.quantity * notebook.price : 0}</td>
      </tr>
    ))}
    <tr>
      <td colSpan="3">Total:</td>
      <td>
      ₹ {notebooks.reduce((total, notebook) => total + (notebook.quantity || 0) * notebook.price, 0)}
      </td>
    </tr>
  </tbody>
</table>

      <h3 className='table_heading'>Stationary</h3>
      <table className="stationary-table">
  <thead>
    <tr>
      <th>Select</th>
      <th>Name</th>
      <th>Price</th>
      <th>Quantity</th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody>
    {stationary.map(item => (
      <tr key={item.id}>
        <td>
          <input
            type="checkbox"
            checked={item.isSelected}
            onChange={() => handleStationaryChange(item.id)}
          />
        </td>
        <td>{item.name}</td>
        <td>₹ {item.price}</td>
        <td>{item.quantity}</td>
        <td>₹ {item.quantity ? item.quantity * item.price : 0}</td>
      </tr>
    ))}
    <tr>
      <td colSpan="4">Total:</td>
      <td>
      ₹ {calculateStationaryTotal()}
      </td>
    </tr>
  </tbody>
</table>
</div>
<div className='glenbard_final_details'>

<div className="school_second_page_div-48">
<div className='student_details_section'>
        <h2 className='glenbard_student_details'>Student Details</h2>
        <div className='glenbard_input_section'>
          <label className='glenbard_input_title'>Student Full Name:</label>
          <input type="text" className='glenbard_input'  name="fullName" value={studentDetails.fullName} onChange={handleInputChange} />
        </div>
        <div  className='glenbard_input_section'>
          <label className='glenbard_input_title' >Father's Name:</label>
          <input type="text" className='glenbard_input' name="fathersName" value={studentDetails.fathersName} onChange={handleInputChange} />
        </div>
        <div className='glenbard_input_section'>
          <label className='glenbard_input_title'>Class:</label>
          <input type="text" className='glenbard_input' name="studentClass" value={studentDetails.studentClass} onChange={handleInputChange} />
        </div>
        <div className='glenbard_input_section'>
          <label className='glenbard_input_title'>Phone Number:</label>
          <input type="text" className='glenbard_input' name="phoneNumber" value={studentDetails.phoneNumber} onChange={handleInputChange} />
        </div>
      </div>

<div className="school_second_page_div-49">

<div className="school_second_page_div-50">Order Summary</div>
  </div>
  <div className="school_second_page_div-52">
  <div className="school_second_page_div-53">


  <div className="school_second_page_div-54">Amount</div>
    <div className="school_second_page_div-55">Discount</div>
    </div>
    <div className="school_second_page_div-56">
    <div className="school_second_page_div-57">{`₹${calculateTotal(books) + calculateTotal(notebooks) +  calculateStationaryTotal()}`}</div>
    <div className="school_second_page_div-58">{`₹0`}</div>
  </div>
  </div>
  <div className="school_second_page_div-59" />
  <div className="school_second_page_div-60">
  <div className="school_second_page_div-61">Total</div>
  <div className="school_second_page_div-62">{`₹${calculateTotal(books) + calculateTotal(notebooks) +  calculateStationaryTotal()}`}</div>
</div>
<button className="school_second_page_div-63"  onClick={handleBuyNow}>Buy Now</button>

</div>



</div>

</div>

    </div>
  );
};

export default GlenbardDetailsPage;

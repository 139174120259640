import React, { useState, useEffect } from 'react';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import { db, storage } from './Firebase';
import ReactGA from 'react-ga';
function School_Services(props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [NameError, setNameError] = useState(false);
  const [Name, setName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [contactInfoError, setContactInfoError] = useState(false);
  const [Detail, setDetail] = useState('');
  const [DetailError, setDetailError] = useState(false);

  const validateContactInfo = (value) => {
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return phoneRegex.test(value) || emailRegex.test(value);
  };

  const handleContactInfoChange = (e) => {
    setContactInfo(e.target.value);
    setContactInfoError(!validateContactInfo(e.target.value));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameError(e.target.value.trim() === ''); // Address is mandatory
  };
  const handleDetailChange = (e) => {
    setDetail(e.target.value);
    setDetailError(e.target.value.trim() === ''); // Address is mandatory
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate contact information
    if (!validateContactInfo(contactInfo)) {
      setContactInfoError(true);
      return; // Don't proceed with the submission
    }
    setContactInfoError(false);

    // Validate address
    if (Name.trim() === '') {
      setNameError(true);
      return; // Don't proceed with the submission
    }
    setNameError(false);



    // Save order data only when the file URL is available
    console.log('About to call saveOrderData');
    console.log('About to call saveOrderData Inside');
    saveOrderData();
    window.gtag('event', 'conversion', {'send_to': 'AW-11246127201/mcHGCNDQtPsYEOGQyfIp'});
  };

  const saveOrderData = async () => {
    console.log('saveOrderData function called');
    const ordersRef = db.collection('Mobile_Call_Back');

    const orderData = {
      Name: Name,
      Contact: contactInfo,
      Detail: Detail,
    };
    console.log('Order Data:', orderData);
    try {
      // Use add to let Firestore generate a unique document ID
      await ordersRef.add(orderData);

      // Reset fields
      setContactInfo('');
      setDetail('');
      setName('');

      // Optionally, you can show a success message to the user
      alert('Request submitted successfully!');
    } catch (error) {
      // Handle any errors that may occur during data submission
      console.error('Error submitting request:', error);
      alert('Error submitting request. Please try again.');
    }
  };

  const [NameError2, setNameError2] = useState(false);
  const [Name2, setName2] = useState('');
  const [contactInfo2, setContactInfo2] = useState('');
  const [contactInfoError2, setContactInfoError2] = useState(false);
  const [Detail2, setDetail2] = useState('');
  const [DetailError2, setDetailError2] = useState(false);



  const validateContactInfo2 = (value) => {
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return phoneRegex.test(value) || emailRegex.test(value);
  };

  const handleContactInfoChange2 = (e) => {
    setContactInfo2(e.target.value);
    setContactInfoError2(!validateContactInfo2(e.target.value));
  };

  const handleNameChange2 = (e) => {
    setName2(e.target.value);
    setNameError2(e.target.value.trim() === ''); // Address is mandatory
  };
  const handleDetailChange2 = (e) => {
    setDetail2(e.target.value);
    setDetailError2(e.target.value.trim() === ''); // Address is mandatory
  };
  const handleSubmit2 = async (e) => {
    e.preventDefault();

    // Validate contact information
    if (!validateContactInfo2(contactInfo2)) {
      setContactInfoError2(true);
      return; // Don't proceed with the submission
    }
    setContactInfoError(false);

    // Validate address
    if (Name2.trim() === '') {
      setNameError2(true);
      return; // Don't proceed with the submission
    }
    setNameError2(false);



    // Save order data only when the file URL is available
    console.log('About to call saveOrderData');
    console.log('About to call saveOrderData Inside');
    saveOrderData2();
  };

  const saveOrderData2 = async () => {
    console.log('saveOrderData function called');
    const ordersRef = db.collection('Mobile_Call_Back');

    const orderData = {
      Name: Name2,
      Contact: contactInfo2,
      Detail: Detail2,
    };
    console.log('Order Data:', orderData);
    try {
      // Use add to let Firestore generate a unique document ID
      await ordersRef.add(orderData);

      // Reset fields
      setContactInfo2('');
      setDetail2('');
      setName2('');

      // Optionally, you can show a success message to the user
      alert('Request submitted successfully!');
    } catch (error) {
      // Handle any errors that may occur during data submission
      console.error('Error submitting request:', error);
      alert('Error submitting request. Please try again.');
    }
  };




  return (
    <>
      <div className="school_services_div">

        <div className="school_services_div-19">

          <div className="school_services_div-20">
            <div className="school_services_div-21">
              <div className="school_services_div-22">
                
                <div className="school_services_div-24">Custoking</div>
              </div>
              <div className="school_services_div-25">School Solutions</div>
              <div className="school_services_div-26">
                We are a one-stop shop for both schools and students.
              </div>
            </div>
          </div>
        </div>
        <div className="school_services_div-27">
          <div className="school_services_div-28">
            <div className="school_services_div-29">
              <div className="school_services_column">
                <div className="school_services_div-30">
                  <div className="school_services_div-31">
                    Best Quality School Supplies at the Best Price
                  </div>
                  <div className="school_services_div-32" />
                  <div className="school_services_div-33">
                    Are you looking for a place to buy school supplies for your
                    school? Custoking can meet all your requirements. We have a large assortment of your school
                    supplies like uniforms, stationary ,id cards etc. In
                    addition to stationery, you can also find housekeeping,
                    pantry and sporting goods as well as services like pest control and IT. We are a one-stop shop
                    for all your school or university needs.
                  </div>
                  <div className="school_services_div-34">
                    Custo is an online platform for school supplies, materials,
                    solutions and services for both schools and
                    students. We are a leading provider of custo supplies and
                    offer a one-stop solution for school supplies and materials. We are proud to offer our customers
                    the largest selection of school supplies and service solutions.
                  </div>
                  <div className="school_services_div-35">
                    <div className="school_services_div-36">
                      <div className="school_services_column-2">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6d5a4f8b-2c47-4393-b575-9bcff44d2089?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d5a4f8b-2c47-4393-b575-9bcff44d2089?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d5a4f8b-2c47-4393-b575-9bcff44d2089?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d5a4f8b-2c47-4393-b575-9bcff44d2089?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d5a4f8b-2c47-4393-b575-9bcff44d2089?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d5a4f8b-2c47-4393-b575-9bcff44d2089?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d5a4f8b-2c47-4393-b575-9bcff44d2089?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6d5a4f8b-2c47-4393-b575-9bcff44d2089?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="school_services_img-4"
                        />
                      </div>
                      <div className="school_services_column-3">
                        <div className="school_services_div-33">
                          We offer a huge inventory of art and craft products
                          to encourage creativity. We love and revere creativity and strive to bring the best of the craft
world to craft lovers and consumers in India. Custo carries an exciting and wide range of products, a
                          
                          huge selection of art tools, paints, specialty
                          
                          papers, artist pens and various other art products.
                        
                          With massive research and development programs,
                          
                          our team has now developed a sophisticated and
                        
                          tested model to expand its presence across India.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="school_services_div-38">
                    Reduce hassles and cost of school shopping
                  </div>
                  <div className="school_services_div-39">
                    We are India's leading retailer of school supplies,
                    providing one-stop solutions for all the needs of school
                  
                    administrators and parents as well. Our goal is to make the
                    lives of schools and parents a little easier by
                    
                    helping you save money, save time, reduce hassle and reduce
                    the cost of school shopping!
                  </div>
                </div>
              </div>{" "}
              <div className="school_services_column-4">
                <div className="school_services_div-40">
              
                  <form onSubmit={handleSubmit} className="school_services_div-41">
                    <div className="school_services_div-42">
                      <div className="school_services_div-43" />{" "}
                      <div className="school_services_div-44">Request Call Back</div>
                    </div>{" "}
                    <div className="school_services_div-45">
                      Don't Hesitate To Contact Us
                      
                      If You Have Any Question
                    </div>
                    <div className="school_services_div-46">
                    <input
      type="text"
      id="schoolName"
      value={Name}
      onChange={handleNameChange}
      className={NameError ? 'error' : ''}
      placeholder="Enter School name"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: NameError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {NameError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
                    </div>
              
                    <div className="school_services_div-49">
                    <input
      type="text"
      id="contactInfo"
      value={contactInfo}
      onChange={handleContactInfoChange}
      className={contactInfoError ? 'error' : ''}
      placeholder="Phone Number or Email (Required)"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: contactInfoError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {contactInfoError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
                    </div>

                    <div className="school_services_div-55">
                    <input
      type="text"
      id="Detail"
      value={Detail}
      onChange={handleDetailChange}
      className={DetailError ? 'error' : ''}
      placeholder="Enter Details"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: DetailError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {DetailError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
                    </div>
                    <button type = "submit" className="school_services_div-56">Request Call Back</button>
                  </form>
                  <div className="school_services_div-57">
                    <div className="school_services_div-58" />
                    <div className="school_services_div-59"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="school_services_div-60">
            <div className="school_services_div-61">
              <div className="school_services_column-5">
                <div className="school_services_div-62">
                  Our vision is to provide schools, students and
                
                  parents with a compelling, technology-
                  
                  enabled shopping experience for everything
                  
                  they need to succeed at work and play. We
              
                  want to be a partner for schools, a guide for
                  
                  parents, and a buddy for kids. With years of
                
                  experience in the market, we cover all the
                
                  school needs of many schools across India and
                  
                  have in-depth expertise in school uniforms,
                  
                  bags, shoes, textbooks, etc.
                </div>
              </div>
              <div className="school_services_column-6">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/23b281d8-5f21-4710-bbf0-fb7e05a4b97e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/23b281d8-5f21-4710-bbf0-fb7e05a4b97e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/23b281d8-5f21-4710-bbf0-fb7e05a4b97e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/23b281d8-5f21-4710-bbf0-fb7e05a4b97e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/23b281d8-5f21-4710-bbf0-fb7e05a4b97e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/23b281d8-5f21-4710-bbf0-fb7e05a4b97e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/23b281d8-5f21-4710-bbf0-fb7e05a4b97e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/23b281d8-5f21-4710-bbf0-fb7e05a4b97e?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="school_services_img-10"
                />
              </div>
            </div>
          </div>
          <div className="school_services_div-63">
            Get quality school supplies at a low cost
          </div>
          <div className="school_services_div-64">
            Remember how exciting it was to go back to school after the
            vacations and re-dress everything from
          
            books, bags and shoes? Give a child their favorite superhero school
            bag and see the joy on their face.
            
            Appropriate and quality school supplies are a necessary part of a
            good education and help keep
            
            students motivated and happy.
          </div>
          <div className="school_services_div-65">
            <div className="school_services_div-66">
              <div className="school_services_column-7">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/598ad630-0a0b-4407-a739-429cba98a065?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/598ad630-0a0b-4407-a739-429cba98a065?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/598ad630-0a0b-4407-a739-429cba98a065?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/598ad630-0a0b-4407-a739-429cba98a065?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/598ad630-0a0b-4407-a739-429cba98a065?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/598ad630-0a0b-4407-a739-429cba98a065?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/598ad630-0a0b-4407-a739-429cba98a065?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/598ad630-0a0b-4407-a739-429cba98a065?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="school_services_img-11"
                />
              </div>
              <div className="school_services_column-8">
                <div className="school_services_div-67">
                  Cusotking takes care of everything
                  
                  students need for education and play, so parents
                  
                  and guardians can effortlessly provide their
                
                  children with quality school supplies. We are India's
                  
                  first of its kind comprehensive e-commerce
                
                  platform dedicated to meeting all the needs of
                
                  students - in school and at play. With our range of
                  
                  products, we fulfill every little need related to
                  
                  education and hobbies for a student. Our goal is to
                  
                  make parents' lives easier by simplifying the
                  
                  selection process and also offering home delivery.
                </div>
              </div>
            </div>
          </div>
          <div className="school_services_div-68">
            A wide range of education and sports products
          </div>
          <div className="school_services_div-69">
            We have a wide range of education and sports-related products like
            school uniforms, books, shoes,
            
            bags, stationery, accessories, toys and games and many sports and
            technology-related products like
          
            football, basketball, cricket sets, keyboards, mice, speakers,
            headphones, etc.
          </div>
          <div className="school_services_div-70">
            <div className="school_services_div-71">
              <div className="school_services_column-9">
                <div className="school_services_div-72">
                  It is not necessary for parents to get all the
              
                  school supplies at once, so they can make
                  
                  several trips to get the prescribed books. The
                  
                  situation is similar with toys: There are options
                  
                  to buy toys/books online or offline. The key
                  
                  difference is that our selection makes parents'
                  
                  lives easier by offering selected goods from the
                
                  best brands.
                </div>
              </div>{" "}
              <div className="school_services_column-10">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d1f20d57-c0d1-453d-8c7d-cc136084a600?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f20d57-c0d1-453d-8c7d-cc136084a600?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f20d57-c0d1-453d-8c7d-cc136084a600?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f20d57-c0d1-453d-8c7d-cc136084a600?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f20d57-c0d1-453d-8c7d-cc136084a600?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f20d57-c0d1-453d-8c7d-cc136084a600?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f20d57-c0d1-453d-8c7d-cc136084a600?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1f20d57-c0d1-453d-8c7d-cc136084a600?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="school_services_img-12"
                />
              </div>
            </div>
          </div>{" "}
          <div className="school_services_div-73">
            As a technology-enabled integrated platform,
            
            we enable parents to shop anytime, anywhere.
          
            This is supported by efficient back-end logistics that ensure
            products are delivered on time. All products
            
            on the website are organized by age/interest/price range, etc. This
            makes it easier and faster for parents
            
            to search for specific products or even discover relevant products
            on the website.
          </div>{" "}
          <div className="school_services_div-74">School Solutions Categories</div>{" "}
          <div className="school_services_div-75" />{" "}
          <div className="school_services_div-76">
            <div className="school_services_div-77">
              <div className="school_services_column-11">
                <div className="school_services_div-78">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ba28b751-f5d1-4d9f-b44e-2334d5fc3219?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba28b751-f5d1-4d9f-b44e-2334d5fc3219?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba28b751-f5d1-4d9f-b44e-2334d5fc3219?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba28b751-f5d1-4d9f-b44e-2334d5fc3219?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba28b751-f5d1-4d9f-b44e-2334d5fc3219?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba28b751-f5d1-4d9f-b44e-2334d5fc3219?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba28b751-f5d1-4d9f-b44e-2334d5fc3219?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba28b751-f5d1-4d9f-b44e-2334d5fc3219?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-13"
                  />{" "}
                  <div className="school_services_div-79">Stationery</div>
                </div>
              </div>{" "}
              <div className="school_services_column-12">
                <div className="school_services_div-80">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5ad08b7-363b-4bc4-8a06-c688eee9b59b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5ad08b7-363b-4bc4-8a06-c688eee9b59b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5ad08b7-363b-4bc4-8a06-c688eee9b59b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5ad08b7-363b-4bc4-8a06-c688eee9b59b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5ad08b7-363b-4bc4-8a06-c688eee9b59b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5ad08b7-363b-4bc4-8a06-c688eee9b59b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5ad08b7-363b-4bc4-8a06-c688eee9b59b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5ad08b7-363b-4bc4-8a06-c688eee9b59b?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-14"
                  />{" "}
                  <div className="school_services_div-81">Teacher Supplies</div>
                </div>
              </div>{" "}
              <div className="school_services_column-13">
                <div className="school_services_div-82">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f1895215-2631-4437-a858-2d75e0a57d42?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1895215-2631-4437-a858-2d75e0a57d42?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1895215-2631-4437-a858-2d75e0a57d42?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1895215-2631-4437-a858-2d75e0a57d42?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1895215-2631-4437-a858-2d75e0a57d42?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1895215-2631-4437-a858-2d75e0a57d42?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1895215-2631-4437-a858-2d75e0a57d42?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1895215-2631-4437-a858-2d75e0a57d42?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-15"
                  />{" "}
                  <div className="school_services_div-83">Xerox Services</div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="school_services_div-84">
            <div className="school_services_div-85">
              <div className="school_services_column-14">
                <div className="school_services_div-86">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1436281b-b879-4244-a461-c3e238809533?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1436281b-b879-4244-a461-c3e238809533?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1436281b-b879-4244-a461-c3e238809533?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1436281b-b879-4244-a461-c3e238809533?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1436281b-b879-4244-a461-c3e238809533?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1436281b-b879-4244-a461-c3e238809533?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1436281b-b879-4244-a461-c3e238809533?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1436281b-b879-4244-a461-c3e238809533?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-16"
                  />{" "}
                  <div className="school_services_div-87">Uniforms</div>
                </div>
              </div>{" "}
              <div className="school_services_column-15">
                <div className="school_services_div-88">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1a40225f-cf71-4304-a0d3-5b25cffef3ba?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a40225f-cf71-4304-a0d3-5b25cffef3ba?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a40225f-cf71-4304-a0d3-5b25cffef3ba?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a40225f-cf71-4304-a0d3-5b25cffef3ba?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a40225f-cf71-4304-a0d3-5b25cffef3ba?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a40225f-cf71-4304-a0d3-5b25cffef3ba?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a40225f-cf71-4304-a0d3-5b25cffef3ba?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a40225f-cf71-4304-a0d3-5b25cffef3ba?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-17"
                  />{" "}
                  <div className="school_services_div-89">Lunch Boxes</div>
                </div>
              </div>{" "}
              <div className="school_services_column-16">
                <div className="school_services_div-90">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/07a3ba27-1e1a-4f57-98cb-169bc0a5e2d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/07a3ba27-1e1a-4f57-98cb-169bc0a5e2d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/07a3ba27-1e1a-4f57-98cb-169bc0a5e2d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/07a3ba27-1e1a-4f57-98cb-169bc0a5e2d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/07a3ba27-1e1a-4f57-98cb-169bc0a5e2d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/07a3ba27-1e1a-4f57-98cb-169bc0a5e2d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/07a3ba27-1e1a-4f57-98cb-169bc0a5e2d4?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/07a3ba27-1e1a-4f57-98cb-169bc0a5e2d4?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-18"
                  />{" "}
                  <div className="school_services_div-91">Bottles</div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="school_services_div-92">
            <div className="school_services_div-93">
              <div className="school_services_column-17">
                <div className="school_services_div-94">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5edd90cb-24b0-47c6-b144-0eccb8b82325?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5edd90cb-24b0-47c6-b144-0eccb8b82325?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5edd90cb-24b0-47c6-b144-0eccb8b82325?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5edd90cb-24b0-47c6-b144-0eccb8b82325?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5edd90cb-24b0-47c6-b144-0eccb8b82325?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5edd90cb-24b0-47c6-b144-0eccb8b82325?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5edd90cb-24b0-47c6-b144-0eccb8b82325?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5edd90cb-24b0-47c6-b144-0eccb8b82325?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-19"
                  />{" "}
                  <div className="school_services_div-95">Bags</div>
                </div>
              </div>{" "}
              <div className="school_services_column-18">
                <div className="school_services_div-96">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0daaf6f0-5889-4857-98ab-9b5f55ffcf56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0daaf6f0-5889-4857-98ab-9b5f55ffcf56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0daaf6f0-5889-4857-98ab-9b5f55ffcf56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0daaf6f0-5889-4857-98ab-9b5f55ffcf56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0daaf6f0-5889-4857-98ab-9b5f55ffcf56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0daaf6f0-5889-4857-98ab-9b5f55ffcf56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0daaf6f0-5889-4857-98ab-9b5f55ffcf56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0daaf6f0-5889-4857-98ab-9b5f55ffcf56?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-20"
                  />{" "}
                  <div className="school_services_div-97">Geometry</div>
                </div>
              </div>{" "}
              <div className="school_services_column-19">
                <div className="school_services_div-98">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/056322e5-e5e9-4e3d-b01b-bb2fe9ba31bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/056322e5-e5e9-4e3d-b01b-bb2fe9ba31bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/056322e5-e5e9-4e3d-b01b-bb2fe9ba31bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/056322e5-e5e9-4e3d-b01b-bb2fe9ba31bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/056322e5-e5e9-4e3d-b01b-bb2fe9ba31bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/056322e5-e5e9-4e3d-b01b-bb2fe9ba31bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/056322e5-e5e9-4e3d-b01b-bb2fe9ba31bd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/056322e5-e5e9-4e3d-b01b-bb2fe9ba31bd?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-21"
                  />{" "}
                  <div className="school_services_div-99">Id Cards</div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="school_services_div-100">
          <div className="school_services_div-101">
            <div className="school_services_column-20">
              <div className="school_services_div-102">
                <div className="school_services_div-103">
                  <div className="school_services_div-104" />{" "}
                  <div className="school_services_div-105">Customers</div>
                </div>{" "}
                <div className="school_services_div-106">Our Partner Schools</div>{" "}
                <div className="school_services_div-107">
                  Custoking has a wide network of partner school who help us in
                  our endeavour to provide
                
                  the best in Uniform,Stationery supplies, Housekeeping, Pantry
                  items and several other services.
                </div>{" "}
                <div className="school_services_div-108">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/00031e6a-7a0a-47be-925b-b1c28cc7a2e8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/00031e6a-7a0a-47be-925b-b1c28cc7a2e8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/00031e6a-7a0a-47be-925b-b1c28cc7a2e8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/00031e6a-7a0a-47be-925b-b1c28cc7a2e8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/00031e6a-7a0a-47be-925b-b1c28cc7a2e8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/00031e6a-7a0a-47be-925b-b1c28cc7a2e8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/00031e6a-7a0a-47be-925b-b1c28cc7a2e8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/00031e6a-7a0a-47be-925b-b1c28cc7a2e8?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-22"
                  />{" "}
                  סּ
                </div>
              </div>
            </div>{" "}

          </div>
        </div>{" "}
        <div className="school_services_div-110">
          <div className="school_services_div-111">
            <div className="school_services_div-112">
              <div className="school_services_column-22">
                <div className="school_services_div-113">
                  <div className="school_services_div-114">
                    <div className="school_services_div-115" />{" "}
                    <div className="school_services_div-116">WE LOVE WHAT WE DO</div>
                  </div>{" "}
                  <div className="school_services_div-117">
                    Let’s Create Something
                    <br />
                    Together
                  </div>{" "}
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a083ed5-47d2-40c4-b4eb-809b5edc70fe?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="school_services_img-24"
                  />
                </div>
              </div>{" "}
              <div className="school_services_column-23">
                <form onSubmit={handleSubmit2} className="school_services_div-118">
                  <div className="school_services_div-119">
                    <div className="school_services_div-120" />{" "}
                    <div className="school_services_div-121">Request Call Back</div>
                  </div>{" "}
                  <div className="school_services_div-122">
                    Don't Hesitate To Contact Us If You
                    <br />
                    Have Any Question
                  </div>
                  <div className="school_services_div-123">Heavy Discount on Bulk Orders</div>
                  <div className="school_services_div-124">
                    <div className="school_services_div-125">
                    <input
      type="text"
      id="schoolName2"
      value={Name2}
      onChange={handleNameChange2}
      className={NameError2 ? 'error' : ''}
      placeholder="Enter School name"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: NameError2 ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {NameError2 && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
                    </div>

                  </div>
                  <div className="school_services_div-129"/>
                  <div className="school_services_div-130">
                    <div className="school_services_div-131">
                    <input
      type="text"
      id="contactInfo2"
      value={contactInfo2}
      onChange={handleContactInfoChange2}
      className={contactInfoError2 ? 'error' : ''}
      placeholder="Phone Number or Email (Required)"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: contactInfoError2 ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {contactInfoError2 && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
                    </div>

                  </div>
                  <div className="school_services_div-135">
                  <input
      type="text"
      id="Detail2"
      value={Detail2}
      onChange={handleDetailChange2}
      className={DetailError2 ? 'error' : ''}
      placeholder="Enter Details"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: DetailError2 ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {DetailError2 && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
                  </div>
                  <button type = "submit" className="school_services_div-136">Request Call Back</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .school_services_div {
          display: flex;
          flex-direction: column;
        }
        .school_services_div-2 {
          justify-content: space-between;
          box-shadow: 0px 4px 20px 1px rgba(221, 221, 221, 0.65);
          background-color: #fff;
          align-self: stretch;
          display: flex;
          width: 100%;
          gap: 20px;
          padding: 4px 30px;
        }
        @media (max-width: 991px) {
          .school_services_div-2 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0 20px;
          }
        }
        .school_services_img {
          aspect-ratio: 3.07;
          object-fit: contain;
          object-position: center;
          width: 209px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-3 {
          align-self: center;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 11px;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_services_div-3 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_services_div-4 {
          justify-content: center;
          color: #000;
          align-self: center;
          white-space: nowrap;
          margin: auto 0;
          font: 600 15px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-4 {
            white-space: initial;
          }
        }
        .school_services_div-5 {
          justify-content: center;
          color: #000;
          align-self: center;
          margin: auto 0;
          font: 600 15px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-6 {
          justify-content: center;
          color: #f60;
          align-self: center;
          margin: auto 0;
          font: 600 14.5px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_img-2 {
          aspect-ratio: 0.88;
          object-fit: contain;
          object-position: center;
          width: 14px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_services_div-7 {
          justify-content: center;
          color: #000;
          align-self: center;
          margin: auto 0;
          font: 600 15px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-8 {
          justify-content: space-between;
          align-items: start;
          border-right: 1px solid #e9e9e9;
          border-left: 1px solid #e9e9e9;
          align-self: stretch;
          display: flex;
          gap: 11px;
          padding: 1px 19px 1px 26px;
        }
        @media (max-width: 991px) {
          .school_services_div-8 {
            justify-content: center;
            padding-left: 20px;
          }
        }
        .school_services_div-9 {
          justify-content: center;
          color: #3664a2;
          text-align: center;
          opacity: 0.85;
          align-self: center;
          margin: auto 0;
          font: 400 24px/24px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-10 {
          justify-content: center;
          color: #2eacf6;
          text-align: center;
          opacity: 0.85;
          font: 400 24px/24px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-11 {
          justify-content: center;
          color: #007bb6;
          text-align: center;
          opacity: 0.85;
          align-self: center;
          margin: auto 0;
          font: 400 24px/24px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-12 {
          justify-content: center;
          color: #cb2027;
          text-align: center;
          opacity: 0.85;
          align-self: center;
          margin: auto 0;
          font: 400 24px/24px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-13 {
          justify-content: center;
          color: #cd368c;
          text-align: center;
          opacity: 0.85;
          margin-top: 12px;
          white-space: nowrap;
          font: 400 24px/24px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-13 {
            white-space: initial;
          }
        }
        .school_services_div-14 {
          align-self: center;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          margin: auto 0;
        }
        .school_services_div-15 {
          justify-content: center;
          color: #f90;
          flex-grow: 1;
          white-space: nowrap;
          margin: auto 0;
          font: 700 15px/18px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-15 {
            white-space: initial;
          }
        }
        .school_services_div-16 {
          justify-content: space-between;
          background-color: #f90;
          align-self: stretch;
          display: flex;
          gap: 10px;
          padding: 12px 20px 12px 25px;
        }
        @media (max-width: 991px) {
          .school_services_div-16 {
            padding-left: 20px;
          }
        }
        .school_services_div-17 {
          justify-content: center;
          color: #fff;
          text-align: center;
          letter-spacing: 0.30000001192092896px;
          white-space: nowrap;
          font: 400 24px/24px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-17 {
            white-space: initial;
          }
        }
        .school_services_div-18 {
          justify-content: center;
          color: #fff;
          letter-spacing: 0.30000001192092896px;
          text-transform: uppercase;
          align-self: center;
          white-space: nowrap;
          margin: auto 0;
          font: 600 13px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-18 {
            white-space: initial;
          }
        }
        .school_services_div-19 {
          disply: flex;

          flex-direction: column;
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          min-height: 400px;
          width: 100%;
          background: url('https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FSchool_Services.jpeg?alt=media&token=eccbdfdd-c3d7-48dd-afe1-e76ec084bac0') center/cover no-repeat; /* Replace 'your-image-url.jpg' with the actual URL of your image */
          background-color: rgba(0, 0, 0, 0.5);
          justify-content: center;
        }
        @media (max-width: 991px) {
          .school_services_div-19 {
            max-width: 100%;
          }
        }
        .school_services_img-3 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .school_services_div-20 {
          position: relative;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          height:100%;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          padding:100px 20px;
        }
        @media (max-width: 991px) {
          .school_services_div-20 {
            max-width: 100%;
          }
        }
        .school_services_div-21 {
          display: flex;
          width: 643px;
          max-width: 100%;
          flex-direction: column;
          margin: 91px 0 66px 324px;
        }
        @media (max-width: 991px) {
          .school_services_div-21 {
            margin: 40px 0;
          }
        }
        .school_services_div-22 {
          align-self: start;
          display: flex;
          width: 143px;
          max-width: 100%;
          gap: 12px;
        }
        .school_services_div-23 {
          justify-content: center;
          color: #fff;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          white-space: nowrap;
          font: 500 8px/16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-23 {
            white-space: initial;
          }
        }
        .school_services_div-24 {
          justify-content: center;
          color: #ffe500;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          margin: auto 0;
          font: 500 12px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-25 {
          justify-content: center;
          color: #fff;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          align-self: start;
          white-space: nowrap;
          margin: 9px 0 0 0;
          font: 700 39px/42px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-25 {
            margin-left: 10px;
            white-space: initial;
          }
        }
        .school_services_div-26 {
          justify-content: center;
          color: #fff;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          letter-spacing: 0.4000000059604645px;
          margin-top: 12px;
          font: 400 18px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-26 {
            max-width: 100%;
          }
        }
        .school_services_div-27 {
          align-self: center;
          z-index: 1;
          display: flex;
          margin-top: -220px;
          width: 100%;
          max-width: 1540px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_services_div-27 {
            max-width: 100%;
            margin-top: -200px;
          }
        }
        .school_services_div-28 {
        }
        @media (max-width: 991px) {
          .school_services_div-28 {
            max-width: 100%;
          }
        }
        .school_services_div-29 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_services_div-29 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_services_column {
            width: 100%;
          }
        }
        .school_services_div-30 {
          display: flex;
          margin-top: 305px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_services_div-30 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .school_services_div-31 {
          justify-content: center;
          color: #000;
          align-self: stretch;
          white-space: nowrap;
          font: 700 26px/32px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-31 {
            max-width: 100%;
            margin-top:170px;
            padding:5px;
            white-space: initial;
          }
        }
        .school_services_div-32 {
          background-color: #f60;
          align-self: start;
          display: flex;
          margin-top: 12px;
          width: 75px;
          height: 3px;
          flex-direction: column;
        }
        .school_services_div-33 {
          justify-content: center;
          color: #333;
          align-self: stretch;
          margin-top: 4px;
          padding:8px;
          font: 400 15px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-33 {
            max-width: 100%;
          }
        }
        .school_services_div-34 {
          justify-content: center;
          color: #333;
          align-self: stretch;
          padding:8px;
          margin-top: 15px;
          font: 400 15px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-34 {
            max-width: 100%;
          }
        }
        .school_services_div-35 {
          align-self: stretch;
          margin-top: 29px;
        }
        @media (max-width: 991px) {
          .school_services_div-35 {
            max-width: 100%;
          }
        }
        .school_services_div-36 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_services_div-36 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 49%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_services_column-2 {
            width: 100%;
          }
        }
        .school_services_img-4 {
          aspect-ratio: 1.26;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 0px 20px 3px rgba(51, 51, 51, 0.1);
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .school_services_img-4 {
            margin-top: 31px;
          }
        }
        .school_services_column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 51%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-3 {
            width: 100%;
            margin-left:0;
          }
        }
        .school_services_div-37 {
          justify-content: center;
          color: #333;
          font: 400 15.5px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-37 {
            margin-top: 33px;
            max-width:100%;
            padding:8px;
            margin-right:5px;
            margin-left:auto;
          }
        }
        .school_services_div-38 {
          justify-content: center;
          color: #f60;
          align-self: stretch;
          margin-top: 19px;
          white-space: nowrap;
          font: 700 20px/24px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-38 {
            max-width: 100%;
            white-space: initial;
            padding:8px;
          }
        }
        .school_services_div-39 {
          justify-content: center;
          color: #333;
          align-self: stretch;
          margin-top: 7px;
          font: 400 15px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-39 {
            max-width: 100%;
            padding:8px;
          }
        }
        .school_services_column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-4 {
            width: 100%;
            margin-left:0;
            padding:5px;
            border-radius:4px;
          }
        }
        .school_services_div-40 {
          display: flex;
          flex-direction: column;
          align-items: start;
        }
        @media (max-width: 991px) {
          .school_services_div-40 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .school_services_div-41 {
          box-shadow: 0px 0px 20px 3px rgba(51, 51, 51, 0.1);
          background-color: #fff;
          display: flex;
          width: 383px;
          max-width: 100%;
          flex-direction: column;
          padding: 31px 30px;
        }
        @media (max-width: 991px) {
          .school_services_div-41 {
            padding: 20px  20px;
          }
        }
        .school_services_div-42 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
        }
        .school_services_div-43 {
          background-color: #f60;
          display: flex;
          width: 30px;
          height: 2px;
          flex-direction: column;
          margin: auto 0;
        }
        .school_services_div-44 {
          justify-content: center;
          color: #f60;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          align-self: stretch;
          flex-grow: 1;
          white-space: nowrap;
          font: 700 14.5px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-44 {
            white-space: initial;
          }
        }
        .school_services_div-45 {
          justify-content: center;
          color: #333;
          margin-top: 11px;
          font: 500 23px/30px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-46 {
          justify-content: space-between;
        
          background-color: #fff;
          display: flex;
          margin-top: 22px;
          gap: 9px;
          height:50px;
        }
        @media (max-width: 991px) {
          .school_services_div-46 {
            padding:0;
          }
        }
        .school_services_img-5 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 18px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-47 {
          justify-content: center;
          color: #333;
          align-self: center;
          flex-grow: 1;
          white-space: nowrap;
          margin: auto 0;
          font: 500 12px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-47 {
            white-space: initial;
          }
        }
        .school_services_div-48 {
          justify-content: center;
          color: #f00;
          margin-top: 5px;
          white-space: nowrap;
          font: 500 10px/11px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-48 {
            white-space: initial;
          }
        }
        .school_services_div-49 {
          justify-content: space-between;

          background-color: #fff;
          display: flex;
          margin-top: 5px;
          gap: 9px;
          height:50px;
        }
        @media (max-width: 991px) {
          .school_services_div-49 {
            
          }
        }
        .school_services_img-6 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 18px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-50 {
          justify-content: center;
          color: #333;
          align-self: center;
          flex-grow: 1;
          white-space: nowrap;
          margin: auto 0;
          font: 500 12px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-50 {
            white-space: initial;
          }
        }
        .school_services_div-51 {
          justify-content: space-between;
          border: 1px solid #ddd;
          background-color: #fff;
          display: flex;
          margin-top: 18px;
          gap: 9px;
          padding: 15px 80px 15px 12px;
        }
        @media (max-width: 991px) {
          .school_services_div-51 {
            padding-right: 20px;
          }
        }
        .school_services_img-7 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 18px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-52 {
          justify-content: center;
          color: #333;
          align-self: center;
          flex-grow: 1;
          white-space: nowrap;
          margin: auto 0;
          font: 500 12px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-52 {
            white-space: initial;
          }
        }
        .school_services_div-53 {
          justify-content: space-between;
          border: 1px solid #ededed;
          background-color: #fff;
          display: flex;
          margin-top: 18px;
          gap: 11px;
          padding: 15px 80px 15px 12px;
        }
        @media (max-width: 991px) {
          .school_services_div-53 {
            padding-right: 20px;
          }
        }
        .school_services_img-8 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 18px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-54 {
          justify-content: center;
          color: #000;
          align-self: center;
          flex-grow: 1;
          white-space: nowrap;
          margin: auto 0;
          font: 500 12px/46px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-54 {
            white-space: initial;
          }
        }
        .school_services_div-55 {
      
          background-color: #fff;
          display: flex;
          margin-top: 18px;
          flex-direction: column;
          align-items: start;
         height: 60px;
        }
        .school_services_img-9 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 18px;
          overflow: hidden;
          margin-bottom: 28px;
          max-width: 100%;
        }
        .school_services_div-56 {
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          background-color: #f90;
          margin-top: 20px;
          padding: 19px 20px;
          font: 600 15px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-56 {
            white-space: initial;
          }
        }
        .school_services_div-57 {
          align-self: end;
          display: flex;
          margin-top: 26px;
          width: 50px;
          max-width: 100%;
          flex-direction: column;
        }
        .school_services_div-58 {
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          background-color: #f60;
          display: flex;
          width: 100%;
          height: 50px;
          flex-direction: column;
        }
        .school_services_div-59 {
          justify-content: center;
          color: #fff;
          text-align: center;
          border-radius: 50px;
          background-color: #25d366;
          margin-top: 10px;
          aspect-ratio: 1;
          width: 100%;
          height: 50px;
          padding: 0 19px;
          font: 400 30px/30px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-60 {
          align-self: start;
          margin-top: 14px;
          width: 736px;
          max-width: 100%;
        }
        .school_services_div-61 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_services_div-61 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_services_column-5 {
            width: 100%;
          }
        }
        .school_services_div-62 {
          justify-content: center;
          color: #333;
          font: 400 16px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-62 {
            margin-top: 37px;
            padding:8px;
          }
        }
        .school_services_column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-6 {
            width: 100%;
            margin-left:0;
          }
        }
        .school_services_img-10 {
          aspect-ratio: 1.26;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 0px 20px 3px rgba(51, 51, 51, 0.1);
          overflow: hidden;
          margin-top: 12px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .school_services_img-10 {
            margin-top: 40px;
          }
        }
        .school_services_div-63 {
          justify-content: center;
          color: #f60;
          margin-top: 19px;
          white-space: nowrap;
          font: 700 20px/24px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-63 {
            max-width: 100%;
            white-space: initial;
            padding:8px;
          }
        }
        .school_services_div-64 {
          justify-content: center;
          color: #333;
          margin-top: 7px;
          max-width:50%;
          font: 400 15px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-64 {
            max-width: 100%;
            padding:8px;
          }
        }
        .school_services_div-65 {
          align-self: start;
          margin-top: 14px;
          width: 764px;
          max-width: 100%;
        }
        .school_services_div-66 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_services_div-66 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 49%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_services_column-7 {
            width: 100%;
          }
        }
        .school_services_img-11 {
          aspect-ratio: 1.26;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 0px 20px 3px rgba(51, 51, 51, 0.1);
          overflow: hidden;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_services_img-11 {
            margin-top: 40px;
          }
        }
        .school_services_column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 51%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-8 {
            width: 100%;
            margin-left:0;
          }
        }
        .school_services_div-67 {
          justify-content: center;
          color: #333;
          font: 400 16px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-67 {
            margin-top: 31px;
            padding:8px;
          }
        }
        .school_services_div-68 {
          justify-content: center;
          color: #f60;
          margin-top: 22px;
          white-space: nowrap;
          font: 700 20px/24px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-68 {
            max-width: 100%;
            white-space: initial;
            padding:8px;
          }
        }
        .school_services_div-69 {
          justify-content: center;
          color: #333;
          margin-top: 7px;
          max-width:50%;
          font: 400 15px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-69 {
            max-width: 100%;
            padding:8px;
          }
        }
        .school_services_div-70 {
          align-self: start;
          margin-top: 14px;
          width: 736px;
          max-width: 100%;
        }
        .school_services_div-71 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_services_div-71 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 49%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_services_column-9 {
            width: 100%;
          }
        }
        .school_services_div-72 {
          justify-content: center;
          color: #333;
          font: 400 16px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-72 {
            margin-top: 35px;
            padding:8px;
          }
        }
        .school_services_column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 51%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-10 {
            width: 100%;
            margin-left:0;
          }
        }
        .school_services_img-12 {
          aspect-ratio: 1.26;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 0px 20px 3px rgba(51, 51, 51, 0.1);
          overflow: hidden;
          margin-top: 12px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .school_services_img-12 {
            margin-top: 40px;
          }
        }
        .school_services_div-73 {
          justify-content: center;
          color: #333;
          max-width:50%;
          font: 400 15px/28px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-73 {
            max-width: 100%;
            padding:8px;
          }
        }
        .school_services_div-74 {
          justify-content: center;
          color: #000;
          margin-top: 57px;
          white-space: nowrap;
          font: 700 25px/27px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-74 {
            max-width: 100%;
            margin-top: 40px;
            white-space: initial;
            padding:8px;
          }
        }
        .school_services_div-75 {
          background-color: #f60;
          align-self: start;
          display: flex;
          margin-top: 9px;
          width: 75px;
          height: 3px;
          flex-direction: column;
        }
        .school_services_div-76 {
          align-self: start;
          margin-top: 20px;
          width: 760px;
          max-width: 100%;
          
        }
        .school_services_div-77 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_services_div-77 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_services_column-11 {
            width: 100%;
          }
        }
        .school_services_div-78 {
          justify-content: center;
          align-items: center;
          border: 1px solid #e5e5e5;
          background-color: #fafafa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 22px 78px;
        }
        @media (max-width: 991px) {
          .school_services_div-78 {
            margin-top: 8px;
            padding: 0 20px;
          }
        }
        .school_services_img-13 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 90px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .school_services_div-79 {
          justify-content: center;
          color: #000;
          text-align: center;
          align-self: stretch;
          margin-top: 15px;
          white-space: nowrap;
          font: 600 16px/20px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-79 {
            white-space: initial;
          }
        }
        .school_services_column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 34%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-12 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_services_div-80 {
          border: 1px solid #e5e5e5;
          background-color: #fafafa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          align-items: center;
          width: 100%;
          margin: 0 auto;
          padding: 22px 29px 22px 79px;
        }
        @media (max-width: 991px) {
          .school_services_div-80 {
            margin-top: 8px;
            padding: 0 20px;
          }
        }
        .school_services_img-14 {
          aspect-ratio: 1.52;
          object-fit: contain;
          object-position: center;
          width: 137px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .school_services_div-81 {
          justify-content: center;
          color: #000;
          text-align: center;
          align-self: stretch;
          margin-top: 15px;
          font: 600 16px/20px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-13 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_services_div-82 {
          justify-content: center;
          align-items: center;
          border: 1px solid #e5e5e5;
          background-color: #fafafa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 23px 67px;
        }
        @media (max-width: 991px) {
          .school_services_div-82 {
            margin-top: 8px;
            padding: 0 20px;
          }
        }
        .school_services_img-15 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 90px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-83 {
          justify-content: center;
          color: #000;
          text-align: center;
          align-self: stretch;
          margin-top: 15px;
          white-space: nowrap;
          font: 600 16px/20px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-83 {
            white-space: initial;
          }
        }
        .school_services_div-84 {
          align-self: start;
          margin-top: 15px;
          width: 767px;
          max-width: 100%;
        }
        .school_services_div-85 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_services_div-85 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_services_column-14 {
            width: 100%;
          }
        }
        .school_services_div-86 {
          justify-content: center;
          align-items: center;
          border: 1px solid #e5e5e5;
          background-color: #fafafa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 23px 78px;
        }
        @media (max-width: 991px) {
          .school_services_div-86 {
            margin-top: 15px;
            padding: 0 20px;
          }
        }
        .school_services_img-16 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 90px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .school_services_div-87 {
          justify-content: center;
          color: #000;
          text-align: center;
          align-self: stretch;
          margin-top: 14px;
          white-space: nowrap;
          font: 600 15px/20px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-87 {
            white-space: initial;
          }
        }
        .school_services_column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-15 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_services_div-88 {
          justify-content: center;
          align-items: center;
          border: 1px solid #e5e5e5;
          background-color: #fafafa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 23px 75px;
        }
        @media (max-width: 991px) {
          .school_services_div-88 {
            margin-top: 15px;
            padding: 0 20px;
          }
        }
        .school_services_img-17 {
          aspect-ratio: 1.06;
          object-fit: contain;
          object-position: center;
          width: 95px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .school_services_div-89 {
          justify-content: center;
          color: #000;
          text-align: center;
          align-self: stretch;
          margin-top: 16px;
          white-space: nowrap;
          font: 600 16px/20px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-89 {
            white-space: initial;
          }
        }
        .school_services_column-16 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-16 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_services_div-90 {
          justify-content: center;
          align-items: center;
          border: 1px solid #e5e5e5;
          background-color: #fafafa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 23px 78px;
        }
        @media (max-width: 991px) {
          .school_services_div-90 {
            margin-top: 15px;
            padding: 0 20px;
          }
        }
        .school_services_img-18 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 90px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .school_services_div-91 {
          justify-content: center;
          color: #000;
          text-align: center;
          margin-top: 16px;
          white-space: nowrap;
          font: 600 16px/20px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-91 {
            white-space: initial;
          }
        }
        .school_services_div-92 {
          align-self: start;
          margin-top: 15px;
          width: 761px;
          max-width: 100%;
        }
        .school_services_div-93 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_services_div-93 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column-17 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 34%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_services_column-17 {
            width: 100%;
          }
        }
        .school_services_div-94 {
          justify-content: center;
          align-items: center;
          border: 1px solid #e5e5e5;
          background-color: #fafafa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 21px 78px;
        }
        @media (max-width: 991px) {
          .school_services_div-94 {
            margin-top: 9px;
            padding: 0 20px;
          }
        }
        .school_services_img-19 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 90px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .school_services_div-95 {
          justify-content: center;
          color: #000;
          text-align: center;
          margin-top: 15px;
          white-space: nowrap;
          font: 600 16px/20px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-95 {
            white-space: initial;
          }
        }
        .school_services_column-18 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-18 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_services_div-96 {
          justify-content: center;
          align-items: center;
          border: 1px solid #e5e5e5;
          background-color: #fafafa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 22px 78px;
        }
        @media (max-width: 991px) {
          .school_services_div-96 {
            margin-top: 9px;
            padding: 0 20px;
          }
        }
        .school_services_img-20 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 90px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .school_services_div-97 {
          justify-content: center;
          color: #000;
          text-align: center;
          align-self: stretch;
          margin-top: 15px;
          white-space: nowrap;
          font: 600 16px/20px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-97 {
            white-space: initial;
          }
        }
        .school_services_column-19 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-19 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_services_div-98 {
          justify-content: center;
          align-items: center;
          border: 1px solid #e5e5e5;
          background-color: #fafafa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 23px 78px;
        }
        @media (max-width: 991px) {
          .school_services_div-98 {
            margin-top: 9px;
            padding: 0 20px;
          }
        }
        .school_services_img-21 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 90px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .school_services_div-99 {
          justify-content: center;
          color: #000;
          text-align: center;
          align-self: stretch;
          margin-top: 15px;
          font: 600 16px/20px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-100 {
          background-color: #fafafa;
          align-self: stretch;
          margin-top: 80px;
          width: 100%;
          padding-bottom: 30px;
        }
        @media (max-width: 991px) {
          .school_services_div-100 {
            max-width: 100%;
            padding-left: 20px;
            margin-top: 40px;
          }
        }
        .school_services_div-101 {
          gap: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

        }
        @media (max-width: 991px) {
          .school_services_div-101 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column-20 {
          display: flex;
          align-self: center;
          flex-direction: column;
          line-height: normal;
          width: 72%;
          margin: 40px 0 0 0;
        
        }
        @media (max-width: 991px) {
          .school_services_column-20 {
            width: 100%;
          }
        }
        .school_services_div-102 {
          z-index: 1;
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_services_div-102 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .school_services_div-103 {
          align-self: center;
          display: flex;
          width: 143px;
          max-width: 100%;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
        }
        .school_services_div-104 {
          background-color: #f60;
          display: flex;
          width: 30px;
          height: 2px;
          flex-direction: column;
          margin: auto 0;
        }
        .school_services_div-105 {
          justify-content: center;
          color: #f60;
          text-align: center;
          text-transform: uppercase;
          font: 700 14px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-106 {
          justify-content: center;
          color: #000;
          text-align: center;
          align-self: center;
          margin-top: 11px;
          max-width: 341px;
          font: 500 28px/38px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_div-107 {
          justify-content: center;
          color: #333;
          text-align: center;
          align-self: center;
          margin-top: 14px;
          max-width: 746px;
          font: 400 15px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-107 {
            max-width: 100%;
            padding:8px;
          }
        }
        .school_services_div-108 {
          disply: flex;
          flex-direction: column;
          justify-content: center;
          color: #999;
          text-align: center;
          position: relative;
          overflow: hidden;
          align-self: stretch;
          min-height: 145px;
          margin-top: 25px;
          width: 100%;
          padding: 64px 0 57px;
          font: 400 40px/40px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-108 {
            max-width: 100%;
          }
        }
        .school_services_img-22 {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .school_services_div-109 {
          position: relative;
        }
        .school_services_column-21 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 28%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-21 {
            width: 100%;
          }
        }
        .school_services_img-23 {
          aspect-ratio: 1.14;
          object-fit: contain;
          object-position: center;
          width: 456px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-110 {
          justify-content: center;
          align-items: center;
          background: linear-gradient(90deg, #f60 0%, #fc7972 100%);
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 50px 20px;
        }
        @media (max-width: 991px) {
          .school_services_div-110 {
            max-width: 100%;
          }
        }
        .school_services_div-111 {
          width: 1140px;
          max-width: 100%;
          margin: 30px 0;
        }
        .school_services_div-112 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_services_div-112 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_services_column-22 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 46%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_services_column-22 {
            width: 100%;
          }
        }
        .school_services_div-113 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_services_div-113 {
            margin-top: 40px;
          }
        }
        .school_services_div-114 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
        }
        .school_services_div-115 {
          background-color: #fff;
          display: flex;
          width: 30px;
          height: 2px;
          flex-direction: column;
          margin: auto 0;
        }
        .school_services_div-116 {
          justify-content: center;
          color: #fff;
          text-transform: uppercase;
          align-self: stretch;
          flex-grow: 1;
          white-space: nowrap;
          font: 700 15px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-116 {
            white-space: initial;
          }
        }
        .school_services_div-117 {
          justify-content: center;
          color: #fff;
          margin-top: 12px;
          font: 500 37px/46px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_services_img-24 {
          aspect-ratio: 1.13;
          object-fit: contain;
          object-position: center;
          width: 100%;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          margin-top: 22px;
        }
        .school_services_column-23 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 54%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_services_column-23 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_services_div-118 {
          background-color: #fff;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 50px 40px;
        }
        @media (max-width: 991px) {
          .school_services_div-118 {
            max-width: 100%;
            margin-top: 40px;
            padding: 20px 20px;
          }
        }
        .school_services_div-119 {
          align-self: start;
          display: flex;
          width: 182px;
          max-width: 100%;
          align-items: center;
          gap: 10px;
        }
        .school_services_div-120 {
          background-color: #f60;
          display: flex;
          width: 30px;
          height: 2px;
          flex-direction: column;
          margin: auto 0;
        }
        .school_services_div-121 {
          justify-content: center;
          color: #f60;
          text-transform: uppercase;
          align-self: stretch;
          flex-grow: 1;
          white-space: nowrap;
          font: 700 14.5px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-121 {
            white-space: initial;
          }
        }
        .school_services_div-122 {
          justify-content: center;
          color: #000;
          align-self: stretch;
          margin-top: 9px;
          font: 500 26px/36px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-122 {
            max-width: 100%;
          }
        }
        .school_services_div-123 {
          justify-content: center;
          color: #fff;
          letter-spacing: 0.4000000059604645px;
          white-space: nowrap;
          border-radius: 5px;;
          background-color: #7913ff;
          align-self: start;
          margin-top: 13px;
          width: 249px;
          max-width: 100%;
          padding: 11px 20px;
          font: 600 14px/15px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-123 {
            white-space: initial;
            padding:10px;
          }
        }
        .school_services_div-124 {
          align-self: stretch;
          display: flex;
          margin-top: 25px;
          width: 100%;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_services_div-124 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .school_services_div-125 {
          justify-content: space-between;
          height:50px;
          background-color: #fff;
          display: flex;
          width:100%;
          gap: 9px;
         
        }
        @media (max-width: 991px) {
          .school_services_div-125 {
            padding-right: 20px;
          }
        }
        .school_services_img-25 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-126 {
          justify-content: center;
          color: #555;
          align-self: center;
          flex-grow: 1;
          white-space: nowrap;
          margin: auto 0;
          font: 500 13px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-126 {
            white-space: initial;
          }
        }
        .school_services_div-127 {
          justify-content: space-between;
          border: 1px solid #ddd;
          background-color: #fff;
          display: flex;
          gap: 9px;
          padding: 14px 55px 14px 14px;
        }
        @media (max-width: 991px) {
          .school_services_div-127 {
            padding-right: 20px;
          }
        }
        .school_services_img-26 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-128 {
          justify-content: center;
          color: #555;
          align-self: center;
          flex-grow: 1;
          white-space: nowrap;
          margin: auto 0;
          font: 500 13px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-128 {
            white-space: initial;
          }
        }
        .school_services_div-129 {
          justify-content: center;
          color: #f00;
          letter-spacing: 0.4000000059604645px;
          align-self: stretch;
          margin-top: 7px;
          white-space: nowrap;
          font: 500 10px/11px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-129 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_services_div-130 {
          align-self: stretch;
          display: flex;
          margin-top: 10px;
          width: 100%;

          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_services_div-130 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .school_services_div-131 {
          justify-content: space-between;
          height:50px;
          background-color: #fff;
          display: flex;
          width:100%;
          gap: 9px;
        
        }
        @media (max-width: 991px) {
          .school_services_div-131 {
            padding-right: 20px;
          }
        }
        .school_services_img-27 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-132 {
          justify-content: center;
          color: #555;
          align-self: center;
          flex-grow: 1;
          white-space: nowrap;
          margin: auto 0;
          font: 500 13px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-132 {
            white-space: initial;
          }
        }
        .school_services_div-133 {
          justify-content: space-between;
          border: 1px solid #ededed;
          background-color: #fff;
          display: flex;
          gap: 9px;
          padding: 14px 80px 14px 14px;
        }
        @media (max-width: 991px) {
          .school_services_div-133 {
            padding-right: 20px;
          }
        }
        .school_services_img-28 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 20px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_services_div-134 {
          justify-content: center;
          color: #000;
          align-self: center;
          flex-grow: 1;
          white-space: nowrap;
          margin: auto 0;
          font: 500 13px/46px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-134 {
            white-space: initial;
          }
        }
        .school_services_div-135 {
     
          background-color: #fff;
          align-self: stretch;
          display: flex;
          margin-top: 25px;
          flex-direction: column;
          align-items: start;
          height:60px;
        }
        @media (max-width: 991px) {
          .school_services_div-135 {
            max-width: 100%;
          }
        }
        .school_services_img-29 {
          aspect-ratio: 2.25;
          object-fit: contain;
          object-position: center;
          width: 45px;
          overflow: hidden;
          margin-bottom: 16px;
          max-width: 100%;
        }
        .school_services_div-136 {
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          background-color: #f90;
          align-self: center;
          margin-top: 27px;
          width: 320px;
          max-width: 100%;
          padding: 19px 20px;
          font: 700 14.5px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .school_services_div-136 {
            white-space: initial;
          }
        }
      `}</style>
    </>
  );
}

export default School_Services;

import React , {useEffect} from 'react';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
function Web_design() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
                      <Helmet>
        <title>Elevate Your Presence: Expert Web & App Design Services by Custoking</title>
        <meta name="description" content="Discover cutting-edge web and app design services at Custoking. Creative solutions that transform your digital presence. From conceptualization to execution, we craft experiences that resonate with your audience." />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div className="web_div">
        <div className="web_div-2">
          <div className="web_div-3">
            <div className="web_div-4">One stop solution for all your needs!</div>
            <div className="web_div-5">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/edd53ee3-2f9c-4f6b-9963-c96073c57091?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="img"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3668e3ba-3433-4bbf-9d36-fff166fe7f5c?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="img"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e73b77bf-a74f-4b29-be36-bab213381c14?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="web_img-2"
              />
            </div>
          </div>

        </div>
        <div className="web_div-12">
          <div className="web_div-13">What do you need designed?</div>
          <div className="web_div-14">
          <Link to = "/Logo_Identity" style={{ textDecoration: 'none' }}>
            <div className="web_div-15">Logo & identity </div>
            </Link>
            <Link to = "/Web_Design" style={{ textDecoration: 'none' }}>
            <div className="web_div-16">Web & app design</div>
            </Link>
            <Link to = "/Business_Design"  style={{ textDecoration: 'none' }}>
            <div className="web_div-17">Business & advertising</div>
            </Link>
            <Link to = "/Apparel_Design" style={{ textDecoration: 'none' }}>
            <div className="web_div-18">Clothing & merchandise</div>
            </Link>
          </div>
        </div>
        <div className="web_div-19">
          <div className="web_div-20">
            <div className="column">
              <div className="web_div-21">
                <div className="web_div-22">
                  <div className="web_div-23">
                    <div className="web_column-2">
                      <div className="web_div-24">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c4e5f251-d498-4982-af52-fa17cbb43485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4e5f251-d498-4982-af52-fa17cbb43485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4e5f251-d498-4982-af52-fa17cbb43485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4e5f251-d498-4982-af52-fa17cbb43485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4e5f251-d498-4982-af52-fa17cbb43485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4e5f251-d498-4982-af52-fa17cbb43485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4e5f251-d498-4982-af52-fa17cbb43485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4e5f251-d498-4982-af52-fa17cbb43485?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="web_img-4"
                        />
                        <div className="web_div-25">Website Builders</div>
                        <div className="web_div-26">
                          Easily bring your website
                          <br />
                          to life, so you can stay
                          <br />
                          focused on running your
                          <br />
                          business
                        </div>
                      </div>
                    </div>
                    <div className="web_column-3">
                      <div className="web_div-27">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/25b643dd-e342-4e25-87d2-abce466c0a4d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/25b643dd-e342-4e25-87d2-abce466c0a4d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/25b643dd-e342-4e25-87d2-abce466c0a4d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/25b643dd-e342-4e25-87d2-abce466c0a4d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/25b643dd-e342-4e25-87d2-abce466c0a4d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/25b643dd-e342-4e25-87d2-abce466c0a4d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/25b643dd-e342-4e25-87d2-abce466c0a4d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/25b643dd-e342-4e25-87d2-abce466c0a4d?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="web_img-5"
                        />
                        <div className="web_div-28">Web page design</div>
                        <div className="web_div-29">
                          Engaging custom web design that connects with visitors
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="web_div-30">
                  <div className="web_div-31">
                    <div className="web_column-4">
                      <div className="web_div-32">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b34c637a-44bc-40e2-9387-f5cb4742ab65?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b34c637a-44bc-40e2-9387-f5cb4742ab65?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b34c637a-44bc-40e2-9387-f5cb4742ab65?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b34c637a-44bc-40e2-9387-f5cb4742ab65?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b34c637a-44bc-40e2-9387-f5cb4742ab65?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b34c637a-44bc-40e2-9387-f5cb4742ab65?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b34c637a-44bc-40e2-9387-f5cb4742ab65?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b34c637a-44bc-40e2-9387-f5cb4742ab65?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="web_img-6"
                        />
                        <div className="web_div-33">Website Redesign</div>
                        <div className="web_div-34">
                          A refreshed website to better showcase your business
                        </div>
                      </div>
                    </div>
                    <div className="web_column-5">
                      <div className="web_div-35">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/36ae63ab-22a5-452e-a65a-dd6cfdc3ba19?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/36ae63ab-22a5-452e-a65a-dd6cfdc3ba19?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/36ae63ab-22a5-452e-a65a-dd6cfdc3ba19?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/36ae63ab-22a5-452e-a65a-dd6cfdc3ba19?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/36ae63ab-22a5-452e-a65a-dd6cfdc3ba19?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/36ae63ab-22a5-452e-a65a-dd6cfdc3ba19?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/36ae63ab-22a5-452e-a65a-dd6cfdc3ba19?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/36ae63ab-22a5-452e-a65a-dd6cfdc3ba19?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="web_img-7"
                        />
                        <div className="web_div-36">Blog</div>
                        <div className="web_div-37">
                          Custom blog design to <br />
                          keep them reading
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="web_column-6">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e6759a70-a268-40a7-aa05-83551139b49f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6759a70-a268-40a7-aa05-83551139b49f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6759a70-a268-40a7-aa05-83551139b49f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6759a70-a268-40a7-aa05-83551139b49f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6759a70-a268-40a7-aa05-83551139b49f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6759a70-a268-40a7-aa05-83551139b49f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6759a70-a268-40a7-aa05-83551139b49f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6759a70-a268-40a7-aa05-83551139b49f?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="web_img-8"
              />
            </div>
          </div>
        </div>
        <div className="web_div-38">
          <div className="web_div-39">
            <div className="web_column-7">
              <div className="web_div-40">
                <div className="web_div-41">
                  <div className="web_column-8">
                    <div className="web_div-42">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ce5c6845-59a9-45ef-9f83-03ae3eb68fab?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce5c6845-59a9-45ef-9f83-03ae3eb68fab?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce5c6845-59a9-45ef-9f83-03ae3eb68fab?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce5c6845-59a9-45ef-9f83-03ae3eb68fab?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce5c6845-59a9-45ef-9f83-03ae3eb68fab?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce5c6845-59a9-45ef-9f83-03ae3eb68fab?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce5c6845-59a9-45ef-9f83-03ae3eb68fab?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ce5c6845-59a9-45ef-9f83-03ae3eb68fab?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="web_img-9"
                      />
                      <div className="web_div-43">
                        WordPress theme
                        
                        design
                        <br />
                      </div>
                      <div className="web_div-44">
                        A custom WordPress
                        <br />
                        theme that does
                        <br />
                        everything you need it to
                      </div>
                    </div>
                  </div>
                  <div className="web_column-9">
                    <div className="web_div-45">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6ab1f24a-d509-4721-8b95-a9286c3bbcf2?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab1f24a-d509-4721-8b95-a9286c3bbcf2?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab1f24a-d509-4721-8b95-a9286c3bbcf2?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab1f24a-d509-4721-8b95-a9286c3bbcf2?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab1f24a-d509-4721-8b95-a9286c3bbcf2?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab1f24a-d509-4721-8b95-a9286c3bbcf2?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab1f24a-d509-4721-8b95-a9286c3bbcf2?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ab1f24a-d509-4721-8b95-a9286c3bbcf2?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="web_img-10"
                      />
                      <div className="web_div-46">
                        Landing page <br />
                        design
                      </div>
                      <div className="web_div-47">
                        Landing page that gets clicks
                      </div>
                    </div>
                  </div>
                  <div className="web_column-10">
                    <div className="web_div-48">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b8d6c9cb-b0c4-4610-8e3a-ebf3b709f1fe?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8d6c9cb-b0c4-4610-8e3a-ebf3b709f1fe?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8d6c9cb-b0c4-4610-8e3a-ebf3b709f1fe?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8d6c9cb-b0c4-4610-8e3a-ebf3b709f1fe?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8d6c9cb-b0c4-4610-8e3a-ebf3b709f1fe?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8d6c9cb-b0c4-4610-8e3a-ebf3b709f1fe?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8d6c9cb-b0c4-4610-8e3a-ebf3b709f1fe?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8d6c9cb-b0c4-4610-8e3a-ebf3b709f1fe?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="web_img-11"
                      />
                      <div className="web_div-49">Icon or button</div>
                      <div className="web_div-50">
                        Professionally designed
                        <br />
                        icons, buttons and <br />
                        favicons for web & app
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="web_column-11">
              <div className="web_div-51">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bdf7ce68-90e6-4471-9495-ccab0bd4b9c3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdf7ce68-90e6-4471-9495-ccab0bd4b9c3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdf7ce68-90e6-4471-9495-ccab0bd4b9c3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdf7ce68-90e6-4471-9495-ccab0bd4b9c3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdf7ce68-90e6-4471-9495-ccab0bd4b9c3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdf7ce68-90e6-4471-9495-ccab0bd4b9c3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdf7ce68-90e6-4471-9495-ccab0bd4b9c3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdf7ce68-90e6-4471-9495-ccab0bd4b9c3?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="web_img-12"
                />
                <div className="web_div-52">App Icon</div>
                <div className="web_div-53">
                  A stunning app icon guaranteed to get you
                  
                  downloads
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="web_div-54">
          <div className="web_div-55">
            <div className="web_column-12">
              <div className="web_div-56">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8a8591e2-994e-4382-8ab2-ea6aa0a70801?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8591e2-994e-4382-8ab2-ea6aa0a70801?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8591e2-994e-4382-8ab2-ea6aa0a70801?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8591e2-994e-4382-8ab2-ea6aa0a70801?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8591e2-994e-4382-8ab2-ea6aa0a70801?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8591e2-994e-4382-8ab2-ea6aa0a70801?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8591e2-994e-4382-8ab2-ea6aa0a70801?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8591e2-994e-4382-8ab2-ea6aa0a70801?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="web_img-13"
                />
                <div className="web_div-57">Website Icon</div>
                <div className="web_div-58">
                  A website icon that users
                  <br />
                  will recognize
                </div>
              </div>
            </div>
            <div className="web_column-13">
              <div className="web_div-59">
                <div className="web_div-60">
                  <div className="web_column-14">
                    <div className="web_div-61">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/38a1e203-84b9-4bc2-830e-60815dd08a18?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/38a1e203-84b9-4bc2-830e-60815dd08a18?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/38a1e203-84b9-4bc2-830e-60815dd08a18?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/38a1e203-84b9-4bc2-830e-60815dd08a18?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/38a1e203-84b9-4bc2-830e-60815dd08a18?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/38a1e203-84b9-4bc2-830e-60815dd08a18?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/38a1e203-84b9-4bc2-830e-60815dd08a18?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/38a1e203-84b9-4bc2-830e-60815dd08a18?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="web_img-14"
                      />
                      <div className="web_div-62">Form</div>
                      <div className="web_div-63">
                        Form customized to <br />
                        collect the data you need
                      </div>
                    </div>
                  </div>
                  <div className="web_column-15">
                    <div className="web_div-64">
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1ed6ee24-333f-41c5-81c6-ff3b97dbf514?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed6ee24-333f-41c5-81c6-ff3b97dbf514?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed6ee24-333f-41c5-81c6-ff3b97dbf514?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed6ee24-333f-41c5-81c6-ff3b97dbf514?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed6ee24-333f-41c5-81c6-ff3b97dbf514?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed6ee24-333f-41c5-81c6-ff3b97dbf514?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed6ee24-333f-41c5-81c6-ff3b97dbf514?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed6ee24-333f-41c5-81c6-ff3b97dbf514?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                        className="web_img-15"
                      />
                      <div className="web_div-65">App design</div>
                      <div className="web_div-66">
                        A user friendly app that
                        <br />
                        gets downloaded
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="web_column-16">
              <div className="web_div-67">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1b824cc0-d4ab-45b3-aa3b-27f384291719?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b824cc0-d4ab-45b3-aa3b-27f384291719?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b824cc0-d4ab-45b3-aa3b-27f384291719?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b824cc0-d4ab-45b3-aa3b-27f384291719?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b824cc0-d4ab-45b3-aa3b-27f384291719?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b824cc0-d4ab-45b3-aa3b-27f384291719?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b824cc0-d4ab-45b3-aa3b-27f384291719?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b824cc0-d4ab-45b3-aa3b-27f384291719?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="web_img-16"
                />
                <div className="web_div-68">ios App</div>
                <div className="web_div-69">
                  An iOS app design that’ll
                  <br />
                  be the apple of your eye
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="web_div-70">
          <div className="web_div-71">
            <div className="web_div-72">
              <div className="web_column-17">
                <div className="web_div-73">
                  <div className="web_div-74">
                    <div className="web_column-18">
                      <div className="web_div-75">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6fc79a16-2bb6-4d32-bf06-bdf739024299?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6fc79a16-2bb6-4d32-bf06-bdf739024299?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6fc79a16-2bb6-4d32-bf06-bdf739024299?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6fc79a16-2bb6-4d32-bf06-bdf739024299?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6fc79a16-2bb6-4d32-bf06-bdf739024299?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6fc79a16-2bb6-4d32-bf06-bdf739024299?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6fc79a16-2bb6-4d32-bf06-bdf739024299?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6fc79a16-2bb6-4d32-bf06-bdf739024299?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="web_img-17"
                        />
                        <div className="web_div-76">Android App</div>
                        <div className="web_div-77">
                          An app that looks great on any Android device
                        </div>
                      </div>
                    </div>
                    <div className="web_column-19">
                      <div className="web_div-78">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f749529a-bce1-4a62-a9a6-785f3fc0339d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f749529a-bce1-4a62-a9a6-785f3fc0339d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f749529a-bce1-4a62-a9a6-785f3fc0339d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f749529a-bce1-4a62-a9a6-785f3fc0339d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f749529a-bce1-4a62-a9a6-785f3fc0339d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f749529a-bce1-4a62-a9a6-785f3fc0339d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f749529a-bce1-4a62-a9a6-785f3fc0339d?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f749529a-bce1-4a62-a9a6-785f3fc0339d?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="web_img-18"
                        />
                        <div className="web_div-79">Facebook cover</div>
                        <div className="web_div-80">
                          A custom Facebook cover
                          <br />
                          that’ll get more clicks,
                          <br />
                          likes and shares
                        </div>
                      </div>
                    </div>
                    <div className="web_column-20">
                      <div className="web_div-81">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f153a7f6-e2d4-4960-8506-af2b5133f9c6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f153a7f6-e2d4-4960-8506-af2b5133f9c6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f153a7f6-e2d4-4960-8506-af2b5133f9c6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f153a7f6-e2d4-4960-8506-af2b5133f9c6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f153a7f6-e2d4-4960-8506-af2b5133f9c6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f153a7f6-e2d4-4960-8506-af2b5133f9c6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f153a7f6-e2d4-4960-8506-af2b5133f9c6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f153a7f6-e2d4-4960-8506-af2b5133f9c6?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="web_img-19"
                        />
                        <div className="web_div-82">Social media page</div>
                        <div className="web_div-83">
                          Social media backgrounds
                          <br />
                          and images to engage your
                          <br />
                          followers
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="web_column-21">
                <div className="web_div-84">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1cffe126-5e72-43db-99ba-0f7754cb5824?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1cffe126-5e72-43db-99ba-0f7754cb5824?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1cffe126-5e72-43db-99ba-0f7754cb5824?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1cffe126-5e72-43db-99ba-0f7754cb5824?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1cffe126-5e72-43db-99ba-0f7754cb5824?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1cffe126-5e72-43db-99ba-0f7754cb5824?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1cffe126-5e72-43db-99ba-0f7754cb5824?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1cffe126-5e72-43db-99ba-0f7754cb5824?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="web_img-20"
                  />
                  <div className="web_div-85">Twitter</div>
                  <div className="web_div-86">
                    A Twitter header that
                    <br />
                    complements your tweets
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="web_div-87">
            <div className="web_div-88">
              <div className="web_column-22">
                <div className="web_div-89">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8e9d7394-dbec-448b-81fc-a8ba69669ce0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8e9d7394-dbec-448b-81fc-a8ba69669ce0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8e9d7394-dbec-448b-81fc-a8ba69669ce0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8e9d7394-dbec-448b-81fc-a8ba69669ce0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8e9d7394-dbec-448b-81fc-a8ba69669ce0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8e9d7394-dbec-448b-81fc-a8ba69669ce0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8e9d7394-dbec-448b-81fc-a8ba69669ce0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8e9d7394-dbec-448b-81fc-a8ba69669ce0?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="web_img-21"
                  />
                  <div className="web_div-90">Youtube</div>
                  <div className="web_div-91">
                    Youtube channel design
                    <br /> that will build your subscriber list
                  </div>
                </div>
              </div>
              <div className="web_column-23">
                <div className="web_div-92">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5eb3634d-da53-419c-83a3-82ae0dcd20c0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eb3634d-da53-419c-83a3-82ae0dcd20c0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eb3634d-da53-419c-83a3-82ae0dcd20c0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eb3634d-da53-419c-83a3-82ae0dcd20c0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eb3634d-da53-419c-83a3-82ae0dcd20c0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eb3634d-da53-419c-83a3-82ae0dcd20c0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eb3634d-da53-419c-83a3-82ae0dcd20c0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eb3634d-da53-419c-83a3-82ae0dcd20c0?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="web_img-22"
                  />
                  <div className="web_div-93">Banner ad</div>
                  <div className="web_div-94">
                    Web ads that drive
                    <br />
                    conversions
                  </div>
                </div>
              </div>
              <div className="web_column-24">
                <div className="web_div-95">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/07c27431-8219-4e44-b83c-d54f05157350?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/07c27431-8219-4e44-b83c-d54f05157350?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/07c27431-8219-4e44-b83c-d54f05157350?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/07c27431-8219-4e44-b83c-d54f05157350?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/07c27431-8219-4e44-b83c-d54f05157350?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/07c27431-8219-4e44-b83c-d54f05157350?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/07c27431-8219-4e44-b83c-d54f05157350?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/07c27431-8219-4e44-b83c-d54f05157350?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="web_img-23"
                  />
                  <div className="web_div-96">App design</div>
                  <div className="web_div-97">
                    Web. app or digital design
                    <br />
                    so cutting edge we haven’t <br />
                    created a category for it
                    <br />
                    yet
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="web_div-98">
          <div className="web_div-99">
            Still haven’t found what you’re looking for? Search and you shall
            find
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d2d1149d-940e-42ae-9ec1-572b9cb62b5f?apiKey=db6f3fe714c542fe811ae992d63ea686&"
            className="web_img-24"
          />
        </div>
        <div className="web_div-100">
          <div className="web_div-101">
            <div className="web_div-102">
              <div className="web_column-25">
                <div className="web_div-103">
                  <div className="web_div-104">
                    <div className="web_column-26">
                      <div className="web_div-105">
                        <div className="web_div-106">Company</div>
                        <div className="web_div-107">About</div>
                        <div className="web_div-108">Contact</div>
                        <div className="web_div-109">Careers</div>
                        <div className="web_div-110">Team</div>
                        <div className="web_div-111">Press releases</div>
                        <div className="web_div-112">In the media</div>
                        <div className="web_div-113">Testimonals</div>
                      </div>
                    </div>
                    <div className="web_column-27">
                      <div className="web_div-114">
                        <div className="web_div-115">Design services</div>
                        <div className="web_div-116">Design contests</div>
                        <div className="web_div-117">1-to-1 projects</div>
                        <div className="web_div-118">Find a designer</div>
                        <div className="web_div-119">Discover inspiration</div>
                        <div className="web_div-120">Pricing</div>
                        <div className="web_div-121">Agencies</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="web_column-28">
                <div className="web_div-122">
                  <div className="web_div-123">
                    <div className="web_column-29">
                      <div className="web_div-124">
                        <div className="web_div-125">Get a design</div>
                        <div className="web_div-126">Logo design</div>
                        <div className="web_div-127">Business card</div>
                        <div className="web_div-128">Web page design</div>
                        <div className="web_div-129">Brand guide</div>
                        <div className="web_div-130">Packaging design</div>
                        <div className="web_div-131">T-Shirt design</div>
                        <div className="web_div-132">Book cover design</div>
                        <div className="web_div-133">Browse all categories</div>
                      </div>
                    </div>
                    <div className="web_column-30">
                      <div className="web_div-134">
                        <div className="web_div-135">Resources</div>
                        <div className="web_div-136">Become a designer</div>
                        <div className="web_div-137">Blog</div>
                        <div className="web_div-138">Awards</div>
                        <div className="web_div-139">Affiliates</div>
                        <div className="web_div-140">Logo - ideas</div>
                        <div className="web_div-141">T-shirt ideas</div>
                        <div className="web_div-142">Designer resources</div>
                        <div className="web_div-143">Featured Partners</div>
                        <div className="web_div-144">Help</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="web_div-145">
            <div className="web_div-146">Help?</div>
          </div>
          <div className="web_div-147" />
        </div>
      </div>
      <style jsx>{`
        .web_div {
          background-color: #fff;
          display: flex;
          flex-direction: column;
        }
        .web_div-2 {
          align-self: stretch;
          display: flex;
          margin-top: 38px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-2 {
            max-width: 100%;
            margin-top: 10px;
          }
        }
        .web_div-3 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 993px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .web_div-3 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .web_div-4 {
          color: #000;
          text-align: center;
          align-self: stretch;
          max-width: 1038px;
          flex-grow: 1;
          flex-basis: auto;
          font: 300 36px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-4 {
            max-width: 100%;
          }
        }
        .web_div-5 {
          align-self: stretch;
          display: flex;
          width: 185px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .web_div-5 {
            justify-content: center;
          }
        }
        .img {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .web_img-2 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .web_div-6 {
          background-color: #d9d9d9;
          align-self: center;
          display: flex;
          margin-top: 27px;
          width: 100%;
          max-width: 1511px;
          flex-grow: 1;
          flex-direction: column;
          padding: 77px 20px 58px;
        }
        @media (max-width: 991px) {
          .web_div-6 {
            max-width: 100%;
          }
        }
        .web_div-7 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 992px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .web_div-7 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .web_div-8 {
          color: #000;
          text-align: center;
          align-self: center;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
          font: 600 36px Inter, sans-serif;
        }
        .web_div-9 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .web_div-10 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .web_div-11 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .web_div-12 {
          align-self: center;
          display: flex;
        
          width: 100%;
          max-width: 1395px;
          flex-direction: column;
          padding: 20px 20px 0  20px;
        }
        @media (max-width: 991px) {
          .web_div-12 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-13 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 800px;
          font: 600 30px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-13 {
            max-width: 100%;
          }
        }
        .web_div-14 {
          align-self: center;
          display: flex;
          width: 100%;
          height: 60px;
          align-items: stretch; /* Change to stretch to make all items equal height */
          justify-content: space-between; /* Equally space the items */
        
          
          margin: 30px 20px 0 20px;
        }
        @media (max-width: 991px) {
          .web_div-14 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
            height: auto;
            gap:5px;
          }
        }
        .web_img-3 {
          aspect-ratio: 0.87;
          object-fit: cover;
          object-position: center;
          width: 99px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .web_div-15 {
          color: #000;
          height: 80px;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 300px;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; /* Center vertically */
          text-align: center; /* Center horizontally */
          padding: 15px;
          font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-15 {
            padding-left: 1px;
          }
        }
        @media (max-width: 640px) {
          .web_div-15 {
            width: 304px;
          }
        }
        .web_div-16 {
          color: #000;
          height: 80px;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 300px;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; /* Center vertically */
          text-align: center; /* Center horizontally */
          padding: 15px;
          font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .web_div-16 {
            width: 306px;
          }
        }
        .web_div-17 {
          color: #000;
          height: 80px;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 300px;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; /* Center vertically */
          text-align: center; /* Center horizontally */
          padding: 15px;
          font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .web_div-17 {
            width: 305px;
          }
        }
        .web_div-18 {
          color: #000;
          height: 80px;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 300px;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; /* Center vertically */
          text-align: center; /* Center horizontally */
          padding: 15px;
          font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .web_div-18 {
            width: 311px;
          }
        }
        .web_div-19 {
          align-self: center;
          display: flex;
          margin-top: 100px;
          width: 100%;
          max-width: 1387px;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .web_div-19 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-20 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-20 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 45%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .web_div-21 {
          display: flex;
          margin-top: 96px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-21 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-22 {
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .web_div-22 {
            max-width: 100%;
          }
        }
        .web_div-23 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-23 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-2 {
            width: 100%;
          }
        }
        .web_div-24 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-24 {
            margin-top: 40px;
          }
        }
        .web_img-4 {
          aspect-ratio: 1.43;
          object-fit: cover;
          object-position: center;
          width: 200px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-4 {
            align-self: center;
          }
        }
        .web_div-25 {
          color: #000;
          align-self: center;
          max-width: 300px;
          margin: 20px 0 0 20px;
          font: 600 27px Inter, sans-serif;
        }
        .web_div-26 {
          color: #7a7a7a;
          align-self: end;
          margin-top: 44px;
          max-width: 290px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-26 {
            margin-top: 40px;
            align-self:center;
            text-align:center;
          }
        }
        .web_column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-3 {
            width: 100%;
            margin-left:0;
          }
        }
        .web_div-27 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-27 {
            margin-top: 40px;
          }
        }
        .web_img-5 {
          aspect-ratio: 1.18;
          object-fit: cover;
          object-position: center;
          width: 165px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-5 {
            align-self:center;
          }
        }
        .web_div-28 {
          color: #000;
          align-self: center;
          margin-top: 20px;
          max-width: 300px;
          font: 600 27px Inter, sans-serif;
        }
        .web_div-29 {
          color: #7a7a7a;
          align-self: end;
          margin-top: 38px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-29 {
            align-self:center;
            text-align:center;
          }

        }
        .web_div-30 {
          align-self: center;
          margin-top: 107px;
          width: 570px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_div-30 {
            margin-top: 40px;
          }
        }
        .web_div-31 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-31 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-4 {
            width: 100%;
          }
        }
        .web_div-32 {
          display: flex;
          margin-top: 8px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-32 {
            margin-top: 40px;
          }
        }
        .web_img-6 {
          aspect-ratio: 1.07;
          object-fit: cover;
          object-position: center;
          width: 120px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-6 {
            align-self:center;
            text-align:center;
          }

        }
        .web_div-33 {
          color: #000;
          align-self: start;
          margin: 20px 0 0 17px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-33 {
            margin:auto;
            align-self:center;
          }
        }
        .web_div-34 {
          color: #7a7a7a;
          align-self: center;
          margin-top: 40px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-34 {
            
            text-align:center;
          }
        }
        .web_column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-5 {
            width: 100%;
            margin-left: 0;
          }
        }
        .web_div-35 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-35 {
            margin-top: 40px;
          }
        }
        .web_img-7 {
          aspect-ratio: 1.53;
          object-fit: cover;
          object-position: center;
          width: 184px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-7 {
            align-self: center;
          }
        }
        .web_div-36 {
          color: #000;
          align-self: start;
          margin: 21px 0 0 12px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-36 {
            margin: auto;
            align-self: center;
          }
        }
        .web_div-37 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 40px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-37 {
            margin:auto;
            align-self: center;
            text-align:center;
          }
        }
        .web_column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 55%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-6 {
            width: 100%;
            margin-left:0;
          }
        }
        .web_img-8 {
          aspect-ratio: 1.06;
          object-fit: cover;
          object-position: center;
          width: 812px;
          overflow: hidden;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-8 {
            margin-top: 10px;
          }
        }
        .web_div-38 {
          align-self: center;
          margin-top: 127px;
          width: 100%;
          max-width: 1243px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .web_div-38 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-39 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-39 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 80%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-7 {
            width: 100%;
          }
        }
        .web_div-40 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .web_div-40 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-41 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-41 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 36%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-8 {
            width: 100%;
          }
        }
        .web_div-42 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-42 {
            margin-top: 40px;
          }
        }
        .web_img-9 {
          aspect-ratio: 1.23;
          object-fit: cover;
          object-position: center;
          width: 152px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-9 {
            align-self: center;
          }
        }
        .web_div-43 {
          color: #000;
          align-self: center;
          margin-top: 35px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_img-9 {
            text-align:center;
          }
        }
        .web_div-44 {
          color: #7a7a7a;
          align-self: start;
          margin: 13px 0 0 19px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-44 {
            margin: auto;
            align-self: center;
            text-align:center;
          }
        }
        .web_column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 31%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-9 {
            width: 100%;
            margin-left:0;
          }
        }
        .web_div-45 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-45 {
            margin-top: 40px;
          }
        }
        .web_img-10 {
          aspect-ratio: 1.12;
          object-fit: cover;
          object-position: center;
          width: 152px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .web_div-46 {
          color: #000;
          align-self: start;
          margin: 14px 0 0 11px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-46 {
            margin-left: 0;
            align-self: center;
            text-align:center;

          }
        }
        .web_div-47 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 20px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-47 {
            margin-left: 0;
            align-self: center;

          }
        }
        .web_column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-10 {
            width: 100%;
            margin-left: 0;
          }
        }
        .web_div-48 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .web_div-48 {
            margin-top: 40px;
          }
        }
        .web_img-11 {
          aspect-ratio: 1.15;
          object-fit: cover;
          object-position: center;
          width: 152px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_div-48 {
            align-self: center;
          }
        }
        .web_div-49 {
          color: #000;
          align-self: start;
          margin: 16px 0 0 13px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-49 {
            margin-left: 0;
            align-self: center;
          }
        }
        .web_div-50 {
          color: #7a7a7a;
          align-self: start;
          margin: 32px 0 0 13px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-50 {
            margin-left: 0;
            align-self: center;
            text-align:center;

          }
        }
        .web_column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 20%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-11 {
            width: 100%;
            margin-left: 0;
          }
        }
        .web_div-51 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .web_div-51 {
            margin-top: 40px;
          }
        }
        .web_img-12 {
          aspect-ratio: 1.92;
          object-fit: cover;
          object-position: center;
          width: 205px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-12 {
            align-self: center;
          }
        }
        .web_div-52 {
          color: #000;
          align-self: start;
          margin: 24px 0 0 10px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-52  {
            align-self: center;
            text-align:center;
            margin:auto;
          }
        }
        .web_div-53 {
          color: #7a7a7a;
          align-self: start;
          margin: 26px 0 0 10px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-53  {
            align-self: center;
            text-align:center;
            margin:auto;
          }
        }
        .web_div-54 {
          align-self: center;
          margin-top: 123px;
          width: 100%;
          max-width: 1286px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .web_div-54 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-55 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-55 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-12 {
            width: 100%;
          }
        }
        .web_div-56 {
          display: flex;
          margin-top: 17px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-56 {
            margin-top: 40px;
          }
        }
        .web_img-13 {
          aspect-ratio: 1.62;
          object-fit: cover;
          object-position: center;
          width: 159px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-13 {
            align-self:center;
            margin:auto 0;
            display:flex;
          }
        }
        .web_div-57 {
          color: #000;
          align-self: center;
          margin: 33px 0 0 -26px;
          font: 600 27px Inter, sans-serif;
        }

        .web_div-58 {
          color: #7a7a7a;
          align-self: end;
          margin-top: 39px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-58 {
            align-self: center;
            text-align: center;
          }
        }
        .web_column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 53%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-13 {
            width: 100%;
            margin-left: 0;

          }
        }
        .web_div-59 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .web_div-59 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-60 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-60 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-14 {
            width: 100%;
          }
        }
        .web_div-61 {
          display: flex;
          margin-top: 14px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-61 {
            margin-top: 40px;
          }
        }
        .web_img-14 {
          aspect-ratio: 1.5;
          object-fit: cover;
          object-position: center;
          width: 156px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-14 {
            align-self: center;

          }
        }
        .web_div-62 {
          color: #000;
          align-self: start;
          margin: 31px 0 0 16px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-62 {
            margin-left: 0;
            align-self:center;

          }
        }
        .web_div-63 {
          color: #7a7a7a;
          align-self: start;
          margin: 39px 0 0 15px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-63 {
            margin-left: 0;
            text-align:center;
            align-self:center;
          }
        }
        .web_column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-15 {
            width: 100%;
            margin-left:0;
          }
        }
        .web_div-64 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-64 {
            margin-top: 40px;
          }
        }
        .web_img-15 {
          aspect-ratio: 0.97;
          object-fit: cover;
          object-position: center;
          width: 111px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-15 {
            
            align-self:center;
          }
        }
        .web_div-65 {
          color: #000;
          align-self: start;
          margin: 33px 0 0 13px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-65 {
            margin-left: 0;
            align-self:center;
          }
        }
        .web_div-66 {
          color: #7a7a7a;
          align-self: start;
          margin: 33px 0 0 13px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-66 {
            margin-left: 0;
            align-self:center;
          }
        }
        .web_column-16 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 23%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-16 {
            width: 100%;
            margin-left:0;
          }
        }
        .web_div-67 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-67 {
            margin-top: 40px;
          }
        }
        .web_img-16 {
          aspect-ratio: 0.9;
          object-fit: cover;
          object-position: center;
          width: 104px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-16 {
            align-self: center;
          }
        }
        .web_div-68 {
          color: #000;
          align-self: start;
          margin: 33px 0 0 17px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-68 {
            margin-left: 0;
            align-self: center;
          }
        }
        .web_div-69 {
          color: #7a7a7a;
          align-self: start;
          margin: 26px 0 0 15px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-69 {
            margin-left: 0;
            align-self: center;
          }
        }
        .web_div-70 {
          align-self: center;
          display: flex;
          margin-top: 140px;
          width: 100%;
          max-width: 1270px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-70 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-71 {
          align-self: center;
          width: 100%;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .web_div-71 {
            max-width: 100%;
          }
        }
        .web_div-72 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-72 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-17 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 79%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-17 {
            width: 100%;
          }
        }
        .web_div-73 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .web_div-73 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-74 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-74 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-18 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 35%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-18 {
            width: 100%;
          }
        }
        .web_div-75 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-75 {
            margin-top: 40px;
          }
        }
        .web_img-17 {
          aspect-ratio: 1.17;
          object-fit: cover;
          object-position: center;
          width: 147px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .web_div-76 {
          color: #000;
          align-self: start;
          margin: 23px 0 0 16px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-76 {
            margin-left: 10px;
          }
        }
        .web_div-77 {
          color: #7a7a7a;
          align-self: start;
          margin: 38px 0 0 16px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-77 {
            margin-left: 10px;
          }
        }
        .web_column-19 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 31%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-19 {
            width: 100%;
          }
        }
        .web_div-78 {
          display: flex;
          margin-top: 18px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-78 {
            margin-top: 40px;
          }
        }
        .web_img-18 {
          aspect-ratio: 1.3;
          object-fit: cover;
          object-position: center;
          width: 164px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .web_div-79 {
          color: #000;
          align-self: start;
          margin-top: 12px;
          font: 600 27px Inter, sans-serif;
        }
        .web_div-80 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 40px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .web_column-20 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-20 {
            width: 100%;
          }
        }
        .web_div-81 {
          display: flex;
          margin-top: 18px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-81 {
            margin-top: 40px;
          }
        }
        .web_img-19 {
          aspect-ratio: 1.24;
          object-fit: cover;
          object-position: center;
          width: 156px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .web_img-10 {
            margin-top: 40px;
            align-self: center;
          }
        }
        .web_div-82 {
          color: #000;
          align-self: start;
          margin-top: 15px;
          font: 600 27px Inter, sans-serif;
        }
        .web_div-83 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 27px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .web_column-21 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 21%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-21 {
            width: 100%;
          }
        }
        .web_div-84 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .web_div-84 {
            margin-top: 40px;
          }
        }
        .web_img-20 {
          aspect-ratio: 1.08;
          object-fit: cover;
          object-position: center;
          width: 136px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .web_div-85 {
          color: #000;
          align-self: start;
          margin: 18px 0 0 10px;
          font: 600 27px Inter, sans-serif;
        }
        .web_div-86 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 37px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .web_div-87 {
          align-self: start;
          margin-top: 103px;
          width: 924px;
          max-width: 100%;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .web_div-87 {
            margin-top: 40px;
          }
        }
        .web_div-88 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-88 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-22 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 34%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-22 {
            width: 100%;
          }
        }
        .web_div-89 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-89 {
            margin-top: 40px;
          }
        }
        .web_img-21 {
          aspect-ratio: 1.52;
          object-fit: cover;
          object-position: center;
          width: 181px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .web_div-90 {
          color: #000;
          align-self: start;
          margin: 24px 0 0 27px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-90 {
            margin-left: 10px;
          }
        }
        .web_div-91 {
          color: #7a7a7a;
          align-self: end;
          margin-top: 37px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .web_column-23 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 31%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-23 {
            width: 100%;
          }
        }
        .web_div-92 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-92 {
            margin-top: 40px;
          }
        }
        .web_img-22 {
          aspect-ratio: 1.43;
          object-fit: cover;
          object-position: center;
          width: 172px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
        }
        .web_div-93 {
          color: #000;
          align-self: start;
          margin-top: 23px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-93 {
            margin-left: 2px;
          }
        }
        .web_div-94 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 39px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .web_column-24 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 35%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-24 {
            width: 100%;
          }
        }
        .web_div-95 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-95 {
            margin-top: 40px;
          }
        }
        .web_img-23 {
          aspect-ratio: 1.21;
          object-fit: cover;
          object-position: center;
          width: 136px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .web_div-96 {
          color: #000;
          align-self: start;
          margin-top: 38px;
          font: 600 27px Inter, sans-serif;
        }
        .web_div-97 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 28px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .web_div-98 {
          align-self: center;
          display: flex;
          margin-top: 174px;
          width: 100%;
          max-width: 1115px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .web_div-98 {
            max-width: 100%;
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .web_div-99 {
          color: #000;
          align-self: start;
          margin-top: 7px;
          max-width: 1177px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 32px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-99 {
            max-width: 100%;
          }
        }
        .web_img-24 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 39px;
          fill: #fff;
          stroke-width: 3px;
          stroke: #000;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .web_div-100 {
          background-color: #f3f2f0;
          align-self: stretch;
          display: flex;
          margin-top: 170px;
          width: 100%;
          flex-direction: column;
          padding: 75px 20px 187px;
        }
        @media (max-width: 991px) {
          .web_div-100 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-101 {
          align-self: center;
          width: 100%;
          max-width: 1176px;
        }
        @media (max-width: 991px) {
          .web_div-101 {
            max-width: 100%;
          }
        }
        .web_div-102 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-102 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-25 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-25 {
            width: 100%;
          }
        }
        .web_div-103 {
        }
        @media (max-width: 991px) {
          .web_div-103 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-104 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-104 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-26 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 47%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-26 {
            width: 100%;
          }
        }
        .web_div-105 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-105 {
            margin-top: 40px;
          }
        }
        .web_div-106 {
          color: #000;
          align-self: start;
          width: 242px;
          font: 600 28px Inter, sans-serif;
        }
        .web_div-107 {
          color: #000;
          align-self: start;
          margin-top: 41px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-107 {
            margin-top: 40px;
          }
        }
        .web_div-108 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-109 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-110 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-111 {
          color: #000;
          margin-top: 33px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-112 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-113 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_column-27 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 53%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-27 {
            width: 100%;
          }
        }
        .web_div-114 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-114 {
            margin-top: 40px;
          }
        }
        .web_div-115 {
          color: #000;
          align-self: start;
          width: 241px;
          font: 600 28px Inter, sans-serif;
        }
        .web_div-116 {
          color: #000;
          align-self: start;
          margin-top: 40px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-117 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-118 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-119 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          text-wrap: nowrap;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-119 {
            text-wrap: initial;
          }
        }
        .web_div-120 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-121 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_column-28 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-28 {
            width: 100%;
          }
        }
        .web_div-122 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .web_div-122 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .web_div-123 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .web_div-123 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .web_column-29 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .web_column-29 {
            width: 100%;
          }
        }
        .web_div-124 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-124 {
            margin-top: 40px;
          }
        }
        .web_div-125 {
          color: #000;
          align-self: start;
          width: 241px;
          font: 600 28px Inter, sans-serif;
        }
        .web_div-126 {
          color: #000;
          align-self: start;
          margin-top: 42px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-126 {
            margin-top: 40px;
          }
        }
        .web_div-127 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-128 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-129 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-130 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-131 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-132 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-133 {
          color: #000;
          margin-top: 28px;
          font: 300 24px Inter, sans-serif;
        }
        .web_column-30 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .web_column-30 {
            width: 100%;
          }
        }
        .web_div-134 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .web_div-134 {
            margin-top: 40px;
          }
        }
        .web_div-135 {
          color: #000;
          align-self: start;
          width: 242px;
          font: 600 28px Inter, sans-serif;
        }
        .web_div-136 {
          color: #000;
          align-self: start;
          margin-top: 48px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .web_div-136 {
            margin-top: 40px;
          }
        }
        .web_div-137 {
          color: #000;
          align-self: start;
          margin-top: 29px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-138 {
          color: #000;
          align-self: start;
          margin-top: 26px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-139 {
          color: #000;
          align-self: start;
          margin-top: 31px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-140 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-141 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-142 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-143 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-144 {
          color: #000;
          align-self: start;
          margin-top: 26px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .web_div-145 {
          border-radius: 30px;
          background-color: rgba(0, 0, 0, 0.76);
          align-self: start;
          display: flex;
          margin-top: 50px;
          width: 194px;
          max-width: 100%;
          flex-direction: column;
          padding: 18px 20px 11px;
        }
        @media (max-width: 991px) {
          .web_div-145 {
            margin: 40px 0 0 -3px;
          }
        }
        .web_div-146 {
          color: #fff;
          align-self: center;
          width: 100%;
          font: 600 40px Inter, sans-serif;
        }
        .web_div-147 {
          background-color: #000;
          align-self: stretch;
          min-height: 1px;
          width: 100%;
          margin: 27px -20px -37px 0;
        }
        @media (max-width: 991px) {
          .web_div-147 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
      `}</style>
    </>
  );
}


export default Web_design;


import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import './Clothing_Customization.css';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
function Clothing() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
              <Helmet>
        <title>Apparel Customization Hub - Elevate Your Brand with Custoking</title>
        <meta name="description" content="Explore Custoking's customization hub for apparel and merchandise. Elevate your brand's identity with tailored solutions. From uniforms to branded merchandise, craft your unique style effortlessly." />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div className="clothing_div">
        <div className="clothing_div-2">
          <div className="clothing_div-3">
            <div className="clothing_div-4">One stop solution for all your needs!</div>
            <div className="clothing_div-5">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c85470f1-9428-47c0-a958-690fef6526c9?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="img"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/de720de8-6c1f-4e0b-a224-1ffbead6c32d?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="img"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2b80be8b-2361-4980-b4b3-3c5623e4da69?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="clothing_img-2"
              />
            </div>
          </div>

        </div>
        <div className="clothing_div-12">
          <div className="clothing_div-13">What do you need designed?</div>
          <div className="clothing_div-14">

            <Link to= "/Logo_Identity" style={{ textDecoration: 'none' }}>
            <div className="clothing_div-15">Logo & identity </div>
            </Link>
            <Link to = "/Web_Design" style={{ textDecoration: 'none' }}>
            <div className="clothing_div-16">Web & app design</div>
            </Link>
            <Link to = "/Business_Design"style={{ textDecoration: 'none' }}>
            <div className="clothing_div-17">Business & advertising</div>
            </Link>
            <Link to = "/Apparel_Design"style={{ textDecoration: 'none' }}>
            <div className="clothing_div-18">Clothing & merchandise</div>
            </Link>
          </div>
        </div>
        <div className="clothing_div-19">
          <div className="clothing_div-20">
            <div className="clothing_div-21">
              <div className="clothing_column">
                <div className="clothing_div-22">
                  <div className="clothing_div-23">
                    <div className="clothing_div-24">
                      <div className="column-2">
                        <div className="clothing_div-25">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/99758a04-2dfb-4e6b-9a20-cf5b05520e96?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/99758a04-2dfb-4e6b-9a20-cf5b05520e96?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/99758a04-2dfb-4e6b-9a20-cf5b05520e96?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/99758a04-2dfb-4e6b-9a20-cf5b05520e96?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/99758a04-2dfb-4e6b-9a20-cf5b05520e96?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/99758a04-2dfb-4e6b-9a20-cf5b05520e96?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/99758a04-2dfb-4e6b-9a20-cf5b05520e96?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/99758a04-2dfb-4e6b-9a20-cf5b05520e96?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                            className="clothing_img-4"
                          />
                          <div className="clothing_div-26">T-Shirt</div>
                          <div className="clothing_div-27">
                            Shirts they’ll wanna keep in their closet
                          </div>
                        </div>
                      </div>
                      <div className="clothing_div-55" />
                      <div className="column-3">
                        <div className="clothing_div-28">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/61ee03ef-483a-4ba4-972b-29e2c750b820?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/61ee03ef-483a-4ba4-972b-29e2c750b820?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/61ee03ef-483a-4ba4-972b-29e2c750b820?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/61ee03ef-483a-4ba4-972b-29e2c750b820?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/61ee03ef-483a-4ba4-972b-29e2c750b820?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/61ee03ef-483a-4ba4-972b-29e2c750b820?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/61ee03ef-483a-4ba4-972b-29e2c750b820?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/61ee03ef-483a-4ba4-972b-29e2c750b820?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                            className="clothing_img-5"
                          />
                          <div className="clothing_div-29">Clothing or apparel</div>
                          <div className="clothing_div-30">
                            Apparel design that fits
                            <br />
                            your style
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clothing_bottom_line"/>
                  
                  </div>
                  
                  <div className="clothing_div-31">
                    <div className="clothing_div-32">
                      <div className="column-4">
                        <div className="clothing_div-33">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d03f935f-ee55-456f-bcb8-218d582dd8ad?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d03f935f-ee55-456f-bcb8-218d582dd8ad?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d03f935f-ee55-456f-bcb8-218d582dd8ad?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d03f935f-ee55-456f-bcb8-218d582dd8ad?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d03f935f-ee55-456f-bcb8-218d582dd8ad?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d03f935f-ee55-456f-bcb8-218d582dd8ad?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d03f935f-ee55-456f-bcb8-218d582dd8ad?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d03f935f-ee55-456f-bcb8-218d582dd8ad?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                            className="clothing_img-6"
                          />
                          <div className="clothing_div-34">Jersey</div>
                          <div className="clothing_div-35">
                            Custom jersey design that
                            <br />
                            scores with fans
                          </div>
                        </div>
                      </div>
                      <div className="clothing_div-55" />
                      <div className="column-5">
                        <div className="clothing_div-36">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ad8d4820-73c7-48eb-a431-367c08dcd3f6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad8d4820-73c7-48eb-a431-367c08dcd3f6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad8d4820-73c7-48eb-a431-367c08dcd3f6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad8d4820-73c7-48eb-a431-367c08dcd3f6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad8d4820-73c7-48eb-a431-367c08dcd3f6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad8d4820-73c7-48eb-a431-367c08dcd3f6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad8d4820-73c7-48eb-a431-367c08dcd3f6?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ad8d4820-73c7-48eb-a431-367c08dcd3f6?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                            className="clothing_img-7"
                          />
                          <div className="clothing_div-37">Merchandise</div>
                          <div className="clothing_div-38">
                            Merchandise design to
                            <br />
                            bring your product to life
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          
              </div>
              <div className="clothing_column-6">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9c418cd3-26fe-47d9-9def-a5f07f14b485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c418cd3-26fe-47d9-9def-a5f07f14b485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c418cd3-26fe-47d9-9def-a5f07f14b485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c418cd3-26fe-47d9-9def-a5f07f14b485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c418cd3-26fe-47d9-9def-a5f07f14b485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c418cd3-26fe-47d9-9def-a5f07f14b485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c418cd3-26fe-47d9-9def-a5f07f14b485?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9c418cd3-26fe-47d9-9def-a5f07f14b485?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="clothing_img-8"
                />
           
              </div>
            </div>
            <div className="clothing_bottom_line"/>
          </div>
          <div className="clothing_div-39">
            <div className="clothing_div-40">
            <div className="column-10">
                <div className="clothing_div-54">
                 
                  <div className="clothing_div-56">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="clothing_img-12"
                    />
                    <div className="clothing_div-57">Hat & Cap</div>
                    <div className="clothing_div-58">
                      cup design that leaves ‘em thirsty for more
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-11">
                <div className="clothing_div-54">
                  <div className="clothing_div-55" />
                  <div className="clothing_div-56">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6058cc7-bd8c-4e4b-98e4-ca30eba8c2cc?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="clothing_img-12"
                    />
                    <div className="clothing_div-57">Hat & Cap</div>
                    <div className="clothing_div-58">
                      cup design that leaves ‘em thirsty for more
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-10">
              
                <div className="clothing_div-49">
                <div className="clothing_div-55" />
                  <div className="clothing_div-51">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/cab2675c-8923-4a21-8fe3-1eaa07e17a87?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cab2675c-8923-4a21-8fe3-1eaa07e17a87?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cab2675c-8923-4a21-8fe3-1eaa07e17a87?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cab2675c-8923-4a21-8fe3-1eaa07e17a87?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cab2675c-8923-4a21-8fe3-1eaa07e17a87?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cab2675c-8923-4a21-8fe3-1eaa07e17a87?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cab2675c-8923-4a21-8fe3-1eaa07e17a87?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/cab2675c-8923-4a21-8fe3-1eaa07e17a87?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="clothing_img-11"
                    />
                    <div className="clothing_div-52">Shopping Bag</div>
                    <div className="clothing_div-53">
                      On-brand shopping bag
                      <br />
                      design that draws <br />
                      attention
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-11">
                <div className="clothing_div-54">
                  <div className="clothing_div-55" />
                  <div className="clothing_div-56">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3d31861b-7f3e-4939-a4d9-42a36ff798aa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d31861b-7f3e-4939-a4d9-42a36ff798aa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d31861b-7f3e-4939-a4d9-42a36ff798aa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d31861b-7f3e-4939-a4d9-42a36ff798aa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d31861b-7f3e-4939-a4d9-42a36ff798aa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d31861b-7f3e-4939-a4d9-42a36ff798aa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d31861b-7f3e-4939-a4d9-42a36ff798aa?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d31861b-7f3e-4939-a4d9-42a36ff798aa?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="clothing_img-12"
                    />
                    <div className="clothing_div-57">Cup or mug</div>
                    <div className="clothing_div-58">
                      cup design that leaves ‘em thirsty for more
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="clothing_bottom_line"/>
          </div>
          <div className="clothing_div-59">
            <div className="clothing_div-60">
              <div className="column-12">
                <div className="clothing_div-61">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0bedcc80-bf8c-453e-86f6-46ed2b9078cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0bedcc80-bf8c-453e-86f6-46ed2b9078cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0bedcc80-bf8c-453e-86f6-46ed2b9078cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0bedcc80-bf8c-453e-86f6-46ed2b9078cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0bedcc80-bf8c-453e-86f6-46ed2b9078cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0bedcc80-bf8c-453e-86f6-46ed2b9078cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0bedcc80-bf8c-453e-86f6-46ed2b9078cc?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0bedcc80-bf8c-453e-86f6-46ed2b9078cc?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="clothing_img-13"
                  />
                  <div className="clothing_div-62">Sticker</div>
                  <div className="clothing_div-63">
                    A sticker design that can go just about anywhere
                  </div>
                </div>
              </div>
              <div className="clothing_div-55" />
              <div className="column-13">
                <div className="clothing_div-64">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/191ef4ee-16c3-4b67-a8e6-291d0f94c57c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/191ef4ee-16c3-4b67-a8e6-291d0f94c57c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/191ef4ee-16c3-4b67-a8e6-291d0f94c57c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/191ef4ee-16c3-4b67-a8e6-291d0f94c57c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/191ef4ee-16c3-4b67-a8e6-291d0f94c57c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/191ef4ee-16c3-4b67-a8e6-291d0f94c57c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/191ef4ee-16c3-4b67-a8e6-291d0f94c57c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/191ef4ee-16c3-4b67-a8e6-291d0f94c57c?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="clothing_img-14"
                  />
                  <div className="clothing_div-65">Other clothing or merchandise</div>
                  <div className="clothing_div-66">
                    Clothing and merchandise that’s more than one - size fits
                    all
                  </div>
                </div>
              </div>
            </div>
             <div className="clothing_bottom_line"/>
          </div>
        </div>
        <div className="clothing_div-67">
          <div className="clothing_div-68">
            Still haven’t found what you’re looking for? Search and you shall
            find
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3f51e0ac-753a-48d8-bf42-2c4f067480c0?apiKey=db6f3fe714c542fe811ae992d63ea686&"
            className="clothing_img-15"
          />
        </div>
        <div className="clothing_div-69">
          <div className="clothing_div-70">
            <div className="clothing_div-71">
              <div className="column-14">
                <div className="clothing_div-72">
                  <div className="clothing_div-73">
                    <div className="column-15">
                      <div className="clothing_div-74">
                        <div className="clothing_div-75">Company</div>
                        <div className="clothing_div-76">About</div>
                        <div className="clothing_div-77">Contact</div>
                        <div className="clothing_div-78">Careers</div>
                        <div className="clothing_div-79">Team</div>
                        <div className="clothing_div-80">Press releases</div>
                        <div className="clothing_div-81">In the media</div>
                        <div className="clothing_div-82">Testimonals</div>
                      </div>
                    </div>
                    <div className="column-16">
                      <div className="clothing_div-83">
                        <div className="clothing_div-84">Design services</div>
                        <div className="clothing_div-85">Design contests</div>
                        <div className="clothing_div-86">1-to-1 projects</div>
                        <div className="clothing_div-87">Find a designer</div>
                        <div className="clothing_div-88">Discover inspiration</div>
                        <div className="clothing_div-89">Pricing</div>
                        <div className="clothing_div-90">Agencies</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-17">
                <div className="clothing_div-91">
                  <div className="clothing_div-92">
                    <div className="column-18">
                      <div className="clothing_div-93">
                        <div className="clothing_div-94">Get a design</div>
                        <div className="clothing_div-95">Logo design</div>
                        <div className="clothing_div-96">Business card</div>
                        <div className="clothing_div-97">Web page design</div>
                        <div className="clothing_div-98">Brand guide</div>
                        <div className="clothing_div-99">Packaging design</div>
                        <div className="clothing_div-100">T-Shirt design</div>
                        <div className="clothing_div-101">Book cover design</div>
                        <div className="clothing_div-102">Browse all categories</div>
                      </div>
                    </div>
                    <div className="column-19">
                      <div className="clothing_div-103">
                        <div className="clothing_div-104">Resources</div>
                        <div className="clothing_div-105">Become a designer</div>
                        <div className="clothing_div-106">Blog</div>
                        <div className="clothing_div-107">Awards</div>
                        <div className="clothing_div-108">Affiliates</div>
                        <div className="clothing_div-109">Logo - ideas</div>
                        <div className="clothing_div-110">T-shirt ideas</div>
                        <div className="clothing_div-111">Designer resources</div>
                        <div className="clothing_div-112">Featured Partners</div>
                        <div className="clothing_div-113">Help</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clothing_div-114">
            <div className="clothing_div-115">Help?</div>
          </div>
          <div className="clothing_div-116" />
        </div>
      </div>
      
    </>
  );
}

export default Clothing


import React , {useEffect} from "react";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
function OneStopSolution() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
                  <Helmet>
        <title>Crafting Identity, Forging Recognition: Logo & Identity Services by Custoking</title>
        <meta name="description" content="Unleash the power of memorable branding. Explore CustoKing's Logo & Identity services, where uniqueness meets recognition. Transform your identity into a visual masterpiece that speaks volumes." />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div className="div">
        <div className="one_stop_div-2">
          <div className="one_stop_div-3">
            <div className="one_stop_div-4">One stop solution for all your needs!</div>
            <div className="one_stop_div-5">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b7e41316-ec36-4582-8495-cc15d2ff4673?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="img"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/695ae8f8-1604-475c-950a-d049c40e9e46?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="img"
              />
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e1763da6-6da8-42a2-9ce4-738087d08050?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="one_stop_img-2"
              />
            </div>
          </div>

        </div>
        <div className="one_stop_div-12">
          <div className="one_stop_div-13">What do you need designed?</div>
          <div className="one_stop_div-14">
          <Link to = "/Logo_Identity" style={{ textDecoration: 'none' }}>
            <div className="one_stop_div-15">Logo & identity </div>
            </Link>
            <Link to = "/Web_Design" style={{ textDecoration: 'none' }}>
            <div className="one_stop_div-16">Web & app design</div>
            </Link>
            <Link to = "/Business_Design"  style={{ textDecoration: 'none' }}>

            <div className="one_stop_div-17">Business & advertising</div>
            </Link>
            <Link to = "/Apparel_Design" style={{ textDecoration: 'none' }}>

            <div className="one_stop_div-18">Clothing & merchandise</div>
            </Link>
          </div>
        </div>
        <div className="one_stop_div-19">
          <div className="one_stop_div-20">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c5060363-42f6-4d3e-9589-1a5b256d1f91?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="one_stop_img-4"
            />
            <div className="one_stop_div-21">Most Popular</div>
          </div>
          <div className="one_stop_div-22">
            <div className="one_stop_div-23">
              <div className="column">
                <div className="one_stop_div-24">
                  <div className="one_stop_div-25">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d894f8c6-7d87-4baf-be35-5b704a119c6b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d894f8c6-7d87-4baf-be35-5b704a119c6b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d894f8c6-7d87-4baf-be35-5b704a119c6b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d894f8c6-7d87-4baf-be35-5b704a119c6b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d894f8c6-7d87-4baf-be35-5b704a119c6b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d894f8c6-7d87-4baf-be35-5b704a119c6b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d894f8c6-7d87-4baf-be35-5b704a119c6b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d894f8c6-7d87-4baf-be35-5b704a119c6b?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="one_stop_img-5"
                    />
                    <div className="one_stop_div-26">Logo & brand identity pack</div>
                  </div>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6e526271-11fb-4155-9010-af727efd76da?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e526271-11fb-4155-9010-af727efd76da?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e526271-11fb-4155-9010-af727efd76da?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e526271-11fb-4155-9010-af727efd76da?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e526271-11fb-4155-9010-af727efd76da?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e526271-11fb-4155-9010-af727efd76da?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e526271-11fb-4155-9010-af727efd76da?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e526271-11fb-4155-9010-af727efd76da?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="one_stop_img-6"
                  />
                </div>
              </div>
              <div className="one_stop_div-31" />
              <div className="column-2">
             
                <div className="one_stop_div-27">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e56f5c62-4ac9-4082-b137-974d2d2bbd61?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e56f5c62-4ac9-4082-b137-974d2d2bbd61?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e56f5c62-4ac9-4082-b137-974d2d2bbd61?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e56f5c62-4ac9-4082-b137-974d2d2bbd61?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e56f5c62-4ac9-4082-b137-974d2d2bbd61?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e56f5c62-4ac9-4082-b137-974d2d2bbd61?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e56f5c62-4ac9-4082-b137-974d2d2bbd61?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e56f5c62-4ac9-4082-b137-974d2d2bbd61?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="one_stop_img-7"
                  />
                  <div className="one_stop_div-28">Logo design</div>
                  <div className="one_stop_div-29">
                    An unforgettable logo crafted for your brand
                  </div>
                </div>
              </div>
              <div className="column-3">
                <div className="one_stop_div-30">
                  <div className="one_stop_div-31" />
                  <div className="one_stop_div-32">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/be7ab098-b343-401c-a1d4-2da35a97aab5?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/be7ab098-b343-401c-a1d4-2da35a97aab5?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/be7ab098-b343-401c-a1d4-2da35a97aab5?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/be7ab098-b343-401c-a1d4-2da35a97aab5?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/be7ab098-b343-401c-a1d4-2da35a97aab5?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/be7ab098-b343-401c-a1d4-2da35a97aab5?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/be7ab098-b343-401c-a1d4-2da35a97aab5?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/be7ab098-b343-401c-a1d4-2da35a97aab5?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="one_stop_img-8"
                    />
                    <div className="one_stop_div-33">Business card</div>
                    <div className="one_stop_div-34">
                      A unique card designed to build connections
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="one_stop_div-41" />
          <div className="one_stop_div-35">
            <div className="one_stop_div-36">
              <div className="column-4">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b271dd70-e24e-4b69-ac8e-f805bbaefdc8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b271dd70-e24e-4b69-ac8e-f805bbaefdc8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b271dd70-e24e-4b69-ac8e-f805bbaefdc8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b271dd70-e24e-4b69-ac8e-f805bbaefdc8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b271dd70-e24e-4b69-ac8e-f805bbaefdc8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b271dd70-e24e-4b69-ac8e-f805bbaefdc8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b271dd70-e24e-4b69-ac8e-f805bbaefdc8?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b271dd70-e24e-4b69-ac8e-f805bbaefdc8?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="one_stop_img-9"
                />
              </div>
              <div className="column-5">
                <div className="one_stop_div-37">
                  <div className="one_stop_div-38">Logo & brand guide</div>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/459ad220-5b57-4a53-90f9-70eae38bf79f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/459ad220-5b57-4a53-90f9-70eae38bf79f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/459ad220-5b57-4a53-90f9-70eae38bf79f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/459ad220-5b57-4a53-90f9-70eae38bf79f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/459ad220-5b57-4a53-90f9-70eae38bf79f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/459ad220-5b57-4a53-90f9-70eae38bf79f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/459ad220-5b57-4a53-90f9-70eae38bf79f?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/459ad220-5b57-4a53-90f9-70eae38bf79f?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="one_stop_img-10"
                  />
                </div>
              </div>
              <div className="one_stop_div-31" />
              <div className="column-6">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/39f5e6c3-a9bc-45f4-a9a0-23d209eb6b2e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/39f5e6c3-a9bc-45f4-a9a0-23d209eb6b2e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/39f5e6c3-a9bc-45f4-a9a0-23d209eb6b2e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/39f5e6c3-a9bc-45f4-a9a0-23d209eb6b2e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/39f5e6c3-a9bc-45f4-a9a0-23d209eb6b2e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/39f5e6c3-a9bc-45f4-a9a0-23d209eb6b2e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/39f5e6c3-a9bc-45f4-a9a0-23d209eb6b2e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/39f5e6c3-a9bc-45f4-a9a0-23d209eb6b2e?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="one_stop_img-11"
                />
              </div>
              <div className="column-7">
                <div className="one_stop_div-39">
                  <div className="one_stop_div-40">Logo & business card</div>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/34e180e5-0bff-4850-a09b-89665290ba43?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/34e180e5-0bff-4850-a09b-89665290ba43?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/34e180e5-0bff-4850-a09b-89665290ba43?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/34e180e5-0bff-4850-a09b-89665290ba43?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/34e180e5-0bff-4850-a09b-89665290ba43?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/34e180e5-0bff-4850-a09b-89665290ba43?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/34e180e5-0bff-4850-a09b-89665290ba43?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/34e180e5-0bff-4850-a09b-89665290ba43?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="one_stop_img-12"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="one_stop_div-41" />
          <div className="one_stop_div-42">
            <div className="one_stop_div-43">
              <div className="column-8">
                <div className="one_stop_div-44">
                  <div className="one_stop_div-45">
                    <div className="one_stop_div-46">
                      <div className="column-9">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b051e070-fe22-4ea9-8360-aaa4397a8ca0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b051e070-fe22-4ea9-8360-aaa4397a8ca0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b051e070-fe22-4ea9-8360-aaa4397a8ca0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b051e070-fe22-4ea9-8360-aaa4397a8ca0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b051e070-fe22-4ea9-8360-aaa4397a8ca0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b051e070-fe22-4ea9-8360-aaa4397a8ca0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b051e070-fe22-4ea9-8360-aaa4397a8ca0?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b051e070-fe22-4ea9-8360-aaa4397a8ca0?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="one_stop_img-13"
                        />
                      </div>
                      <div className="column-10">
                        <div className="one_stop_div-47">
                          <div className="one_stop_div-48">Logo & website</div>
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c55717fa-1698-442e-945a-b847598b2ac3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c55717fa-1698-442e-945a-b847598b2ac3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c55717fa-1698-442e-945a-b847598b2ac3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c55717fa-1698-442e-945a-b847598b2ac3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c55717fa-1698-442e-945a-b847598b2ac3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c55717fa-1698-442e-945a-b847598b2ac3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c55717fa-1698-442e-945a-b847598b2ac3?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c55717fa-1698-442e-945a-b847598b2ac3?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                            className="one_stop_img-14"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="one_stop_div-49" />
                  <div className="one_stop_div-50">
                    <div className="one_stop_div-51">
                      <div className="column-11">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/76db1b69-33a4-4344-9a83-5f3246932876?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/76db1b69-33a4-4344-9a83-5f3246932876?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/76db1b69-33a4-4344-9a83-5f3246932876?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/76db1b69-33a4-4344-9a83-5f3246932876?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/76db1b69-33a4-4344-9a83-5f3246932876?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/76db1b69-33a4-4344-9a83-5f3246932876?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/76db1b69-33a4-4344-9a83-5f3246932876?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/76db1b69-33a4-4344-9a83-5f3246932876?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="one_stop_img-15"
                        />
                      </div>
                      <div className="column-12">
                        <div className="one_stop_div-52">
                          <div className="one_stop_div-53">Logo & social media pack</div>
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/db2fddf2-377d-4531-b15b-91ca1469e258?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/db2fddf2-377d-4531-b15b-91ca1469e258?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/db2fddf2-377d-4531-b15b-91ca1469e258?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/db2fddf2-377d-4531-b15b-91ca1469e258?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/db2fddf2-377d-4531-b15b-91ca1469e258?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/db2fddf2-377d-4531-b15b-91ca1469e258?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/db2fddf2-377d-4531-b15b-91ca1469e258?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/db2fddf2-377d-4531-b15b-91ca1469e258?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                            className="one_stop_img-16"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-13">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ea9a3f98-87ba-447a-a14e-cec19bbd6a53?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ea9a3f98-87ba-447a-a14e-cec19bbd6a53?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ea9a3f98-87ba-447a-a14e-cec19bbd6a53?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ea9a3f98-87ba-447a-a14e-cec19bbd6a53?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ea9a3f98-87ba-447a-a14e-cec19bbd6a53?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ea9a3f98-87ba-447a-a14e-cec19bbd6a53?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ea9a3f98-87ba-447a-a14e-cec19bbd6a53?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ea9a3f98-87ba-447a-a14e-cec19bbd6a53?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="one_stop_img-17"
                />
              </div>
            </div>
          </div>
          <div className="one_stop_div-54" />
          <div className="one_stop_div-55">
            <div className="one_stop_div-56">
              <div className="column-14">
                <div className="one_stop_div-57">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e6996a5e-97a3-4434-8e8e-2c523da38418?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6996a5e-97a3-4434-8e8e-2c523da38418?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6996a5e-97a3-4434-8e8e-2c523da38418?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6996a5e-97a3-4434-8e8e-2c523da38418?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6996a5e-97a3-4434-8e8e-2c523da38418?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6996a5e-97a3-4434-8e8e-2c523da38418?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6996a5e-97a3-4434-8e8e-2c523da38418?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6996a5e-97a3-4434-8e8e-2c523da38418?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                    className="one_stop_img-18"
                  />
                  <div className="one_stop_div-58">Brand Guide</div>
                  <div className="one_stop_div-59">
                    A comprehensive guide of your brand’s fonts, colors and
                    style
                  </div>
                </div>
              </div>
              <div className="column-15">
                <div className="one_stop_div-60">
                  <div className="one_stop_div-61" />
                  <div className="one_stop_div-62">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/12367965-6787-480c-87b4-d8ce5d43846e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/12367965-6787-480c-87b4-d8ce5d43846e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/12367965-6787-480c-87b4-d8ce5d43846e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/12367965-6787-480c-87b4-d8ce5d43846e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/12367965-6787-480c-87b4-d8ce5d43846e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/12367965-6787-480c-87b4-d8ce5d43846e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/12367965-6787-480c-87b4-d8ce5d43846e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/12367965-6787-480c-87b4-d8ce5d43846e?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="one_stop_img-19"
                    />
                    <div className="one_stop_div-63">Stationery</div>
                    <div className="one_stop_div-64">
                      Letterhead and envelopes that send your brand’s message
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-16">
                <div className="one_stop_div-65">
                  <div className="one_stop_div-66">
                    <div className="column-17">
                      <div className="one_stop_div-67" />
                    </div>
                    <div className="column-18">
                      <div className="one_stop_div-68">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f64211c2-c018-4450-9156-0b9abdc1572c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f64211c2-c018-4450-9156-0b9abdc1572c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f64211c2-c018-4450-9156-0b9abdc1572c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f64211c2-c018-4450-9156-0b9abdc1572c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f64211c2-c018-4450-9156-0b9abdc1572c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f64211c2-c018-4450-9156-0b9abdc1572c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f64211c2-c018-4450-9156-0b9abdc1572c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f64211c2-c018-4450-9156-0b9abdc1572c?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                          className="one_stop_img-20"
                        />
                        <div className="one_stop_div-69">Full-Service Brand Pack</div>
                        <div className="one_stop_div-70">
                          A strategically crafted brand identity guided <br />
                          by your personal Creative Director from <br />
                          our studio
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="one_stop_div-71" />
        </div>
        <div className="one_stop_div-72">
          <div className="one_stop_div-73">
            <div className="one_stop_div-74">
              <div className="column-19">
                <div className="one_stop_div-75">
                  <div className="one_stop_div-76">
                    Find the right brand solution for you
                  </div>
                  <div className="one_stop_div-77">
                    Answer a few simple questions about your business and we’ll
                    recommend a design solution that’s right for you
                  </div>
                  <div className="one_stop_div-78">
                    <div className="one_stop_div-79">Take the brand quiz</div>
                  </div>
                </div>
              </div>
              <div className="column-20">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/68440db8-1559-4229-8ab8-c54634aeb043?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/68440db8-1559-4229-8ab8-c54634aeb043?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/68440db8-1559-4229-8ab8-c54634aeb043?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/68440db8-1559-4229-8ab8-c54634aeb043?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/68440db8-1559-4229-8ab8-c54634aeb043?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/68440db8-1559-4229-8ab8-c54634aeb043?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/68440db8-1559-4229-8ab8-c54634aeb043?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/68440db8-1559-4229-8ab8-c54634aeb043?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="one_stop_img-21"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="one_stop_div-80">
          <div className="one_stop_div-81">
            Still haven’t found what you’re looking for? Search and you shall
            find
          </div>
          <div className="one_stop_div-82">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4fe8832-2eb9-429e-ba56-ee57508bbd29?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="one_stop_img-22"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/87aede02-79b9-4527-8dc6-5db7bbe23898?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="one_stop_img-23"
            />
          </div>
        </div>
        <div className="one_stop_div-83">
          <div className="one_stop_div-84">
            <div className="one_stop_div-85">
              <div className="column-21">
                <div className="one_stop_div-86">
                  <div className="one_stop_div-87">
                    <div className="one_stop_div-88">
                      <div className="column-22">
                        <div className="one_stop_div-89">
                          <div className="one_stop_div-90">Company</div>
                          <div className="one_stop_div-91">About</div>
                          <div className="one_stop_div-92">Contact</div>
                          <div className="one_stop_div-93">Careers</div>
                          <div className="one_stop_div-94">Team</div>
                          <div className="one_stop_div-95">Press releases</div>
                          <div className="one_stop_div-96">In the media</div>
                          <div className="one_stop_div-97">Testimonals</div>
                        </div>
                      </div>
                      <div className="column-23">
                        <div className="one_stop_div-98">
                          <div className="one_stop_div-99">Design services</div>
                          <div className="one_stop_div-100">Design contests</div>
                          <div className="one_stop_div-101">1-to-1 projects</div>
                          <div className="one_stop_div-102">Find a designer</div>
                          <div className="one_stop_div-103">Discover inspiration</div>
                          <div className="one_stop_div-104">Pricing</div>
                          <div className="one_stop_div-105">Agencies</div>
                        </div>
                      </div>
                      <div className="column-24">
                        <div className="one_stop_div-106">
                          <div className="one_stop_div-107">Get a design</div>
                          <div className="one_stop_div-108">Logo design</div>
                          <div className="one_stop_div-109">Business card</div>
                          <div className="one_stop_div-110">Web page design</div>
                          <div className="one_stop_div-111">Brand guide</div>
                          <div className="one_stop_div-112">Packaging design</div>
                          <div className="one_stop_div-113">T-Shirt design</div>
                          <div className="one_stop_div-114">Book cover design</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="one_stop_div-115">Browse all categories</div>
                </div>
              </div>
              <div className="column-25">
                <div className="one_stop_div-116">
                  <div className="one_stop_div-117">Resources</div>
                  <div className="one_stop_div-118">Become a designer</div>
                  <div className="one_stop_div-119">Blog</div>
                  <div className="one_stop_div-120">Awards</div>
                  <div className="one_stop_div-121">Affiliates</div>
                  <div className="one_stop_div-122">Logo - ideas</div>
                  <div className="one_stop_div-123">T-shirt ideas</div>
                  <div className="one_stop_div-124">Designer resources</div>
                  <div className="one_stop_div-125">Featured Partners</div>
                  <div className="one_stop_div-126">Help</div>
                </div>
              </div>
            </div>
          </div>
          <div className="one_stop_div-127">
            <div className="one_stop_div-128">
              <div className="one_stop_div-129">Help?</div>
            </div>
            <div className="one_stop_div-130" />
            <div className="one_stop_div-131" />
          </div>
        </div>
      </div>
      <style jsx>{`
        .div {
          justify-content: flex-end;
          align-items: center;
          background-color: #fff;
          display: flex;
          flex-direction: column;
        }
        .one_stop_div-2 {
          align-self: stretch;
          display: flex;
          margin-top: 34px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-2 {
            max-width: 100%;
          }
        }
        .one_stop_div-3 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 993px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-3 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .one_stop_div-4 {
          color: #000;
          text-align: center;
          align-self: stretch;
          max-width: 1038px;
          flex-grow: 1;
          flex-basis: auto;
          font: 300 36px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-4 {
            max-width: 100%;
          }
        }
        .one_stop_div-5 {
          align-self: stretch;
          display: flex;
          width: 185px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-5 {
            justify-content: center;
          }
        }
        .img {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .one_stop_img-2 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .one_stop_div-6 {
          background-color: #d9d9d9;
          align-self: center;
          display: flex;
          margin-top: 27px;
          width: 100%;
          max-width: 1511px;
          flex-grow: 1;
          flex-direction: column;
          padding: 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-6 {
            max-width: 100%;
          }
        }
        .one_stop_div-7 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 992px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-7 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .one_stop_div-8 {
          color: #000;
          text-align: center;
          align-self: center;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
          font: 600 36px Inter, sans-serif;
        }
        .one_stop_div-9 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .one_stop_div-10 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .one_stop_div-11 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .one_stop_div-12 {
          align-self: center;
          display: flex;
        
          width: 100%;
          max-width: 1395px;
          flex-direction: column;
          padding: 20px 20px 0  20px;

      
        }
        @media (max-width: 991px) {
          .one_stop_div-12 {
            max-width: 100%;
            margin-top: 5px;
          }
        }
        .one_stop_div-13 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 800px;
          font: 600 30px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-13 {
            max-width: 100%;
          }
        }
        .one_stop_div-14 {
          align-self: center;
          display: flex;
          width: 100%;
          height: 60px;
          align-items: stretch; /* Change to stretch to make all items equal height */
          justify-content: space-between; /* Equally space the items */
        
          
          margin: 30px 20px 0 20px;
        }
        
        @media (max-width: 991px) {
          .one_stop_div-14 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
          }
        }
        .one_stop_img-3 {
          aspect-ratio: 0.87;
          object-fit: cover;
          object-position: center;
          height: 80px;
          width: 99px;
          overflow: hidden;
          object-fit: cover;
          align-self: stretch;
          max-width: 100%;
          border-radius: 10px;
        }
        .one_stop_div-15 {
          color: #000;
          height: 80px;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 300px;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; /* Center vertically */
          text-align: center; /* Center horizontally */
          padding: 15px;
          font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-15 {
            padding-left: 1px;
          }
        }
        @media (max-width: 640px) {
          .one_stop_div-15 {
            width: 275px;
          }
        }
        .one_stop_div-16 {
          color: #000;
          height: 80px;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 300px;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; /* Center vertically */
          text-align: center; /* Center horizontally */
          padding: 15px;
          font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .one_stop_div-16 {
            width: 269px;
          }
        }
        .one_stop_div-17 {
          color: #000;
          height: 80px;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 300px;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; /* Center vertically */
          text-align: center; /* Center horizontally */
          padding: 15px;
          font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .one_stop_div-17 {
            width: 272px;
          }
        }
        .one_stop_div-18 {
          color: #000;
          height: 80px;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 300px;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; /* Center vertically */
          text-align: center; /* Center horizontally */
          padding: 15px;
          font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .one_stop_div-18 {
            width: 276px;
          }
        }
        .one_stop_div-19 {
          align-self: center;
          display: flex;
          margin-top: 100px;
          width: 100%;
          max-width: 1387px;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-19 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-20 {
          border-radius: 30px;
          background-color: #01a1c1;
          align-self: start;
          display: flex;
          margin-left: 31px;
          width: 299px;
          max-width: 100%;
          align-items: flex-start;
          gap: 1px;
          padding: 13px 28px 13px 15px;
        }
        @media (max-width: 991px) {
          .one_stop_div-20 {
            margin-left: 10px;
          }
        }
        .one_stop_img-4 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 43px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .one_stop_div-21 {
          color: #fffbfb;
          align-self: center;
          max-width: 300px;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
          font: 700 32px Inter, sans-serif;
        }
        .one_stop_div-22 {
          align-self: center;
          margin-top: 36px;
          width: 100%;
          max-width: 1252px;
        }
        @media (max-width: 991px) {
          .one_stop_div-22 {
            max-width: 100%;
          }
        }
        .one_stop_div-23 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-23 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 51%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .one_stop_div-24 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .one_stop_div-24 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-25 {
          align-self: start;
          z-index: 1;
          display: flex;
          width: 444px;
          max-width: 100%;
          align-items: flex-start;
          gap: 3px;
        }
        @media (max-width: 991px) {
          .one_stop_div-25 {
            flex-wrap: wrap;
          }
        }
        .one_stop_img-5 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 94px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .one_stop_div-26 {
          color: #000;
          align-self: center;
          max-width: 399px;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
          font: 600 27px Inter, sans-serif;
        }
        .one_stop_img-6 {
          aspect-ratio: 3.03;
          object-fit: cover;
          object-position: center;
          width: 572px;
          overflow: hidden;
          align-self: end;
          margin-top: -15px;
          max-width: 100%;
          flex-grow: 1;
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .one_stop_div-27 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-27 {
            margin-top: 21px;
          }
        }
        .one_stop_img-7 {
          aspect-ratio: 0.93;
          object-fit: cover;
          object-position: center;
          width: 108px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .one_stop_div-28 {
          color: #000;
          align-self: start;
          margin-top: 15px;
          font: 600 27px Inter, sans-serif;
        }
        .one_stop_div-29 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 10px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 24%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .one_stop_div-30 {
          display: flex;
          margin-top: 5px;
          width: 291px;
          max-width: 100%;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-30 {
            margin-top: 26px;
          }
        }
        .one_stop_div-31 {
          background-color: #000;
          align-self: start;
          width: 1px;
         
          height: 350px;
          flex-grow: 1;
          flex-basis: auto;
        }
        .one_stop_div-32 {
          align-self: start;
          display: flex;
          margin-top: 11px;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
        }
        .one_stop_img-8 {
          aspect-ratio: 0.94;
          object-fit: cover;
          object-position: center;
          width: 94px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .one_stop_div-33 {
          color: #000;
          align-self: start;
          margin-top: 14px;
          font: 600 27px Inter, sans-serif;
        }
        .one_stop_div-34 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 24px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .one_stop_div-35 {
          align-self: center;
       
          width: 100%;
          max-width: 1216px;
        }
        @media (max-width: 991px) {
          .one_stop_div-35 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-36 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-36 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 8%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .one_stop_img-9 {
          aspect-ratio: 0.86;
          object-fit: cover;
          object-position: center;
          width: 96px;
          overflow: hidden;
          margin-top: 4px;
          max-width: 100%;
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 47%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .one_stop_div-37 {
          display: flex;
          margin-top: 51px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-37 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-38 {
          color: #000;
          align-self: start;
          margin-left: 29px;
          max-width: 300px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-38 {
            margin-left: 10px;
          }
        }
        .one_stop_img-10 {
        
          object-fit: fill;
          object-position: center;
          width: 100%;
          height: 150px;
          align-self: start;
          margin-top: 17px;
   
      
        }
        @media (max-width: 991px) {
          .one_stop_img-10 {
            max-width: 100%;
          }
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 8%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .one_stop_img-11 {
          aspect-ratio: 0.7;
          object-fit: cover;
          object-position: center;
          width: 84px;
          overflow: hidden;
          max-width: 100%;
        }
        .column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 38%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-7 {
            width: 100%;
          }
        }
        .one_stop_div-39 {
          display: flex;
          margin-top: 49px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-39 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-40 {
          color: #000;
          align-self: start;
          margin-left: 22px;
          max-width: 419px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-40 {
            margin-left: 10px;
          }
        }
        .one_stop_img-12 {
          aspect-ratio: 3.55;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: start;
          margin-top: 19px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .one_stop_img-12 {
            max-width: 100%;
          }
        }
        .one_stop_div-41 {
          background-color: #000;
          align-self: center;
          width: 1232px;
          height: 1px;
          margin: 0 0 0 -27px;
        }
        @media (max-width: 991px) {
          .one_stop_div-41 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-42 {
          align-self: stretch;
          margin-top: 10px;
        }
        @media (max-width: 991px) {
          .one_stop_div-42 {
            max-width: 100%;
          }
        }
        .one_stop_div-43 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-43 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-8 {
            width: 100%;
          }
        }
        .one_stop_div-44 {
          display: flex;
          margin-top: 52px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-44 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-45 {
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .one_stop_div-45 {
            max-width: 100%;
            margin-right: 9px;
          }
        }
        .one_stop_div-46 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-46 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 15%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-9 {
            width: 100%;
          }
        }
        .one_stop_img-13 {
          aspect-ratio: 0.9;
          object-fit: cover;
          object-position: center;
          width: 104px;
          overflow: hidden;
          max-width: 100%;
        }
        .column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 85%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-10 {
            width: 100%;
          }
        }
        .one_stop_div-47 {
          display: flex;
          margin-top: 38px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-47 {
            max-width: 100%;
          }
        }
        .one_stop_div-48 {
          color: #000;
          align-self: start;
          max-width: 300px;
          font: 600 27px Inter, sans-serif;
        }
        .one_stop_img-14 {
          aspect-ratio: 2.49;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: start;
          margin-top: 6px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .one_stop_img-14 {
            max-width: 100%;
          }
        }
        .one_stop_div-49 {
          background-color: #000;
          align-self: center;
          margin-top: 4px;
          width: 590px;
          height: 1px;
        }
        @media (max-width: 991px) {
          .one_stop_div-49 {
            max-width: 100%;
          }
        }
        .one_stop_div-50 {
          align-self: stretch;
          margin-top: 62px;
        }
        @media (max-width: 991px) {
          .one_stop_div-50 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-51 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-51 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 18%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-11 {
            width: 100%;
          }
        }
        .one_stop_img-15 {
          aspect-ratio: 1.12;
          object-fit: cover;
          object-position: center;
          width: 129px;
          overflow: hidden;
          max-width: 100%;
        }
        .column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 82%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-12 {
            width: 100%;
          }
        }
        .one_stop_div-52 {
          display: flex;
          margin-top: 42px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-52 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-53 {
          color: #000;
          align-self: start;
          margin-left: 56px;
          max-width: 419px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-53 {
            margin-left: 10px;
          }
        }
        .one_stop_img-16 {
          aspect-ratio: 2.68;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: start;
          margin-top: 17px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .one_stop_img-16 {
            max-width: 100%;
          }
        }
        .column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-13 {
            width: 100%;
          }
        }
        .one_stop_img-17 {
          aspect-ratio: 1.26;
          object-fit: cover;
          object-position: center;
          width: 749px;
          overflow: hidden;
          max-width: 100%;
        }
        .one_stop_div-54 {
          background-color: #000;
          align-self: center;
          width: 1232px;
          height: 1px;
          margin: 37px 0 0 -30px;
        }
        @media (max-width: 991px) {
          .one_stop_div-54 {
            max-width: 100%;
          }
        }
        .one_stop_div-55 {
          align-self: center;
          z-index: 1;
          width: 100%;
          max-width: 1216px;
        }
        @media (max-width: 991px) {
          .one_stop_div-55 {
            max-width: 100%;
          }
        }
        .one_stop_div-56 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-56 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 26%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-14 {
            width: 100%;
          }
        }
        .one_stop_div-57 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .one_stop_div-57 {
            margin-top: 40px;
          }
        }
        .one_stop_img-18 {
          aspect-ratio: 1.33;
          object-fit: cover;
          object-position: center;
          width: 144px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .one_stop_div-58 {
          color: #000;
          align-self: start;
          margin: 5px 0 0 18px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-58 {
            margin-left: 10px;
          }
        }
        .one_stop_div-59 {
          color: #7a7a7a;
          align-self: start;
          margin: 34px 0 0 18px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-59 {
            margin-left: 10px;
          }
        }
        .column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 29%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-15 {
            width: 100%;
          }
        }
        .one_stop_div-60 {
          display: flex;
          width: 297px;
          max-width: 100%;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: space-between;
          
        }
        @media (max-width: 991px) {
          .one_stop_div-60 {
            margin-top: 40px;
          }
        }
        .one_stop_div-61 {
          background-color: #000;
          align-self: stretch;
          width: 1px;
          height: 412px;
          flex-grow: 1;
          flex-basis: auto;
        }
        .one_stop_div-62 {
          align-self: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
        }
        .one_stop_img-19 {
          aspect-ratio: 1.39;
          object-fit: cover;
          object-position: center;
          width: 124px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
          margin-left: 5px;
        }
        .one_stop_div-63 {
          color: #000;
          align-self: start;
          margin: 13px 0 0 25px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-63 {
            margin-left: 10px;
          }
        }
        .one_stop_div-64 {
          color: #7a7a7a;
          align-self: start;
          margin: 29px 0 0 14px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-64 {
            margin-left: 10px;
          }
        }
        .column-16 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 45%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-16 {
            width: 100%;
          }
        }
        .one_stop_div-65 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .one_stop_div-65 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-66 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-66 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-17 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 0%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-17 {
            width: 100%;
          }
        }
        .one_stop_div-67 {
          background-color: #000;
          width: 1px;
          height: 412px;
          margin: 0 auto;
        }
        @media (max-width: 991px) {
          .one_stop_div-67 {
            margin-top: 33px;
          }
        }
        .column-18 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 100%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-18 {
            width: 100%;
          }
        }
        .one_stop_div-68 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .one_stop_div-68 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_img-20 {
          aspect-ratio: 0.97;
          object-fit: cover;
          object-position: center;
          width: 94px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .one_stop_div-69 {
          color: #000;
          align-self: start;
          max-width: 400px;
          margin: 5px 0 0 18px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-69 {
            margin-left: 10px;
          }
        }
        .one_stop_div-70 {
          color: #7a7a7a;
          align-self: start;
          margin: 32px 0 0 17px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-70 {
            max-width: 100%;
          }
        }
        .one_stop_div-71 {
          background-color: #000;
          align-self: center;
          margin-left: -30px;
          width: 1232px;
          height: 1px;
        }
        @media (max-width: 991px) {
          .one_stop_div-71 {
            max-width: 100%;
          }
        }
        .one_stop_div-72 {
          border-radius: 20px;
          background-color: #f3f2f0;
          align-self: center;
          display: flex;
          margin-top: 158px;
          width: 100%;
          max-width: 1208px;
          flex-direction: column;
          padding: 78px 13px 34px 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-72 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-73 {
          align-self: stretch;
          margin-left: 23px;
        }
        @media (max-width: 991px) {
          .one_stop_div-73 {
            max-width: 100%;
          }
        }
        .one_stop_div-74 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-74 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-19 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 59%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-19 {
            width: 100%;
          }
        }
        .one_stop_div-75 {
          display: flex;
          margin-right: -132px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-75 {
            max-width: 100%;
          }
        }
        .one_stop_div-76 {
          color: #000;
          align-self: start;
          margin-left: 17px;
          font: 700 48px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-76 {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .one_stop_div-77 {
          color: #000;
          align-self: start;
          max-width: 638px;
          margin: 46px 0 0 18px;
          font: 400 24px/125% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-77 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-78 {
          border-radius: 50px;
          background-color: #000;
          align-self: start;
          display: flex;
          margin-top: 50px;
          width: 436px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
          padding: 31px 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-78 {
            margin-top: 40px;
          }
        }
        .one_stop_div-79 {
          color: #fff4f4;
          align-self: center;
          max-width: 412px;
          font: 600 36px Inter, sans-serif;
        }
        .column-20 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 41%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-20 {
            width: 100%;
          }
        }
        .one_stop_img-21 {
          aspect-ratio: 1.56;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          margin-top: 37px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .one_stop_img-21 {
            max-width: 100%;
          }
        }
        .one_stop_div-80 {
          align-self: center;
          display: flex;
          margin-top: 181px;
          width: 100%;
          max-width: 1115px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-80 {
            max-width: 100%;
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .one_stop_div-81 {
          color: #000;
          align-self: start;
          margin-top: 7px;
          max-width: 1177px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 32px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-81 {
            max-width: 100%;
          }
        }
        .one_stop_div-82 {
          disply: flex;
          flex-direction: column;
          fill: #fff;
          stroke-width: 3px;
          stroke: #000;
          overflow: hidden;
          align-self: start;
          position: relative;
          display: flex;
          aspect-ratio: 1;
          width: 39px;
          max-width: 100%;
        }
        .one_stop_img-22 {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .one_stop_img-23 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          fill: #fff;
          stroke-width: 3px;
          stroke: #000;
          overflow: hidden;
          align-self: stretch;
          flex-grow: 1;
        }
        .one_stop_div-83 {
          background-color: #f3f2f0;
          align-self: stretch;
          display: flex;
          margin-top: 221px;
          width: 100%;
          flex-direction: column;
          padding: 75px 0 187px;
        }
        @media (max-width: 991px) {
          .one_stop_div-83 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-84 {
          align-self: center;
          width: 100%;
          max-width: 1176px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-84 {
            max-width: 100%;
          }
        }
        .one_stop_div-85 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-85 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-21 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 80%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-21 {
            width: 100%;
          }
        }
        .one_stop_div-86 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-86 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .one_stop_div-87 {
          align-self: end;
          width: 842px;
          max-width: 100%;
        }
        .one_stop_div-88 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .one_stop_div-88 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-22 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 30%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-22 {
            width: 100%;
          }
        }
        .one_stop_div-89 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-89 {
            margin-top: 40px;
          }
        }
        .one_stop_div-90 {
          color: #000;
          align-self: start;
          width: 242px;
          font: 600 28px Inter, sans-serif;
        }
        .one_stop_div-91 {
          color: #000;
          align-self: start;
          margin-top: 41px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-91 {
            margin-top: 40px;
          }
        }
        .one_stop_div-92 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-93 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-94 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-95 {
          color: #000;
          margin-top: 33px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-96 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-97 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .column-23 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 38%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-23 {
            width: 100%;
          }
        }
        .one_stop_div-98 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-98 {
            margin-top: 40px;
          }
        }
        .one_stop_div-99 {
          color: #000;
          align-self: start;
          width: 241px;
          font: 600 28px Inter, sans-serif;
        }
        .one_stop_div-100 {
          color: #000;
          align-self: start;
          margin-top: 40px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-101 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-102 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-103 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          text-wrap: nowrap;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-103 {
            text-wrap: initial;
          }
        }
        .one_stop_div-104 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-105 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .column-24 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 32%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-24 {
            width: 100%;
          }
        }
        .one_stop_div-106 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-106 {
            margin-top: 40px;
          }
        }
        .one_stop_div-107 {
          color: #000;
          align-self: start;
          width: 241px;
          font: 600 28px Inter, sans-serif;
        }
        .one_stop_div-108 {
          color: #000;
          align-self: start;
          margin-top: 42px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-108 {
            margin-top: 40px;
          }
        }
        .one_stop_div-109 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-110 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-111 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-112 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-113 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-114 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-115 {
          color: #000;
          margin: 28px 88px 0 0;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-115 {
            margin-right: 10px;
          }
        }
        .column-25 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 20%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-25 {
            width: 100%;
          }
        }
        .one_stop_div-116 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .one_stop_div-116 {
            margin-top: 40px;
          }
        }
        .one_stop_div-117 {
          color: #000;
          align-self: start;
          width: 242px;
          font: 600 28px Inter, sans-serif;
        }
        .one_stop_div-118 {
          color: #000;
          align-self: start;
          margin-top: 48px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .one_stop_div-118 {
            margin-top: 40px;
          }
        }
        .one_stop_div-119 {
          color: #000;
          align-self: start;
          margin-top: 29px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-120 {
          color: #000;
          align-self: start;
          margin-top: 26px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-121 {
          color: #000;
          align-self: start;
          margin-top: 31px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-122 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-123 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-124 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-125 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-126 {
          color: #000;
          align-self: start;
          margin-top: 26px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .one_stop_div-127 {
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          margin: 50px 0 -37px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .one_stop_div-127 {
            max-width: 100%;
            margin: 40px 0 10px;
          }
        }
        .one_stop_div-128 {
          border-radius: 30px;
          background-color: rgba(0, 0, 0, 0.76);
          align-self: start;
          display: flex;
          margin-left: 17px;
          width: 194px;
          max-width: 100%;
          flex-direction: column;
          padding: 18px 20px 11px;
        }
        @media (max-width: 991px) {
          .one_stop_div-128 {
            margin-left: 10px;
          }
        }
        .one_stop_div-129 {
          color: #fff;
          align-self: center;
          width: 100%;
          font: 600 40px Inter, sans-serif;
        }
        .one_stop_div-130 {
          background-color: #000;
          align-self: start;
          min-height: 1px;
          margin-top: 27px;
          width: 100%;
        }
        @media (max-width: 991px) {
          .one_stop_div-130 {
            max-width: 100%;
          }
        }
        .one_stop_div-131 {
          background-color: #000;
          align-self: start;
          z-index: 1;
          min-height: 1px;
          width: 100%;
        }
        @media (max-width: 991px) {
          .one_stop_div-131 {
            max-width: 100%;
          }
        }
      `}</style>
    </>
  );
}

export default OneStopSolution;

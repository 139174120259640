import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const School_Category_UniformContext = createContext();

export const School_Category_UniformContextProvider = ({ children }) => {
  const [school_category_uniform, setSchool_Category_Uniform] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Uniforms/School/School_Uniforms').get();
        const school_category_uniformData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));

        // Shuffle the array randomly
        const shuffledData = shuffleArray(school_category_uniformData);

        // Get the first 10 items (or less if the array has fewer than 10 items)
        const randomItems = shuffledData.slice(0, 10);

        setSchool_Category_Uniform(randomItems);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  // Function to shuffle an array randomly
  const shuffleArray = (array) => {
    let currentIndex = array.length;
    let randomIndex, tempValue;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      tempValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = tempValue;
    }

    return array;
  };

  return (
    <School_Category_UniformContext.Provider value={{ school_category_uniform }}>
      {children}
    </School_Category_UniformContext.Provider>
  );
};


import * as React from "react";

function Design() {
  return (
    <>
      <div className="div">
        <div className="des_div-2">
          <div className="des_div-3">
            <div className="des_div-4">One stop solution for all your needs!</div>
            <div className="des_div-5">
              <img
                loading="lazy"
                srcSet="..."
                className="img"
              />
              <img
                loading="lazy"
                srcSet="..."
                className="img"
              />
              <img
                loading="lazy"
                srcSet="..."
                className="des_img2"
              />
            </div>
          </div>
          <div className="des_div-6">
            <div className="des_div-7">
              <div className="des_div-8">Your work</div>
              <div className="des_div-9">Designers</div>
              <div className="des_div-10">Categories</div>
              <div className="des_div-11">Inspiration</div>
            </div>
          </div>
        </div>
        <div className="des_div-12">
          <div className="des_div-13">What do you need designed?</div>
          <div className="des_div-14">
            <img
              loading="lazy"
              srcSet="..."
              className="des_img3"
            />
            <div className="des_div-15">Logo & identity </div>
            <div className="des_div-16">Web & app design</div>
            <div className="des_div-17">Business & advertising</div>
            <div className="des_div-18">Clothing & merchandise</div>
          </div>
        </div>
        <div className="des_div-19">
          <div className="des_div-20">
            <div className="des_div-21">
              <div className="column">
                <div className="des_div-22">
                  <div className="des_div-23">
                    <div className="des_div-24">
                      <div className="column-2">
                        <div className="des_div-25">
                          <img
                            loading="lazy"
                            srcSet="..."
                            className="des_img4"
                          />
                          <div className="des_div-26">T-Shirt</div>
                          <div className="des_div-27">
                            Shirts they’ll wanna keep in their closet
                          </div>
                        </div>
                      </div>
                      <div className="column-3">
                        <div className="des_div-28">
                          <img
                            loading="lazy"
                            srcSet="..."
                            className="des_img5"
                          />
                          <div className="des_div-29">Clothing or apparel</div>
                          <div className="des_div-30">
                            Apparel design that fits
                            <br />
                            your style
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="des_div-31">
                    <div className="des_div-32">
                      <div className="column-4">
                        <div className="des_div-33">
                          <img
                            loading="lazy"
                            srcSet="..."
                            className="des_img6"
                          />
                          <div className="des_div-34">Jersey</div>
                          <div className="des_div-35">
                            Custom jersey design that
                            <br />
                            scores with fans
                          </div>
                        </div>
                      </div>
                      <div className="column-5">
                        <div className="des_div-36">
                          <img
                            loading="lazy"
                            srcSet="..."
                            className="des_img7"
                          />
                          <div className="des_div-37">Merchandise</div>
                          <div className="des_div-38">
                            Merchandise design to
                            <br />
                            bring your product to life
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-6">
                <img
                  loading="lazy"
                  srcSet="..."
                  className="des_img8"
                />
              </div>
            </div>
          </div>
          <div className="des_div-39">
            <div className="des_div-40">
              <div className="column-7">
                <div className="des_div-41">
                  <div className="des_div-42">
                    <div className="column-8">
                      <div className="des_div-43">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="des_img9"
                        />
                        <div className="des_div-44">Bag & Tote</div>
                        <div className="des_div-45">
                          Custom bag design that
                          <br />
                          carries your brand further
                        </div>
                      </div>
                    </div>
                    <div className="column-9">
                      <div className="des_div-46">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="des_img10"
                        />
                        <div className="des_div-47">Hat & Cap</div>
                        <div className="des_div-48">
                          A custom hat or cap
                          <br />
                          designed to get you
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-10">
                <div className="des_div-49">
                  <div className="des_div-50" />
                  <div className="des_div-51">
                    <img
                      loading="lazy"
                      srcSet="..."
                      className="des_img11"
                    />
                    <div className="des_div-52">Shopping Bag</div>
                    <div className="des_div-53">
                      On-brand shopping bag
                      <br />
                      design that draws <br />
                      attention
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-11">
                <div className="des_div-54">
                  <div className="des_div-55" />
                  <div className="des_div-56">
                    <img
                      loading="lazy"
                      srcSet="..."
                      className="des_img12"
                    />
                    <div className="des_div-57">Cup or mug</div>
                    <div className="des_div-58">
                      cup design that leaves ‘em thirsty for more
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="des_div-59">
            <div className="des_div-60">
              <div className="column-12">
                <div className="des_div-61">
                  <img
                    loading="lazy"
                    srcSet="..."
                    className="des_img13"
                  />
                  <div className="des_div-62">Sticker</div>
                  <div className="des_div-63">
                    A sticker design that can go just about anywhere
                  </div>
                </div>
              </div>
              <div className="column-13">
                <div className="des_div-64">
                  <img
                    loading="lazy"
                    srcSet="..."
                    className="des_img14"
                  />
                  <div className="des_div-65">Other clothing or merchandise</div>
                  <div className="des_div-66">
                    Clothing and merchandise that’s more than one - size fits
                    all
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="des_div-67">
          <div className="des_div-68">
            Still haven’t found what you’re looking for? Search and you shall
            find
          </div>
          <img
            loading="lazy"
            srcSet="..."
            className="des_img15"
          />
        </div>
        <div className="des_div-69">
          <div className="des_div-70">
            <div className="des_div-71">
              <div className="column-14">
                <div className="des_div-72">
                  <div className="des_div-73">
                    <div className="column-15">
                      <div className="des_div-74">
                        <div className="des_div-75">Company</div>
                        <div className="des_div-76">About</div>
                        <div className="des_div-77">Contact</div>
                        <div className="des_div-78">Careers</div>
                        <div className="des_div-79">Team</div>
                        <div className="des_div-80">Press releases</div>
                        <div className="des_div-81">In the media</div>
                        <div className="des_div-82">Testimonals</div>
                      </div>
                    </div>
                    <div className="column-16">
                      <div className="des_div-83">
                        <div className="des_div-84">Design services</div>
                        <div className="des_div-85">Design contests</div>
                        <div className="des_div-86">1-to-1 projects</div>
                        <div className="des_div-87">Find a designer</div>
                        <div className="des_div-88">Discover inspiration</div>
                        <div className="des_div-89">Pricing</div>
                        <div className="des_div-90">Agencies</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-17">
                <div className="des_div-91">
                  <div className="des_div-92">
                    <div className="column-18">
                      <div className="des_div-93">
                        <div className="des_div-94">Get a design</div>
                        <div className="des_div-95">Logo design</div>
                        <div className="des_div-96">Business card</div>
                        <div className="des_div-97">Web page design</div>
                        <div className="des_div-98">Brand guide</div>
                        <div className="des_div-99">Packaging design</div>
                        <div className="des_div-100">T-Shirt design</div>
                        <div className="des_div-101">Book cover design</div>
                        <div className="des_div-102">Browse all categories</div>
                      </div>
                    </div>
                    <div className="column-19">
                      <div className="des_div-103">
                        <div className="des_div-104">Resources</div>
                        <div className="des_div-105">Become a designer</div>
                        <div className="des_div-106">Blog</div>
                        <div className="des_div-107">Awards</div>
                        <div className="des_div-108">Affiliates</div>
                        <div className="des_div-109">Logo - ideas</div>
                        <div className="des_div-110">T-shirt ideas</div>
                        <div className="des_div-111">Designer resources</div>
                        <div className="des_div-112">Featured Partners</div>
                        <div className="des_div-113">Help</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="des_div-114">
            <div className="des_div-115">Help?</div>
          </div>
          <div className="des_div-116" />
        </div>
      </div>
      <style jsx>{`
        .div {
          background-color: #fff;
          display: flex;
          flex-direction: column;
        }
        .des_div-2 {
          align-self: stretch;
          display: flex;
          margin-top: 38px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-2 {
            max-width: 100%;
          }
        }
        .des_div-3 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 993px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .des_div-3 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .des_div-4 {
          color: #000;
          text-align: center;
          align-self: stretch;
          max-width: 1038px;
          flex-grow: 1;
          flex-basis: auto;
          font: 300 36px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-4 {
            max-width: 100%;
          }
        }
        .des_div-5 {
          align-self: stretch;
          display: flex;
          width: 185px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .des_div-5 {
            justify-content: center;
          }
        }
        .img {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .des_img2 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .des_div-6 {
          background-color: #d9d9d9;
          align-self: center;
          display: flex;
          margin-top: 27px;
          width: 100%;
          max-width: 1511px;
          flex-grow: 1;
          flex-direction: column;
          padding: 77px 20px 58px;
        }
        @media (max-width: 991px) {
          .des_div-6 {
            max-width: 100%;
          }
        }
        .des_div-7 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 992px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .des_div-7 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .des_div-8 {
          color: #000;
          text-align: center;
          align-self: center;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
          font: 600 36px Inter, sans-serif;
        }
        .des_div-9 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .des_div-10 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .des_div-11 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .des_div-12 {
          align-self: center;
          display: flex;
          margin-top: 129px;
          width: 100%;
          max-width: 1395px;
          flex-direction: column;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .des_div-12 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .des_div-13 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 569px;
          font: 600 36px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-13 {
            max-width: 100%;
          }
        }
        .des_div-14 {
          align-self: center;
          display: flex;
          width: 100%;
          align-items: flex-start;
          gap: 14px;
          margin: 49px 0 0 -20px;
        }
        @media (max-width: 991px) {
          .des_div-14 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
          }
        }
        .des_img3 {
          aspect-ratio: 1.86;
          object-fit: cover;
          object-position: center;
          width: 99px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        @media (max-width: 640px) {
          .des_img3 {
            width: 326px;
          }
        }
        .des_div-15 {
          color: #000;
          text-align: center;
          align-self: stretch;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 255px;
          max-width: 100%;
          padding: 43px 19px 43px 20px;
          font: 600 30px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-15 {
            padding-left: 1px;
          }
        }
        @media (max-width: 640px) {
          .des_div-15 {
            width: 330px;
          }
        }
        .des_div-16 {
          color: #000;
          text-align: center;
          align-self: stretch;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 282px;
          max-width: 100%;
          padding: 46px 13px 39px 12px;
          font: 600 30px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .des_div-16 {
            width: 330px;
          }
        }
        .des_div-17 {
          color: #000;
          text-align: center;
          align-self: stretch;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 336px;
          max-width: 100%;
          padding: 46px 5px 39px;
          font: 600 30px Inter, sans-serif;
        }
        .des_div-18 {
          color: #000;
          text-align: center;
          align-self: stretch;
          border-radius: 10px;
          background-color: #d9d9d9;
          width: 355px;
          max-width: 100%;
          padding: 46px 6px 39px 5px;
          font: 600 30px Inter, sans-serif;
        }
        .des_div-19 {
          align-self: center;
          display: flex;
          margin-top: 200px;
          width: 100%;
          max-width: 1386px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-19 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .des_div-20 {
          align-self: center;
          width: 100%;
          max-width: 1376px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .des_div-20 {
            max-width: 100%;
          }
        }
        .des_div-21 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .des_div-21 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 45%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .des_div-22 {
          display: flex;
          margin-top: 246px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-22 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .des_div-23 {
          align-self: start;
          width: 575px;
          max-width: 100%;
        }
        .des_div-24 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .des_div-24 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 54%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .des_div-25 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-25 {
            margin-top: 40px;
          }
        }
        .des_img4 {
          aspect-ratio: 1.04;
          object-fit: cover;
          object-position: center;
          width: 117px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-26 {
          color: #000;
          margin: 34px 0 0 15px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-26 {
            margin-left: 10px;
          }
        }
        .des_div-27 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 75px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-27 {
            margin: 40px 0 0 4px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 46%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .des_div-28 {
          display: flex;
          margin-top: 18px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-28 {
            margin-top: 40px;
          }
        }
        .des_img5 {
          aspect-ratio: 1.43;
          object-fit: cover;
          object-position: center;
          width: 110px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-29 {
          color: #000;
          margin-top: 38px;
          font: 600 27px Inter, sans-serif;
        }
        .des_div-30 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 45px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-30 {
            margin-top: 40px;
          }
        }
        .des_div-31 {
          align-self: start;
          margin-top: 152px;
          width: 602px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .des_div-31 {
            margin-top: 40px;
          }
        }
        .des_div-32 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .des_div-32 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
        }
        .des_div-33 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-33 {
            margin-top: 40px;
          }
        }
        .des_img6 {
          aspect-ratio: 1.06;
          object-fit: cover;
          object-position: center;
          width: 166px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-34 {
          color: #000;
          margin: 21px 0 0 21px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-34 {
            margin-left: 10px;
          }
        }
        .des_div-35 {
          color: #7a7a7a;
          align-self: end;
          margin-top: 27px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .des_div-36 {
          display: flex;
          margin-top: 13px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-36 {
            margin-top: 40px;
          }
        }
        .des_img7 {
          aspect-ratio: 1.12;
          object-fit: cover;
          object-position: center;
          width: 147px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-37 {
          color: #000;
          margin: 33px 0 0 14px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-37 {
            margin-left: 10px;
          }
        }
        .des_div-38 {
          color: #7a7a7a;
          align-self: start;
          margin: 26px 0 0 14px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-38 {
            margin-left: 10px;
          }
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 55%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .des_img8 {
          aspect-ratio: 0.69;
          object-fit: cover;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .des_img8 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .des_div-39 {
          align-self: center;
          margin-top: 58px;
          width: 100%;
          max-width: 1246px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .des_div-39 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .des_div-40 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .des_div-40 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 49%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-7 {
            width: 100%;
          }
        }
        .des_div-41 {
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .des_div-41 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .des_div-42 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .des_div-42 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 55%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-8 {
            width: 100%;
          }
        }
        .des_div-43 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-43 {
            margin-top: 40px;
          }
        }
        .des_img9 {
          aspect-ratio: 1.31;
          object-fit: cover;
          object-position: center;
          width: 152px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-44 {
          color: #000;
          margin-top: 9px;
          font: 600 27px Inter, sans-serif;
        }
        .des_div-45 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 25px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 45%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-9 {
            width: 100%;
          }
        }
        .des_div-46 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .des_div-46 {
            margin-top: 40px;
          }
        }
        .des_img10 {
          aspect-ratio: 1.25;
          object-fit: cover;
          object-position: center;
          width: 125px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-47 {
          color: #000;
          margin: 9px 0 0 14px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-47 {
            margin-left: 10px;
          }
        }
        .des_div-48 {
          color: #7a7a7a;
          align-self: start;
          margin: 19px 0 0 14px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-48 {
            margin-left: 10px;
          }
        }
        .column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 24%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-10 {
            width: 100%;
          }
        }
        .des_div-49 {
          display: flex;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: space-between;
          gap: 9px;
        }
        @media (max-width: 991px) {
          .des_div-49 {
            margin-top: 40px;
          }
        }
        .des_div-50 {
          background-color: #000;
          align-self: stretch;
          width: 1px;
          height: 467px;
          flex-grow: 1;
          flex-basis: auto;
        }
        .des_div-51 {
          align-self: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
        }
        .des_img11 {
          aspect-ratio: 1.4;
          object-fit: cover;
          object-position: center;
          width: 162px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-52 {
          color: #000;
          margin: 8px 0 0 -12px;
          font: 600 27px Inter, sans-serif;
        }
        .des_div-53 {
          color: #7a7a7a;
          align-self: end;
          margin-top: 10px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 27%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-11 {
            width: 100%;
          }
        }
        .des_div-54 {
          display: flex;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: space-between;
          gap: 9px;
        }
        @media (max-width: 991px) {
          .des_div-54 {
            margin-top: 40px;
          }
        }
        .des_div-55 {
          background-color: #000;
          align-self: stretch;
          width: 1px;
          height: 467px;
          flex-grow: 1;
          flex-basis: auto;
        }
        .des_div-56 {
          align-self: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
          margin: auto 0;
        }
        .des_img12 {
          aspect-ratio: 1.42;
          object-fit: cover;
          object-position: center;
          width: 179px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-57 {
          color: #000;
          margin: 18px 0 0 24px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-57 {
            margin-left: 10px;
          }
        }
        .des_div-58 {
          color: #7a7a7a;
          align-self: end;
          margin-top: 6px;
          max-width: 290px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .des_div-59 {
          align-self: start;
          margin-top: 71px;
          width: 632px;
          max-width: 100%;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .des_div-59 {
            margin-top: 40px;
          }
        }
        .des_div-60 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .des_div-60 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 53%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-12 {
            width: 100%;
          }
        }
        .des_div-61 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .des_div-61 {
            margin-top: 40px;
          }
        }
        .des_img13 {
          aspect-ratio: 1.04;
          object-fit: cover;
          object-position: center;
          width: 132px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-62 {
          color: #000;
          margin: 7px 0 0 24px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-62 {
            margin-left: 10px;
          }
        }
        .des_div-63 {
          color: #7a7a7a;
          align-self: end;
          margin-top: 62px;
          max-width: 290px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-63 {
            margin-top: 40px;
          }
        }
        .column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 47%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-13 {
            width: 100%;
          }
        }
        .des_div-64 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-64 {
            margin-top: 40px;
          }
        }
        .des_img14 {
          aspect-ratio: 0.98;
          object-fit: cover;
          object-position: center;
          width: 162px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-65 {
          color: #000;
          align-self: start;
          margin-top: 19px;
          max-width: 245px;
          font: 600 27px Inter, sans-serif;
        }
        .des_div-66 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 33px;
          font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .des_div-67 {
          align-self: center;
          display: flex;
          margin-top: 836px;
          width: 100%;
          max-width: 1115px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .des_div-67 {
            max-width: 100%;
            margin-top: 40px;
            flex-wrap: wrap;
          }
        }
        .des_div-68 {
          color: #000;
          align-self: start;
          margin-top: 7px;
          max-width: 1177px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 32px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-68 {
            max-width: 100%;
          }
        }
        .des_img15 {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          width: 39px;
          fill: #fff;
          stroke-width: 3px;
          stroke: #000;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .des_div-69 {
          background-color: #f3f2f0;
          align-self: stretch;
          display: flex;
          margin-top: 221px;
          width: 100%;
          flex-direction: column;
          padding: 75px 20px 187px;
        }
        @media (max-width: 991px) {
          .des_div-69 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .des_div-70 {
          align-self: center;
          width: 100%;
          max-width: 1176px;
        }
        @media (max-width: 991px) {
          .des_div-70 {
            max-width: 100%;
          }
        }
        .des_div-71 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .des_div-71 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-14 {
            width: 100%;
          }
        }
        .des_div-72 {
        }
        @media (max-width: 991px) {
          .des_div-72 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .des_div-73 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .des_div-73 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 47%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-15 {
            width: 100%;
          }
        }
        .des_div-74 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-74 {
            margin-top: 40px;
          }
        }
        .des_div-75 {
          color: #000;
          align-self: start;
          width: 242px;
          font: 600 28px Inter, sans-serif;
        }
        .des_div-76 {
          color: #000;
          align-self: start;
          margin-top: 41px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-76 {
            margin-top: 40px;
          }
        }
        .des_div-77 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-78 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-79 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-80 {
          color: #000;
          margin-top: 33px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-81 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-82 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .column-16 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 53%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-16 {
            width: 100%;
          }
        }
        .des_div-83 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-83 {
            margin-top: 40px;
          }
        }
        .des_div-84 {
          color: #000;
          align-self: start;
          width: 241px;
          font: 600 28px Inter, sans-serif;
        }
        .des_div-85 {
          color: #000;
          align-self: start;
          margin-top: 40px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-86 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-87 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-88 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          text-wrap: nowrap;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-88 {
            text-wrap: initial;
          }
        }
        .des_div-89 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-90 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .column-17 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-17 {
            width: 100%;
          }
        }
        .des_div-91 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .des_div-91 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .des_div-92 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .des_div-92 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-18 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-18 {
            width: 100%;
          }
        }
        .des_div-93 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-93 {
            margin-top: 40px;
          }
        }
        .des_div-94 {
          color: #000;
          align-self: start;
          width: 241px;
          font: 600 28px Inter, sans-serif;
        }
        .des_div-95 {
          color: #000;
          align-self: start;
          margin-top: 42px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-95 {
            margin-top: 40px;
          }
        }
        .des_div-96 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-97 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-98 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-99 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-100 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-101 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-102 {
          color: #000;
          margin-top: 28px;
          font: 300 24px Inter, sans-serif;
        }
        .column-19 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-19 {
            width: 100%;
          }
        }
        .des_div-103 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .des_div-103 {
            margin-top: 40px;
          }
        }
        .des_div-104 {
          color: #000;
          align-self: start;
          width: 242px;
          font: 600 28px Inter, sans-serif;
        }
        .des_div-105 {
          color: #000;
          align-self: start;
          margin-top: 48px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .des_div-105 {
            margin-top: 40px;
          }
        }
        .des_div-106 {
          color: #000;
          align-self: start;
          margin-top: 29px;
          width: 241px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-107 {
          color: #000;
          align-self: start;
          margin-top: 26px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-108 {
          color: #000;
          align-self: start;
          margin-top: 31px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-109 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-110 {
          color: #000;
          align-self: start;
          margin-top: 27px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-111 {
          color: #000;
          align-self: start;
          margin-top: 32px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-112 {
          color: #000;
          align-self: start;
          margin-top: 33px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-113 {
          color: #000;
          align-self: start;
          margin-top: 26px;
          width: 242px;
          font: 300 24px Inter, sans-serif;
        }
        .des_div-114 {
          border-radius: 30px;
          background-color: rgba(0, 0, 0, 0.76);
          align-self: start;
          display: flex;
          margin-top: 50px;
          width: 194px;
          max-width: 100%;
          flex-direction: column;
          padding: 18px 20px 11px;
        }
        @media (max-width: 991px) {
          .des_div-114 {
            margin: 40px 0 0 -3px;
          }
        }
        .des_div-115 {
          color: #fff;
          align-self: center;
          width: 100%;
          font: 600 40px Inter, sans-serif;
        }
        .des_div-116 {
          background-color: #000;
          align-self: stretch;
          min-height: 1px;
          width: 100%;
          margin: 27px -20px -37px 0;
        }
        @media (max-width: 991px) {
          .des_div-116 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
      `}</style>
    </>
  );
}
export default Design;


import React, { useContext, useState , useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import {RiDeleteBin6Line} from 'react-icons/ri';
import { db, storage } from './Firebase';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import {AiOutlinePlus,AiOutlineMinus} from 'react-icons/ai';
import Select from 'react-select';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

function Submit_Request_Form(props) {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const history = useHistory();

    const uniform_options = [
        { value: 'all', label: 'All Classes' },
        { value: 'primary', label: 'Primary Uniform' },
        { value: 'Senior Class', label: 'Senior Class Uniforms' },
        // Add more options as needed
      ];
      const fabric_options = [
        { value: 'Mafatlal', label: 'Mafatlal' },
        { value: 'Valji', label: 'Valji' },

        // Add more options as needed
      ];
      const shipping_options = [
        { value: 'open', label: 'I am open to all suggestions' },
        { value: 'Home Deliver', label: 'Deliver to my door' },
        { value: 'Freight Forwarder', label: 'I want my order shipped to my freight forwarder' },
        // Add more options as needed
      ];
    const [Requirement, setRequirement] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [orderQuantity, setorderQuantity] = useState(30);
    const [contactInfoError, setContactInfoError] = useState(false);
    const [RequirementError, setRequirementError] = useState(false);
    const [QuantityError, setQuantityError] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const [deliveryOption, setDeliveryOption] = useState(shipping_options[0].value);
    const handleDeliveryOptionChange = (selectedOption) => {
        setDeliveryOption(selectedOption.value);
      };
    const handleFileChange = (e) => {
        if (e && e.target) {
          const file = e.target.files[0];
          setSelectedFile(file);
    
          // Automatically upload the file to Firebase Storage
          uploadFile(file);
        }
      };
      const uploadFile = (file) => {
        const storageRef = storage.ref(`/Order Uploads/${file.name}`);
        const uploadTask = storageRef.put(file);
    
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Progress
          },
          (error) => {
            // Error handling
            console.error(error);
            // Add code to inform the user about the error
          },
          () => {
            // Success, get file URL
            storageRef.getDownloadURL().then((url) => {
              setFileURL(url);
            });
          }
        );
      };
      const handleFileOpen = () => {
        // Trigger download when the user clicks on the uploaded file name
        if (fileURL) {
          window.open(fileURL, '_blank');
        }
      };
    const validateContactInfo = (value) => {
      const phoneRegex = /^\d{10}$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      return phoneRegex.test(value) || emailRegex.test(value);
    };

    const handleContactInfoChange = (e) => {
      setContactInfo(e.target.value);
      setContactInfoError(!validateContactInfo(e.target.value));
    };
    const handleClassOptionChange = (selectedOption) => {
        setClassOption(selectedOption.value);
      };
      const [classOption, setClassOption] = useState(uniform_options[0].value);
      const [fabricOption, setFabricOption] = useState(fabric_options[0].value);
      const handleFabricOptionChange = (selectedOption) => {
        setFabricOption(selectedOption.value);
      };
    
    const handleRequirementChange = (e) => {
      setRequirement(e.target.value);
      setRequirementError(e.target.value.trim() === ''); // Address is mandatory
    };


    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // Validate contact information
      if (!validateContactInfo(contactInfo)) {
        setContactInfoError(true);
        return; // Don't proceed with the submission
      }
      setContactInfoError(false);
    
      // Validate address
      if (Requirement.trim() === '') {
        setRequirementError(true);
        return; // Don't proceed with the submission
      }
      setRequirementError(false);
    
      // Construct the order data, including product details, uploaded file, and selected options
      const orderData = {
        Requirement: Requirement,
        Contact: contactInfo,
        orderQuantity: orderQuantity,
        products: orderDetails.map((product) => ({
          name: product.name,
          description: product.description,
          price: product.price,
          quantity: product.quantity,
          image: product.image,
        })),
        uploadedFile: fileURL, // Include the file URL
        selectedOptions: {
          classOption: classOption,
          fabricOption: fabricOption,
          deliveryOption: deliveryOption,
        },
        timestamp: new Date(), // Add timestamp

      };
    
      // Save order data to Firebase
      try {
        const ordersRef = db.collection('Orders');
        await ordersRef.add(orderData);
    
        // Reset fields and state
        setContactInfo('');
        setRequirement('');
        setorderQuantity(30);
        setOrderDetails([]);
        setFileURL(null);
        setClassOption(uniform_options[0].value);
        setFabricOption(fabric_options[0].value);
        setDeliveryOption(shipping_options[0].value);
    
        // Optionally, you can show a success message to the user
        window.gtag('event', 'conversion', {'send_to': 'AW-11246127201/mcHGCNDQtPsYEOGQyfIp'});
        alert('Request submitted successfully!');
         history.push('/PartnerProgram');
      } catch (error) {
        // Handle any errors that may occur during data submission
        console.error('Error submitting request:', error);
        alert('Error submitting request. Please try again.');
      }
    };
    
    















    const location = useLocation();
    const [orderDetails, setOrderDetails] = useState(props.location.state?.orderDetails || []);

  



  

    const calculateTotalOrderPrice = (orderDetails) => {
      return orderDetails.reduce((total, product) => {
        return total + product.price * product.quantity;
      }, 0);
    };
  
    const [totalOrderPrice, setTotalOrderPrice] = useState(calculateTotalOrderPrice(orderDetails));
  
    const updateTotalOrderPrice = (updatedOrderDetails) => {
      const newTotalOrderPrice = calculateTotalOrderPrice(updatedOrderDetails);
      setTotalOrderPrice(newTotalOrderPrice);
    };
  
    const handleRemoveProduct = (index) => {
      setOrderDetails((prevOrderDetails) => {
        const updatedOrderDetails = [...prevOrderDetails];
        updatedOrderDetails.splice(index, 1);
        updateTotalOrderPrice(updatedOrderDetails); // Update total order price here
        return updatedOrderDetails;
      });
    };
  
    const handleQuantityChange = (index, action) => {
      setOrderDetails((prevOrderDetails) => {
        const updatedOrderDetails = [...prevOrderDetails];
        const currentQuantity = updatedOrderDetails[index].quantity || 10;
  
        // Update quantity based on the action
        if (action === 'increase') {
          updatedOrderDetails[index].quantity = currentQuantity + 1;
        } else if (action === 'decrease' && currentQuantity > 1) {
          updatedOrderDetails[index].quantity = currentQuantity - 1;
        }
  
        updateTotalOrderPrice(updatedOrderDetails); // Update total order price here
  
        return updatedOrderDetails;
      });
    };

    
  
    console.log('Order Details:', orderDetails);
  return (
    <>
        <>
      <Helmet>
        <title>Submit</title>
        <meta name="description" content="Submission of request" />
        {/* Add more meta tags as needed */}
      </Helmet>
      {/* Other content of your component */}
    </>
      <div className="submit_request_form_div">
        <div className="submit_request_form_div-2">
          <div className="submit_request_form_div-3">
            <div className="submit_request_form_div-5">Submit Request</div>
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FIcons%2Fline-3.svg?alt=media&token=774beabf-6ba3-44d3-ab12-bf1b280cc156"
              className="submit_request_form_img"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b97a934f-9b99-48a1-b335-00b3e5ed258e?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="submit_request_form_img-2"
            />
            <div className="submit_request_form_div-5">Negotiate With Us</div>
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FIcons%2Fline-3.svg?alt=media&token=774beabf-6ba3-44d3-ab12-bf1b280cc156"
              className="submit_request_form_img"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f000b91-3c80-42ff-bdcf-2592b01915a3?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="submit_request_form_img-2"
            />
            <div className="submit_request_form_div-5">Recieve Sample if necessay</div>
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FIcons%2Fline-3.svg?alt=media&token=774beabf-6ba3-44d3-ab12-bf1b280cc156"
              className="submit_request_form_img-4"
            />
                      <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fdelivery.png?alt=media&token=1618afb7-00d4-43eb-826d-781bc68dca90"
            className="submit_request_form_img-2"
          />
          <div className="submit_request_form_div-5">Make Payment and wait for the delivery</div>
          </div>

        </div>
        <div className="submit_request_form_div-8">
          <div className="submit_request_form_div-9">
            <div className="submit_request_form_div-10">Product Detail</div>
            <div className="submit_request_form_div-11">Order summary</div>
          </div>
          <form onSubmit={handleSubmit}>
          <div className="submit_request_form_div-12">
            <div className="submit_request_form_div-13">
              <div className="submit_request_form_column">
                <div className="submit_request_form_div-14">
                  <div className="submit_request_form_div-15">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9b931e8a-a950-44f3-831b-3f45db27d09e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b931e8a-a950-44f3-831b-3f45db27d09e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b931e8a-a950-44f3-831b-3f45db27d09e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b931e8a-a950-44f3-831b-3f45db27d09e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b931e8a-a950-44f3-831b-3f45db27d09e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b931e8a-a950-44f3-831b-3f45db27d09e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b931e8a-a950-44f3-831b-3f45db27d09e?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b931e8a-a950-44f3-831b-3f45db27d09e?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="submit_request_form_img-6"
                    />
                  </div>
                  <div className="submit_request_form_div-16">
                    <div className="submit_request_form_div-17">Uniforms :</div>
        
                    <Select
  className='submit_request_form_div-18'
  classNamePrefix='custom-select'
  options={uniform_options}
  defaultValue={uniform_options[0]}
  isSearchable={false}
  onChange={handleClassOptionChange}
  theme={(theme) => ({
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary: '#2684FF',
    },
  })}
  styles={{
    control: (provided) => ({
      ...provided,
      width: '100%', // Default width for mobile
    }),
    '@media (min-width: 768px)': {
      control: {
        width: 300, // Set the desired width for laptop
      },
    },
  }}
/>



                    
                  </div>
                  <div className="submit_request_form_div-20">Fabric Used</div>
                  <div className="submit_request_form_div-21">
                  <Select
  className='submit_request_form_div-22'
  classNamePrefix='custom-select'
  options={fabric_options}
  defaultValue={fabric_options[0]}
  isSearchable={false}
  onChange={handleFabricOptionChange}
  theme={(theme) => ({
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary: '#2684FF',
    },
  })}
  styles={{
    control: (provided) => ({
      ...provided,
      width: '100% !important', // Default width for mobile
    }),
    '@media (min-width: 768px)': {
      control: {
        width: '100% !important', // Set the desired width for laptop
      },
    },
  }}
/>




                  </div>
                  <div className="submit_request_form_div-23">Customisation Details: </div>
                  <div className="submit_request_form_div-24">
                    <div className="submit_request_form_div-25">                    {!selectedFile && (
                      <>
                        <label htmlFor='file-input' className='submit_request_form_custom-file-upload'>
                          <i className='fas fa-cloud-upload-alt'></i> Choose File
                        </label>
                        <input
                          id='file-input'
                          type='file'
                          accept='.gif, .jpeg, .png, .tif, .docx, .doc, .xlsx, .xls, .txt, .pdf'
                          onChange={handleFileChange}
                        />
                      </>
                    )}
                    {selectedFile && (
                      <div className='submit_request_form_uploaded-file'>
                        <p>
                          <button className='submit_request_form_file-link' onClick={handleFileOpen}>
                            {selectedFile.name}
                          </button>
                        </p>
                        <button className='submit_request_form_button_2' onClick={() => setSelectedFile(null)}>
                        <RiDeleteBin6Line />
                        </button>
                      </div>
                    )}</div>

                  </div>
                  <div className="submit_request_form_div-26">
                    <div className="submit_request_form_div-27">
                      <div className="submit_request_form_div-28">Quantity: </div>
                      <div className="submit_request_form_div-29">
                
                        <input
        type="number"
        placeholder="Enter Quantity"
    
        value={orderQuantity}
        onChange={(e) => setorderQuantity(e.target.value)}
        style={{
          width: '100%',
          height: '40px',
          borderRadius: '5px',
          border: QuantityError ? '2px solid red' : '1px solid #ccc',
          padding: '5px',
          outline: 'none',
        }}
      /> {/* Closing parenthesis added here */}

      {QuantityError && (
        <span
          style={{
            marginLeft: '5px',
            color: 'red',
            height: '10px',
            width: '10px',
          }}
          role="img"
          aria-label="info"
        >
          <HiOutlineInformationCircle />
        </span>
      )}


                        

                      </div>
                    </div>
                    <div className="submit_request_form_div-31">
                      <div className="submit_request_form_div-32">Shipping Options</div>
                      <div className="submit_request_form_div-33">
  <Select
    className='custom-select-control'
    classNamePrefix='custom-select'
    options={shipping_options}
    defaultValue={shipping_options[0]}
    isSearchable={false}
    onChange={handleDeliveryOptionChange}
    theme={(theme) => ({
      ...theme,
      borderRadius: 5,
      colors: {
        ...theme.colors,
        primary: '#2684FF',
      },
    })}
    styles={{
      control: (provided) => ({
        ...provided,
        width: '100%', // Default width for mobile
      }),
    }}
  />
</div>

                    </div>
                  </div>
                  <div className="submit_request_form_div-34">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bd992dc8-64d4-47fe-bcce-9f8f8442b00a?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="submit_request_form_img-13"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a4bffb1c-ea99-441e-a9a0-66705d41cb19?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="submit_request_form_img-14"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b9ca35ea-a161-4fb2-b16e-74625b67bbd0?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                      className="submit_request_form_img-15"
                    />
                  </div>
                </div>
              </div>
              <div className="submit_request_form_column-2">
                <div className="submit_request_form_div-35">
              
                <div className="submit_request_form_div-36" style={{ maxHeight: '400px', overflowY: 'auto' }}>
  {orderDetails.map((product, index) => (
    <div key={index} className="submit_request_form_div-37">
      <div className="submit_request_form_div-38">
        <img
          loading="lazy"
          src={product.image}
          className="submit_request_form_img-16"
        />
        <div className="submit_request_form_div-39">
          <div className="submit_request_form_div-40">{product.name}</div>
          <div className="submit_request_form_div-41">{product.description}</div>
          <div className="submit_request_form_div-42">{`₹ ${product.price}`}</div>
        </div>
      </div>
      {/* Add quantity-related elements here */}
      <div className="submit_request_form_div-53">
        <button onClick={() => handleQuantityChange(index, 'increase')} className='submit_request_form_quantity_button'><AiOutlinePlus/></button>
        <div className="submit_request_form_div-54">{product.quantity}</div>
        <button onClick={() => handleQuantityChange(index, 'decrease')}className='submit_request_form_quantity_button'><AiOutlineMinus/></button>
      </div>
      <button onClick={() => handleRemoveProduct(index)} className='submit_request_form_delete'>
        <RiDeleteBin6Line />
      </button>
      {/* ... other elements ... */}
    </div>
  ))}
</div>
<div className='submit_request_form_total_display'>
  <div className='submit_request_form_total_text'>
    Approximate Price:
  </div>
<div className='submit_request_form_total_price'>₹{totalOrderPrice}</div>
</div>

                  <div className="submit_request_form_div-59">
                    <div className="submit_request_form_div-60">One request, multiple quotes</div>
                    <div className="submit_request_form_div-61">
                    <input
      type="text"
      id="productName"
      value={Requirement}
      onChange={handleRequirementChange}
      className={RequirementError ? 'error' : ''}
      placeholder="Enter School Name"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: RequirementError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {RequirementError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
                    </div>
                    <div className="submit_request_form_div-61">
                    <input
      type="text"
      id="contactInfo"
      value={contactInfo}
      onChange={handleContactInfoChange}
      className={contactInfoError ? 'error' : ''}
      placeholder="Phone Number or Email (Required)"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: contactInfoError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {contactInfoError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
                    </div>
                   
                  </div>
                  <button type="submit" className="submit_request_form_div-66">Submit</button>
                </div>
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
      <style jsx>{`


        .submit_request_form_custom-file-upload {
            display: inline-block;
            cursor: pointer;
            font-size: 16px;
            color: #53c3f4;
            border: 2px solid  #ccd;
            padding: 8px 20px;
            border-radius: 5px;
            background-color: white;
            width: 631px;
            height: 40px;
            text-align: center;
            transition: background-color 0.3s;
        }
        @media (max-width: 991px) {
          .submit_request_form_custom-file-upload {
            width:100%;
          }
        }
        .submit_request_form_custom-file-upload:hover {
            background-color: #53c3f4;
            color: white;
        }
        .submit_request_form_uploaded-file {
            display: flex;
            flex-direction: row;
            align-items: left;
            width: 631px;
            font: arial;
            border-radius: 0.5rem;
            background-color: #fafafa;
            height: 40px;
          
          }
          
          .p {
            font-size: 14px;
            margin-bottom: 5px;
            flex-basis: 80%;
            align-items: center;
            padding: 10px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          
          }
          .submit_request_form_button_2 {
            cursor: pointer;
            color: #ff0000;
            background: none;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            border: none;
            font-size: 14px;
            align-items: center;
            margin-left:auto;
            margin-right:10px;

          }
          .submit_request_form_file-link{
            color: black;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          }
  
        .submit_request_form_div {
          background-color: #fff;
          display: flex;
          flex-direction: column;
        }
        .submit_request_form_div-2 {
          background-color: #2d72b2;
          display: flex;
          width: 100%;
          align-items: end;
          justify-content: space-between;
          gap: 11px;
          padding: 20px 25px 10px 25px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-2 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            padding-left: 20px;
          }
        }
        .submit_request_form_total_display
        {
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          padding: 10px;
          height: 40px;
          justify-content: space-between;
          width: 80%;
          
        }
        .submit_request_form_total_text {
          align-self: left;
          display: flex;
          font: 24px Arial, Helvetica, sans-serif;  /* Corrected font property */
          font-weight: 550;
        }
        .submit_request_form_total_price
        {
          font: 600 18px Inter, sans-serif;
          color: blue;
        }


        .submit_request_form_div-3 {
          justify-content: center;
          align-items: flex-start;
          display: flex;
          margin-top: 40px;
          gap: 0px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-3 {
            max-width: 100%;
            flex-wrap: wrap;
            margin-top: 40px;
            flex-direction:column;
            align-items:center;
            gap:3px;
            margin-top:10px;
          }
        }
        .submit_request_form_div-4 {
          color: var(--color-white, #fff);
          font: 400 24px Inter, sans-serif;
        }
        .submit_request_form_quantity_button
        {
            background:white;
            text-align:center;
            color: black;
        }
        .submit_request_form_img{
          aspect-ratio: 13.12;
          object-fit: contain;
          object-position: center;
          width: 210px;
          stroke-width: 3px;
          stroke: #78b689;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;  
        }
        @media (max-width: 991px) {
          .submit_request_form_img {
            display:none;

          }
        }
        .submit_request_form_img-2 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .submit_request_form_div-5 {
          color: var(--color-white, #fff);
          margin: auto 0;
          font: 400 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-5 {
            text-align:center;
          }
        }
        .submit_request_form_img-3 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .submit_request_form_div-6 {
          color: var(--color-white, #fff);
          font: 400 24px Inter, sans-serif;
        }
        .submit_request_form_img-4 {
          aspect-ratio: 9.19;
          object-fit: contain;
          object-position: center;
          width: 147px;
          stroke-width: 3px;
          stroke: #78b689;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .submit_request_form_img-4 {
            display:none;
          }
        }
        .submit_request_form_img-5 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          margin-top: 69px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .submit_request_form_img-5 {
            margin-top: 40px;
          }
        }
        .submit_request_form_delete
        {
            margin-right:5px;
            margin-left:5px;
        }
        .submit_request_form_div-7 {
          color: var(--color-white, #fff);
          margin-top: 54px;
          font: 400 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-7 {
            margin-top: 40px;
          }
        }
        .submit_request_form_div-8 {
          display: flex;
          width: 100%;
          flex-direction: column;
          margin: 29px 0 51px;
          padding: 0 49px 0 50px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-8 {
            max-width: 100%;
            margin-bottom: 40px;
            padding: 0 20px;
          }
        }
        .submit_request_form_div-9 {
          display: flex;
          align-items: start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-9 {
            max-width: 100%;
            flex-wrap: wrap;
            align-items:center;

          }
        }
        .submit_request_form_div-10 {
          color: #232121;
          font: 400 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-10 {
            max-width: 100%;
            flex-wrap: wrap;
            display:none;
          }
        }
        .submit_request_form_div-11 {
          color: #292d32;
          margin-top: 16px;
          white-space: nowrap;
          font: 400 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-11 {
            white-space: initial;
            text-align:center;
            align-self:center;
            margin-top:0;
            display:flex;
          }
        }
        .submit_request_form_div-12 {
          margin-top: 9px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-12 {
            max-width: 100%;
          }
        }
        .submit_request_form_div-13 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-13 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .submit_request_form_column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 60%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .submit_request_form_column {
            width: 100%;
          }
        }
        .submit_request_form_div-14 {
          border-radius: 8px;
          box-shadow: 6px 4px 13px 0px rgba(0, 0, 0, 0.25);
          background-color: #ccc;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          margin: 0 auto;
          padding: 30px 190px 30px 190px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-14 {
            max-width: 100%;
            margin-top: 40px;
            padding: 0 20px;
          }
        }
        .submit_request_form_div-15 {
          justify-content: center;
          border-radius: 8px;
          box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
          background-color: #fff;
          align-self: center;
          display: flex;
          width: 358px;
          max-width: 100%;
          flex-direction: column;
          padding: 15px 20px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-15 {
            max-width: 100%;
            margin-top: 30px;
      
          }
        }
        .submit_request_form_img-6 {
          aspect-ratio: 1.59;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
        }
        .submit_request_form_div-16 {
          justify-content: space-between;
          align-items: center;
          align-self: center;
          display: flex;
          margin: 33px 0 10px 0;
          width: 631px;
          max-width: 100%;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-16 {
            flex-wrap: wrap;
          }
        }
        .submit_request_form_div-17 {
          color: #525252;
          margin: auto 0;
          font: 400 24px Jost, sans-serif;
        }
        .submit_request_form_div-18 {
          justify-content: space-between;
          border-radius: 8px;
          background-color: #ededed;
          align-self: stretch;
          display: flex;
          gap: 20px;
          width: 300px;
        
        }
        @media (max-width: 991px) {
          .submit_request_form_div-18 {
            width:100%;
          }
        }
        .submit_request_form_div-19 {
          color: #525252;
          font: 400 16px Jost, sans-serif;
        }
        .submit_request_form_img-7 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          max-width: 100%;
        }
        .submit_request_form_div-20 {
          color: #888;
          align-self: start;
          margin-top: 24px;
          white-space: nowrap;
          font: 400 16px Jost, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-20 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .submit_request_form_div-21 {
          justify-content: space-between;
          border-radius: 8px;
          background-color: #ededed;
          align-self: center;
          display: flex;
          margin-top: 16px;
          width: 631px;
          max-width: 100%;
          gap: 20px;
    
        }
        @media (max-width: 991px) {
          .submit_request_form_div-21 {
            flex-wrap: wrap;
          }
        }
        .submit_request_form_div-22 {
          color: #525252;
          font: 400 20px Jost, sans-serif;

          width:100%;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-22 {
            width:100%;
          }
        }
        .submit_request_form_img-8 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .submit_request_form_div-23 {
          color: #525252;
          align-self: start;
          margin-top: 24px;
          white-space: nowrap;
          font: 400 16px Jost, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-23 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .submit_request_form_div-24 {
          justify-content: space-between;
          border-radius: 8px;
          background-color: #ededed;
          align-self: center;
          display: flex;
          margin-top: 16px;
          width: 631px;
          max-width: 100%;
          gap: 20px;
        
        }
        @media (max-width: 991px) {
          .submit_request_form_div-24 {
            flex-wrap: wrap;
            width:100%;
          }
        }
        .submit_request_form_div-25 {
          color: #525252;
          font: 400 20px Jost, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-25 {
            flex-wrap: wrap;
            width:100%;
          }
        }
        .submit_request_form_img-9 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .submit_request_form_div-26 {
          align-self:center;
          display: flex;
          margin-top: 24px;
          width: 631px;
          max-width: 100%;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-26 {
            flex-wrap: wrap;
          }
        }
        .submit_request_form_div-27 {
          justify-content: center;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
        }
        .submit_request_form_div-28 {
          color: #525252;
          white-space: nowrap;
          font: 400 16px Jost, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-28 {
            white-space: initial;
          }
        }
        .submit_request_form_div-29 {
          justify-content: space-between;
          border-radius: 8px;
          background-color: #ededed;
          display: flex;
          margin-top: 16px;
          gap: 20px;

        }
        .submit_request_form_div-30 {
          color: #525252;
          font: 400 20px Jost, sans-serif;
        }
        .submit_request_form_img-10 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .submit_request_form_div-31 {
          justify-content: center;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-31{
            white-space: initial;
            width:100%;
          }
        }
        .submit_request_form_div-32 {
          color: #525252;
          white-space: nowrap;
          font: 400 16px Jost, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-32 {
            white-space: initial;
          }
        }
        .submit_request_form_div-33 {
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
          background-color: #ededed;
          display: flex;
          margin-top: 16px;
          gap: 20px;

        }
        @media (max-width: 991px) {
          .submit_request_form_div-33 {
            white-space: initial;
            width:100%;
          }
        }
        .submit_request_form_img-11 {
          aspect-ratio: 5.29;
          object-fit: contain;
          object-position: center;
          width: 37px;
          overflow: hidden;
          max-width: 100%;
          margin: auto 0;
        }
        .submit_request_form_img-12 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 24px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .submit_request_form_div-34 {
          align-self: end;
          display: flex;
          margin-top: 26px;
          width: 150px;
          max-width: 100%;
          gap: 4px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-34 {
            justify-content: center;
          }
        }
        .submit_request_form_img-13 {
          aspect-ratio: 3.06;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
          margin: auto 0;
        }
        .submit_request_form_img-14 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .submit_request_form_img-15 {
          aspect-ratio: 1.48;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
          margin: auto 0;
        }
        .submit_request_form_column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 40%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .submit_request_form_column-2 {
            width: 100%;
            margin-left:0;
          }
        }
        .submit_request_form_div-35 {
          display: flex;
          margin-top: 16px;
          flex-grow: 1;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-35 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .submit_request_form_div-36 {
          border-radius: 8px;
          border: 1px solid #d9d9d9;
          align-self: stretch;
          display: flex;
          flex-direction:column;
          justify-content: space-between;
          gap: 20px;
          padding: 8px 8px 0;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-36 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .submit_request_form_div-37 {
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: row;
        }
        .submit_request_form_div-38 {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
        .submit_request_form_img-16 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 100px;
          overflow: hidden;
          max-width: 100%;
        }
        .submit_request_form_div-39 {
          align-self: center;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
          margin: auto 0;
        }
        .submit_request_form_div-40 {
          color: #525252;
          white-space: nowrap;
          font: 500 18px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-40 {
            white-space: initial;
          }
        }
        .submit_request_form_div-41 {
          color: #888;
          margin-top: 9px;
          white-space: nowrap;
          font: 400 12px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-41 {
            white-space: initial;
          }
        }
        .submit_request_form_div-42 {
          color: #525252;
          margin-top: 31px;
          white-space: nowrap;
          font: 600 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-42 {
            white-space: initial;
          }
        }
        .submit_request_form_div-43 {
          display: flex;
          margin-top: 16px;
          justify-content: space-between;
          gap: 20px;
        }
        .submit_request_form_img-17 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 100px;
          overflow: hidden;
          max-width: 100%;
        }
        .submit_request_form_div-44 {
          align-self: center;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
          margin: auto 0;
        }
        .submit_request_form_div-45 {
          color: #525252;
          white-space: nowrap;
          font: 500 18px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-45 {
            white-space: initial;
          }
        }
        .submit_request_form_div-46 {
          color: #888;
          margin-top: 6px;
          white-space: nowrap;
          font: 400 12px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-46 {
            white-space: initial;
          }
        }
        .submit_request_form_div-47 {
          color: #525252;
          margin-top: 29px;
          white-space: nowrap;
          font: 600 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-47 {
            white-space: initial;
          }
        }
        .submit_request_form_div-48 {
          display: flex;
          margin-top: 16px;
          justify-content: space-between;
          gap: 20px;
        }
        .submit_request_form_img-18 {
          aspect-ratio: 1.72;
          object-fit: contain;
          object-position: center;
          width: 100px;
          overflow: hidden;
          max-width: 100%;
        }
        .submit_request_form_div-49 {
          align-self: center;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
          margin: auto 0;
        }
        .submit_request_form_div-50 {
          color: #525252;
          white-space: nowrap;
          font: 500 18px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-50 {
            white-space: initial;
          }
        }
        .submit_request_form_div-51 {
          color: #888;
          margin-top: 6px;
          white-space: nowrap;
          font: 400 12px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-51 {
            white-space: initial;
          }
        }
        .submit_request_form_div-52 {
          display: flex;
          flex-basis: 0%;
          flex-direction: column;
        }
        .submit_request_form_img-19 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          overflow: hidden;
        }
        .submit_request_form_div-53 {

            display: flex;
            margin-left: auto;
            justify-content: space-evenly;  /* Corrected property name */
            flex-direction: column;
          }
          
        .submit_request_form_div-54 {
          color: #888;
          text-align: center;
          white-space: nowrap;
          background-color: var(--color-primary-tints, #faf0ca);
          aspect-ratio: 1;
          padding: 12px 14px;
          font: 700 12px/135% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-54 {
            white-space: initial;
          }
        }
        .submit_request_form_img-20 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          overflow: hidden;
        }
        .submit_request_form_img-21 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          overflow: hidden;
          margin-top: 20px;
        }
        .submit_request_form_div-55 {
          background-color: var(--color-primary-tints, #faf0ca);
          display: flex;
          aspect-ratio: 1;
          flex-direction: column;
        }
        .submit_request_form_div-56 {
          color: #888;
          text-align: center;
          white-space: nowrap;
          background-color: var(--color-primary-tints, #faf0ca);
          aspect-ratio: 1;
          align-items: start;
          padding: 12px 14px;
          font: 700 12px/135% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-56 {
            white-space: initial;
          }
        }
        .submit_request_form_img-22 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          overflow: hidden;
        }
        .submit_request_form_img-23 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          overflow: hidden;
          margin-top: 20px;
        }
        .submit_request_form_div-57 {
          background-color: var(--color-primary-tints, #faf0ca);
          display: flex;
          aspect-ratio: 1.3333333333333333;
          flex-direction: column;
        }
        .submit_request_form_div-58 {
          color: #888;
          text-align: center;
          white-space: nowrap;
          background-color: var(--color-primary-tints, #faf0ca);
          aspect-ratio: 1.3333333333333333;
          padding: 12px 14px 3px;
          font: 700 12px/135% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-58 {
            white-space: initial;
          }
        }
        .submit_request_form_div-59 {
          border: 3px solid #dcdee3;
          background-color: #fff;
          align-self: stretch;
          display: flex;
          margin-top: 26px;
          flex-direction: column;
          padding: 29px 20px 23px 25px;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-59 {
            max-width: 100%;
            padding-left: 20px;
          }
        }
        .submit_request_form_div-60 {
          color: #292626;
          text-decoration-line: underline;
          white-space: nowrap;
          font: 700 26px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-60 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .submit_request_form_div-61 {
          color: #c6c4cb;
          letter-spacing: 0.9px;
          text-decoration-line: underline;
          white-space: nowrap;
          width:60%;
          border-radius: 5px;
          border: 1.5px solid #ebe9ed;
          margin-top: 27px;
          align-items: start;
          height:40px;
          font: 500 18px/56% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-61 {
            white-space: initial;
            width: 100%;
          }
        }
        .submit_request_form_div-62 {
          align-self: center;
          widht:100%;

          display: flex;
          padding-right: 9px;
          justify-content: space-between;
          gap: 20px;
          margin: auto 0 ;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-62 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .submit_request_form_div-63 {
          border-radius: 5px;
          border: 1.5px solid #ebe9ed;
          display: flex;
          width: 197px;
          height: 61px;
          flex-direction: column;
        }
        .submit_request_form_div-64 {
          border-radius: 5px;
          border: 1.5px solid #ebe9ed;
          display: flex;
          width: 197px;
          height: 61px;
          flex-direction: column;
        }
        .submit_request_form_div-65 {
          color: #fff;
          letter-spacing: 0.7px;
          text-decoration-line: underline;
          align-self: start;
          width: 366px;
          max-width: 100%;
          margin: 50px 0 0 29px;
          font: 700 14px/71% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .submit_request_form_div-65 {
            margin: 40px 0 0 10px;
          }
        }
        .submit_request_form_div-66 {
            color: #525252;
            white-space: nowrap;
            display: flex;
            justify-content: center; /* Aligns the content horizontally */
            align-items: center;
            text-align: center; /* Aligns the text inside the container */
            border-radius: 8px;
            box-shadow: 4px 3px 8px 0px rgba(0, 0, 0, 0.25);
            background-color: var(--color-primary, #f4d35e);
            margin: 20px 0 auto 0;
            width: 469px;
            max-width: 100%;
            padding: 18px 20px;
            font: 600 24px Roboto, sans-serif;
          }
          
          @media (max-width: 991px) {
            .submit_request_form_div-66 {
              white-space: initial;
            }
          }
          
        }
      `}</style>
    </>
  );
}

export default Submit_Request_Form;

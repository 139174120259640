import React, { createContext, useContext, useState } from 'react';

// Create a new context
const SearchContext = createContext();

// Create a custom hook to use the search context
export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};

// Create a provider component to wrap your app and provide the search context
export const SearchProvider = ({ children }) => {
  const [searchInput, setSearchInput] = useState('');

  const value = {
    searchInput,
    setSearchInput,
  };

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  );
};

/*import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';




const firebaseConfig = {
  apiKey: "AIzaSyBHMup3m_fmUvC7JnkiaQFa4DxveLCeW0s",
  authDomain: "challenge-e5b79.firebaseapp.com",
  databaseURL: "https://challenge-e5b79-default-rtdb.firebaseio.com",
  projectId: "challenge-e5b79",
  storageBucket: "challenge-e5b79.appspot.com",
  messagingSenderId: "1052663719381",
  appId: "1:1052663719381:web:82e41b01cbe4eeeb55c95c",
  measurementId: "G-VNYF0Y3BVL"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const db = firebase.initializeApp(firebaseConfig).firestore();

export {storage, db};


import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBHMup3m_fmUvC7JnkiaQFa4DxveLCeW0s",
  authDomain: "challenge-e5b79.firebaseapp.com",
  databaseURL: "https://challenge-e5b79-default-rtdb.firebaseio.com",
  projectId: "challenge-e5b79",
  storageBucket: "challenge-e5b79.appspot.com",
  messagingSenderId: "1052663719381",
  appId: "1:1052663719381:web:82e41b01cbe4eeeb55c95c",
  measurementId: "G-VNYF0Y3BVL"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const storage = getStorage(app);

export { db, storage };
*/
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics'; 
import "firebase/compat/storage";
import "firebase/compat/performance";

const firebaseConfig = {
    apiKey: "AIzaSyBHMup3m_fmUvC7JnkiaQFa4DxveLCeW0s",
    authDomain: "challenge-e5b79.firebaseapp.com",
    projectId: "challenge-e5b79",
    storageBucket: "challenge-e5b79.appspot.com",
    messagingSenderId: "1052663719381",
    appId: "1:1052663719381:web:82e41b01cbe4eeeb55c95c",
    measurementId: "G-VNYF0Y3BVL"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();  
const storage = firebase.storage();
const db = firebaseApp.firestore();
const perf = firebase.performance();

export { storage, db, analytics }; 

export default firebase;




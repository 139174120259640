import React, {useContext} from 'react';
import { MedicalUniformsContext } from './Medical_Uniforms_Data';
import './Product_Test.css';


function MedicalUniforms () {

    const {medicaluniforms} = useContext(MedicalUniformsContext);
    console.log(medicaluniforms);

    return(
        <>
            {medicaluniforms.length !== 0 && <h1>Medical Uniforms</h1>}
            <div className='products-container'>
                {medicaluniforms.length === 0 && <div>slow internet...no products to display</div>}
                {medicaluniforms.map(product => (
                    <div className='product-card' key={product.ProductID}>
                        <div className='product-img'>
                            <img src={product.ProductImg} alt="not found" />
                        </div>
                        <div className='product-name'>
                            {product.ProductName}
                        </div>
                        <div className='product-name'>
                            {product.ProductPrice}
                        </div>
                        <button className='addcart-btn' >ADD TO CART</button>
                    </div>
                ))}
            </div>
        </>
    )
}
export default MedicalUniforms;



import generateProductPage from './Generate_Product_Page';

import { IDCardsContext } from './ID_Cards_Data'; 
import { IoCall } from 'react-icons/io5';
import React, {  useEffect } from 'react';
import './Product_Test.css';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';




const AdditionalElements = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
              <>
      <Helmet>
        <title>Elevate School Identity with Exclusive Custom ID Cards - Custoking</title>
        <meta name="description" content="Elevate your school's identity with Custoking's custom-designed ID cards. Our expertly crafted cards are made for students and staff, ensuring cohesion within educational community. Explore our range of options to find the perfect fit for the school!" />
        {/* Add more meta tags as needed */}
      </Helmet>
      {/* Other content of your component */}
    </>

      {/* Add your additional elements here */}
      <div className='product_page_heading'>ID Cards</div>
      <div className='product_page_heading_display'>
        <div className='heading_display_left'>
          <img src = "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
        </div>
        <div className='heading_display_cards'>
          <div className='heading_page_card_display_left'>
            <img src= "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
            <p>Product Name 1</p>
          </div>
          <div className='heading_page_card_display'>
            <img src= "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
            <p>Product Name 1</p>
          </div>
          <div className='heading_page_card_display_right'>
            <img src= "https://storage.googleapis.com/challenge-e5b79.appspot.com/Images/Writing_Supplies/A5%20Size%20Hardcover%20Spiral%20Notebook%20Simple%20Cover%20Design%20Custom%20Notebook%20for%20Students.jpg"/>
            <p>Product Name 1</p>
          </div>
        </div>
        <div className='heading_display_right'>
          <div className='heading_display_right_txt'>CustoKing Best Seller</div>
          <div className='heading_display_right_contact_div'>
          <a className='heading_display_right_contact_us_bg' href="tel:+917478004111">
          <a className='heading_display_right_contact_us'  href="tel:+917478004111">Contact Us</a>
          </a>
          </div>
        </div>
      </div>
      
    </div>
  );
};


const Product_Formatting = generateProductPage(IDCardsContext  , 'ID-Cards', 'idcards');
const IDCards = () => {
  return (
    <>
      <AdditionalElements />
      <Product_Formatting />
    </>
  );
};


export default IDCards;








// ProductSearch.js
/*import React, { useState } from 'react';
import { db } from './Firebase';

const ProductSearch = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const searchProducts = async () => {
    // Perform the search across all collections
    try {
      const results = await searchAllCollections(searchInput.toLowerCase());
      // Update state with the search results
      setSearchResults(results);
    } catch (error) {
      console.error("Search Error:", error);
    }
  };

 /* const searchAllCollections = async query => {
    const collections = ['Product/Products/t-shirts', 'Product/Products/Writing', 'Product/Products/Promotional_Gifts'];
  
    // Fetch data from each collection
    const promises = collections.map(async collection => {
      const results = await searchCollection(query, collection);
      console.log(`Results for collection ${collection}:`, results);
      return results;
    });
  
    // Concatenate results from all collections
    const resultsArray = await Promise.all(promises);
    const results = resultsArray.reduce((acc, current) => acc.concat(current), []);
    console.log('Final results:', results);
    return results;
  };
  
  
  const searchCollection = async (query, collection) => {
    const ref = db.collection(collection);
  
    // Execute the query
    const snapshot = await ref.where('name', '>=', query.toLowerCase()).get();
  
    // Get results from the snapshot
    const results = snapshot.docs.map(doc => {
      const data = doc.data();
      const productName = data.name.toLowerCase();
      
      // Check if any part of the product name contains the query string
      const isMatch = productName.includes(query.toLowerCase());
  
      if (isMatch) {
        console.log(`Matched: ${data.name}`);
      }
  
      return isMatch ? data : null;
    }).filter(result => result !== null);
  
    console.log(`Collection: ${collection}, Query: ${query}, Results: ${results.length}, Data: ${JSON.stringify(results)}`);
  
    return results;
  };*/
  /*
  const searchAllCollections = async query => {
    const collections = ['Product/Products/Writing','Product/Products/t-shirts',  'Product/Products/Promotional_Gifts'];
  
    // Fetch data from each collection
    const promises = collections.map(async collection => {
      return searchCollection(query, collection);
    });
  
    // Concatenate results from all collections
    const resultsArray = await Promise.all(promises);
    const results = resultsArray.reduce((acc, current) => acc.concat(current), []);
    
    console.log(`Query: ${query}, Results: ${results.length}`);
    
    return results;
  };
  
  const searchCollection = async (query, collection) => {
    const ref = db.collection(collection);
  
    // Execute the query
    const snapshot = await ref.where('name', '>=', query.toLowerCase()).get();
  
    // Get results from the snapshot
    const results = snapshot.docs.map(doc => {
      const data = doc.data();
      const productName = data.name.toLowerCase();
  
      // Check if any part of the product name contains any word from the query
      const isMatch = query.split(' ').some(word => productName.includes(word.toLowerCase()));
  
      if (isMatch) {
        console.log(`Collection: ${collection}, Matched: ${data.name}`);
      }
  
      return isMatch ? data : null;
    }).filter(result => result !== null);
  
    return results;
  };
  
  
  
  
  
  
  
  
  

  return (
    <div>
      <h1>Product Search</h1>

      <label htmlFor="searchInput">Search:</label>
      <input
        type="text"
        id="searchInput"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
      />
      <button onClick={searchProducts}>Search</button>

      <div>
        {searchResults.length === 0 ? (
          <p>No results found.</p>
        ) : (
          <ul>
            {searchResults.map(result => (
              <li key={result.id}>
                Name: {result.name}, Price: {result.price}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ProductSearch;*/
// ProductSearch.js
// ProductSearch.js
/*
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { db } from './Firebase'; // Import your Firebase configuration

function ProductSearch() {
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // Fetch data initially or whenever the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch data from all relevant collections and combine them
      const collections = ['Product/Products/t-shirts', 'Product/Products/Writing', 'Product/Products/Promotional_Gifts'];
      const promises = collections.map(async collection => fetchCollectionData(collection));
      const resultsArray = await Promise.all(promises);
      const results = resultsArray.reduce((acc, current) => acc.concat(current), []);

      // Update state with the fetched data
      setSearchResults(results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCollectionData = async (collection) => {
    const snapshot = await db.collection(collection).get();
    return snapshot.docs.map(doc => doc.data());
  };

  const handleSearch = () => {
    // Filter results based on search input
    const filteredResults = searchResults.filter(item =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    );

    // Update state with the filtered results
    setSearchResults(filteredResults);
  };

  return (
    <div>
      <Container>
        <h1 className='text-center mt-4'>Product Search</h1>
        <Form>
          <InputGroup className='my-3'>
            <Form.Control
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder='Search products'
            />
          </InputGroup>
        </Form>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
  
            </tr>
          </thead>
          <tbody>
            {searchResults.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>

              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default ProductSearch;*/
/*
import React, { useState, useEffect } from 'react';
import { db } from './Firebase';

function ProductSearch() {
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // Fetch data initially or whenever the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch data from all relevant collections and combine them
      const collections = ['Product/Products/t-shirts', 'Product/Products/Writing', 'Product/Products/Promotional_Gifts'];
      const promises = collections.map(async collection => fetchCollectionData(collection));
      const resultsArray = await Promise.all(promises);
      const results = resultsArray.reduce((acc, current) => acc.concat(current), []);

      // Update state with the fetched data
      setSearchResults(results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCollectionData = async (collection) => {
    const snapshot = await db.collection(collection).get();
    return snapshot.docs.map(doc => doc.data());
  };

  const handleSearch = () => {
    // Filter results based on search input
    const filteredResults = searchResults.filter(item =>
      item.name && item.name.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    // Update state with the filtered results
    setSearchResults(filteredResults);
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Product Search</h1>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <input
          type="text"
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder='Search products'
          style={{ padding: '5px', marginRight: '5px' }}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <table style={{ width: '80%', margin: 'auto', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
   
          </tr>
        </thead>
        <tbody>
          {searchResults.map((item, index) => (
            <tr key={index} style={{ border: '1px solid #ddd', padding: '8px' }}>
              <td>{item.name}</td>
      
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductSearch;
*/
/*
import React, { useState, useEffect } from 'react';
import { db } from './Firebase';

function ProductSearch() {
  const [searchInput, setSearchInput] = useState('');
  const [originalData, setOriginalData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // Fetch data initially or whenever the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch data from all relevant collections and combine them
      const collections = ['Product/Products/t-shirts', 'Product/Products/Writing', 'Product/Products/Promotional_Gifts'];
      const promises = collections.map(async collection => fetchCollectionData(collection));
      const resultsArray = await Promise.all(promises);
      const results = resultsArray.reduce((acc, current) => acc.concat(current), []);

      // Save the original data and update state with the fetched data
      setOriginalData(results);
      setSearchResults(results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCollectionData = async (collection) => {
    const snapshot = await db.collection(collection).get();
    return snapshot.docs.map(doc => doc.data());
  };

  const handleSearch = () => {
    // Filter results based on search input
    const filteredResults = originalData.filter(item =>
      item.name && item.name.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    // Update state with the filtered results
    setSearchResults(filteredResults);
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Product Search</h1>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <input
          type="text"
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder='Search products'
          style={{ padding: '5px', marginRight: '5px' }}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <table style={{ width: '80%', margin: 'auto', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
         
          </tr>
        </thead>
        <tbody>
          {searchResults.map((item, index) => (
            <tr key={index} style={{ border: '1px solid #ddd', padding: '8px' }}>
              <td>{item.name}</td>
             
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductSearch;
*//*

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { db } from './Firebase';

function ProductSearch() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const initialSearchInput = queryParams.get('query') || ''; // Get the 'query' parameter from the URL

  const [searchInput, setSearchInput] = useState(initialSearchInput);
  const [originalData, setOriginalData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // Fetch data initially or whenever the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch data from all relevant collections and combine them
      const collections = ['Product/Products/t-shirts', 'Product/Products/Writing', 'Product/Products/Promotional_Gifts','Uniforms/School/School_Uniforms'];
      const promises = collections.map(async collection => fetchCollectionData(collection));
      const resultsArray = await Promise.all(promises);
      const results = resultsArray.reduce((acc, current) => acc.concat(current), []);

      // Save the original data and update state with the fetched data
      setOriginalData(results);
      setSearchResults(results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCollectionData = async (collection) => {
    const snapshot = await db.collection(collection).get();
    return snapshot.docs.map(doc => doc.data());
  };

  const handleSearch = () => {
    // Filter results based on search input
    const filteredResults = originalData.filter(item =>
      item.name && item.name.toLowerCase().includes(searchInput.toLowerCase())
    );

    // Update state with the filtered results
    setSearchResults(filteredResults);
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Product Search</h1>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <input
          type="text"
          value={searchInput} // Set the value to controlled input
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder='Search products'
          style={{ padding: '5px', marginRight: '5px' }}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <table style={{ width: '80%', margin: 'auto', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
            
          </tr>
        </thead>
        <tbody>
          {searchResults.map((item, index) => (
            <tr key={index} style={{ border: '1px solid #ddd', padding: '8px' }}>
              <td>{item.name}</td>
      
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductSearch;

*/

/*
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { db } from './Firebase';

function ProductSearch() {
  const [originalData, setOriginalData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [location.search]); // Refetch data whenever the search query changes

  const fetchData = async () => {
    try {
      const collections = ['Product/Products/t-shirts', 'Product/Products/Writing', 'Product/Products/Promotional_Gifts','Uniforms/School/School_Uniforms','Stationary/cards/ID_Cards', 'School Section/Category Display/School Accessories','Product/Products/Linen','Product/Products/Printing_Supplies',  'School Section/Category Display/Stationary'];
      const promises = collections.map(async (collection) => fetchCollectionData(collection));
      const resultsArray = await Promise.all(promises);
      const results = resultsArray.reduce((acc, current) => acc.concat(current), []);

      setOriginalData(results);
      setSearchResults(results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCollectionData = async (collection) => {
    const snapshot = await db.collection(collection).get();
    return snapshot.docs.map((doc) => doc.data());
  };

  useEffect(() => {
    handleSearch();
  }, [location.search, originalData]); // Trigger search when search query or data changes
/*
  const handleSearch = () => {
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get('search') || ''; // Get search term from query parameter

    const filteredResults = originalData.filter(
      (item) => item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setSearchResults(filteredResults);
  };
*/
/*
const handleSearch = () => {
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('search') || ''; // Get search term from query parameter

  // Split the search term into an array of keywords
  const keywords = searchTerm.toLowerCase().split(' ').filter(keyword => keyword.trim() !== '');

  // Filter products based on the keywords
  const filteredResults = originalData.filter(item => {
    if (!item.name) return false; // Skip items without a name

    // Check if all keywords are present in the lowercase product name
    return keywords.every(keyword => item.name.toLowerCase().includes(keyword));
  });

  setSearchResults(filteredResults);
};
  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Product Search</h1>

      <table style={{ width: '80%', margin: 'auto', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
      
          </tr>
        </thead>
        <tbody>
          {searchResults.map((item, index) => (
            <tr key={index} style={{ border: '1px solid #ddd', padding: '8px' }}>
              <td>{item.name}</td>
            
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductSearch;
*/

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { db } from './Firebase';



function generateRandomRating() {
  return Math.random() < 0.5 ? 4 : 5;
}

function renderStars(rating) {
  const stars = [];
  for (let i = 0; i < rating; i++) {
    stars.push(<span key={i}>&#9733;</span>);
  }
  return stars;
}

function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateRandomPurchaseText() {
  const randomNumber = generateRandomNumber(1, 5);
  return `${randomNumber}k Bought last month`;
}
function ProductSearch() {
  const [originalData, setOriginalData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const history = useHistory();

  // Dictionary to map collection names to their respective product pages
  const collectionPages = {
    'Product/Products/t-shirts': '/t_shirt',
    'Product/Products/Writing': '/Writing_Supplies',
    'Product/Products/Promotional_Gifts': '/Promotional_Gifts',
    'Uniforms/School/School_Uniforms':'/SchoolUniform',
    'Stationary/cards/ID_Cards':'/ID-Cards',
    'School Section/Category Display/School Accessories':'/Accessories',
    'Product/Products/Linen':'/Linen',
    'Product/Products/Printing_Supplies':'/Printing_Supplies',
    'School Section/Category Display/Stationary':'/Stationary'




    // Add other collection pages as needed
  };

  useEffect(() => {
    fetchData();
  }, [location.search]); // Refetch data whenever the search query changes

  const fetchData = async () => {
    try {
      const collections = [ 'Product/Products/Writing', 'Product/Products/Promotional_Gifts','Uniforms/School/School_Uniforms','Stationary/cards/ID_Cards', 'School Section/Category Display/School Accessories',  'School Section/Category Display/Stationary'];
      const promises = collections.map(async (collection) => fetchCollectionData(collection));
      const resultsArray = await Promise.all(promises);

      // Add a 'collection' property to each item in the results
      const results = resultsArray.reduce((acc, current, index) => {
        const collectionName = collections[index];
        const itemsWithCollection = current.map(item => ({ ...item, collection: collectionName }));
        return acc.concat(itemsWithCollection);
      }, []);

      setOriginalData(results);
      setSearchResults(results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCollectionData = async (collection) => {
    const snapshot = await db.collection(collection).get();
    return snapshot.docs.map((doc) => ({
      ...doc.data(),
      ProductID: doc.id, // Add the document ID to the data
    }));
  };
  

  useEffect(() => {
    handleSearch();
  }, [location.search, originalData]); // Trigger search when search query or data changes

  const handleSearch = () => {
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get('search') || ''; // Get search term from query parameter

    // Split the search term into an array of keywords
    const keywords = searchTerm.toLowerCase().split(' ').filter(keyword => keyword.trim() !== '');

    // Filter products based on the keywords
    const filteredResults = originalData.filter(item => {
      if (!item.name) return false; // Skip items without a name

      // Check if all keywords are present in the lowercase product name
      return keywords.every(keyword => item.name.toLowerCase().includes(keyword));
    });

    setSearchResults(filteredResults);
  };

  // Function to handle product click and redirect to the associated page
  const handleProductClick = (collection, productId) => {
    // Get the associated page from the dictionary
    const productPage = collectionPages[collection];
    
    // Redirect to the associated page with the product ID
    history.push(`${productPage}/${productId}`);
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Product Search</h1>


      <div className='products-container'>
      {searchResults.length === 0 && <div>No item to display</div>}
      {searchResults.map((item, index) => (
      <div  className='product-card-link'  style={{  cursor: 'pointer' }} onClick={() => handleProductClick(item.collection, item.ProductID)} >

<div className='product-card' key={item.ProductID}>
<div className='product-img'>
                  <img src={item.image} alt="not found" />
                </div>
                <div className='product-name ellipsis_product'>
                  {item.name}
                </div>
                <div className='product-price'>
                  {item.price}
                </div>
                <div className='min_order'>
                  Min. Order: 20pcs  
                </div>
                <div className='product-rating'>
                  {renderStars(generateRandomRating())}
                </div>
                <div className='product-purchases'>
                  {generateRandomPurchaseText()}
                </div>
                <button className='addcart-btn'>Place Order Request</button>




  </div>

      </div> 
   ))}

      </div>
      <div className="school_landing_div-402">

  
<div className="school_landing_div-409">
  <div className="school_landing_div-410">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Customer services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
      Help Center
      <br />
      Report abuse
      <br />
      File a case
      <br />
      Piolicies & rules
      <br />
      Get paid for your feedback
    </span>
  </div>
  <div className="school_landing_div-411">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      About Us
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      About Custoking.com
      <br />
      About Custoking Group
      <br />
      Sitemap
      <br />
      Custoking.com Blog
    </span>
  </div>
  <div className="school_landing_div-412">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Source on Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Resources
      <br />
      All categories
      <br />
      Request for Quotation
      <br />
      Ready to Ship
      <br />
      Buyer partners
      <br />
      Custoking.com Select
    </span>
  </div>
  <div className="school_landing_div-413">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Partner with Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Supplier memberships
      <br />
      Learning Center
      <br />
      Partner Program
    </span>
  </div>
  <div className="school_landing_div-414">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Trade services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Trade Assuarance
      <br />
      Business identity
      <br />
      Logistics services
      <br />
      Production Monitoring & Inspespection
      <br />
      Services
      <br />
      Letter of Credit
    </span>
  </div>
</div>




<div className="school_landing_div-430">
  Browse Alphabetically Onetouch | Showroom | Country Search | Affliate
</div>
<div className="school_landing_div-431">
  Product Listing Policy-Intellectual Property Protection-Privacy
  Policy-Terms of Use-User Information Legal Enquiry Guide
</div>

</div>
    </div>
  );
}

export default ProductSearch;


import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const T_Shirt_Display_Context = createContext();

export const T_Shirt_Display_ContextProvider = ({ children }) => {
  const [t_shirt_display, setT_Shirt_Display] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Product/Products/t-shirts').get();
        const t_shirt_display_Data = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().image,
          ProductName: doc.data().name,
          ProductPrice: doc.data().price,
        }));

        // Shuffle the array randomly
        const shuffledData = shuffleArray(t_shirt_display_Data);

        // Get the first 10 items (or less if the array has fewer than 10 items)
        const randomItems = shuffledData.slice(0, 10);

        setT_Shirt_Display(randomItems);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  // Function to shuffle an array randomly
  const shuffleArray = (array) => {
    let currentIndex = array.length;
    let randomIndex, tempValue;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      tempValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = tempValue;
    }

    return array;
  };

  return (
    <T_Shirt_Display_Context.Provider value={{ t_shirt_display }}>
      {children}
    </T_Shirt_Display_Context.Provider>
  );
};

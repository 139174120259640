import React, { useContext } from 'react';
import { CalendarsContext } from './Calendars_Data'; // Import the BusinessCardsContext

function Calendars() {
  const { calendars } = useContext(CalendarsContext);
  console.log(calendars);

  return (
    <>
      {calendars.length !== 0 && <h1>Calendars</h1>}
      <div className='products-container'>
        {calendars.length === 0 && <div>slow internet...no products to display</div>}
        {calendars.map(product => (
          <div className='product-card' key={product.ProductID}>
            <div className='product-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
            <div className='product-name'>
              {product.ProductName}
            </div>
            <div className='product-name'>
              {product.ProductPrice}
            </div>
            <button className='addcart-btn' >ADD TO CART</button>
          </div>
        ))}
      </div>
    </>
  )
}

export default Calendars;

import React from "react";
import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";  // Ensure that you import hydrate and render
import ReactGA from "react-ga";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reducer, { initialState } from "./reducer";
import { StateProvider } from "./StateProvider";

ReactGA.initialize('G-VNYF0Y3BVL');

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </React.StrictMode>,
    rootElement
  );
}

serviceWorker.unregister();

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Product_Test.css'

function generateRandomRating() {
  return Math.random() < 0.5 ? 4 : 5;
}

function renderStars(rating) {
  const stars = [];
  for (let i = 0; i < rating; i++) {
    stars.push(<span key={i}>&#9733;</span>);
  }
  return stars;
}

function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateRandomPurchaseText() {
  const randomNumber = generateRandomNumber(1, 5);
  return `${randomNumber}k Bought last month`;
}

function generateProductPage(ContextProvider, pageLink, productsVariable) {
  return function ProductPage() {
    const { [productsVariable]: products } = useContext(ContextProvider);
    
    return (
      <>
        <div className='products-container'>
          {products.length === 0 && <div>Slow internet...no products to display</div>}
          {products.map(product => (
            <Link to={`/${pageLink}/${product.ProductID}`} className='product-card-link' key={product.ProductID}>
              <div className='product-card' key={product.ProductID}>
                <div className='product-img'>
                  <img src={product.ProductImg} alt={`Custoking ${product.ProductName}`}/>
                </div>
                <div className='product-name ellipsis_product'>
                  {product.ProductName}
                </div>
                <div className='product-price'>
                  {product.ProductPrice}
                </div>
                <div className='min_order'>
                  Min. Order: 20pcs  
                </div>
                <div className='product-rating'>
                  {renderStars(generateRandomRating())}
                </div>
                <div className='product-purchases'>
                  {generateRandomPurchaseText()}
                </div>
                <button className='addcart-btn'>Place Order Request</button>
              </div>
            </Link>
          ))}


          
        </div>
        <div className="school_landing_div-402">

  
<div className="school_landing_div-409">
  <div className="school_landing_div-410">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Customer services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
      Help Center
      <br />
      Report abuse
      <br />
      File a case
      <br />
      Piolicies & rules
      <br />
      Get paid for your feedback
    </span>
  </div>
  <div className="school_landing_div-411">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      About Us
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      About Custoking.com
      <br />
      About Custoking Group
      <br />
      Sitemap
      <br />
      Custoking.com Blog
    </span>
  </div>
  <div className="school_landing_div-412">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Source on Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Resources
      <br />
      All categories
      <br />
      Request for Quotation
      <br />
      Ready to Ship
      <br />
      Buyer partners
      <br />
      Custoking.com Select
    </span>
  </div>
  <div className="school_landing_div-413">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Partner with Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Supplier memberships
      <br />
      Learning Center
      <br />
      Partner Program
    </span>
  </div>
  <div className="school_landing_div-414">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Trade services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Trade Assuarance
      <br />
      Business identity
      <br />
      Logistics services
      <br />
      Production Monitoring & Inspespection
      <br />
      Services
      <br />
      Letter of Credit
    </span>
  </div>
</div>




<div className="school_landing_div-430">
  Browse Alphabetically Onetouch | Showroom | Country Search | Affliate
</div>
<div className="school_landing_div-431">
  Product Listing Policy-Intellectual Property Protection-Privacy
  Policy-Terms of Use-User Information Legal Enquiry Guide
</div>

</div>
      </>
    );
  };
}

export default generateProductPage;

import React, { useState, useEffect } from 'react';
import { HiOutlineInformationCircle, HiArrowLongRight } from 'react-icons/hi2';
import { useHistory } from 'react-router-dom';
import { db, storage } from './Firebase';
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

function School_Uniform_Static_Details() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const history = useHistory();
  const [contactInfo, setContactInfo] = useState('');
  const [contactInfoError, setContactInfoError] = useState(false);
  const validateContactInfo = (value) => {
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return phoneRegex.test(value) || emailRegex.test(value);
  };

  const handleContactInfoChange = (e) => {
    setContactInfo(e.target.value);
    setContactInfoError(!validateContactInfo(e.target.value));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate contact information
    if (!validateContactInfo(contactInfo)) {
      setContactInfoError(true);
      return; // Don't proceed with the submission
    }
    setContactInfoError(false);

    // Validate address



    // Save order data only when the file URL is available
    console.log('About to call saveOrderData');
    console.log('About to call saveOrderData Inside');
    saveOrderData();
    window.gtag('event', 'conversion', {'send_to': 'AW-11246127201/mcHGCNDQtPsYEOGQyfIp'});
  };

  const saveOrderData = async () => {
    console.log('saveOrderData function called');
    const ordersRef = db.collection('HELP');

    const orderData = {
    
      Contact: contactInfo,

    };
    console.log('Order Data:', orderData);
    try {
      // Use add to let Firestore generate a unique document ID
      await ordersRef.add(orderData);

      // Reset fields
      setContactInfo('');
    

      // Optionally, you can show a success message to the user
      alert('Submitted successfully!');
    } catch (error) {
      // Handle any errors that may occur during data submission
      console.error('Error submitting:', error);
      alert('Error submitting. Please try again.');
    }
  };

  return (
    <>
        <>
      <Helmet>
        <title>Crafting Unique School Uniforms with Love: Elevate Style, Celebrate Individuality</title>
        <meta name="description" content="Discover personalized school uniforms at Custoking. We design with passion, ensuring every student stands out with the right fit and a touch of style that reflects their individuality." />
        {/* Add more meta tags as needed */}

        <meta name="keywords" content="uniform manufacturers, Hyderabad uniforms, custom uniforms, professional attire"/>
        <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "Custoking",
          "description": "Custoking - Your Trusted Uniform Manufacturers in Hyderabad. We specialize in providing high-quality uniforms for various industries. Explore our wide range of customizable uniforms tailred to your specific needs.",
          "url": "https://www.custoking.com/School_Uniform_Details",
          "logo": "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FCusto%20King.jpeg?alt=media&token=55c1b851-7aef-48a4-948d-d1955c2169fa&_gl=1*1o2phvi*_ga*MTg4NzM1MTY3OC4xNjk4NjY4MjI1*_ga_CW55HF8NVT*MTY5ODgxMDYxMi42LjEuMTY5ODgxMDY1My4xOS4wLjA.",
          "image": "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FCusto%20King.jpeg?alt=media&token=55c1b851-7aef-48a4-948d-d1955c2169fa&_gl=1*1o2phvi*_ga*MTg4NzM1MTY3OC4xNjk4NjY4MjI1*_ga_CW55HF8NVT*MTY5ODgxMDYxMi42LjEuMTY5ODgxMDY1My4xOS4wLjA.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Hiline Complex",
            "addressLocality": "Hyderabad",
            "addressRegion": "Telangana",
            "postalCode": "500034",
            "addressCountry": "India"
          },
          "telephone": "7478004111",
          "priceRange": "380", // Adjust based on your pricing
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8", // Adjust based on your reviews
            "reviewCount": "25" // Adjust based on the number of reviews
          },
          "openingHours": "Mo-Su 00:00-24:00",
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "17.4078932", // Adjust based on your location
            "longitude": "78.4501220" // Adjust based on your location
          }
        }`}
      </script>
      <meta name="author" content="Custoking"/>
      </Helmet>
      {/* Other content of your component */}
    </>
      <div className="school_uniform_static_div">
        <div className="school_uniform_static_div-2">

          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fd11b007-81fe-442e-8c28-3401c3a0a29b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd11b007-81fe-442e-8c28-3401c3a0a29b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd11b007-81fe-442e-8c28-3401c3a0a29b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd11b007-81fe-442e-8c28-3401c3a0a29b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd11b007-81fe-442e-8c28-3401c3a0a29b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd11b007-81fe-442e-8c28-3401c3a0a29b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd11b007-81fe-442e-8c28-3401c3a0a29b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd11b007-81fe-442e-8c28-3401c3a0a29b?apiKey=db6f3fe714c542fe811ae992d63ea686&"
            className="school_uniform_static_img-3"
          />
          <div className="school_uniform_static_div-10">
            <div className="school_uniform_static_div-11">
              <div className="school_uniform_static_column">
                <div className="school_uniform_static_div-12">
                  <div className="school_uniform_static_div-13">Primary school uniforms</div>
                  <div className="school_uniform_static_div-14">
                    Little buds are known for their active nature and joyful spirit. Valji fabrics aim to Provide the most durable fabrics for primary school kids that help them move freely and live fully. With Valji uniforms parents stay stressfree regarding the clothes.
                  </div>
                  <div className="school_uniform_static_div-15">
                    
                  </div>
                  <div className="school_uniform_static_div-16">
                    
                  </div>
                  <div className="school_uniform_static_div-17">
                    
                  </div>
                  <div className="school_uniform_static_div-18">
                    
                  </div>
                  <Link to = "/Second_Page" style={{ textDecoration: 'none' }}  className="school_uniform_static_div-19">Request for Quotation</Link>
                </div>
              </div>
              <div className="school_uniform_static_column-2">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9a616e33-759b-4ae3-b9e6-6fcd828e06dd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a616e33-759b-4ae3-b9e6-6fcd828e06dd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a616e33-759b-4ae3-b9e6-6fcd828e06dd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a616e33-759b-4ae3-b9e6-6fcd828e06dd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a616e33-759b-4ae3-b9e6-6fcd828e06dd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a616e33-759b-4ae3-b9e6-6fcd828e06dd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a616e33-759b-4ae3-b9e6-6fcd828e06dd?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9a616e33-759b-4ae3-b9e6-6fcd828e06dd?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="school_uniform_static_img-4"
                />
              </div>
              <div className="school_uniform_static_column-3">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/eed8c083-d73e-4438-9daf-9d7a0377a905?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/eed8c083-d73e-4438-9daf-9d7a0377a905?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eed8c083-d73e-4438-9daf-9d7a0377a905?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/eed8c083-d73e-4438-9daf-9d7a0377a905?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/eed8c083-d73e-4438-9daf-9d7a0377a905?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eed8c083-d73e-4438-9daf-9d7a0377a905?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/eed8c083-d73e-4438-9daf-9d7a0377a905?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/eed8c083-d73e-4438-9daf-9d7a0377a905?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="school_uniform_static_img-5"
                />
              </div>
            </div>
          </div>
          <div className="school_uniform_static_div-20">
            <div className="school_uniform_static_div-21">
              <div className="school_uniform_static_column-4">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b6d4620-7951-4329-9142-18a3e9ddd43b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b6d4620-7951-4329-9142-18a3e9ddd43b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b6d4620-7951-4329-9142-18a3e9ddd43b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b6d4620-7951-4329-9142-18a3e9ddd43b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b6d4620-7951-4329-9142-18a3e9ddd43b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b6d4620-7951-4329-9142-18a3e9ddd43b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b6d4620-7951-4329-9142-18a3e9ddd43b?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b6d4620-7951-4329-9142-18a3e9ddd43b?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="school_uniform_static_img-6"
                />
              </div>
              <div className="school_uniform_static_column-5">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/71d01acb-2322-47eb-adbd-9ae1fe027c56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/71d01acb-2322-47eb-adbd-9ae1fe027c56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/71d01acb-2322-47eb-adbd-9ae1fe027c56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/71d01acb-2322-47eb-adbd-9ae1fe027c56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/71d01acb-2322-47eb-adbd-9ae1fe027c56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/71d01acb-2322-47eb-adbd-9ae1fe027c56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/71d01acb-2322-47eb-adbd-9ae1fe027c56?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/71d01acb-2322-47eb-adbd-9ae1fe027c56?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="school_uniform_static_img-6"
                />
              </div>
              <div className="school_uniform_static_column-6">
                <div className="school_uniform_static_div-22">
                  <div className="school_uniform_static_div-23">Secondary School Uniforms</div>
                  <div className="school_uniform_static_div-24">
                    Colours that define your mood and pattern that
                  </div>
                  <div className="school_uniform_static_div-25">
                    coexist with your style. We curate every
                  </div>
                  <div className="school_uniform_static_div-26">
                    combination with utmost detail and love. So let
                  </div>
                  <div className="school_uniform_static_div-27">
                    the children radiate their energies and develop
                  </div>
                  <div className="school_uniform_static_div-28">in their zone of excellence</div>
                  <Link to = "/Second_Page" style={{ textDecoration: 'none' }}  className="school_uniform_static_div-29">Request for Quotation</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="school_uniform_static_div-30">
          <div className="school_uniform_static_div-31">

            
            <div className="school_landing_div-205" id = 'School_Uniform'>
            <div className="school_landing_div-206">School Uniforms</div>
            <div className="school_landing_div-207" />
            <a className="school_landing_div-208"  href = '#RFQ' style={{ textDecoration: 'none' }}>Request for Samples</a>
          </div>
          <Link to = "/Second_Page" style={{ textDecoration: 'none' }} className="school_landing_div-209">
            <div className="school_landing_div-210">
              
              <div className="school_landing_div-211">Source now</div>
            </div>
            <div className="school_landing_div-212">
              <div className="school_landing_div-213">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/075570c4-30b9-401e-a085-4264873d1677?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/075570c4-30b9-401e-a085-4264873d1677?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/075570c4-30b9-401e-a085-4264873d1677?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/075570c4-30b9-401e-a085-4264873d1677?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/075570c4-30b9-401e-a085-4264873d1677?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/075570c4-30b9-401e-a085-4264873d1677?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/075570c4-30b9-401e-a085-4264873d1677?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/075570c4-30b9-401e-a085-4264873d1677?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="school_landing_img-81"
                />
                <div className="school_landing_div-214">Premium Mafatlal Fabric</div>
              </div>
              <div className="school_landing_div-215">Premium Mafatlal and Valji Fabrics</div>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/47732a3a-423a-4d47-ae2e-81dc3e4f6ff1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/47732a3a-423a-4d47-ae2e-81dc3e4f6ff1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/47732a3a-423a-4d47-ae2e-81dc3e4f6ff1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/47732a3a-423a-4d47-ae2e-81dc3e4f6ff1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/47732a3a-423a-4d47-ae2e-81dc3e4f6ff1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/47732a3a-423a-4d47-ae2e-81dc3e4f6ff1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/47732a3a-423a-4d47-ae2e-81dc3e4f6ff1?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/47732a3a-423a-4d47-ae2e-81dc3e4f6ff1?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="school_landing_img-82"
              />
              <div className="school_landing_div-216">In House - Quality Stitching</div>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e6f1677c-7b6d-4a18-a686-b38f723b5e94?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6f1677c-7b6d-4a18-a686-b38f723b5e94?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6f1677c-7b6d-4a18-a686-b38f723b5e94?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6f1677c-7b6d-4a18-a686-b38f723b5e94?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6f1677c-7b6d-4a18-a686-b38f723b5e94?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6f1677c-7b6d-4a18-a686-b38f723b5e94?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6f1677c-7b6d-4a18-a686-b38f723b5e94?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e6f1677c-7b6d-4a18-a686-b38f723b5e94?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="school_landing_img-83"
              />
            </div>
            <div className="school_landing_div-217" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Uniform Accessories </div>
              <div className="school_landing_div-220">
                <div className="school_landing_div-221">School Tie and Belts</div>
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0e8982a0-e0c2-41d8-a01a-20daf9aa0a60?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e8982a0-e0c2-41d8-a01a-20daf9aa0a60?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e8982a0-e0c2-41d8-a01a-20daf9aa0a60?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e8982a0-e0c2-41d8-a01a-20daf9aa0a60?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e8982a0-e0c2-41d8-a01a-20daf9aa0a60?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e8982a0-e0c2-41d8-a01a-20daf9aa0a60?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e8982a0-e0c2-41d8-a01a-20daf9aa0a60?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e8982a0-e0c2-41d8-a01a-20daf9aa0a60?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">Customisations</div>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/282f502b-20cb-498c-8bbc-681179c0275c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/282f502b-20cb-498c-8bbc-681179c0275c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/282f502b-20cb-498c-8bbc-681179c0275c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/282f502b-20cb-498c-8bbc-681179c0275c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/282f502b-20cb-498c-8bbc-681179c0275c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/282f502b-20cb-498c-8bbc-681179c0275c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/282f502b-20cb-498c-8bbc-681179c0275c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/282f502b-20cb-498c-8bbc-681179c0275c?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="school_landing_img-85"
              />
            </div>
            <div className="school_landing_div-223" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Events Uniforms </div>
              <div className="school_landing_div-220">
                <div className="school_landing_div-221">Annual Day/ Traditional Dresses</div>
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool_Uniforms%2FAnnual%20Day%20Uniform.jpg?alt=media&token=6396b4dd-fc5a-42f3-9046-d582959d6eaa"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">White Uniform</div>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a96adced-d8f2-41dc-a95e-824cd90da977?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a96adced-d8f2-41dc-a95e-824cd90da977?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a96adced-d8f2-41dc-a95e-824cd90da977?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a96adced-d8f2-41dc-a95e-824cd90da977?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a96adced-d8f2-41dc-a95e-824cd90da977?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a96adced-d8f2-41dc-a95e-824cd90da977?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a96adced-d8f2-41dc-a95e-824cd90da977?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a96adced-d8f2-41dc-a95e-824cd90da977?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="school_landing_img-85"
              />
            </div>
            <div className="school_landing_div-223" />
            <div className="school_landing_div-218">
              <div className="school_landing_div-219">Sports Uniforms </div>
              <div className="school_landing_div-220">
               
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool_Uniforms%2FSports%20Uniform.jpg?alt=media&token=1a9ff936-dfb1-47d5-a911-917c98989bc7"
                  className="school_landing_img-84"
                />
              </div>
              <div className="school_landing_div-222">Track Pants/ Suits</div>
              <img
                loading="lazy"
                srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FSchool_Uniforms%2FTrack%20Suits.webp?alt=media&token=95ccc328-c400-4beb-8733-c84e146e604d"
                className="school_landing_img-85"
              />
            </div>
          </Link>
          <form onSubmit={handleSubmit}>
          <div className='school_second_page_contact_us'>
            <div className='school_second_page_contact_us_message'>
              Get Uniform Catalog
            </div>
            <div className='school_second_page_contact_us_subheading'>
              Submit details and we will send you the catalog
            </div>
          <div className="school_second_page_contact_us_input">
    <input
      type="text"
      id="contactInfo"
      value={contactInfo}
      onChange={handleContactInfoChange}
      className={contactInfoError ? 'error' : ''}
      placeholder="Phone Number or Email (Required)"
      required
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: contactInfoError ? '2px solid red' : '1px solid #ccc',
        padding: '5px',
        outline: 'none',
      }}
    />
    {contactInfoError && (
      <span
        style={{
          marginLeft: '5px',
          color: 'red',
          height: '10px',
          width: '10px',
        }}
        role="img"
        aria-label="info"
      >
        <HiOutlineInformationCircle />
      </span>
    )}
    </div>
    <button type="submit" className="school_second_page_contact_us_submit">
    Submit
  </button>
  </div>
  </form>




          </div>
        </div>
        <div className="school_landing_div-402">

  
<div className="school_landing_div-409">
  <div className="school_landing_div-410">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Customer services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
      Help Center
      <br />
      Report abuse
      <br />
      File a case
      <br />
      Piolicies & rules
      <br />
      Get paid for your feedback
    </span>
  </div>
  <div className="school_landing_div-411">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      About Us
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      About Custoking.com
      <br />
      About Custoking Group
      <br />
      Sitemap
      <br />
      Custoking.com Blog
    </span>
  </div>
  <div className="school_landing_div-412">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Source on Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Resources
      <br />
      All categories
      <br />
      Request for Quotation
      <br />
      Ready to Ship
      <br />
      Buyer partners
      <br />
      Custoking.com Select
    </span>
  </div>
  <div className="school_landing_div-413">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Partner with Custoking.com
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Supplier memberships
      <br />
      Learning Center
      <br />
      Partner Program
    </span>
  </div>
  <div className="school_landing_div-414">
    <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 600 }}>
      Trade services
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      <br />
    </span>
    <span style={{fontFamily: 'Roboto, sans-serif',fontWeight: 400}}>
      Trade Assuarance
      <br />
      Business identity
      <br />
      Logistics services
      <br />
      Production Monitoring & Inspespection
      <br />
      Services
      <br />
      Letter of Credit
    </span>
  </div>
</div>




<div className="school_landing_div-430">
  Browse Alphabetically Onetouch | Showroom | Country Search | Affliate
</div>
<div className="school_landing_div-431">
  Product Listing Policy-Intellectual Property Protection-Privacy
  Policy-Terms of Use-User Information Legal Enquiry Guide
</div>

</div>
        
      </div>{" "}
      <style jsx>{`
        .school_uniform_static_div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .school_second_page_contact_us {
          border-radius: 6px;
          border: 1px solid var(--gray-300, #dee2e7);
          background-color: #fff;
          align-self: stretch;
          display: flex;
          
          flex-grow: 1;
          flex-direction: column;
          width:400px;
          margin: 30px auto;
          padding: 25px 20px;
        }
        @media (max-width: 991px) {
          .school_second_page_contact_us {
            max-width: 100%;
            padding:50px;
          
            margin: 30px 0;
            display:flex;
            align-self: center;
          }
        }
        .school_landing_div-402 {
          border: 1px solid #fff;
          background-color: #445268;
          align-self: start;
          display: flex;
          margin-top: 31px;
          width: 100%;
          flex-direction: column;
          padding: 50px 29px 34px 80px;
        }
        @media (max-width: 991px) {
          .school_landing_div-402 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .school_landing_div-403 {
          color: #fff;
          letter-spacing: 0.8px;
          align-self: center;
          margin-top: 6px;
          white-space: nowrap;
          font: 400 16px/62.5% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-403 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-404 {
          align-self: center;
          display: flex;
          margin-top: 16px;
          width: 436px;
          max-width: 100%;
          align-items: flex-start;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_landing_div-404 {
            flex-wrap: wrap;
          }
        }
        .school_landing_div-405 {
          align-self: start;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
          width: 300px;
        }
        .school_landing_div-406 {
          color: #adb1b9;
          letter-spacing: 0.9px;
          align-self: stretch;
          white-space: nowrap;
          border: 1px solid rgba(255, 255, 255, 0.6);
          background-color: #313d51;
          width: 100%;
          padding: 14px 80px 14px 10px;
          font: 400 18px/56% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-406 {
            white-space: initial;
            padding-right: 20px;
          }
        }
        .school_landing_div-407 {
          color: #adb1b9;
          letter-spacing: 0.575px;
          align-self: stretch;
          margin-top: 9px;
          white-space: nowrap;
          font: 400 11.5px/87% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-407 {
            white-space: initial;
          }
        }
        .school_landing_div-408 {
          color: #fff;
          letter-spacing: 0.7px;
          align-self: start;
          white-space: nowrap;
          border: 1px solid rgba(255, 255, 255, 0.6);
          background-color: #313d51;
          width: 116px;
          max-width: 100%;
          padding: 15px 26px 15px 24px;
          font: 400 14px/71% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-408 {
            white-space: initial;
            padding: 0 20px;
          }
        }
        .school_landing_div-409 {
          align-self: center;
          display: flex;
          margin-top: 32px;
          width: 100%;
          max-width: 1351px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_landing_div-409 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_landing_div-410 {
          color: #fff;
          letter-spacing: 0.9px;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-411 {
          color: #fff;
          letter-spacing: 0.9px;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-412 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: start;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-413 {
          color: #fff;
          letter-spacing: 0.9px;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-414 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: start;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-415 {
          background-color: #364358;
          align-self: center;
          margin-top: 37px;
          width: 1220px;
          height: 1px;
        }
        @media (max-width: 991px) {
          .school_landing_div-415 {
            max-width: 100%;
          }
        }
        .school_landing_div-416 {
          align-self: center;
          display: flex;
          margin-top: 18px;
          width: 100%;
          max-width: 1166px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_landing_div-416 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .school_landing_div-417 {
          align-self: start;
          display: flex;
          margin-top: 4px;
          align-items: flex-start;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .school_landing_div-417 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_landing_div-418 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin: auto 0;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-419 {
          justify-content: space-between;
          align-items: flex-start;
          border-radius: 5px;
          background-color: #999;
          align-self: stretch;
          display: flex;
          width: 190px;
          max-width: 100%;
          gap: 10px;
          padding: 5px 15px 5px 5px;
        }
        .school_landing_img-140 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 35px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_landing_div-420 {
          color: #fff;
          letter-spacing: 0.8px;
          font: 700 16px/18px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-421 {
          justify-content: space-between;
          align-items: flex-start;
          border-radius: 5px;
          background-color: #a4c639;
          align-self: stretch;
          display: flex;
          width: 185px;
          max-width: 100%;
          gap: 5px;
          padding: 5px 15px 5px 5px;
        }
        .school_landing_img-141 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 35px;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_landing_div-422 {
          color: #fff;
          letter-spacing: 0.8px;
          font: 700 16px/18px Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-423 {
          align-self: start;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 4px;
        }
        @media (max-width: 991px) {
          .school_landing_div-423 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_landing_div-424 {
          color: #fff;
          letter-spacing: 0.9px;
          margin: auto 0;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_img-142 {
          aspect-ratio: 0.92;
          object-fit: contain;
          object-position: center;
          width: 55px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_landing_div-425 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin: auto 0;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_img-143 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_img-144 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_img-145 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_img-146 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_img-147 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(0, 0, 0, 0);
          overflow: hidden;
          align-self: center;
          max-width: 100%;
          margin: auto 0;
        }
        .school_landing_div-426 {
          align-self: center;
          display: flex;
          margin-top: 46px;
          width: 100%;
          max-width: 1057px;
          align-items: flex-start;
          gap: 18px;
        }
        @media (max-width: 991px) {
          .school_landing_div-426 {
            max-width: 100%;
            flex-wrap: wrap;
            margin-top: 40px;
          }
        }
        .school_landing_img-148 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 25px;
          fill: rgba(222, 196, 196, 0);
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .school_landing_div-427 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: start;
          white-space: nowrap;
          flex-grow: 1;
          flex-basis: auto;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-427 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-428 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: end;
          white-space: nowrap;
          margin: 4px 30px 0 0;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-428 {
            max-width: 100%;
            margin-right: 10px;
            white-space: initial;
          }
        }
        .school_landing_div-429 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin-left: 52px;
          white-space: nowrap;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-429 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-430 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin-top: 9px;
          white-space: nowrap;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-430 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-431 {
          color: #fff;
          letter-spacing: 0.9px;
          align-self: center;
          margin-top: 16px;
          white-space: nowrap;
          font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-431 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_landing_div-432 {
          align-self: center;
          display: flex;
          margin-top: 29px;
          width: 805px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_landing_div-432 {
            flex-wrap: wrap;
          }
        }
        .school_second_page_contact_us_message {
          color: #292626;
          align-self: start;
        
          display: flex;
          
          font: 700 26px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_contact_us_message {
            white-space: initial;
          }
        }
        .school_second_page_contact_us_submit {
          color: #fff;
          letter-spacing: 0.7px;
          align-self: center;
          white-space: nowrap;
          border-radius: 10px;
          background-color: #0175fe;
          margin-top: 20px;
        
          width: 100%;
          padding: 15px;
          text-align: center;
          font: 700 14px/71% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_contact_us_submit {
            white-space: initial;
            padding: 20px 20px;
          }
        }
        .school_second_page_contact_us_subheading
        {
          margin-top: 5px;
          font: 500 15px/102% Roboto, -apple-system, Roboto, Helvetica,
          sans-serif;
        }
        .school_second_page_contact_us_input {
          color: #c6c4cb;
          letter-spacing: 0.9px;
          align-self: center;
          white-space: nowrap;
          border-radius: 5px;

          margin-top: 27px;
          width: 451px;
          max-width: 100%;
    
          height: 40px;
          font: 500 18px/56% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_second_page_contact_us_input {
            white-space: initial;
            padding-right: 20px;
          }
        }
        .school_uniform_static_div-2 {
          display: flex;
          width: 100%;
          max-width: 1707px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-2 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-3 {
          align-self: stretch;
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-3 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .school_uniform_static_img {
          aspect-ratio: 3.26;
          object-fit: contain;
          object-position: center;
          width: 202px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_uniform_static_div-4 {
          align-self: end;
          display: flex;
          margin-top: 29px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 10px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-4 {
            justify-content: center;
          }
        }
        .school_uniform_static_div-5 {
          justify-content: center;
          color: #000;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          align-self: center;
          margin: auto 0;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-6 {
          justify-content: center;
          color: #000;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          align-self: center;
          margin: auto 0;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-7 {
          justify-content: center;
          color: #000;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          align-self: center;
          margin: auto 0;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_img-2 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 20px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_uniform_static_div-8 {
          justify-content: center;
          color: #000;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          align-self: center;
          margin: auto 0;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-9 {
          justify-content: center;
          color: #000;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          align-self: center;
          white-space: nowrap;
          margin: auto 0;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-9 {
            white-space: initial;
          }
        }
        .school_uniform_static_img-3 {
          aspect-ratio: 1.58;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 15px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_img-3 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-10 {
          align-self: center;
          width: 100%;
          max-width: 100%;
          margin-top:80px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-10 {
            margin-top: 40px;
            width:100%;
            margin:auto;
          }
        }
        .school_uniform_static_div-11 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-11 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_uniform_static_column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column {
            width: 100%;
          }
        }
        .school_uniform_static_div-12 {
          display: flex;
          margin-top: 205px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-12 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .school_uniform_static_div-13 {
          justify-content: center;
          color: #000;
          letter-spacing: 2px;
          text-transform: uppercase;
          align-self: stretch;
          white-space: nowrap;
          font: 600 20px/140% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-13 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_uniform_static_div-14 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          margin-top: 26px;
          font: 400 14px/171% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-14 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-15 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          font: 400 14px/171% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-15 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-16 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          font: 400 14px/171% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-16 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-17 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          font: 400 14px/171% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-17 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-18 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          white-space: nowrap;
          font: 400 14px/171% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-18 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_uniform_static_div-19 {
          color: #fff;
          letter-spacing: 0.7px;
          text-decoration-line: underline;
          white-space: nowrap;
          border-radius: 20px;
          background-color: #0175fe;
          align-self: start;
          width: 228px;
          max-width: 100%;
          align-items: center;
          margin: 94px 0 0 51px;
          padding: 14px 20px 9px;
          font: 700 14px/71% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
          text-align: center;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-19 {
            white-space: initial;
            margin: 40px 0 0 10px;
          }
        }
        .school_uniform_static_column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-2 {
            width: 100%;
            margin-left: 0;

          }
        }
        .school_uniform_static_img-4 {
          aspect-ratio: 0.75;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
            0px 10px 15px -3px rgba(0, 0, 0, 0.1);
          overflow: hidden;
        }
        @media (max-width: 991px) {
          .school_uniform_static_img-4 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .school_uniform_static_column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-3 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_uniform_static_img-5 {
          aspect-ratio: 0.75;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
            0px 10px 15px -3px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_uniform_static_img-5 {
            margin-top: 40px;
          }
        }
        .school_uniform_static_div-20 {
          align-self: center;
          margin-top: 160px;
          width: 100%;
          max-width: 100%;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-20 {
            margin-top: 40px;
          }
        }
        .school_uniform_static_div-21 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-21 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_uniform_static_column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-4 {
            width: 100%;
          }
        }
        .school_uniform_static_img-6 {
          aspect-ratio: 0.75;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
            0px 10px 15px -3px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_uniform_static_img-6 {
            margin-top: 40px;
          }
        }
        .school_uniform_static_column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;



        }
        @media (max-width: 991px) {
          .school_uniform_static_column-5 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_uniform_static_img-7 {
          aspect-ratio: 0.75;
          object-fit: contain;
          object-position: center;
          width: 100%;
          box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
            0px 10px 15px -3px rgba(0, 0, 0, 0.1);
          overflow: hidden;
        }
        @media (max-width: 991px) {
          .school_uniform_static_img-7 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .school_uniform_static_column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 34%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-6 {
            width: 100%;
            margin-left: 0;
          }
        }
        .school_uniform_static_div-22 {
          display: flex;
          margin-top: 239px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-22 {
            margin-top: 40px;
          }
        }
        .school_uniform_static_div-23 {
          justify-content: center;
          color: #000;
          letter-spacing: 2px;
          text-transform: uppercase;
          align-self: stretch;
          white-space: nowrap;
          font: 600 20px/140% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-23 {
            white-space: initial;
          }
        }
        .school_uniform_static_div-24 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          margin-top: 28px;
          font: 400 14px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-25 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          font: 400 14px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-26 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          font: 400 14px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-27 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          font: 400 14px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-28 {
          justify-content: center;
          color: #374151;
          letter-spacing: 1.4px;
          align-self: stretch;
          white-space: nowrap;
          font: 400 14px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-28 {
            white-space: initial;
          }
        }
        .school_uniform_static_div-29 {
          color: #fff;
          letter-spacing: 0.7px;
          text-decoration-line: underline;
          white-space: nowrap;
          border-radius: 20px;
          background-color: #0175fe;
          align-self: start;
          width: 228px;
          max-width: 100%;
          align-items: center;
          margin: 131px 0 0 47px;
          padding: 10px;
          font: 700 14px/71% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
          text-align: center;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-29 {
            white-space: initial;
            margin: 40px 0 0 10px;
          }
        }
        .school_uniform_static_div-30 {
          background-color: #f9fafb;
          align-self: stretch;
          display: flex;
          margin-top: 80px;
          width: 100%;
          flex-direction: column;
          align-items: center;
          padding: 40px 20px 120px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-30 {
            max-width: 100%;
            padding-bottom: 100px;
            margin-top: 40px;
          }
        }
        .school_uniform_static_div-31 {
          disply: flex;
          flex-direction: column;
          overflow: hidden;
          position: relative;
          display: flex;
          
          margin-bottom: -24px;
          width: 100%;
          max-width: 1535px;
          align-items: center;
          padding: 63px 44px 662px -4px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-31 {
            max-width: 100%;
            margin-bottom: 10px;
            padding: 0 20px 100px 0;
          }
        }
        .school_uniform_static_img-8 {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: fill;
          object-position: center;
        }
        .school_uniform_static_div-32 {
          position: relative;
          align-self: stretch;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-32 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_uniform_static_div-33 {
          color: #292626;
          text-decoration-line: underline;
          align-self: start;
          flex-grow: 1;
          flex-basis: auto;
          font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-34 {
          border-radius: 10px;
          border: 1px solid #b7a4a4;
          background-color: #d9d9d9;
          display: flex;
          width: 962px;
          height: 8px;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-34 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-35 {
          color: #292626;
          text-decoration-line: underline;
          align-self: start;
          flex-grow: 1;
          flex-basis: auto;
          font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-36 {
          position: relative;
          background-color: #fff;
          align-self: stretch;
          margin-top: 32px;
          padding: 0 9px 0 -4px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-36 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-37 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-37 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_uniform_static_column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 22%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-7 {
            width: 100%;
          }
        }
        .school_uniform_static_img-9 {
          aspect-ratio: 0.97;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
        }
        @media (max-width: 991px) {
          .school_uniform_static_img-9 {
            margin-top: 17px;
          }
        }
        .school_uniform_static_column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 59%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-8 {
            width: 100%;
          }
        }
        .school_uniform_static_div-38 {
          position: relative;
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-38 {
            max-width: 100%;
            margin-top: 34px;
          }
        }
        .school_uniform_static_div-39 {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-39 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .school_uniform_static_div-40 {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-40 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .school_uniform_static_div-41 {
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
        }
        .school_uniform_static_div-42 {
          display: flex;
          justify-content: space-between;
          gap: 7px;
        }
        .school_uniform_static_img-10 {
          aspect-ratio: 0.97;
          object-fit: contain;
          object-position: center;
          width: 34px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_uniform_static_div-43 {
          color: #000;
          text-decoration-line: underline;
          margin: auto 0;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-44 {
          color: #000;
          text-decoration-line: underline;
          opacity: 0.5;
          font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-45 {
          align-self: start;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
        }
        .school_uniform_static_div-46 {
          color: #000;
          text-decoration-line: underline;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-47 {
          color: #000;
          text-decoration-line: underline;
          opacity: 0.5;
          align-self: end;
          margin-top: 13px;
          font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-48 {
          color: #000;
          text-decoration-line: underline;
          align-self: start;
          white-space: nowrap;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-48 {
            white-space: initial;
          }
        }
        .school_uniform_static_div-49 {
          margin-top: 125px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-49 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .school_uniform_static_div-50 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-50 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .school_uniform_static_column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 34%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-9 {
            width: 100%;
          }
        }
        .school_uniform_static_div-51 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-51 {
            margin-top: 23px;
          }
        }
        .school_uniform_static_div-52 {
          color: #000;
          text-decoration-line: underline;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-53 {
          display: flex;
          align-items: start;
          justify-content: space-between;
          gap: 20px;
        }
        .school_uniform_static_img-11 {
          aspect-ratio: 0.94;
          object-fit: contain;
          object-position: center;
          width: 34px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_uniform_static_img-12 {
          aspect-ratio: 0.95;
          object-fit: contain;
          object-position: center;
          width: 80px;
          overflow: hidden;
          align-self: end;
          margin-top: 22px;
          max-width: 100%;
        }
        .school_uniform_static_column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 21%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-10 {
            width: 100%;
          }
        }
        .school_uniform_static_div-54 {
          color: #000;
          text-decoration-line: underline;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-54 {
            margin-top: 23px;
          }
        }
        .school_uniform_static_column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 46%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-11 {
            width: 100%;
          }
        }
        .school_uniform_static_div-55 {
          display: flex;
          flex-grow: 1;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-55 {
            margin-top: 23px;
          }
        }
        .school_uniform_static_div-56 {
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
          align-items: end;
        }
        .school_uniform_static_div-57 {
          color: #000;
          text-decoration-line: underline;
          white-space: nowrap;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-57 {
            white-space: initial;
          }
        }
        .school_uniform_static_img-13 {
          aspect-ratio: 2.79;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
          margin-top: 24px;
        }
        .school_uniform_static_img-14 {
          aspect-ratio: 0.9;
          object-fit: contain;
          object-position: center;
          width: 84px;
          overflow: hidden;
          align-self: end;
          margin-top: 25px;
          max-width: 100%;
        }
        .school_uniform_static_column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 19%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_column-12 {
            width: 100%;
          }
        }
        .school_uniform_static_div-58 {
          position: relative;
          display: flex;
          margin-top: 19px;
          flex-direction: column;
          align-items: end;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-58 {
            margin-top: 36px;
          }
        }
        .school_uniform_static_div-59 {
          color: #000;
          text-decoration-line: underline;
          align-self: stretch;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_img-15 {
          aspect-ratio: 0.97;
          object-fit: contain;
          object-position: center;
          width: 111px;
          overflow: hidden;
          margin-top: 10px;
          max-width: 100%;
        }
        .school_uniform_static_div-60 {
          color: #000;
          text-decoration-line: underline;
          align-self: stretch;
          margin-top: 32px;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-61 {
          position: relative;
          color: #fff;
          letter-spacing: 0.7px;
          text-decoration-line: underline;
          white-space: nowrap;
          border-radius: 20px;
          background-color: #0175fe;
          width: 228px;
          max-width: 100%;
          align-items: center;
          margin: 46px 0 -132px;
          padding: 14px 20px 10px;
          font: 700 14px/71% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-61 {
            white-space: initial;
            margin: 40px 0 10px;
          }
        }
        .school_uniform_static_div-62 {
          justify-content: center;
          align-items: center;
          background-color: #f3f4f6;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 88px 20px 82px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-62 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-63 {
          display: flex;
          width: 891px;
          max-width: 100%;
          flex-direction: column;
          align-items: end;
        }
        .school_uniform_static_div-64 {
          border-radius: 8px;
          background-color: #fff;
          display: flex;
          width: 672px;
          max-width: 100%;
          flex-direction: column;
          align-items: center;
          padding: 48px 0 40px;
        }
        .school_uniform_static_div-65 {
          justify-content: center;
          color: #000;
          text-align: center;
          white-space: nowrap;
          font: 400 24px/133% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-65 {
            white-space: initial;
          }
        }
        .school_uniform_static_div-66 {
          align-self: stretch;
          display: flex;
          margin-top: 35px;
          flex-direction: column;
          padding: 0 40px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-66 {
            max-width: 100%;
            padding: 0 20px;
          }
        }
        .school_uniform_static_div-67 {
          justify-content: center;
          color: #4b5563;
          text-transform: uppercase;
          align-self: stretch;
          white-space: nowrap;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-67 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_uniform_static_div-68 {
          justify-content: center;
          color: #9ca3af;
          white-space: nowrap;
          align-items: start;
          border: 1px solid #e5e7eb;
          background-color: #f3f4f6;
          align-self: stretch;
          margin-top: 12px;
          padding: 20px 13px;
          font: 400 16px Open Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-68 {
            white-space: initial;
            max-width: 100%;
          }
        }
        .school_uniform_static_div-69 {
          justify-content: center;
          color: #4b5563;
          text-transform: uppercase;
          align-self: stretch;
          margin-top: 7px;
          white-space: nowrap;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-69 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_uniform_static_div-70 {
          justify-content: center;
          color: #9ca3af;
          white-space: nowrap;
          align-items: start;
          border: 1px solid #e5e7eb;
          background-color: #f3f4f6;
          align-self: stretch;
          margin-top: 12px;
          padding: 17px 13px;
          font: 400 16px Open Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-70 {
            white-space: initial;
            max-width: 100%;
          }
        }
        .school_uniform_static_div-71 {
          justify-content: center;
          color: #4b5563;
          text-transform: uppercase;
          align-self: stretch;
          margin-top: 7px;
          white-space: nowrap;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-71 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_uniform_static_div-72 {
          justify-content: center;
          color: #9ca3af;
          white-space: nowrap;
          align-items: start;
          border: 1px solid #e5e7eb;
          background-color: #f3f4f6;
          align-self: stretch;
          margin-top: 12px;
          padding: 19px 13px;
          font: 400 16px Open Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-72 {
            white-space: initial;
            max-width: 100%;
          }
        }
        .school_uniform_static_div-73 {
          justify-content: center;
          color: #4b5563;
          text-transform: uppercase;
          align-self: stretch;
          margin-top: 7px;
          white-space: nowrap;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-73 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_uniform_static_div-74 {
          justify-content: center;
          color: #9ca3af;
          white-space: nowrap;
          align-items: start;
          border: 1px solid #e5e7eb;
          background-color: #f3f4f6;
          align-self: stretch;
          margin-top: 12px;
          padding: 19px 13px;
          font: 400 16px Open Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-74 {
            white-space: initial;
            max-width: 100%;
          }
        }
        .school_uniform_static_div-75 {
          justify-content: center;
          color: #4b5563;
          text-transform: uppercase;
          align-self: stretch;
          margin-top: 7px;
          white-space: nowrap;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-75 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_uniform_static_div-76 {
          justify-content: center;
          color: #9ca3af;
          white-space: nowrap;
          align-items: start;
          border: 1px solid #e5e7eb;
          background-color: #f3f4f6;
          align-self: stretch;
          margin-top: 12px;
          padding: 17px 13px;
          font: 400 16px Open Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-76 {
            white-space: initial;
            max-width: 100%;
          }
        }
        .school_uniform_static_div-77 {
          justify-content: center;
          color: #4b5563;
          text-transform: uppercase;
          align-self: stretch;
          margin-top: 7px;
          white-space: nowrap;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-77 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .school_uniform_static_div-78 {
          border: 1px solid #e5e7eb;
          background-color: #f3f4f6;
          align-self: stretch;
          display: flex;
          margin-top: 12px;
          height: 98px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-78 {
            max-width: 100%;
          }
        }
        .school_uniform_static_div-79 {
          border-radius: 3px;
          border: 1px solid #d3d3d3;
          box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
          background-color: #f9f9f9;
          align-self: start;
          display: flex;
          margin-top: 20px;
          width: 302px;
          max-width: 100%;
          flex-direction: column;
          padding: 9px 12px 9px 8px;
        }
        .school_uniform_static_div-80 {
          display: flex;
          width: 100%;
          align-items: start;
          justify-content: space-between;
          gap: 20px;
        }
        .school_uniform_static_div-81 {
          display: flex;
          margin-top: 12px;
          gap: 7px;
        }
        .school_uniform_static_div-82 {
          disply: flex;
          flex-direction: column;
          overflow: hidden;
          position: relative;
          display: flex;
          aspect-ratio: 1.2666666666666666;
          width: 38px;
          padding: 1px 5px;
        }
        .school_uniform_static_img-16 {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .school_uniform_static_div-83 {
          position: relative;
          border-radius: 2px;
          border: 2px solid #c1c1c1;
          background-color: #fff;
          display: flex;
          height: 28px;
          flex-direction: column;
        }
        .school_uniform_static_div-84 {
          justify-content: center;
          color: #000;
          align-self: center;
          white-space: nowrap;
          margin: auto 0;
          font: 400 14px/121% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-84 {
            white-space: initial;
          }
        }
        .school_uniform_static_div-85 {
          align-self: stretch;
          display: flex;
          flex-basis: 0%;
          flex-direction: column;
          align-items: center;
        }
        .school_uniform_static_img-17 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_uniform_static_div-86 {
          justify-content: center;
          color: #555;
          text-align: center;
          align-self: stretch;
          margin-top: 6px;
          white-space: nowrap;
          font: 400 10px/100% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-86 {
            white-space: initial;
          }
        }
        .school_uniform_static_div-87 {
          align-self: end;
          display: flex;
          margin-top: 5px;
          width: 55px;
          max-width: 100%;
          gap: 1px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-87 {
            justify-content: center;
          }
        }
        .school_uniform_static_div-88 {
          justify-content: center;
          color: #555;
          text-align: right;
          font: 400 8px Roboto, sans-serif;
        }
        .school_uniform_static_div-89 {
          justify-content: center;
          color: #000;
          text-align: right;
          align-self: center;
          margin: auto 0;
          font: 400 8px Roboto, sans-serif;
        }
        .school_uniform_static_div-90 {
          justify-content: center;
          color: #555;
          text-align: right;
          align-self: start;
          white-space: nowrap;
          font: 400 8px Roboto, sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-90 {
            white-space: initial;
          }
        }
        .school_uniform_static_div-91 {
          justify-content: center;
          color: #fff;
          text-align: center;
          letter-spacing: 0.35px;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          background-color: #fdd948;
          align-self: start;
          margin-top: 16px;
          width: 101px;
          max-width: 100%;
          padding: 15px 20px;
          font: 400 14px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-91 {
            white-space: initial;
          }
        }
        .school_uniform_static_div-92 {
          align-self: stretch;
          display: flex;
          margin-top: 12px;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .school_uniform_static_div-92 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_uniform_static_div-93 {
          align-self: start;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
          align-items: center;
        }
        .school_uniform_static_img-18 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 30px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_uniform_static_div-94 {
          justify-content: center;
          color: #6b7280;
          text-align: center;
          letter-spacing: 1.12px;
          text-transform: uppercase;
          align-self: stretch;
          margin-top: 11px;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_div-95 {
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
          align-items: center;
        }
        .school_uniform_static_img-19 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 30px;
          overflow: hidden;
          max-width: 100%;
        }
        .school_uniform_static_div-96 {
          justify-content: center;
          color: #6b7280;
          text-align: center;
          letter-spacing: 1.12px;
          text-transform: uppercase;
          align-self: stretch;
          margin-top: 11px;
          font: 600 11.2px/143% Open Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_uniform_static_img-20 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 30px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }




        .school_landing_div-205 {
          align-self: center;
          display: flex;
          margin-top: 33px;
          width: 100%;
          max-width: 1371px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 15px;
        }
        @media (max-width: 991px) {
          .school_landing_div-205 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_landing_div-206 {
          color: #292626;
          align-self: start;
          flex-grow: 1;
          flex-basis: auto;
          font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-207 {
          border-radius: 10px;
          border: 1px solid #b7a4a4;
          background-color: #d9d9d9;
          align-self: center;
          display: flex;
          width: 900px;
          height: 7px;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_landing_div-207 {
            max-width: 100%;
          }
        }
        .school_landing_div-208 {
          color: #292626;
          align-self: start;
          flex-grow: 1;
          flex-basis: auto;
          font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .school_landing_div-209 {
          align-self: center;
          display: flex;
          margin-top: 26px;
          width: 100%;
          max-width: 1391px;
          background: white;
          align-items: flex-start;
          gap: 9px;
        }
        @media (max-width: 991px) {
          .school_landing_div-209 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .school_landing_div-210 {
          disply: flex;
          flex-direction: column;
          overflow: hidden;
          align-self: stretch;
          position: relative;
          display: flex;
          aspect-ratio: 1.0067114093959733;
          padding: 250px 21px 12px 80px;
          background-image: url('https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FUniform%20Background%20Image.png?alt=media&token=af742bc7-8378-4eb6-b5dc-2d386e8066ac');
          background-size:     cover;    
          background-position: center center; 
        }
        @media (max-width: 991px) {
          .school_landing_div-210 {
            padding: 280px 250px 0 50px;
            
            width:100%
          }
        }
        .school_landing_div-211 {
          position: relative;
          color: #000;
          align-self: end;
          white-space: nowrap;
        
          text-align: center; 
          border-radius: 20px;
          background-color: #fff;
          width: 130px;
          max-width: 100%;
          padding: 5px;
          font: 400 16px Roboto, sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-211 {
            white-space: initial;
            padding:10px;
          }
        }
        .school_landing_div-212 {
          align-self: center;
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .school_landing_div-212 {
            width:100%;
            padding:10px;
          }
        }
        .school_landing_div-213 {
          align-self: end;
          display: flex;
          width: 253px;
          max-width: 100%;
          align-items: flex-start;
          gap: 6px;
        }
        @media (max-width: 991px) {
          .school_landing_div-213 {
            width:100%;
            align-self: center;
            align-items:center;
            justify-content:center;
          }
        }
        .school_landing_img-81 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 32px;
          overflow: hidden;
          align-self: stretch;
          max-width: 100%;
        }
        .school_landing_div-214 {
          color: #000;
          align-self: center;
          white-space: nowrap;
          margin: auto 0;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-214 {
            white-space: initial;
          }
        }
        .school_landing_div-215 {
          color: #000;
          opacity: 0.5;
          align-self: end;
          font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-215 {
            width:100%;
            align-self: center;
            text-align:center;
            align-items:center;
            justify-content:center;
          }
        }
        .school_landing_img-82 {
          aspect-ratio: 0.97;
          object-fit: contain;
          object-position: center;
          width: 70px;
          overflow: hidden;
          align-self: end;
          margin-top: 11px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .school_landing_img-82 {
            
            align-self: center;
            text-align:center;
            align-items:center;
            justify-content:center;
          }
        }
        .school_landing_div-216 {
          color: #000;
          align-self: end;
          margin-top: 29px;
          white-space: nowrap;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-216 {
            white-space: initial;
            align-self: center;
            text-align:center;
            align-items:center;
            justify-content:center;
          }
        }
        .school_landing_img-83 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 75px;
          overflow: hidden;
          align-self: end;
          margin-top: 19px;
          max-width: 100%;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .school_landing_img-83 {
            
            align-self: center;
            text-align:center;
            align-items:center;
            justify-content:center;
          }
        }
        .school_landing_div-217 {
          background-color: rgba(0, 0, 0, 0.3);
          align-self: stretch;
          width: 1px;
          height: 298px;
        }
        @media (max-width: 991px) {
          .school_landing_div-217 {
            display:none;
          }
        }
        .school_landing_div-218 {
          align-self: center;
          display: flex;
          flex-direction: column;
          margin: auto 0;
          padding-right: 15px;
        }
        @media (max-width: 991px) {
          .school_landing_div-218 {
          width:100%;
          padding-right: 0;
          }
        }
        .school_landing_div-219 {
          color: #000;
          align-self: end;
          white-space: nowrap;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-219 {
            white-space: initial;
            align-self: center;
            text-align:center;
            
          }
        }
        .school_landing_div-220 {
          align-self: end;
          display: flex;
          margin-top: 12px;
          width: 255px;
          max-width: 100%;
          padding-left: 10px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .school_landing_div-220 {
            padding-left: 0;
            width:100%;
            align-self: center;

          }
        }
        .school_landing_div-221 {
          color: #000;
          opacity: 0.5;

          align-self: end;
          font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-221 {
            align-self: center;
          text-align:center;

          }
        }
        .school_landing_img-84 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 80px;
          overflow: hidden;
          align-self: end;
          margin-top: 15px;
          max-width: 100%;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .school_landing_img-84 {
            align-self: center;
     

          }
        }
        .school_landing_div-222 {
          color: #000;
          align-self: end;
          margin-top: 26px;
          white-space: nowrap;
          font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .school_landing_div-222 {
            white-space: initial;
            align-self: center;
          }
        }
        .school_landing_img-85 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 75px;
          overflow: hidden;
          align-self: end;
          margin-top: 23px;
          max-width: 100%;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .school_landing_img-85 {
       
            align-self: center;
          }
        }
        .school_landing_div-223 {
          background-color: rgba(0, 0, 0, 0.3);
          align-self: stretch;
          width: 1px;
          height: 298px;
        }
        @media (max-width: 991px) {
          .school_landing_div-223 {
            display:none;
          }
        }
        .school_landing_div-224 {
          align-self: center;
          display: flex;
          flex-direction: column;
          width: 418px;
          margin: auto 0;
        }
        .school_landing_div-225 {
          align-self: stretch;
          z-index: 1;
          display: flex;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }

      `}</style>{" "}
    </>
  );
}


export default School_Uniform_Static_Details;


import React from 'react';
import './Design_Services_Page.css';
import { IoCall } from 'react-icons/io5';
function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);

    if (section) {
        // Calculate the offset from the top of the page
        const offset = 20;

        // Use the scrollTo method to scroll to the specified position
        window.scrollTo({
            top: section.offsetTop - offset,
            behavior: "smooth",
        });
    }
}


function Design_Page() {

  return (
    <div className='page_part'>
    < div className='static_page'>
        <div className = 'header_poster' >
            <div className='text_container'>
                <div className='top_title'>
                    Design Services
               </div>
                <div className='second_title'>
                    Let the professionals take it from here
                </div>
                <div className='title_message'>
                    Our community of trusted designers can create standout designs for every stage of your business journey.
                </div>
                <div className='page_section_migration_container'>
                    <div className='First_Button_Container'>
                    <a href="#section_2" className='First_Button'onClick={() => scrollToSection("section_2")}> Learn more </a> 
                        
                    </div>
                    <div className='second_button_container'>
                    <a href="#section_3" className='second_button'onClick={() => scrollToSection("section_3")}> Choose a product </a> 
                        
                    </div>
                </div>
            </div>
            <div className='header_image'>
            <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2Fheader-image.webp?alt=media&token=e1d29707-bf69-4ace-ad85-a7bd978c3e04&_gl=1*172tmm8*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQzOTU2OC4zNy4xLjE2OTc0Mzk2MDcuMjEuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>

            </div>
        </div>
        <div className='section_2' id = "section_2">
            <div className='section_2_title'>
            Collaborate with a designer
            </div>
            <div className='section_2_subtitle'>
            Getting professional design has never been easier. Here's how it's done:
            </div> 
            <div className='a'>
                <div></div>
        <div className='section_2_icons_container'>
            <div className='section_2_icons'>
                <div className='section_2_icon_image'>
                    <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>

                </div>
                <div className='section_2_icon_text'>
                Choose a product based on your unique needs.
                </div>
            </div>
            <div className='section_2_icons'>
                <div className='section_2_icon_image'>
                    <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>

                </div>
                <div className='section_2_icon_text'>
                Choose a product based on your unique needs.
                </div>
            </div>
            <div className='section_2_icons'>
                <div className='section_2_icon_image'>
                    <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>

                </div>
                <div className='section_2_icon_text'>
                Choose a product based on your unique needs.
                </div>
            </div>
            <div className='section_2_icons'>
                <div className='section_2_icon_image'>
                    <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>

                </div>
                <div className='section_2_icon_text'>
                Choose a product based on your unique needs.
                </div>
            </div>
        </div><div></div>
        </div>
        <div className='section_2_next_word'><a href="#section_3" className='section_2_next_word_tag'>Choose a product to get started </a></div>
    </div>
    <div className='section_3' id = "section_3">
        <div className='section_3_heading'>
        Choose what you need designed
        </div>
        <div className='section_3_subheading'>
        Browse custom design options to build your unique brand.
        </div>
        <div className = 'section_3_card_container'>
            <div className = 'section_3_cards'>
                <div className= 'section_3_card_image'>
                <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>

                </div>
                <div className='section_3_card_text'>
                    Business Card Design
                </div>
            </div>
            <div className = 'section_3_cards'>
                <div className= 'section_3_card_image'>
                <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>

                </div>
                <div className='section_3_card_text'>
                    Essential Logo Package
                </div>
            </div>
            <div className = 'section_3_cards'>
                <div className= 'section_3_card_image'>
                <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>

                </div>
                <div className='section_3_card_text'>
                    Flyer Design
                </div>
            </div>
            <div className = 'section_3_cards'>
                <div className= 'section_3_card_image'>
                <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>

                </div>
                <div className='section_3_card_text'>
                    Logo and Flyer Identity Pack
                </div>
            </div>
        </div>


    </div>
    <div className='section_4' id = "section_4">
            <div className='section_4_heading'>More design options</div>
            <div className='section_4_card_container'>
                    <div className='section_4_card'>
                        <div className='section_4_card_image'>
                        <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                        </div>
                        <div className='section_4_card_text'>
                            Folder Leaflet Design
                        </div>
                    </div>
                    <div className='section_4_card'>
                        <div className='section_4_card_image'>
                        <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                        </div>
                        <div className='section_4_card_text'>
                            Letterhead Design
                        </div>
                    </div>
                    <div className='section_4_card'>
                        <div className='section_4_card_image'>
                        <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                        </div>
                        <div className='section_4_card_text'>
                            Postcard Design
                        </div>
                    </div>
                    <div className='section_4_card'>
                        <div className='section_4_card_image'>
                        <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                        </div>
                        <div className='section_4_card_text'>
                            Retractable Banner Design
                        </div>
                    </div>
                    <div className='section_4_card'>
                        <div className='section_4_card_image'>
                        <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                        </div>
                        <div className='section_4_card_text'>
                            Return Address Label Design
                        </div>
                    </div>
                    <div className='section_4_card'>
                        <div className='section_4_card_image'>
                        <img src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F17.png?alt=media&token=a434c441-ff80-49df-936c-eae1ed967b59&_gl=1*1dwqrxf*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzMwNDAwMy4zNi4xLjE2OTczMDQ0NjQuNDUuMC4w"alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                        </div>
                        <div className='section_4_card_text'>
                            Wedding Invitation Design
                        </div>
                    </div>
            </div>
            <div className='section_5' id = "section_5">
                <div className='section_5_heading'>
                Still unsure?
                </div>
                <div className='sectiion_5_subheading'>
                <a href="tel:+917478004111" className='contact_number'>
                        <IoCall/>
                        < div>7478004111</div>
                        </a>
                </div>
            </div>
    </div>


    </div>

    </div>

  )
}

export default Design_Page
import React, { useContext, useState , useEffect} from 'react';
import { BillBooksContext } from './BillBooksData';
import { MedicalUniformsContext } from './Medical_Uniforms_Data';
import './Product_Test.css';
import { IoCall } from 'react-icons/io5';
import { SchoolCategoryContext } from './SchoolCategory_Data';
import { School_Category_UniformContext } from './School_Uniform_Display_Data';
import { School_Category_StationaryContext } from './School_Category_Stationary_Data';
import { Stationary_Display_Context } from './Stationary_Display_Data';
import { IDCardsContext } from './ID_Cards_Data';
import {Promotional_Gift_Display_Context}  from './Promotional_Gift_Display_Data';
import { ID_Cards_Display_Context } from './ID_Cards_Display_Data';
import { School_Category_AccessoriesContext } from './School_Accessories_DisplayData';
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { Writing_Supplies_Display_Context } from './Writing_Supplies_Display_Data';

const bg1 =
  "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F13.png?alt=media&token=d3ff9d9b-d65f-46b7-9efd-3da358150b6c";
const bg2 =
  "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2FEnhance%20your%20Organisations%20Identity%20viz%20Elegant%20Supply.png?alt=media&token=555f72d8-4bce-4eb3-9caa-08c37e7571e6";

function School_Supplies() {
  const [activeImageNum, setCurrent] = useState(0);

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrent(activeImageNum === 2 ? 0 : activeImageNum + 1);
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrent(activeImageNum === 0 ? 2 : activeImageNum - 1);
  };

  // Automatic slide change
  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, [activeImageNum]);

  const imageStyles = {
   width: "100%", // Set the width to adjust the image size
   height: '300px', // Maintain aspect ratio
 
 };
const { billbooks } = useContext(BillBooksContext);
const {medicaluniforms} = useContext(MedicalUniformsContext);
const {schoolcategory} = useContext(SchoolCategoryContext);
const {school_category_stationary} = useContext(Stationary_Display_Context );
const {idcards} = useContext(ID_Cards_Display_Context);
const {school_category_uniform} = useContext(School_Category_UniformContext);
const {school_category_accessories} = useContext(School_Category_AccessoriesContext);
const {promotional_gift_display} = useContext(Promotional_Gift_Display_Context);
const {writing_supplies_display} = useContext(Writing_Supplies_Display_Context)
console.log(school_category_accessories);
console.log(billbooks);
console.log(medicaluniforms);
console.log(schoolcategory);
console.log(school_category_uniform);
console.log(school_category_stationary);
console.log(idcards);
console.log(promotional_gift_display);
console.log(writing_supplies_display)
const [school_category_accessoriesIndex, setSchool_Category_AccessoriesIndex] = useState(0);
const [billbooksIndex, setBillBooksIndex] = useState(0); // State for BillBooks product display
const [medicaluniformsIndex, setMedicalUniformsIndex] = useState(0);
const [schoolcategoryIndex, setSchoolCategory] = useState(0);
const [school_category_uniformIndex, setSchool_Category_UniformIndex] = useState(0);
const [school_category_stationaryIndex, setSchool_Category_StationaryIndex] = useState(0);
const [idcardsIndex, setidcardsIndex] = useState(0);
/*const billbooksSlideStyle = {
    transform: `translateX(-${billbooksIndex * 5}%)`, // Adjust 'billbooksIndex' as needed
  };

  const medicaluniformsSlideStyle = {
    transform: `translateX(-${medicaluniformsIndex * 5}%)`, // Adjust 'medicaluniformsIndex' as needed
  };
*/
const productRedirects = {
  "School Uniform": "/Uniforms",
  "School Accessories": "/Accessories",
  "ID-Cards": "/ID-Cards",
  "Writing Supplies": "/Writing_Supplies",
  "Stationary": "/Stationary",



  // Add more mappings as needed
};

const school_category_accessoriescontainerWidth = 10;
const numschool_category_accessories = school_category_accessories.length;
const school_category_accessoriesWidth = school_category_accessoriescontainerWidth/(numschool_category_accessories-1);
const school_category_accessoriesSlideStyle = {
  transform: `translateX(-${school_category_accessoriesIndex *school_category_accessoriesWidth}%)`,
};

const idcardscontainerWidth = 10;
const numidcards = idcards.length;
const idcardsWidth = idcardscontainerWidth/(numidcards -1);
const idcardsSlideStyle = {
  transform: `translateX(-${idcardsIndex *idcardsWidth}%)`,
};


const school_category_stationarycontainerWidth = 10; // Adjust this to the width of your product container
const numschool_category_stationary = school_category_stationary.length; // Replace with the actual number of products
const school_category_stationaryWidth = school_category_stationarycontainerWidth / (numschool_category_stationary -1);


const school_category_stationarySlideStyle = {
    transform: `translateX(-${school_category_stationaryIndex *school_category_stationaryWidth}%)`,
  };
  const medicaluniformscontainerWidth = 10; // Adjust this to the width of your product container
const nummedicaluniforms = medicaluniforms.length; // Replace with the actual number of products
const medicaluniformsWidth = medicaluniformscontainerWidth / (nummedicaluniforms -1);
  

  const medicaluniformsSlideStyle = {
    transform: `translateX(-${medicaluniformsIndex * medicaluniformsWidth}%)`,
  };
const schoolcategorycontainerWidth = 10;
const numschoolcategory = schoolcategory.length;
const schoolcategoryWidth = schoolcategorycontainerWidth/(numschoolcategory -1);
const schoolcategorySlideStyle = {
    transform: `translateX(-${schoolcategoryIndex *schoolcategoryWidth}%)`,
};

const  school_category_uniformcontainerWidth = 10;
const numschool_category_uniform = school_category_uniform.length;
const  school_category_uniformWidth = school_category_uniformcontainerWidth/(numschool_category_uniform-1);
const school_category_uniformSlideStyle = {
    transform: `translateX(-${school_category_uniformIndex *school_category_uniformWidth}%)`,
};


// Function to handle moving to the previous product
/*const prevBillBooksSlide = () => {
    setBillBooksIndex((prevIndex) => (prevIndex === 0 ? billbooks.length - 1 : prevIndex - 1));
  };
  
  const nextBillBooksSlide = () => {
    setBillBooksIndex((prevIndex) => (prevIndex === billbooks.length - 1 ? 0 : prevIndex + 1));
  };  

  const prevMedicalUniformsSlide = () => {
    setMedicalUniformsIndex((prevIndex) => (prevIndex === 0 ? medicaluniforms.length - 1 : prevIndex - 1));
  };
  
  const nextMedicalUniformsSlide = () => {
    setMedicalUniformsIndex((prevIndex) => (prevIndex === medicaluniforms.length - 1 ? 0 : prevIndex + 1));
  };
  */

  const prevSchool_Category_StationarySlide = () => {
    setSchool_Category_StationaryIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1; // Move to the previous product if not at the beginning
      }
      return prevIndex; // Stay at the beginning
    });
  };

  // Function to slide to the next BillBooks product
  const nextSchool_Category_StationarySlide = () => {
    setSchool_Category_StationaryIndex((prevIndex) => {
      if (prevIndex < school_category_stationary.length - 1) {
        return prevIndex + 1; // Move to the next product if not at the end
      }
      return prevIndex; // Stay at the end
    });
  };

  // Function to slide to the previous Medical Uniforms product
  const previdcardsSlide = () => {
    setidcardsIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1; // Move to the previous product if not at the beginning
      }
      return prevIndex; // Stay at the beginning
    });
  };

  // Function to slide to the next Medical Uniforms product
  const nextidcardsSlide = () => {
    setidcardsIndex((prevIndex) => {
      if (prevIndex < medicaluniforms.length - 1) {
        return prevIndex + 1; // Move to the next product if not at the end
      }
      return prevIndex; // Stay at the end
    });
  };

  const prevSchool_Category_UniformSlide = () => {
    setSchool_Category_UniformIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1; // Move to the previous product if not at the beginning
      }
      return prevIndex; // Stay at the beginning
    });
  };

  // Function to slide to the next Medical Uniforms product
  const nextSchool_Category_UniformSlide = () => {
    setSchool_Category_UniformIndex((prevIndex) => {
      if (prevIndex < school_category_uniform.length - 1) {
        return prevIndex + 1; // Move to the next product if not at the end
      }
      return prevIndex; // Stay at the end
    });
  };

  const prevSchool_Category_AccessoriesSlide = () => {
    setSchool_Category_AccessoriesIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1; // Move to the previous product if not at the beginning
      }
      return prevIndex; // Stay at the beginning
    });
  };

  // Function to slide to the next Medical Uniforms product
  const nextSchool_Category_AccessoriesSlide = () => {
    setSchool_Category_AccessoriesIndex((prevIndex) => {
      if (prevIndex < school_category_accessories.length - 1) {
        return prevIndex + 1; // Move to the next product if not at the end
      }
      return prevIndex; // Stay at the end
    });
  };






return (
<div >
<div className='page_header_links'>
        <div className='pade_header_page_link_left'>
          <div className='page_header_about_us'>
            About Us
          </div>
          <div className='page_header_writing_customization'>
            Writing Customization
          </div>
          <div className='page_header_shipping_options'>
              Shipping Options
          </div>
        </div>
        <div className='page_header_blank_space'>

        </div>
        <div className='pade_header_page_link_right'>
          <div className='page_header_contact'>
            <IoCall/>
            < div>
              7478004111
            </div>
          </div>
          <div className='page_header_help'>
            Help
          </div>
        </div>

      </div>
{billbooks.length !== 0 && 
<section className='page-name-school'>
  <div className='school_page_heading'>
    School Items
  </div>
  <div className='school_page_sub_heading'>
   Discover our products and services
  </div>
</section>
}
<div className = 'category_display'>
<div className='category_heading' align= 'center'>
Source By Categories
</div>
<div className='category-card-container' align = 'center'>
{schoolcategory.map(product => (
      productRedirects[product.ProductName] ? (
        <Link to={productRedirects[product.ProductName]} className='category-card' key={product.ProductID}>
          <div className='category-img'>
            <img src={product.ProductImg} alt="not found" />
          </div>
          <div className='category-name'>
            {product.ProductName}
          </div>
        </Link>
      ) : (
        <div className='category-card' key={product.ProductID} onClick={() => alert(`No page defined for ${product.ProductName}`)}>
          <div className='category-img'>
            <img src={product.ProductImg} alt="not found" />
          </div>
          <div className='category-name'>
            {product.ProductName}
          </div>
        </div>
      )
    ))}
</div>
</div>
<div className='institute_section_page_container'>
<div className='institute_page_left_section'>
<div className="insitute_page_left_div">
        <div className="insitute_page_left_div-2">
          <div className="insitute_page_left_div-3">
            <div className="insitute_page_left_div-4">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/11240382-dd0d-4097-988d-6c1a5278cc73?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="insitute_page_left_img"
              />
              <div className="insitute_page_left_div-5">Institutes</div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e84ad001-e873-4895-b83b-e7599cd59dd7?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="insitute_page_left_img-2"
            />
          </div>
          <div className="insitute_page_left_div-6">
            <div className="insitute_page_left_div-7">School</div>
            <div className="insitute_page_left_div-8">Hospital</div>
          </div>
        </div>
        <div className="insitute_page_left_div-9">
          <div className="insitute_page_left_div-10">
            <div className="insitute_page_left_div-11">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a85b6a6-9dbf-4039-b5ce-d8b4168b5875?apiKey=db6f3fe714c542fe811ae992d63ea686&"
                className="img"
              />
              <div className="insitute_page_left_div-12">Resources</div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/17cb00ce-d240-41c4-9d69-98afe14ee90b?apiKey=db6f3fe714c542fe811ae992d63ea686&"
              className="insitute_page_left_img-3"
            />
          </div>
          <div className="insitute_page_left_div-13">
            <div className="insitute_page_left_div-14">Addresses</div>
            <div className="insitute_page_left_div-15">Comments</div>
            <div className="insitute_page_left_div-16">Posts</div>
            <div className="insitute_page_left_div-17">Purchases</div>
            <div className="insitute_page_left_div-18">Roles</div>
            <div className="insitute_page_left_div-19">Tags</div>
            <div className="insitute_page_left_div-20">Users</div>
          </div>
        </div>
      </div>
</div>
<div className='product_page_main_section_container'>
<div className='product_page_main_section'>

<div className='product_display'>
<div>
<div className='product_heading'>
School Uniforms
</div>

<div className='show_more'>
  <Link to = "/Uniforms" >Show More</Link>
</div>
</div>
<div className='divider-line'></div>

<div className='product-sliding-container'>
{/* "Prev" button */}

{/* Products container */}
<div className='product-slides'>
{school_category_uniform.map((product, index) => (
  
<Link to={`/SchoolUniform/${product.ProductID}`} className={`product-sliding-card ${index === school_category_uniformIndex ? 'active' : ''}`} style={{ textDecoration: 'none' }} key={product.ProductID} >
<div className='product-sliding-img'>
<img src={product.ProductImg} alt="not found" />
</div>
<div className='product-sliding-name ellipsis'>
{product.ProductName}
</div>
<div className='product-sliding-price'>
{product.ProductPrice}
</div>
</Link>

))}
</div>
{/* "Next" button */}

</div>

</div>




























<div className='product_display'>
<div>
<div className='product_heading'>
School Uniforms
</div>

<div className='show_more'>
  <Link to = "/Uniforms" >Show More</Link>
</div>
</div>
<div className='divider-line'></div>

<div className='product-sliding-container'>
{/* "Prev" button */}

{/* Products container */}
<div className='product-slides'>
{school_category_uniform.map((product, index) => (
  
<Link to={`/SchoolUniform/${product.ProductID}`} className={`product-sliding-card ${index === school_category_uniformIndex ? 'active' : ''}`} style={{ textDecoration: 'none' }} key={product.ProductID} >
<div className='product-sliding-img'>
<img src={product.ProductImg} alt="not found" />
</div>
<div className='product-sliding-name ellipsis'>
{product.ProductName}
</div>
<div className='product-sliding-price'>
{product.ProductPrice}
</div>
</Link>

))}
</div>
{/* "Next" button */}

</div>

</div>
<div className='slider_display'>
<img src = 'https://s.alicdn.com/@img/imgextra/i3/O1CN01kEePec1Cf3Gmp6s22_!!6000000000107-0-tps-2880-640.jpg'/>
        </div>

<div className='product_display'>
  <div className='product_heading'>
  School Stationary
  </div>
  <div className='show_more'>
  <Link to = "/Stationary" >
  Show More
  </Link>
</div>
  <div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides' >
      {school_category_stationary.map((product, index) => (
      <Link to={`/Stationary/${product.ProductID}`} className={`product-sliding-card ${index === school_category_stationaryIndex ? 'active' : ''}`} key={product.ProductID}>
        <div className='product-sliding-img'>
          <img src={product.ProductImg} alt="not found" />
        </div>
        <div className='product-sliding-name ellipsis'>
          {product.ProductName}
        </div>
        <div className='product-sliding-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>
      {/* "Next" button */}

  </div>

</div>
<div className='slider_display'>
<img src = 'https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F18.png?alt=media&token=8cb14725-0468-4eca-9cde-3fabade49983&_gl=1*ihs115*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0OTA0MDAuMzYuMC4w'/>
        </div>
<div className='product_display'>
  <div className='product_heading'>
  School ID Cards
  </div>
  <div className='show_more'>
  <Link to = "/ID-Cards" >
  Show More
  </Link>
</div>
  <div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides' >
      {idcards.map((product, index) => (
        
        <Link to={`/ID-Cards/${product.ProductID}`}  className={`product-sliding-card ${index === idcardsIndex ? 'active' : ''}`} key={product.ProductID}>
            
            <div className='product-sliding-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>

          <div className='product-sliding-name ellipsis'>
            {product.ProductName}
        </div>

        <div className='product-sliding-price'>{product.ProductPrice}</div>
        
      </Link>
      ))}
    </div>
      {/* "Next" button */}

  </div>

</div>
<div className='product_display'>
  <div className='product_heading'>
  Promtional Items
  </div>
  <div className='show_more'>
  <Link to = "/Promotional_Gifts" >
  Show More
  </Link>
</div>
  <div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}
 
    {/* Products container */}
    <div className='product-slides'>
      {promotional_gift_display.map((product, index) => (
        
        <Link to={`/Promotional_Gifts/${product.ProductID}`}  className={`product-sliding-card ${index === idcardsIndex ? 'active' : ''}`} key={product.ProductID}>
            <div className='product-sliding-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
          <div className='product-sliding-name ellipsis'>
            {product.ProductName}
        </div>
        <div className='product-sliding-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>
      {/* "Next" button */}

  </div>

</div>

<div className='slider_display'>
<img src = 'https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F12.png?alt=media&token=b8217bf4-2f37-4022-8a6c-86c18d22f3ae&_gl=1*1xaridc*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0OTE0OTMuNTAuMC4w'/>
        </div>
<div className='product_display'>
  <div className='product_heading'>
  Writing Supplies
  </div>
  <div className='show_more'>
  <Link to = "/Writing_Supplies" >

    
  Show More
  </Link>
</div>
  <div className='divider-line'></div>
  < div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides' >
      {writing_supplies_display.map((product, index) => (
        
        <Link to={`/Writing_Supplies/${product.ProductID}`}  className={`product-sliding-card ${index === idcardsIndex ? 'active' : ''}`} key={product.ProductID}>
            <div className='product-sliding-img'>
              <img src={product.ProductImg} alt="not found" />
            </div>
          <div className='product-sliding-name ellipsis'>
            {product.ProductName}
        </div>
        <div className='product-sliding-price'>{product.ProductPrice}</div>
      </Link>
      ))}
    </div>
      {/* "Next" button */}

  </div>

</div>

<div className='product_display'>
  <div className='product_heading'>
    School Accessories
  </div>
  <div className='show_more'>
  <Link to = "/School_Acessories" >
  Show More
  </Link>
</div>
  <div className='divider-line'></div>
  <div className='product-sliding-container'>
    {/* "Prev" button */}

    {/* Products container */}
    <div className='product-slides'>
      {school_category_accessories.map((product, index) => (
        <Link to={`/Accessories/${product.ProductID}`} className={`product-sliding-card ${index === school_category_accessoriesIndex ? 'active' : ''}`} key={product.ProductID}>
          <div className='product-sliding-img'>
            <img src={product.ProductImg} alt="not found" />
          </div>
          <div className='product-sliding-name ellipsis'>
            {product.ProductName}
          </div>
          <div className='product-sliding-price'>{product.ProductPrice}</div>
        </Link>
      ))}
    </div>
    {/* "Next" button */}


  </div>

</div>
</div>
</div>
<div className='institute_page_right_section'>
<div className="insitute_page_custom_div">
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9230bd3a-be60-4b31-9f3f-d648327a780c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9230bd3a-be60-4b31-9f3f-d648327a780c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9230bd3a-be60-4b31-9f3f-d648327a780c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9230bd3a-be60-4b31-9f3f-d648327a780c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9230bd3a-be60-4b31-9f3f-d648327a780c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9230bd3a-be60-4b31-9f3f-d648327a780c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9230bd3a-be60-4b31-9f3f-d648327a780c?apiKey=db6f3fe714c542fe811ae992d63ea686&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9230bd3a-be60-4b31-9f3f-d648327a780c?apiKey=db6f3fe714c542fe811ae992d63ea686&"
          className="insitute_page_custom_img"
        />
        <div className="insitute_page_custom_div-2">
          <div className="insitute_page_custom_div-3">
            <div className="insitute_page_custom_column">
              <div className="insitute_page_custom_div-4">
                <div className="insitute_page_custom_div-5">
                  An easy way to send products inquiry to us
                </div>
                <div className="insitute_page_custom_div-6">
                  Raise a query for products curated just for you
                </div>
              </div>
            </div>
            <div className="insitute_page_custom_column-2">
              <div className="insitute_page_custom_div-7">

                <div className="insitute_page_custom_div-8">Send an inquiry to us</div>
                <div className="insitute_page_custom_div-9">
                  <div className="insitute_page_custom_div-10">
                    
                    What item you need?

                  </div>
                </div>
                <div className="insitute_page_custom_div-12">

                  <div className="insitute_page_custom_div-13">Type more details</div>

                </div>
                <div className="insitute_page_custom_div-14">
                  <div className="insitute_page_custom_div-15">
                    <div className="insitute_page_custom_div-16">

                      Contact Info
                    </div>
                  </div>
 
                </div>
                <div className="insitute_page_custom_div-18">Send inquiry</div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
</div>
<div className="div-185">
          <div className="div-186">
            <div className="div-187">
              <div className="column-7">
                <div className="div-188">
                  <div className="div-189">
                    <div className="column-8">
                      <div className="div-190">
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="img-36"
                        />{" "}
                        <div className="div-191">
                          Best information about the company gies here but now
                          lorem ipsum is
                        </div>{" "}
                        <img
                          loading="lazy"
                          srcSet="..."
                          className="img-37"
                        />
                      </div>
                    </div>{" "}
                    <div className="column-9">
                      <div className="div-192">
                        <div className="div-193">About</div>{" "}
                        <div className="div-194">About Us</div>{" "}
                        <div className="div-195">Find store</div>{" "}
                        <div className="div-196">Categories</div>{" "}
                        <div className="div-197">Blogs</div>
                      </div>
                    </div>{" "}
                    <div className="column-10">
                      <div className="div-198">
                        <div className="div-199">Partnership</div>{" "}
                        <div className="div-200">About Us</div>{" "}
                        <div className="div-201">Find store</div>{" "}
                        <div className="div-202">Categories</div>{" "}
                        <div className="div-203">Blogs</div>
                      </div>
                    </div>{" "}
                    <div className="column-11">
                      <div className="div-204">
                        <div className="div-205">Information</div>{" "}
                        <div className="div-206">Help Center</div>{" "}
                        <div className="div-207">Money Refund</div>{" "}
                        <div className="div-208">Shipping</div>{" "}
                        <div className="div-209">Contact us</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="column-12">
                <div className="div-210">
                  <div className="div-211">
                    <div className="div-212">For users</div>{" "}
                    <div className="div-213">Login</div>{" "}
                    <div className="div-214">Register</div>{" "}
                    <div className="div-215">Settings</div>{" "}
                    <div className="div-216">My Orders</div>
                  </div>{" "}
                  <div className="div-217">
                    <div className="div-218">Get app</div>{" "}
                    <img
                      loading="lazy"
                      srcSet="..."
                      className="img-38"
                    />{" "}
                    <img
                      loading="lazy"
                      srcSet="..."
                      className="img-39"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="div-219">
            <div className="div-220">
              <div className="div-221">© 2023 Ecommerce. </div>{" "}
              <div className="div-222">
                <img
                  loading="lazy"
                  srcSet="..."
                  className="img-40"
                />{" "}
                <div className="div-223">English</div>{" "}
                <img
                  loading="lazy"
                  srcSet="..."
                  className="img-41"
                />
              </div>
            </div>
          </div>
        </div>


</div>
)
}

export default School_Supplies;
import React, {useEffect} from "react";
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function Business_Design() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
                  <Helmet>
        <title>Boost Visibility, Ignite Impact: School Advertisement Solutions by Custoking</title>
        <meta name="description" content="Unlock powerful advertising options for schools at Custoking. From banners to promotional gifts, amplify your school's outreach and make a lasting impression. Explore advertising solutions curated for your needs." />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div className="business_div">
        <div className="business_div-2">
          <div className="business_div-3">One stop solution for all your needs!</div>
          <div className="business_div-4">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1d9f4948-b51e-4ced-b1c7-2c1c1b0f9145?"
              className="business_img"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4534f06-f06b-4d3b-9eab-8e1d3d6363bc?"
              className="business_img"
            />
            <div className="business_div-5" />
          </div>
        </div>

        <div className="business_div-12">
          <div className="business_div-13">What do you need designed?</div>
          <div className="business_div-14">
            <Link to = "/Logo_Identity" style={{ textDecoration: 'none' }}>
            <div className="business_div-15">Logo & identity </div>
            </Link>
            <Link to = "/Web_Design" style={{ textDecoration: 'none' }}>
            <div className="business_div-16">Web & app design</div>
            </Link>
            <Link to = "/Business_Design"  style={{ textDecoration: 'none' }}>
            <div className="business_div-17">Business & advertising</div>
            </Link>
            <Link to = "/Apparel_Design" style={{ textDecoration: 'none' }}>
            <div className="business_div-18">Clothing & merchandise</div>
            </Link>
          </div>
          <div className="business_div-19">
            <div className="business_div-20">
              <div className="business_column">
                <div className="business_div-21">
                  <img
                    loading="lazy"
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2029.png?alt=media&token=51bbe03d-2e06-42d8-a551-b6289cbb111a"
                    className="business_img-2"
                  />
                  <div className="business_div-22">Postcard, flyer or print</div>
                  <div className="business_div-23">
                    Filers and postcards that
                    <br />
                    reach clients
                  </div>
                </div>
              </div>
              <div className="business_column-2">
                <div className="business_div-24">
                  <div className="business_div-25">
                    <img
                      loading="lazy"
                      srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2053.png?alt=media&token=03ba8f91-276a-4957-80c7-95355f5dea94"
                      className="business_img-3"
                    />
                    <div className="business_div-26">Leaflet</div>
                    <div className="business_div-27">
                      Leaflet designs that
                      <br />
                      illustrate your story
                    </div>
                  </div>
                </div>
              </div>
              <div className="business_column-3">
                <div className="business_div-28">
                  <div className="business_div-29">
                    <div className="business_column-4">
                      <div className="business_div-30">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2028.png?alt=media&token=fe65d49b-d886-4c87-b19c-670e0c535d49"
                          className="business_img-4"
                        />
                        <div className="business_div-31">Direct Mail</div>
                        <div className="business_div-32">
                          Direct mail design that <br />
                          speaks to recipients
                        </div>
                      </div>
                    </div>
                    <div className="business_column-5">
                      <div className="business_div-33">
                        <img
                          loading="lazy"
                          srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2027.png?alt=media&token=0a41ec92-6ee0-4057-a0eb-0a14e043d751"
                          className="business_img-5"
                        />
                        <div className="business_div-34">
                          <div className="business_div-35">Flyer</div>
                          <div className="business_div-36">
                            Custom flyer designs that make information
                            <br />
                            beautiful
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="business_div-37">
            <div className="business_div-38">
              <div className="business_column-6">
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2055.png?alt=media&token=62083f9e-206b-4355-90fb-17c0577d2352"
                  className="business_img-6"
                />
              </div>
              <div className="business_column-7">
                <div className="business_div-39">
                  <div className="business_div-40">
                    <div className="business_div-41">
                      <div className="business_column-8">
                        <div className="business_div-42">
                          <img
                            loading="lazy"
                            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2073.png?alt=media&token=60e18393-5dd7-40e3-9da1-2f15d1b72883"
                            className="business_img-7"
                          />
                          <div className="business_div-43">Poster</div>
                          <div className="business_div-44">
                            A popping poster that entices viewers
                          </div>
                        </div>
                      </div>
                      <div className="business_column-9">
                        <div className="business_div-45">
                          <img
                            loading="lazy"
                            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2072.png?alt=media&token=07785846-a0c2-4279-9c74-e7d453dc75a6"
                            className="business_img-8"
                          />
                          <div className="business_div-46">
                            <div className="business_div-47">Album Cover</div>
                            <div className="business_div-48">
                              An album cover that rocks
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business_div-49">
                    <div className="business_div-50">
                      <div className="business_column-10">
                        <div className="business_div-51">
                          <img
                            loading="lazy"
                            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2054.png?alt=media&token=d412a520-c6e8-4c49-8dd4-efbb905deced"
                            className="business_img-9"
                          />
                          <div className="business_div-52">Podcast</div>
                          <div className="business_div-53">
                            Custom podcast art that get the word out
                          </div>
                        </div>
                      </div>
                      <div className="business_column-11">
                        <div className="business_div-54">
                          <img
                            loading="lazy"
                            srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2073(1).png?alt=media&token=8b7a7871-2464-4dc3-b139-c66200c7df94"
                            className="business_img-10"
                          />
                          <div className="business_div-55">
                            <div className="business_div-56">Infographics</div>
                            <div className="business_div-57">
                              An engaging infographic <br />
                              that both shows and tells
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="business_div-58">
            <div className="business_div-59">
              <div className="business_column-12">
                <div className="business_div-60">
                  <img
                    loading="lazy"
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%202023-10-23%20163154%203.png?alt=media&token=bb505ea6-1b3c-461d-af0f-b36f06e4715c"
                    className="business_img-11"
                  />
                  <div className="business_div-61">Brochure</div>
                  <div className="business_div-62">
                    The Printable, foldable way to engage with clients
                  </div>
                </div>
              </div>
              <div className="business_column-13">
                <div className="business_div-63">
                  <img
                    loading="lazy"
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2026.png?alt=media&token=cd0932f2-e204-40ef-acbf-fbed68a7addd"
                    className="business_img-12"
                  />
                  <div className="business_div-64">Booklet</div>
                  <div className="business_div-65">
                    A booklet that tells your brand’s story
                  </div>
                </div>
              </div>
              <div className="business_column-14">
                <div className="business_div-66">
                  <img
                    loading="lazy"
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2058.png?alt=media&token=a46eb9d9-9736-41c3-be4f-c962c5723b90"
                    className="business_img-13"
                  />
                  <div className="business_div-67">
                    <div className="business_div-68">Pamphlet</div>
                    <div className="business_div-69">
                      A pamphlet that delivers all the info you need
                    </div>
                  </div>
                </div>
              </div>
              <div className="business_column-15">
                <div className="business_div-70">
                  <img
                    loading="lazy"
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2056.png?alt=media&token=73715888-067a-4a5a-ad01-0e9f9628f13c"
                    className="business_img-14"
                  />
                  <div className="business_div-71">
                    <div className="business_div-72">Car, truck or van wrap</div>
                    <div className="business_div-73">
                      A vehicle wrap to take advertising on the road
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="business_div-74">
            <div className="business_div-75">
              <div className="business_column-16">
                <div className="business_div-76">
                  <img
                    loading="lazy"
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%202023-10-23%20163154%202.png?alt=media&token=09fad4cb-57f4-41aa-b8b2-d8f3407699d4"
                    className="business_img-15"
                  />
                  <div className="business_div-77">Signage</div>
                  <div className="business_div-78">
                    A sign or banner to get
                    <br />
                    your brand noticed
                  </div>
                </div>
              </div>
              <div className="business_column-17">
                <div className="business_div-79">
                  <img
                    loading="lazy"
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2025.png?alt=media&token=3fd025c6-9023-4583-88db-f1c51a8a17ae"
                    className="business_img-16"
                  />
                  <div className="business_div-80">Billboard</div>
                  <div className="business_div-81">
                    Billboard design that projects your message
                  </div>
                </div>
              </div>
              <div className="business_column-18">
                <div className="business_div-82">
                  <img
                    loading="lazy"
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2059.png?alt=media&token=6972f0c7-fd8c-4070-b024-822b305eda76"
                    className="business_img-17"
                  />
                  <div className="business_div-83">
                    <div className="business_div-84">
                      Trade Show <br />
                      Banner
                    </div>
                    <div className="business_div-85">
                      A custom banner design
                      <br />
                      to draw visitors to your booth
                    </div>
                  </div>
                </div>
              </div>
              <div className="business_column-19">
                <div className="business_div-86">
                  <img
                    loading="lazy"
                    srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2057.png?alt=media&token=05516d95-a1ae-4e7b-a8cf-60fd4eb38413"
                    className="business_img-18"
                  />
                  <div className="business_div-87">Banner</div>
                  <div className="business_div-88">Banners to fly your brand high</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="business_div-89">
          <div className="business_div-90">
            <div className="business_column-20">
              <div className="business_div-91">
                <img
                  loading="lazy"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FScreenshot%202023-10-23%20163154%201.png?alt=media&token=c5c55e2f-70ea-4c65-9ed4-706595e4761e"
                  className="business_img-19"
                />
                <div className="business_div-92">
                  <div className="business_div-93">Email</div>
                  <div className="business_div-94">
                    Custom email templates <br />
                    that users will open
                  </div>
                </div>
              </div>
            </div>
            <div className="business_column-21">
              <div className="business_div-95">
                <div className="business_div-96">
                  <div className="business_column-22">
                    <div className="business_div-97">
                      <img
                        loading="lazy"
                        srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2020.png?alt=media&token=95d52d01-76e1-4069-971d-8a7da9862ba3"
                        className="business_img-20"
                      />
                      <div className="business_div-98">Email Newsletter</div>
                      <div className="business_div-99">
                        An email newsletter
                        <br />
                        template designed to be
                        <br />
                        opened
                      </div>
                    </div>
                  </div>
                  <div className="business_column-23">
                    <div className="business_div-100">
                      <img
                        loading="lazy"
                        srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2019.png?alt=media&token=0121ce9e-af34-4cfe-ace6-da8ea42fcbc7"
                        className="business_img-21"
                      />
                      <div className="business_div-101">
                        <div className="business_div-102">PowerPoint Template</div>
                        <div className="business_div-103">
                          Power Point templates custom designed to rock the
                          boardroom
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="business_column-24">
                    <div className="business_div-104">
                      <img
                        loading="lazy"
                        srcSet="https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2Fimage%2018.png?alt=media&token=b2630660-ce03-4a8f-aa7a-872466b1470b"
                        className="business_img-22"
                      />
                      <div className="business_div-105">
                        <div className="business_div-106">Banner</div>
                        <div className="business_div-107">
                          Appealing menu design that
                          <br />
                          will leave you salivating
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="business_div-108">
          <div className="business_div-109">
            Still haven’t found what you’re looking for? Search and you shall
            find
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8edd972-3770-4701-819e-a2435db60270?"
            className="business_img-23"
          />
        </div>
        <div className="business_div-110">
          <div className="business_div-111">
            <div className="business_div-112">
              <div className="business_column-25">
                <div className="business_div-113">
                  <div className="business_div-114">
                    <div className="business_column-26">
                      <div className="business_div-115">
                        <div className="business_div-116">Company</div>
                        <div className="business_div-117">About</div>
                        <div className="business_div-118">Contact</div>
                        <div className="business_div-119">Careers</div>
                        <div className="business_div-120">Team</div>
                        <div className="business_div-121">Press releases</div>
                        <div className="business_div-122">In the media</div>
                        <div className="business_div-123">Testimonals</div>
                      </div>
                    </div>
                    <div className="business_column-27">
                      <div className="business_div-124">
                        <div className="business_div-125">Design services</div>
                        <div className="business_div-126">Design contests</div>
                        <div className="business_div-127">1-to-1 projects</div>
                        <div className="business_div-128">Find a designer</div>
                        <div className="business_div-129">Discover inspiration</div>
                        <div className="business_div-130">Pricing</div>
                        <div className="business_div-131">Agencies</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="business_column-28">
                <div className="business_div-132">
                  <div className="business_div-133">
                    <div className="business_column-29">
                      <div className="business_div-134">
                        <div className="business_div-135">Get a design</div>
                        <div className="business_div-136">Logo design</div>
                        <div className="business_div-137">Business card</div>
                        <div className="business_div-138">Web page design</div>
                        <div className="business_div-139">Brand guide</div>
                        <div className="business_div-140">Packaging design</div>
                        <div className="business_div-141">T-Shirt design</div>
                        <div className="business_div-142">Book cover design</div>
                        <div className="business_div-143">Browse all categories</div>
                      </div>
                    </div>
                    <div className="business_column-30">
                      <div className="business_div-144">
                        <div className="business_div-145">Resources</div>
                        <div className="business_div-146">Become a designer</div>
                        <div className="business_div-147">Blog</div>
                        <div className="business_div-148">Awards</div>
                        <div className="business_div-149">Affiliates</div>
                        <div className="business_div-150">Logo - ideas</div>
                        <div className="business_div-151">T-shirt ideas</div>
                        <div className="business_div-152">Designer resources</div>
                        <div className="business_div-153">Featured Partners</div>
                        <div className="business_div-154">Help</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="business_div-155">
            <div className="business_div-156">Help?</div>
          </div>
          <div className="business_div-157" />
        </div>
      </div>
      <style jsx>{`
        .business_div {
          background-color: #fff;
          display: flex;
          flex-direction: column;
        }
        .business_div-2 {
          align-self: center;
          display: flex;
          margin-top: 38px;
          width: 100%;
          max-width: 993px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .business_div-2 {
            max-width: 100%;
            flex-wrap: wrap;
            margin-top:0;
          }
        }
        .business_div-3 {
          color: #000;
          text-align: center;
          align-self: stretch;
          max-width: 1038px;
          flex-grow: 1;
          flex-basis: auto;
          font: 300 36px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-3 {
            max-width: 100%;
          }
        }
        .business_div-4 {
          align-self: stretch;
          display: flex;
          width: 185px;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .business_div-4 {
            justify-content: center;
          }
        }
        .business_img {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          flex: 1;
        }
        .business_div-5 {
          border-radius: 50%;
          display: flex;
          height: 36px;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: auto;
          flex: 1;
        }
        .business_div-6 {
          background-color: #d9d9d9;
          align-self: stretch;
          display: flex;
          margin-top: 27px;
          width: 100%;
          flex-direction: column;
          padding: 77px 20px 58px;
        }
        @media (max-width: 991px) {
          .business_div-6 {
            max-width: 100%;
          }
        }
        .business_div-7 {
          align-self: center;
          display: flex;
          width: 100%;
          max-width: 993px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .business_div-7 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .business_div-8 {
          color: #000;
          text-align: center;
          align-self: start;
          margin-top: 4px;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .business_div-9 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .business_div-10 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .business_div-11 {
          color: #000;
          text-align: center;
          align-self: start;
          max-width: 383px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 36px Inter, sans-serif;
        }
        .business_div-12 {
            align-self: center;
            display: flex;
          
            width: 100%;
            max-width: 1395px;
            flex-direction: column;
            padding: 20px 20px 0  20px;
        }
        @media (max-width: 991px) {
          .business_div-12 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-13 {
            color: #000;
            text-align: center;
            align-self: start;
            max-width: 800px;
            font: 600 30px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-13 {
            max-width: 100%;
          }
        }
        .business_div-14 {
            align-self: center;
            display: flex;
            width: 100%;
            height: 60px;
            align-items: stretch; /* Change to stretch to make all items equal height */
            justify-content: space-between; /* Equally space the items */
          
            
            margin: 30px 20px 0 20px;
        }
        @media (max-width: 991px) {
          .business_div-14 {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin: 40px 1px 0 0;
            height:auto;
          }
        }
        .business_div-15 {
            color: #000;
            height: 80px;
            border-radius: 10px;
            background-color: #d9d9d9;
            width: 300px;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center; /* Center vertically */
            text-align: center; /* Center horizontally */
            padding: 15px;
            font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-15 {
            padding-left: 1px;
          }
        }
        @media (max-width: 640px) {
          .business_div-15 {
            width: 304px;
          }
        }
        .business_div-16 {
            color: #000;
            height: 80px;
            border-radius: 10px;
            background-color: #d9d9d9;
            width: 300px;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center; /* Center vertically */
            text-align: center; /* Center horizontally */
            padding: 15px;
            font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .business_div-16 {
            width: 316px;
          }
        }
        .business_div-17 {
            color: #000;
            height: 80px;
            border-radius: 10px;
            background-color: #d9d9d9;
            width: 300px;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center; /* Center vertically */
            text-align: center; /* Center horizontally */
            padding: 15px;
            font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .business_div-17 {
            width: 310px;
          }
        }
        .business_div-18 {
            color: #000;
            height: 80px;
            border-radius: 10px;
            background-color: #d9d9d9;
            width: 300px;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center; /* Center vertically */
            text-align: center; /* Center horizontally */
            padding: 15px;
            font: 600 25px Inter, sans-serif;
        }
        @media (max-width: 640px) {
          .business_div-18 {
            width: 334px;
          }
        }
        .business_div-19 {
            align-self: center;
            display: flex;
            margin-top: 100px;
            width: 100%;
            max-width: 1387px;
            flex-direction: column;
            padding: 0 20px;
        }
        @media (max-width: 991px) {
          .business_div-19 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-20 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-20 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 21%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .business_div-21 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .business_div-21 {
            margin-top: 40px;
          }
        }
        .business_img-2 {
          aspect-ratio: 0.97;
          object-fit: contain;
          object-position: center;
          width: 112px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-22 {
          color: #000;
          margin-top: 33px;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-23 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 19px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 26%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-2 {
            width: 100%;
          }
        }
        .business_div-24 {
          display: flex;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: space-between;
          gap: 5px;
        }
        @media (max-width: 991px) {
          .business_div-24 {
            margin-top: 40px;
          }
        }
        .business_div-25 {
          align-self: center;
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        .business_img-3 {
          aspect-ratio: 1.54;
          object-fit: contain;
          object-position: center;
          width: 156px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-26 {
          color: #000;
          text-align: center;
          margin: 45px 0 0 30px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-26 {
            margin: 40px 0 0 10px;
          }
        }
        .business_div-27 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 46px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-27 {
            margin-top: 40px;
          }
        }
        .business_column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-3 {
            width: 100%;
          }
        }
        .business_div-28 {
          margin-top: 130px;
        }
        @media (max-width: 991px) {
          .business_div-28 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-29 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-29 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 46%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-4 {
            width: 100%;
          }
        }
        .business_div-30 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-30 {
            margin-top: 40px;
          }
        }
        .business_img-4 {
          aspect-ratio: 1.1;
          object-fit: contain;
          object-position: center;
          width: 126px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-31 {
          color: #000;
          text-align: center;
          margin-top: 22px;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-32 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 48px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-32 {
            margin-top: 40px;
          }
        }
        .business_column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 54%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-5 {
            width: 100%;
          }
        }
        .business_div-33 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-33 {
            margin-top: 40px;
          }
        }
        .business_img-5 {
          aspect-ratio: 1.13;
          object-fit: contain;
          object-position: center;
          width: 130px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-34 {
          align-self: start;
          display: flex;
          margin-top: 21px;
          width: 273px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        .business_div-35 {
          color: #000;
          text-align: center;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-36 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 44px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-36 {
            margin-top: 40px;
          }
        }
        .business_div-37 {
          align-self: center;
          margin-top: 36px;
          width: 100%;
          max-width: 1299px;
        }
        @media (max-width: 991px) {
          .business_div-37 {
            max-width: 100%;
          }
        }
        .business_div-38 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-38 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-6 {
            width: 100%;
          }
        }
        .business_img-6 {
          aspect-ratio: 0.8;
          object-fit: contain;
          object-position: center;
          width: 571px;
          overflow: hidden;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .business_img-6 {
            margin-top: 40px;
          }
        }
        .business_column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-7 {
            width: 100%;
          }
        }
        .business_div-39 {
          display: flex;
          margin-top: 49px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-39 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-40 {
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .business_div-40 {
            max-width: 100%;
          }
        }
        .business_div-41 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-41 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-8 {
            width: 100%;
          }
        }
        .business_div-42 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-42 {
            margin-top: 40px;
          }
        }
        .business_img-7 {
          aspect-ratio: 0.98;
          object-fit: contain;
          object-position: center;
          width: 116px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-43 {
          color: #000;
          align-self: start;
          margin: 17px 0 0 26px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-43 {
            margin-left: 10px;
          }
        }
        .business_div-44 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 37px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-9 {
            width: 100%;
          }
        }
        .business_div-45 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-45 {
            margin-top: 40px;
          }
        }
        .business_img-8 {
          aspect-ratio: 0.98;
          object-fit: contain;
          object-position: center;
          width: 114px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-46 {
          align-self: start;
          display: flex;
          margin-top: 21px;
          width: 268px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        .business_div-47 {
          color: #000;
          align-self: stretch;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-48 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 39px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_div-49 {
          align-self: stretch;
          margin-top: 173px;
        }
        @media (max-width: 991px) {
          .business_div-49 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-50 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-50 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-10 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 51%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-10 {
            width: 100%;
          }
        }
        .business_div-51 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-51 {
            margin-top: 40px;
          }
        }
        .business_img-9 {
          aspect-ratio: 1.14;
          object-fit: contain;
          object-position: center;
          width: 136px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-52 {
          color: #000;
          align-self: start;
          margin-top: 34px;
          max-width: 300px;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-53 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 38px;
          max-width: 290px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_column-11 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 49%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-11 {
            width: 100%;
          }
        }
        .business_div-54 {
          display: flex;
          margin-top: 18px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-54 {
            margin-top: 40px;
          }
        }
        .business_img-10 {
          aspect-ratio: 1.55;
          object-fit: contain;
          object-position: center;
          width: 149px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-55 {
          align-self: start;
          display: flex;
          margin-top: 38px;
          width: 281px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        .business_div-56 {
          color: #000;
          align-self: stretch;
          max-width: 300px;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-57 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 33px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_div-58 {
          align-self: center;
          margin-top: 145px;
          width: 100%;
          max-width: 1278px;
        }
        @media (max-width: 991px) {
          .business_div-58 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-59 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-59 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-12 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-12 {
            width: 100%;
          }
        }
        .business_div-60 {
          display: flex;
          margin-top: 13px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-60 {
            margin-top: 40px;
          }
        }
        .business_img-11 {
          aspect-ratio: 2.08;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        .business_div-61 {
          color: #000;
          margin-top: 43px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-61 {
            margin-top: 40px;
          }
        }
        .business_div-62 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 81px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-62 {
            margin-top: 40px;
          }
        }
        .business_column-13 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-13 {
            width: 100%;
          }
        }
        .business_div-63 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-63 {
            margin-top: 40px;
          }
        }
        .business_img-12 {
          aspect-ratio: 1.35;
          object-fit: contain;
          object-position: center;
          width: 168px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-64 {
          color: #000;
          margin: 43px 0 0 29px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-64 {
            margin: 40px 0 0 10px;
          }
        }
        .business_div-65 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 69px;
          max-width: 246px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-65 {
            margin-top: 40px;
          }
        }
        .business_column-14 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 27%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-14 {
            width: 100%;
          }
        }
        .business_div-66 {
          display: flex;
          margin-top: 19px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-66 {
            margin-top: 40px;
          }
        }
        .business_img-13 {
          aspect-ratio: 1.63;
          object-fit: contain;
          object-position: center;
          width: 176px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-67 {
          align-self: end;
          display: flex;
          margin-top: 51px;
          width: 248px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-67 {
            margin-top: 40px;
          }
        }
        .business_div-68 {
          color: #000;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-69 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 51px;
          max-width: 246px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-69 {
            margin-top: 40px;
          }
        }
        .business_column-15 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 23%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-15 {
            width: 100%;
          }
        }
        .business_div-70 {
          display: flex;
          margin-top: 14px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-70 {
            margin-top: 40px;
          }
        }
        .business_img-14 {
          aspect-ratio: 1.25;
          object-fit: contain;
          object-position: center;
          width: 141px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-71 {
          align-self: start;
          display: flex;
          width: 228px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
          margin: 33px 0 0 15px;
        }
        @media (max-width: 991px) {
          .business_div-71 {
            margin-left: 10px;
          }
        }
        .business_div-72 {
          color: #000;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-73 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 32px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_div-74 {
          align-self: center;
          margin-top: 112px;
          width: 100%;
          max-width: 1277px;
        }
        @media (max-width: 991px) {
          .business_div-74 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-75 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-75 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-16 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 24%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-16 {
            width: 100%;
          }
        }
        .business_div-76 {
          display: flex;
          margin-top: 4px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-76 {
            margin-top: 40px;
          }
        }
        .business_img-15 {
          aspect-ratio: 0.98;
          object-fit: contain;
          object-position: center;
          width: 106px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-77 {
          color: #000;
          margin-top: 41px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-77 {
            margin-top: 40px;
          }
        }
        .business_div-78 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 38px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_column-17 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 24%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-17 {
            width: 100%;
          }
        }
        .business_div-79 {
          display: flex;
          margin-top: 8px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-79 {
            margin-top: 40px;
          }
        }
        .business_img-16 {
          aspect-ratio: 0.99;
          object-fit: contain;
          object-position: center;
          width: 115px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-80 {
          color: #000;
          margin-top: 29px;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-81 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 44px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-81 {
            margin-top: 40px;
          }
        }
        .business_column-18 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 27%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-18 {
            width: 100%;
          }
        }
        .business_div-82 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-82 {
            margin-top: 40px;
          }
        }
        .business_img-17 {
          aspect-ratio: 0.83;
          object-fit: contain;
          object-position: center;
          width: 93px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-83 {
          align-self: start;
          display: flex;
          margin-top: 33px;
          width: 251px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        .business_div-84 {
          color: #000;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-85 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 19px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_column-19 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 26%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-19 {
            width: 100%;
          }
        }
        .business_div-86 {
          display: flex;
          margin-top: 8px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-86 {
            margin-top: 40px;
          }
        }
        .business_img-18 {
          aspect-ratio: 1.18;
          object-fit: contain;
          object-position: center;
          width: 141px;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-87 {
          color: #000;
          margin: 26px 0 0 15px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-87 {
            margin-left: 10px;
          }
        }
        .business_div-88 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 44px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-88 {
            margin-top: 40px;
          }
        }
        .business_div-89 {
            align-self: stretch;
            margin-top: 124px;
            width: 100%;
            display: flex;
            padding: 0 20px;
            max-width: 1395px;
            margin: 0 auto 0 auto; /* Center the container horizontally and keep top margin */
          }
          
          @media (max-width: 991px) {
            .business_div-89 {
              max-width: 100%;
              margin: 40px auto 0 auto; /* Center the container horizontally and adjust top margin for smaller screens */
            }
          }
        .business_div-90 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-90 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-20 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 25%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-20 {
            width: 100%;
          }
        }
        .business_div-91 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .business_div-91 {
            margin-top: 40px;
          }
        }
        .business_img-19 {
          aspect-ratio: 3.85;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: end;
        }
        .business_div-92 {
          align-self: end;
          display: flex;
          margin-top: 35px;
          width: 265px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        .business_div-93 {
          color: #000;
          margin-left: 39px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-93 {
            margin-left: 10px;
          }
        }
        .business_div-94 {
          color: #7a7a7a;
          align-self: start;
          margin-top: 36px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_column-21 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 75%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-21 {
            width: 100%;
          }
        }
        .business_div-95 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .business_div-95 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-96 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-96 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-22 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-22 {
            width: 100%;
          }
        }
        .business_div-97 {
          display: flex;
          margin-top: 24px;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-97 {
            margin-top: 40px;
          }
        }
        .business_img-20 {
          aspect-ratio: 3.83;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        .business_div-98 {
          color: #000;
          margin-top: 44px;
          font: 600 27px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-98 {
            margin-top: 40px;
          }
        }
        .business_div-99 {
          color: #7a7a7a;
          align-self: center;
          margin-top: 36px;
          max-width: 285px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_column-23 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 34%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-23 {
            width: 100%;
          }
        }
        .business_div-100 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-100 {
            margin-top: 16px;
          }
        }
        .business_img-21 {
          aspect-ratio: 3.04;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: end;
        }
        .business_div-101 {
          align-self: end;
          display: flex;
          margin-top: 28px;
          width: 311px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        .business_div-102 {
          color: #000;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-103 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 11px;
          max-width: 285px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_column-24 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-24 {
            width: 100%;
          }
        }
        .business_div-104 {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }
        @media (max-width: 991px) {
          .business_div-104 {
            margin-top: 40px;
          }
        }
        .business_img-22 {
          aspect-ratio: 4.61;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
          align-self: end;
        }
        .business_div-105 {
          align-self: end;
          display: flex;
          margin-top: 36px;
          width: 305px;
          max-width: 100%;
          flex-grow: 1;
          flex-direction: column;
        }
        .business_div-106 {
          color: #000;
          font: 600 27px Inter, sans-serif;
        }
        .business_div-107 {
          color: #7a7a7a;
          align-self: stretch;
          margin-top: 36px;
          max-width: 285px;
          font: 400 21px/143% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .business_div-108 {
          align-self: center;
          display: flex;
          margin-top: 217px;
          width: 100%;
          max-width: 1115px;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .business_div-108 {
            max-width: 100%;
            margin-top: 40px;
            flex-wrap: wrap;
          }
        }
        .business_div-109 {
          color: #000;
          align-self: start;
          margin-top: 7px;
          max-width: 1177px;
          flex-grow: 1;
          flex-basis: auto;
          font: 600 32px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-109 {
            max-width: 100%;
          }
        }
        .business_img-23 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 39px;
          fill: #fff;
          stroke-width: 3px;
          stroke: #000;
          overflow: hidden;
          align-self: start;
          max-width: 100%;
        }
        .business_div-110 {
          background-color: #f3f2f0;
          align-self: stretch;
          display: flex;
          margin-top: 221px;
          width: 100%;
          flex-direction: column;
          padding: 75px 20px 187px;
        }
        @media (max-width: 991px) {
          .business_div-110 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-111 {
          align-self: center;
          width: 100%;
          max-width: 1176px;
        }
        @media (max-width: 991px) {
          .business_div-111 {
            max-width: 100%;
          }
        }
        .business_div-112 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-112 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-25 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-25 {
            width: 100%;
          }
        }
        .business_div-113 {
        }
        @media (max-width: 991px) {
          .business_div-113 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-114 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-114 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-26 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 47%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-26 {
            width: 100%;
          }
        }
        .business_div-115 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-115 {
            margin-top: 40px;
          }
        }
        .business_div-116 {
          color: #000;
          font: 600 28px Inter, sans-serif;
        }
        .business_div-117 {
          color: #000;
          margin-top: 41px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-117 {
            margin-top: 40px;
          }
        }
        .business_div-118 {
          color: #000;
          margin-top: 32px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-119 {
          color: #000;
          margin-top: 32px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-120 {
          color: #000;
          margin-top: 33px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-121 {
          color: #000;
          margin-top: 33px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-122 {
          color: #000;
          margin-top: 32px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-123 {
          color: #000;
          margin-top: 32px;
          font: 300 24px Inter, sans-serif;
        }
        .business_column-27 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 53%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-27 {
            width: 100%;
          }
        }
        .business_div-124 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-124 {
            margin-top: 40px;
          }
        }
        .business_div-125 {
          color: #000;
          font: 600 28px Inter, sans-serif;
        }
        .business_div-126 {
          color: #000;
          margin-top: 40px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-127 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-128 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-129 {
          color: #000;
          align-self: stretch;
          margin-top: 27px;
          white-space: nowrap;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-129 {
            white-space: initial;
          }
        }
        .business_div-130 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-131 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_column-28 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-28 {
            width: 100%;
          }
        }
        .business_div-132 {
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .business_div-132 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .business_div-133 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .business_div-133 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .business_column-29 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .business_column-29 {
            width: 100%;
          }
        }
        .business_div-134 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-134 {
            margin-top: 40px;
          }
        }
        .business_div-135 {
          color: #000;
          font: 600 28px Inter, sans-serif;
        }
        .business_div-136 {
          color: #000;
          margin-top: 42px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-136 {
            margin-top: 40px;
          }
        }
        .business_div-137 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-138 {
          color: #000;
          margin-top: 32px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-139 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-140 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-141 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-142 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-143 {
          color: #000;
          margin-top: 28px;
          font: 300 24px Inter, sans-serif;
        }
        .business_column-30 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .business_column-30 {
            width: 100%;
          }
        }
        .business_div-144 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .business_div-144 {
            margin-top: 40px;
          }
        }
        .business_div-145 {
          color: #000;
          font: 600 28px Inter, sans-serif;
        }
        .business_div-146 {
          color: #000;
          margin-top: 48px;
          font: 300 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .business_div-146 {
            margin-top: 40px;
          }
        }
        .business_div-147 {
          color: #000;
          margin-top: 29px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-148 {
          color: #000;
          margin-top: 26px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-149 {
          color: #000;
          margin-top: 31px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-150 {
          color: #000;
          margin-top: 33px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-151 {
          color: #000;
          margin-top: 27px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-152 {
          color: #000;
          margin-top: 32px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-153 {
          color: #000;
          margin-top: 33px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-154 {
          color: #000;
          margin-top: 26px;
          font: 300 24px Inter, sans-serif;
        }
        .business_div-155 {
          border-radius: 30px;
          background-color: rgba(0, 0, 0, 0.76);
          align-self: start;
          display: flex;
          margin-top: 50px;
          width: 194px;
          max-width: 100%;
          flex-direction: column;
          padding: 18px 20px 11px;
        }
        @media (max-width: 991px) {
          .business_div-155 {
            margin: 40px 0 0 -3px;
          }
        }
        .business_div-156 {
          color: #fff;
          align-self: center;
          width: 100%;
          font: 600 40px Inter, sans-serif;
        }
        .business_div-157 {
          background-color: #000;
          align-self: stretch;
          min-height: 1px;
          width: 100%;
          margin: 27px -20px -37px 0;
        }
        @media (max-width: 991px) {
          .business_div-157 {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
      `}</style>
    </>
  );
}

export default Business_Design;
import React, { createContext, useState, useEffect } from 'react';
import { db } from './Firebase';

export const HospitalCategoryContext = createContext();

export const HospitalCategoryContextProvider = ({ children }) => {
  const [hospitalcategory, setHospitalCategory] = useState([]); // Corrected the state variable name

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('Hospital Categories').get();
        const hospitalcategoryData = snapshot.docs.map((doc) => ({
          ProductID: doc.id,
          ProductImg: doc.data().Image,
          ProductName: doc.data().Name,
        }));
        setHospitalCategory(hospitalcategoryData); // Corrected the state setter function
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <HospitalCategoryContext.Provider value={{ hospitalcategory }}>
      {children}
    </HospitalCategoryContext.Provider>
  );
};

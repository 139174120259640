
import generateProductPage from './Generate_Product_Page';
import { School_Category_StationaryContext } from './School_Category_Stationary_Data';
import { IoCall } from 'react-icons/io5';

import { Helmet } from 'react-helmet';
import './Product_Test.css';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';



const AdditionalElements = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
           <>
      <Helmet>
        <title>Stationary</title>
        <meta name="description" content="Get all stationary items here" />
        {/* Add more meta tags as needed */}
      </Helmet>
      {/* Other content of your component */}
    </>
      {/* Add your additional elements here */}
      <h1 className='product_page_heading'>Stationary</h1>
      <div className='product_page_heading_display'>
        <div className='heading_display_left'>
          
          <img  src = "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F12.png?alt=media&token=b8217bf4-2f37-4022-8a6c-86c18d22f3ae"/>
        </div>
        <div className='heading_display_cards'>
          <div className='heading_page_card_display'>
          <div className='product_page_heading_display_image'>
            <img  src= "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FSchool%20Uniforms.webp?alt=media&token=77396c2a-3f17-4486-959d-a988a1874bfe"/>
          </div>
            <div   className='product_page_heading_display_txt'  >School Uniform</div>
          </div>
          <div className='heading_page_card_display'>
          <div className='product_page_heading_display_image'>
            <img  src= "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FSchool%20Uniforms.webp?alt=media&token=77396c2a-3f17-4486-959d-a988a1874bfe"/>
          </div>
            <div   className='product_page_heading_display_txt' >School Uniform</div>
          </div>
          <div className='heading_page_card_display_right'>
          <div className='product_page_heading_display_image'>
            <img  src= "https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FFigma%20Images%2FSchool%20Uniforms.webp?alt=media&token=77396c2a-3f17-4486-959d-a988a1874bfe"/>
            </div>
            <div   className='product_page_heading_display_txt'  >School Uniform</div>
          </div>
        </div>
        <div className='heading_display_right'>
          <div className='heading_display_right_txt'>CustoKing’s Best Seller</div>
          <div className='heading_display_right_contact_div'>
          <a className='heading_display_right_contact_us_bg' href="tel:+917478004111">
          <a className='heading_display_right_contact_us'  href="tel:+917478004111">Contact Us</a>
          </a>
          </div>
        </div>
      </div>
      
    </div>
  );
};


const Product_Formatting = generateProductPage(School_Category_StationaryContext , 'Stationary', 'school_category_stationary');
const School_Category_Stationary = () => {
  return (
    <>
      <AdditionalElements />
      <Product_Formatting />
    </>
  );
};


export default School_Category_Stationary;



